import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import { Flex } from '@aws-amplify/ui-react';

export const DSM_LIGHTER_CERISE = '#F2CEE4';
export const DSM_OCEAN_GREEN = '#009B97';
export const DSM_LIME_GREEN = '#45AC34';
export const DSM_LIME_GREEN_LIGHT = '#DAEED6';
export const DSM_LIGHTEST_LIME_GREEN = '#ECF7EB';
export const DSM_DARK_GREEN = '#007E4B';
const DSM_GREEN_DARKEST = '#00653C';
export const DSM_LIGHTER_OCEAN_GREEN = '#CCEBEA';
export const DSM_LIGHTER_GREEN = '#CCE5DB';
export const DSM_LIGHTER_BLUE = '#CCD6E4';
export const DSM_MID_BLUE = '#0070BA';
export const DSM_CYAN = '#0070BA';
const DSM_CYAN_LIGHTER = '#009FE3';
export const DSM_BLUE = '#003478';
const DSM_PURPLE = '#4E67A7';
const DSM_DARK_PURPLE = '#421651';
export const DSM_MAGENTA = '#BD0A79';

export const DSM_RED = '#E51F22';
export const DSM_LIGHTER_RED = '#FAD2D3';
export const DSM_LIGHTEST_RED = '#FCE9E9';
export const DSM_LIGHTER_ORANGE = '#FCE3CD';
export const DSM_MAIN_SELECTED_COLOR = '#F0F7FB';
const DSM_ORANGE = '#EE7203';
const DSM_DARK_ORANGE = '#BE5B02';
const DSM_SOFT_BLACK = '#1b1b1b';
export const DMS_TRANSPARENT = 'transparent';
export const DSM_BLACK = '#000000';
export const DSM_WHITE = '#FFFFFF';

// Neutrals
export const DSM_DARK_GREY = '#808080';
const DSM_GREY = '#E0E0E0';
const DSM_LIGHT_GREY = '#F0F0F0';

export const DSM_DISABLED_LABEL = 'rgba(0, 0, 0, 0.38)';

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Fira Sans', 'sans-serif'].join(','),
      fontSize: 14,
      color: DSM_SOFT_BLACK,
      body1: {
        // paddingTop: '10px',
        fontSize: '14px',
        lineHeight: '24px',
        color: DSM_SOFT_BLACK,
      },
      h1: {
        fontSize: '96px',
        lineHeight: '112px',
        color: DSM_DARK_GREEN,
      },
      h2: {
        fontSize: '60px',
        lineHeight: '72px',
        color: DSM_DARK_GREEN,
      },
      h3: {
        fontSize: '48px',
        lineHeight: '56px',
        color: DSM_DARK_GREEN,
      },
      h4: {
        fontWeight: 'medium',
        fontSize: '34px',
        lineHeight: '42px',
        color: DSM_DARK_GREEN,
      },
      h5: {
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: '500',
        color: DSM_DARK_GREEN,
      },
      h6: {
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '32px',
        color: DSM_DARK_GREEN,
      },
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        light: DSM_LIME_GREEN,
        main: DSM_DARK_GREEN,
        dark: DSM_OCEAN_GREEN,
        contrastText: '#fff', // will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: DSM_CYAN,
        main: DSM_MID_BLUE,
        dark: DSM_BLUE,
        contrastText: '#fff', // will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: DSM_RED,
        // dark: will be calculated from palette.primary.main,
        contrastText: '#fff', // will be calculated to contrast with palette.primary.main
      },
      warning: {
        // light: will be calculated from palette.primary.main,
        main: DSM_ORANGE,
        // dark: will be calculated from palette.primary.main,
        contrastText: '#fff', // will be calculated to contrast with palette.primary.main
      },
      grey: {
        light: DSM_LIGHT_GREY,
        main: DSM_GREY,
        dark: DSM_DARK_GREY,
        contrastText: '#FFF',
      },
      info: {
        main: DSM_CYAN,
      },
      success: {
        main: DSM_LIME_GREEN,
      },
      text: {
        default: DSM_SOFT_BLACK,
        primary: DSM_SOFT_BLACK,
        secondary: DSM_DARK_GREY,
      },
      textPrimary: DSM_SOFT_BLACK,
      textSecondary: '#FFFFFF',
      textWhite: '#FFFFFF',
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: false, // No more ripple, on the whole application 💣!
      },
    },

    overrides: {
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: DSM_LIME_GREEN,
          },
          '&$active': {
            color: DSM_MID_BLUE,
          },
          color: DSM_DARK_GREY,
        },
        active: {},
        completed: {},
        text: {
          fontSize: '1rem',
        },
      },
      MuiButton: {
        containedPrimary: {
          '&:hover': {
            // you want this to be the same as the backgroundColor above
            backgroundColor: `${DSM_DARK_GREEN} !important`,
          },
        },
        label: {
          fontSize: '15px',
        },
      },
      MuiTableCell: {
        root: {
          fontSize: '14px !important',
        },
        head: {
          fontWeight: 'bold',
        },
      },
      MuiTableRow: {
        root: {
          '&:nth-child(2n)': {
            backgroundColor: '#F6F8F8',
          },
          '&:not(.MuiTableRow-head)': {
            '&:hover': {
              backgroundColor: '#DDECEC !important',
            },
          },
        },
      },
      MuiChip: {
        label: {
          fontSize: '13px',
          lineHeight: '18px',
          paddingLeft: '10px',
          paddingRight: '10px',
        },
      },
      MuiDialog: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.35)',
        },
        paper: {
          padding: '40px',
        },
      },
      MuiPaper: {
        root: {
          boxShadow: '0px 0px 30px 0px #0000001A !important',
        },
      },
      MuiDialogTitle: {
        root: {
          fontSize: '18px !important',
          fontWeight: 'bold',
          color: DSM_SOFT_BLACK,
          lineHeight: '28px',
          paddingBottom: 0,
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '1em',
          color: 'white',
          backgroundColor: '#333',
        },
      },
    },
  }
);

// additional styles for templatePage
export const commonBtnStyles = makeStyles((theme) => ({
  normalBtn: {
    color: theme.palette.primary.light,
  },
}));

// additional styles for menu items
export const headerMenusStyles = makeStyles((theme) => ({
  companyMenu: {
    lineHeight: '55px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  companyMenuItem: {
    color: `${theme.typography.color} !important`,
    fontSize: '14px',
    marginLeft: '30px',
    '&:hover': {
      color: `${theme.palette.error} !important`,
    },
  },
  mainMenuContainer: {
    height: '112px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainMenu: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'end',
    padding: 0,
    textAlign: 'center',
  },
  mainMenuItem: {
    display: 'flex',
    alignItems: 'center',
    color: `${theme.palette.primary.main} !important`,
    // small screen setting first
    // background: 'red',
    boxSizing: 'border-box',
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: '18px !important',

    '&.active': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      paddingTop: '1px',
    },
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      paddingTop: '1px',
      cursor: 'hand',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '13px',
      marginLeft: '24px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '14px',
      marginLeft: '48px !important',
      '&.active': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingTop: '2px',
      },
      '&:hover': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        paddingTop: '2px',
        cursor: 'hand',
      },
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '16px',
      marginLeft: '48px !important',
      '&.active': {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        paddingTop: '3px',
      },
      '&:hover': {
        color: `${theme.palette.primary.light}`,
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        paddingTop: '3px',
        cursor: 'hand',
      },
    },
  },
  mainMenuItemIcon: {
    boxSizing: 'border-box',
    // lineHeight: '109px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    minWidth: '40px',
    color: `${theme.palette.primary.main} !important`,
    fontSize: '24px !important',
    marginLeft: '32px',
    '&:hover': {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      paddingTop: '3px',
      cursor: 'pointer',
    },
  },
  mainMenuItemProfile: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: '24px',
  },
  breadCrumbMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: '30px',
    marginBottom: '30px',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
  },
  breadCrumbMenuItem: {
    boxSizing: 'border-box',
    color: `${theme.palette.primary.main} !important`,
    paddingRight: '10px',
    textDecoration: 'underline',
    '&:hover': {
      color: `${theme.palette.primary.main}`,
    },
  },
  breadCrumbMenuDivider: {
    color: `${theme.palette.primary.main} !important`,
    paddingRight: '10px',
  },
}));

// additional styles for templatePage
export const templateStyles = makeStyles((theme) => ({
  mainCard: {
    minHeight: '250px',
    padding: '30px',
    marginBottom: '1rem !important',
  },
  lightCardSubtitle: {
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    color: `${DSM_DARK_GREY} !important`,
    marginTop: '2rem !important',
    marginBottom: '0.5rem !important',
    '&.error': {
      color: `${DSM_RED} !important`,
    },
  },
  cardTable: {
    marginTop: '18px',
  },
  iconStyles: {
    marginLeft: '20px',
    paddingRight: '5px',
    height: '24px',
    width: '24px',
    cursor: 'pointer',
  },
}));

// Compound Feed styles
export const compoundFeedsStyles = makeStyles((theme) => ({
  title: {
    color: `${DSM_BLUE} !important`,
    fontWeight: 500,
  },
  description: {
    color: `${DSM_DARK_GREY} !important`,
    paddingBottom: '10px',
  },
  tableTitle: {
    color: DSM_SOFT_BLACK,
    lineHeight: `24px !important`,
  },
  tableDescription: {
    color: `${DSM_DARK_GREY} !important`,
  },
  searchInput: {
    width: '95%',
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0070ba !important',
      },
    },
  },
  table: {
    border: `1px solid ${DSM_LIGHT_GREY}`,
    background: DSM_WHITE,
    borderRadius: '0px !important',
  },
  emptyState: {
    margin: '50px !important',
  },
  tabTitle: {
    color: '#1B1B1B !important',
  },
}));

export const footprintMainCardStyles = makeStyles((theme) => ({
  mainCard: {
    minHeight: '250px',
    padding: '30px',
    marginBottom: '1rem !important',
  },
}));

// Footprint Tabs styles
export const footprintTabsStyles = makeStyles((theme) => ({
  tabsBox: {
    borderBottom: `1px solid ${DSM_LIGHT_GREY}`,
    paddingTop: '30px',
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#005A95',
    },
    '& .MuiTab-root': {
      minWidth: '150px',
      padding: '6px 1px',
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#005A95',
    },
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start !important',
      fontSize: '14px !important',
      lineHeight: '24px !important',
      fontWeight: 500,
      textTransform: 'initial',
    },
  },
}));

export const beefTabsStyles = makeStyles((theme) => ({
  tabsBox: {
    borderBottom: `1px solid ${DSM_LIGHT_GREY}`,
    paddingTop: '0px',
    marginBottom: '20px',
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#005A95',
    },
    '& .MuiTab-root': {
      minWidth: '115px',
      padding: '0',
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#005A95',
    },
    '& .MuiTab-wrapper': {
      alignItems: 'center !important',
      fontSize: '14px !important',
      lineHeight: '24px !important',
      fontWeight: 500,
      textTransform: 'initial',
    },
  },
}));

// Footprint details styles
export const footprintDetailsStyles = makeStyles((theme) => ({
  footprintDetailsTitle: {
    fontSize: '20px !important',
    lineHeight: '24px !important',
    paddingTop: '30px',
    color: DSM_SOFT_BLACK,
  },
  footprintDetailsDescription: {
    fontSize: '14px !important',
    lineHeight: '24px !important',
    color: DSM_DARK_GREY,
  },
  buttonStyles: {
    '& .MuiButton-label': {
      textTransform: 'initial',
      fontSize: '14px',
    },
  },
  linkButtonStyles: {
    '& .MuiButton-label': {
      fontSize: '14px !important',
      lineHeight: '24px !important',
      fontWeight: 400,
      color: `${DSM_MID_BLUE} !important`,
      textDecoration: 'underline !important',
      textTransform: 'initial',
    },
  },
  accordionStyles: {
    '& .MuiExpansionPanel-root': {
      position: 'unset',
    },
    '& .MuiExpansionPanel-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiExpansionPanelSummary-root.Mui-expanded': {
      minHeight: '0',
    },
    '& .MuiExpansionPanelSummary-expandIcon': {
      transform: 'rotate(-180deg)',
      color: DSM_SOFT_BLACK,
    },
    '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(-180deg)',
      color: DSM_BLUE,
    },
  },
  accordionSummaryStyles: {
    padding: '0px 10px !important',
    background: '#FFFFFF !important',
    '& .MuiExpansionPanelSummary-content': {
      margin: 0,
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 500,
    },
    '& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetailsStyles: {
    padding: '0 0 0 16px !important',
  },
  accordionFormLableStyles: {
    '& .MuiTypography-body1': {
      fontWeight: '500 !important',
    },
  },
  customizeViewFilterMenuTitleStyles: {
    fontSize: '20px !important',
    lineHeight: '24px !important',
    paddingBottom: '10px !important',
  },
  dividerStyles: {
    marginTop: '10px',
    backgroundColor: DSM_LIGHT_GREY,
  },
  menuPaperStyles: {
    '& .MuiMenu-paper': {
      maxHeight: '418px !important',
      width: '240px !important',
      marginTop: '10px !important',
      border: `1px solid ${DSM_LIGHT_GREY} !important`,
      '& .MuiList-padding': {
        paddingBottom: 0,
      },
    },
  },
  compareButtonsStyles: {
    padding: '25px 0 0 25px !important',
    marginLeft: '-3px !important',
    '& button': {
      height: '35px',
      width: ' 35px',
      padding: 0,
      minWidth: '35px !important',
    },
  },
  swapButtonStyle: {
    color: `${DSM_MID_BLUE} !important`,
    border: `1px solid ${DSM_MID_BLUE} !important`,
  },
  closeButtonStyle: {
    float: 'right',
    background: `${DSM_RED} !important`,
    color: '#FFFFFF !important',
  },
  svgIconColorStyles: {
    color: DSM_DARK_GREY,
  },
  placeholderStyle: {
    color: DSM_DARK_GREY,
  },
}));

// Footprint category tables styles
export const footprintCategoryTableStyles = makeStyles((theme) => ({
  table: {
    borderRadius: '4px',
    border: `1px solid ${DSM_LIGHT_GREY}`,
  },
  tableCellTitle: {
    fontSize: '14px !important',
    lineHeight: '24px !important',
    color: `${DSM_SOFT_BLACK} !important`,
  },
  emissionsTableCellTitle: {
    fontSize: '14px !important',
    lineHeight: '24px !important',
    color: `${DSM_MID_BLUE} !important`,
  },
  tableCellDescription: {
    fontSize: '12px !important',
    lineHeight: '16px !important',
    color: `${DSM_DARK_GREY} !important`,
  },
  menuStyles: {
    '& .MuiPaper-root': {
      boxShadow: '0px 0px 1px 0px #e0e0e0 !important',
    },
  },
  menuItemText: {
    paddingLeft: 10,
  },
  tableErrorCell: {
    border: `1px solid ${DSM_RED}`,
    background: DSM_LIGHTEST_RED,
    color: `${DSM_RED} !important`,
    borderRadius: '16px',
    padding: '2px 12px',
    textAlign: 'center',
  },
  tableSuccessCell: {
    border: `1px solid #4EBC33`,
    background: '#ECF7EB',
    color: '#4EBC33 !important',
    borderRadius: '16px',
    padding: '2px 12px',
    textAlign: 'center',
  },
}));

// Footprint box tables styles
export const footprintBoxStyles = makeStyles((theme) => ({
  footprintCategoryBox: {
    width: '280px',
    height: '153px',
    background: DSM_MID_BLUE,
    borderRadius: '4px',
    padding: '20px',
  },
  footprintCategoryTitle: {
    fontSize: '12px !important',
    lineHeight: '16px !important',
    color: 'white !important',
  },
  footprintCategoryVale: {
    fontSize: '40px !important',
    lineHeight: '16px !important',
    color: 'white !important',
    paddingTop: '25px',
    width: '230px',
  },
  footprintCategoryValeForSmall: {
    fontSize: '16px !important',
    lineHeight: '8px !important',
    color: 'white !important',
    paddingTop: '15px',
    width: '130px',
  },
  footprintCategoryConversationText: {
    fontSize: '14px !important',
    lineHeight: '24px !important',
    color: 'white !important',
    paddingTop: '20px',
  },
  footprintCategoryConversationTextForSmall: {
    fontSize: '11px !important',
    lineHeight: '12px !important',
    color: 'white !important',
    paddingTop: '10px',
  },
  footprintCategoryUnitDescription: {
    fontSize: '10px !important',
    lineHeight: '16px !important',
    color: 'white !important',
    fontWeight: 400,
  },
  footprintCategoryDescription: {
    fontSize: '10px !important',
    lineHeight: '16px !important',
    color: `${DSM_DARK_GREY} !important`,
    fontWeight: 400,
    paddingLeft: '4px',
  },
}));

// additional styles for homeButtons
export const homeButtonsStyles = makeStyles((theme) => ({
  containerHomeBtnBox: {
    padding: '38px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  homeBtn: {
    fontFamily: 'Inter, Fira Sans',
    fontWeight: 600,
    color: 'white',
  },
  homeBtnTitle: {
    fontSize: 45,
    lineHeight: '55px',
    paddingBottom: '45px',
  },
  homeBtnIntro: {
    fontWeight: 'normal',
    fontSize: 20,
    lineHeight: '24px',
    maxWidth: '304px',
  },
}));

// additional styles for process/stage Btns
export const processAndStageStyles = makeStyles((theme) => ({
  stageContainer: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
  stageBtnBox: {
    fontFamily: 'Fira Sans',
    background: DSM_DARK_GREY,
    borderRadius: '4px',
    padding: '16px 12px',
    width: '190px',
    height: '136px',
    '&.inProgress': {
      background: DSM_PURPLE,
    },
    '&.completed': {
      background: DSM_DARK_GREEN,
    },
    '&.error': {
      background: DSM_RED,
    },
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(102%)',
      boxShadow: `2px 2px 4px ${DSM_DARK_GREY}`,
    },
  },
  stageBtnBoxDisabled: {
    fontFamily: 'Fira Sans',
    background: DSM_LIGHT_GREY,
    borderRadius: '4px',
    padding: '16px 12px',
    width: '190px',
    height: '136px',
  },
  stageBtnTitle: {
    fontWeight: '700',
    fontSize: '22px !important',
    lineHeight: '24px !important',
    color: 'white !important',
  },
  whiteProgress: {
    background: 'rgba(255,255,255,.5) !important',

    '& .MuiLinearProgress-bar': {
      backgroundColor: 'white !important',
    },
  },
  impactBoxTitle: {
    fontSize: '16px !important',
    lineHeight: '18px !important',
    color: 'white !important',
    '&.small': {
      fontSize: '14px !important',
    },
    '&.air': {
      color: `${DSM_MID_BLUE} !important`,
    },
    '&.water': {
      color: `${DSM_OCEAN_GREEN} !important`,
    },
    '&.land': {
      color: `${DSM_DARK_GREEN} !important`,
    },
    '&.human': {
      color: `${DSM_MAGENTA}  !important`,
    },
  },
  mediumProgressText: {
    fontSize: '13px !important',
    lineHeight: '15px !important',
    color: 'white !important',
  },
  smallProgressText: {
    fontSize: '11px !important',
    lineHeight: '13px !important',
    color: 'white !important',
  },
  prodProcessBox: {
    fontFamily: 'Fira Sans',
    background: DSM_DARK_GREY,
    borderRadius: '4px',
    marginTop: '1rem',
    marginBottom: '1.5rem',
    padding: '10px 16px',
    width: '100%',
    height: '123px',
    '&.inProgress': {
      background: DSM_PURPLE,
    },
    '&.warning': {
      background: DSM_ORANGE,
    },
    '&:hover': {
      background: DSM_ORANGE,
      cursor: 'pointer',
    },
  },
  prodProcessTitle: {
    paddingLeft: '20px',
    fontWeight: '700',
    fontSize: '16px !important',
    lineHeight: '32px',
    color: 'white !important',
  },
  impactIconBox: {
    width: '30px',
    height: '30px',
    margin: '5px',
  },
  mainProductionSetLink: {
    fontSize: '14px',
    lineHeight: '24px',
    marginRight: '16px',
    color: 'white !important',
  },
  impactBtnBox: {
    width: '240px',
    height: '103px',
    background: DSM_DARK_GREY,
    borderRadius: '4px',
    padding: '12px',
    '&.air': {
      background: DSM_MID_BLUE,
    },
    '&.land': {
      background: DSM_DARK_GREEN,
    },
    '&.human': {
      background: DSM_MAGENTA,
    },
    '&.water': {
      background: DSM_OCEAN_GREEN,
    },
  },
  impactBtnBoxesContainer: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  impactBtnBoxNumber: {
    fontSize: '40px !important',
    fontWeight: 'bold !important',
    color: 'white !important',
  },
  dialogContainer: {
    borderRadius: '12px',
    padding: '0px',
    paddingBottom: '12px',
    width: '530px',
  },
  dialogDescriptionText: {
    marginBottom: '2rem !important',
  },
  dialogCloseBtn: {
    position: 'absolute !important',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogIcon: {
    width: '40px',
    color: DSM_DARK_GREEN,
  },
  datasetTableTitle: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  inlineEditDeleteIcons: {
    color: `${DSM_DARK_GREY} !important`,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const accessSampleDataDialogStyles = makeStyles((theme) => ({
  formLabel: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
    paddingTop: '10px !important',
  },
  formControlLabel: {
    padding: '16px',
    marginLeft: '2px !important',
    borderRadius: '4px',
    border: '1px solid #F0F0F0',
  },
  formControlLabelSelected: {
    padding: '16px',
    marginLeft: '2px !important',
    borderRadius: '4px',
    border: '1px solid #0070BA',
  },
}));

export const processAndStageStylesV2 = makeStyles((theme) => ({
  stageContainer: {
    marginTop: '1rem',
    marginBottom: '0.5rem',
  },
  stageBtnBox: {
    margin: '2rem 0 1rem 0',
    fontFamily: 'Fira Sans',
    background: 'var(--dsm-color-neutral-lightest)',
    borderRadius: '4px',
    border: '1px solid var(--dsm-color-neutral-lighter)',
    padding: '24px 24px 34px 24px',
    width: '218px',
    height: '229px',
    '&.inProgress': {
      background: 'var(--dsm-color-primary-lightest)',
      border: 'var(--dsm-color-neutral-lighter)',
    },
    '&.completed': {
      background: 'var(--dsm-color-primary-lightest)',
      borderColor: 'var(--dsm-color-primary-lighter)',
    },
    '&.error': {
      background: 'var(--dsm-color-error-lightest)',
      borderColor: 'var(--dsm-color-error-lighter)',
    },
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(102%)',
      boxShadow: `2px 2px 4px var(--dsm-color-neutral)`,
    },
  },
  stageBtnBoxDisabled: {
    fontFamily: 'Fira Sans',
    background: 'var(--dsm-color-neutral-lightest)',
    borderRadius: '4px',
    padding: '24px 24px 34px 24px',
    width: '218px',
    height: '229px',
  },
  stageBtnTitle: {
    marginTop: '18px !important',
    fontWeight: '700',
    fontSize: '20px !important',
    lineHeight: '24px !important',
    color: 'var(--dsm-color-basic-black) !important',
  },
  iconHolder: {
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'var(--dsm-color-neutral)',
    borderRadius: '50%',
    color: 'var(--dsm-color-neutral-darker) !important',
    '&.inProgress': {
      backgroundColor: 'var(--dsm-color-primary-neutral)',
    },
    '&.completed': {
      backgroundColor: 'var(--dsm-color-primary-lighter) !important',
      color: 'var(--dsm-color-primary) !important',
    },
    '&.error': {
      backgroundColor: 'var(--dsm-color-error-lighter) !important',
      color: 'var(--dsm-color-error) !important',
    },
  },
  iconBeforeText: {
    height: '14px',
    width: '14px',
    marginRight: '8px',
  },
  iconInTheBox: {
    height: '24px',
  },
  stageBtnDesc: {
    fontSize: '12px !important',
    lineHeight: '16px',
    color: 'var(--dsm-color-neutral-darker) !important',
  },
  stageBtnDescTitle: {
    fontSize: '12px !important',
    lineHeight: '16px',
    fontWeight: 'bold !important',
  },
  completnessText: {
    display: 'flex',
    justifyContent: 'felx-start',
    width: '218px',
    fontSize: '14px !important',
    lineHeight: '16px',
    color: 'var(--dsm-color-success)',
  },
  topVerticalSpacer: {
    marginTop: 'var(--dsm-spacing-px-6)',
  },
  fieldsVerticalSpacer: {
    marginTop: 'var(--dsm-spacing-px-6)',
    marginBottom: 'var(--dsm-spacing-px-6)',
  },
  impactBoxTitle: {
    fontSize: '16px !important',
    lineHeight: '18px !important',
    color: 'white !important',
    '&.small': {
      fontSize: '14px !important',
    },
    '&.air': {
      color: `${DSM_MID_BLUE} !important`,
    },
    '&.water': {
      color: `${DSM_OCEAN_GREEN} !important`,
    },
    '&.land': {
      color: `${DSM_DARK_GREEN} !important`,
    },
    '&.human': {
      color: `${DSM_MAGENTA}  !important`,
    },
  },
  impactIconBox: {
    width: '30px',
    height: '30px',
    margin: '5px',
  },
  mainProductionSetLink: {
    fontSize: '14px',
    lineHeight: '24px',
    marginRight: '16px',
    color: 'white !important',
  },
  impactBtnBox: {
    width: '240px',
    height: '103px',
    background: DSM_DARK_GREY,
    borderRadius: '4px',
    padding: '12px',
    '&.air': {
      background: DSM_MID_BLUE,
    },
    '&.land': {
      background: DSM_DARK_GREEN,
    },
    '&.human': {
      background: DSM_MAGENTA,
    },
    '&.water': {
      background: DSM_OCEAN_GREEN,
    },
  },
  impactBtnBoxesContainer: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  impactBtnBoxNumber: {
    fontSize: '40px !important',
    fontWeight: 'bold !important',
    color: 'white !important',
  },
  dialogContainerWide: {
    background: 'red !important',
    width: '904px !important',
    '& .modal_wrapper': {
      background: 'red',
    },
    '& .modal__container': {
      maxWidth: 'none',
      width: '904px !important',
      background: 'red !important',
    },
  },
  dialogDescriptionText: {
    marginBottom: '2rem !important',
  },
  dialogCloseBtn: {
    position: 'absolute !important',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogRemoveButton: {
    justifySelf: 'end'
  },
  dialogIcon: {
    width: '40px',
    color: DSM_DARK_GREEN,
  },
  datasetTableTitle: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  inlineEditDeleteIcons: {
    color: `${DSM_DARK_GREY} !important`,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dsmGridOneColumn: {
    grid: 'auto/1fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridOneColumnWithBorder: {
    border: '1px solid var(--dsm-color-neutral)',
    rowGap: '0px',
    padding: 'var(--dsm-spacing-px-2)',
    marginBottom: 'var(--dsm-spacing-px-2)',
    grid: 'auto/1fr',
    gap: 'var(--dsm-spacing-px-6)',
    alignItems: 'start',
  },
  dsmGridthreeColumnWithPadding: {
    grid: 'auto/1fr 1fr 1fr',
    alignItems: 'start',
  },
  dsmGridOneColumnIntervention: {
    grid: 'auto/1fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
    rowGap: 'var(--dsm-spacing-px-4)',
  },
  dsmGridOneColumnNoRowgap: {
    grid: 'auto/1fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
    rowGap: 'var(--dsm-spacing-px-05)',
  },
  dsmGridTwoColumn: {
    grid: 'auto/1fr 1fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },

  dsmGridTwoColumn2nd60: {
    grid: 'auto/0.6fr 1fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumn2nd45: {
    grid: 'auto/0.45fr 1fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumn2nd45NoGap: {
    grid: 'auto/0.45fr 1fr',
    padding: '0px',
    gap: '0',
    rowGap: '0',
    alignItems: 'start',
  },
  dsmGridThreeColumnLastSmall: {
    grid: 'auto/1fr 0.7fr 0.25fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridThreeColumnTwoEqualLastSmall: {
    grid: 'auto/1fr 1fr 0.075fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridThreeColumnFirstBigOthersSmall: {
    grid: 'auto/1fr 0.5fr 0.5fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridFourColumnFirstBigOthersSmall: {
    grid: 'auto/1fr 0.5fr 0.4fr 0.075fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridThreeColumn: {
    grid: 'auto/1fr 1fr 1fr',
    gap: 'var(--dsm-spacing-px-4)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumnWithSolidBorder: {
    grid: 'auto/1fr 1fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
    paddingBottom: '10px',
    borderBottom: '1px solid var(--dsm-color-neutral) !important',
    marginBottom: '15px',
  },
  dsmGridthreeColumnRowSpacing: {
    grid: 'auto/1fr 1fr 2fr',
    columnGap: 0,
    rowGap: '50px',
    padding: '0px',
    alignItems: 'start',
    '& div': {
      marginTop: 'var(--dsm-spacing-px-5)',
    },
  },
  boxWithUpperSpacingAndSolidBorder: {
    borderTop: '1px solid var(--dsm-color-neutral) !important',
    paddingTop: '40px',
    marginTop: '20px',
  },
  boxAnimalSystemBaselines: {
    border: '1px solid var(--dsm-color-neutral-darker) !important',
  },
  dsmGridTwoColumn2To3: {
    grid: 'auto/2fr 3fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumn4To1: {
    grid: 'auto/8fr 1fr',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
  },
  dsmGridTwoColumnNoRowSpacing: {
    grid: 'auto/1fr 1fr',
    columnGap: 'var(--dsm-spacing-px-6)',
    rowGap: '0px',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumnNoRowSpacingWithBorder: {
    border: '1px solid var(--dsm-color-neutral)',
    grid: 'auto/1fr 1fr',
    columnGap: 'var(--dsm-spacing-px-6)',
    rowGap: '0px',
    padding: 'var(--dsm-spacing-px-2)',
    marginBottom: 'var(--dsm-spacing-px-2)',
    alignItems: 'start',
  },
  dsmGridSmallerColumnNoRowSpacing: {
    grid: 'auto/0.5fr 1fr 1fr',
    columnGap: '0px',
    rowGap: '0px',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridThreeColumnNoRowSpacing: {
    grid: 'auto/1fr 1fr 1fr',
    columnGap: 'var(--dsm-spacing-px-6)',
    rowGap: '0px',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridFourColumnNoRowSpacing: {
    grid: 'auto/1fr 1fr 1fr 1fr',
    columnGap: 'var(--dsm-spacing-px-6)',
    rowGap: '0px',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumn_2_3_NoRowSpacingWithLines: {
    grid: 'auto/2fr 3fr',
    columnGap: 0,
    rowGap: '0px',
    padding: '10px',
    alignItems: 'stretch',
    maxHeight: '650px',
    overflow: 'scroll',
    '& div': {
      borderBottom: '1px solid var(--dsm-color-neutral-darker) !important',
      paddingTop: 'var(--dsm-spacing-px-2)',
      paddingBottom: 'var(--dsm-spacing-px-2)',
    },
  },
  dsmGridTwoColumn3_NoRowSpacingWithLines: {
    grid: 'auto/1fr 1fr 1fr',
    columnGap: 0,
    rowGap: '0px',
    padding: '10px',
    alignItems: 'stretch',
    maxHeight: '650px',
    overflow: 'scroll',
    '& div': {
      borderBottom: '1px solid var(--dsm-color-neutral-darker) !important',
      paddingTop: 'var(--dsm-spacing-px-2)',
      paddingBottom: 'var(--dsm-spacing-px-2)',
    },
  },
  dsmGridthreeColumn_2_3_NoRowSpacingWithLinesTitle: {
    grid: 'auto/1fr 2fr 1fr',
    columnGap: 0,
    rowGap: '0px',
    padding: '10px',
    alignItems: 'stretch',
    maxHeight: '650px',
    '& div': {
      borderBottom: '1px solid var(--dsm-color-neutral-darker) !important',
      paddingTop: 'var(--dsm-spacing-px-2)',
      paddingBottom: 'var(--dsm-spacing-px-2)',
    },
  },

  dsmGridthreeColumn_2_3_NoRowSpacingWithLinesBody: {
    grid: 'auto/1fr 2fr 1fr',
    columnGap: 0,
    rowGap: '0px',
    padding: '10px',
    alignItems: 'stretch',
    maxHeight: '650px',
    overflow: 'scroll',
    '& div': {
      borderBottom: '1px solid var(--dsm-color-neutral-darker) !important',
      paddingTop: 'var(--dsm-spacing-px-2)',
      paddingBottom: 'var(--dsm-spacing-px-2)',
    },
  },
  dsmGridthreeColumn_2_3_NoRowSpacingWithLinesDescription: {
    textAlign: 'justify',
    paddingRight: '1px',
  },
  dsmGridthreeColumn_2_3_NoRowSpacingWithLinesReference: {
    paddingLeft: '25px',
  },
  dsmGridTwoColumnIntervention: {
    grid: 'auto/1fr 1fr',
    columngGap: 'var(--dsm-spacing-px-6)',
    rowGap: 'var(--dsm-spacing-px-4)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumnsInterventionComponent: {
    grid: 'auto/2.5fr 1fr',
    columnGap: 'var(--dsm-spacing-px-4)',
    rowGap: '0px',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumnsAndButtonInterventionComponent: {
    grid: 'auto/2.5fr 1fr 24px',
    columnGap: 'var(--dsm-spacing-px-4)',
    rowGap: '0px',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumnsIngredientInterventionComponent: {
    grid: 'auto/53% 28% 1fr',
    columnGap: 'var(--dsm-spacing-px-2)',
    rowGap: '0px',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridColumnWithIconBtn: {
    grid: 'auto/1fr 54px',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'end',
    marginBottom: 'var(--dsm-spacing-px-4)',
  },
  dsmGridFeedInput: {
    grid: 'auto/2fr 1fr ',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'end',
  },
  dsmGridFourColumn: {
    grid: 'auto/auto auto auto auto',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'end',
  },
  dsmGridFourFixedColumns: {
    grid: 'auto/repeat(4, minmax(0,1fr))',
    gap: 'var(--dsm-spacing-px-6)',
    padding: '0px',
    alignItems: 'start',
  },
  dsmGridTwoColumnAndIconBtn: {
    grid: 'auto/auto auto var(--dsm-spacing-px-6)',
    gap: 'var(--dsm-spacing-px-8)',
    padding: '0px',
    alignItems: 'end',
  },
  additionalEntriesBlockHolder: {
    border: '1px solid var(--dsm-color-neutral)',
    grid: 'auto/auto',
    columnGap: 'var(--dsm-spacing-px-4)',
    rowGap: '0px',
    padding: 'var(--dsm-spacing-px-2)',
    marginBottom: 'var(--dsm-spacing-px-2)',
    alignItems: 'end',
  },
  additionalEntriesBlockHolderWithoutBorder: {
    grid: "auto/auto", 
    columnGap: "var(--dsm-spacing-px-4)", 
    rowGap: "0px",
    padding: "var(--dsm-spacing-px-2)",
    marginBottom: "var(--dsm-spacing-px-2)",
    alignItems: "end"
  },
  additionalEntriesBlockHolderIntervention: {
    border: '1px solid var(--dsm-color-neutral)',
    grid: 'auto/auto',
    columnGap: 'var(--dsm-spacing-px-4)',
    rowGap: '10px',
    padding: 'var(--dsm-spacing-px-2)',
    marginBottom: 'var(--dsm-spacing-px-2)',
    alignItems: 'end',
  },
  additionalEntriesBlockHolderInterventionPoultry: {
    grid: 'auto/auto',
    columnGap: 'var(--dsm-spacing-px-4)',
    rowGap: '10px',
    padding: 'var(--dsm-spacing-px-2)',
    marginBottom: 'var(--dsm-spacing-px-2)',
    alignItems: 'end',
  },
  additionalEntriesMMSBlockHolder: {
    border: '1px solid var(--dsm-color-neutral)',
    grid: 'auto/1fr',
    rowGap: '5px',
    paddingTop: 'var(--dsm-spacing-px-4)',
    alignItems: 'end',
  },
  datasetsTableIntroTitle: {
    color: 'var(--dsm-color-basic-black) !important',
    fontWeight: '500 !important',
    fontSize: '18px !important',
    lineHeight: '40px !important',

    '&.error': {
      color: `${DSM_RED} !important`,
    },
  },
  headingTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'black',
    marginTop: '10px',
  },
  headingTitleVariant: {
    fontWeight: '500',
    fontSize: '18px',
    color: 'black',
    marginTop: '10px',
  },
  subHeadingTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'black',
    marginTop: '10px',
  },
  subHeadingTitleVariant: {
    fontWeight: '500',
    fontSize: '14px',
    color: 'black',
    marginTop: '10px',
  },
  headingRadioButtonTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'black',
    marginTop: '10px',
    marginLeft: '10px',
  },
  radioGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioGroupLabel: {
    fontFamily: ['Fira Sans', 'sans-serif'].join(','),
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '5px',
  },
  radioGroupLabelError: {
    color: DSM_RED,
  },
  radioGroupInputList: { display: 'flex' },
  radioGroupInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  radioGroupInputLabel: {
    marginLeft: '5px',
    marginBottom: 0,
    fontWeight: 500,
  },
  radioGroupErrorMessage: {
    color: DSM_RED,
    fontSize: 9,
  },
  naturalFeedBlock: {
    grid: 'auto/6fr 4fr 140px 140px 32px 7fr',
    gap: 'var(--dsm-spacing-px-4)',
    alignItems: 'start',
    alignContent: ' space-between',
    padding: '0px',
    marginTop: 'var(--dsm-spacing-px-4)',
  },
  shrimpInputSourcesText: {
    fontWeight: '500',
    fontSize: '18px',
    color: 'black',
    marginTop: '10px',
    marginLeft: '10px'
  },
  modalIconHolder: {
    backgroundColor: '#F0F7FB',
    borderRadius: '24px',
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  modalIcon: {
    height: '24px', 
    width: '24px', 
    color: '#0070BA'
  },
  modalTitle: {
    color: 'var(--neutrals-dsm-black, #1B1B1B)',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px'
  },
  inlineTooltipClass: {
    color: 'var(--dsm-color-neutral-darker)',
    height: '16px',
    width: '16px',
    top: '28px',
    marginLeft: '3px',
    marginBottom: '6px',
  },
  resetButtonContainer: {
    display: 'flex', 
    flexDirection: 'row'
  },
  MMSlightBlueBoxContainer: {
    borderColor: "var(--dsm-color-neutral)",
    borderWidth: "1px 1px 1px 0",
    borderStyle: "solid",
    padding: "0 var(--dsm-spacing-px-4) 0 var(--dsm-spacing-px-8)",
    backgroundColor: DSM_MAIN_SELECTED_COLOR,
  },
  MMSFieldContainer: {
    width: '100%',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },
  beefMMSBlockContainer: {
    border: '1px solid var(--dsm-color-neutral)',
    display: 'flex',
    flexDirection: 'column'
  },
}));

// additional styles for FootprintCategoryDetail
export const footprintCategoryDetailStyles = makeStyles((theme) => ({
  chart: {
    width: '160px',
    height: '160px',
    marginRight: '16px',
  },
  chartContainer: {
    marginTop: '20px',
    width: '50%',
  },
  chartLegend: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
  },
  multipleCharts: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

// additional styles for FootprintCategoryTarget
export const footprintCategoryTargetStyles = makeStyles((theme) => ({
  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  textBoxTitle: {
    fontSize: '14px',
    fontWeight: 500,
    paddingBottom: '10px',
    lineHeight: '24px',
  },
  buttonStyles: {
    backgroundColor: `${DSM_MID_BLUE} !important`,
    '& .MuiButton-label': {
      textTransform: 'initial',
      color: '#FFFFFF !important',
    },
    minWidth: '165px !important',
    width: '45%',
  },
  clearTargetButton: {
    '& .MuiButton-label': {
      textTransform: 'initial',
      color: `${DSM_MID_BLUE} !important`,
    },
    borderColor: `${DSM_MID_BLUE} !important`,
    minWidth: '165px !important',
    width: '45%',
  },
}));

// additional styles for ChartLegend
export const chartLegendStyles = makeStyles((theme) => ({
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  legendDot: {
    width: '8px',
    height: '8px',
    borderRadius: '8px',
  },
}));

// additional styles for TableWithProgressBar
export const tableWithProgressBarStyles = makeStyles((theme) => ({
  tableContainer: {
    border: `1px solid ${DSM_LIGHT_GREY}`,
    borderRadius: '4px',
    marginTop: '20px',
  },
  tableCell: {
    marginLeft: '24px',
    borderBottom: `1px solid ${DSM_LIGHT_GREY}`,
  },
  categoryCell: {
    width: '30%',
  },
  datasetCell: {
    width: '10%',
  },
  dataCell: {
    width: '30%',
  },
  infoCell: {
    width: '15%',
  },
  differenceCell: {
    width: '15%',
  },
  valueCell: {
    borderBottom: `1px solid ${DSM_LIGHT_GREY}`,
  },
  categoreyNameCell: {
    fontWeight: 500,
    fontSize: '14px',
  },
}));

export const chartColors = new Map([
  ['DSM_CYAN_LIGHTER', DSM_CYAN_LIGHTER],
  ['DSM_LIME_GREEN', DSM_LIME_GREEN],
  ['DSM_MAGENTA', DSM_MAGENTA],
  ['DSM_DARK_ORANGE', DSM_DARK_ORANGE],
  ['DSM_BLUE', DSM_BLUE],
  ['DSM_OCEAN_GREEN', DSM_OCEAN_GREEN],
  ['DSM_DARK_PURPLE', DSM_DARK_PURPLE],
  ['DSM_GREEN_DARKEST', DSM_GREEN_DARKEST]
]);

export const tableWithProgressBarColors = {
  linearProgressBackground: DSM_LIGHT_GREY,
  infoValue: DSM_SOFT_BLACK,
  negativeInfoValue: DSM_RED,
};

export const impactColors = {
  air: DSM_MID_BLUE,
  water: DSM_OCEAN_GREEN,
  land: DSM_DARK_GREEN,
  human: DSM_MAGENTA,
};

export const impactBackgroundIconColors = {
  air: DSM_LIGHTER_BLUE,
  water: DSM_LIGHTER_OCEAN_GREEN,
  land: DSM_LIGHTER_GREEN,
  human: DSM_LIGHTER_CERISE,
};

export const dialogsStyle = {
  style: {
    borderRadius: '12px',
    padding: '0px',
    paddingBottom: '12px',
    width: '538px',
  },
};

export const dialogsStyleIntervention = {
  style: {
    borderRadius: '12px',
    padding: '0px',
    paddingBottom: '12px',
    width: '598px',
  },
};

export const dialogsStyleFootprint = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '60%',
  },
};

export const dialogsStyleDouble = {
  style: {
    borderRadius: '12px',
    padding: '0px',
    paddingBottom: '12px',
    width: '1120px',
  },
};

export const dialogsStyleDoubleIntervention = {
  style: {
    borderRadius: '12px',
    padding: '0px',
    paddingBottom: '12px',
    width: '1220px',
  },
};

// v2 dialog styles until DSM modal can be made variable width
export const dialogsStyle2 = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '408px',
  },
};

export const dialogsStyleDescription = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '800px',
  },
};

export const dialogsStyleIntervention2 = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '598px',
  },
};

export const dialogsStyleFootprint2 = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '60%',
  },
};

export const dialogsStyleDouble2 = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '904px',
  },
};

export const dialogsStyleDemiWide2 = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '748px',
  },
};

export const dialogsStyleDoubleIntervention2 = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '1220px',
  },
};

export const dialogsStyleDoubleIntervention2v2 = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '1080px',
  },
};

export const dialogUltraWide = {
  style: {
    borderRadius: '4px',
    padding: '0 0 12px 0',
    width: '1280px',
  },
};

export const feedIngredientsStyles = makeStyles((theme) => ({
  buttonsBox: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  textBoxTitle: {
    fontSize: '14px',
    fontWeight: 500,
    paddingBottom: '10px',
    lineHeight: '24px',
  },
  buttonStyles: {
    backgroundColor: `${DSM_MID_BLUE} !important`,
    '& .MuiButton-label': {
      textTransform: 'initial',
      color: '#FFFFFF !important',
    },
    minWidth: '165px !important',
    width: '45%',
  },
  proxyOriginSelect: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#EE7203',
      },
      '&:hover fieldset': {
        borderColor: '#EE7203',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#EE7203',
      },
    },
  },
  proxyOriginText: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#EE7203',
      },
      '&:hover fieldset': {
        borderColor: '#EE7203',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#EE7203',
      },
    },
  },
  proxyDialogTextBox: {
    fontSize: '14px',
    fontWeight: 400,
    paddingBottom: '10px',
    lineHeight: '20px',
    color: '#475467',
  },
  proxyDialogSubtitleBox: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    paddingBottom: 0,
    lineHeight: '24px',
    color: DSM_SOFT_BLACK,
  },
  proxyAddCustomFeedSelectOption: {
    '&:last-child': {
      background: `${DSM_LIGHT_GREY} !important`,
    },
  },
  proxyTooltip: {
    color: `${DSM_SOFT_BLACK} !important`,
    backgroundColor: `${DSM_MAIN_SELECTED_COLOR} !important`,
    cursor: 'pointer',
  },
}));

export const helpCenterStyles = makeStyles((theme) => ({
  helpCenterContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  helpCenterSubContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  gettingStartedSectionTitle: {
    color: 'var(--dsm-blue-dsm-blue, #003478)',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    alignSelf: 'stretch',
  },
  gettingStartedSectionGrid: {
    grid: 'auto/1fr 1fr',
    padding: '0px',
    alignItems: 'start',
  },
  documentCard: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '8px',
    height: '80px',
    width: '100%',
    cursor: 'pointer',
    border: '1px solid #F0F0F0',
  },
  documentIconHolder: {
    backgroundColor: '#F0F7FB',
    borderRadius: '24px',
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  documentIcon: {
    height: '24px',
    width: '24px',
    color: '#0070BA',
  },
}));

export function MaterialThemeProvider(props) {
  const { children } = props;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
