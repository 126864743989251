/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from "uuid";
import { UserProfilePrefs } from "../../../Helpers/UserProfilePrefs";
import { defaultUnitsV2 as defaultMetric } from "../../../../sustell_15/utils/unit-utils";
import { setInterventionFloatValue } from "./outCommonMapperSustell";
import { ManureForm } from "../../../../../graphql/generated/blonk/pigs";
import { getManureFormForSingleEntry } from "../../../../sustell_15/helpers/manureManagementSystemsLookups";
import { dashToCamelCaseString } from "../../../../sustell_15/utils/string-utils";
import { floatValueInDefaultUnit } from "../../Baseline/mapper/floatHelperFunctions";
import {
  mapCommonInterventionDataOutSustell,
  mapResourceUseSustell,
} from "./outCommonMapperSustellV2";

const mapHousingForOneStage = (housingFormData, stageHousingDataObj) => {
  // const userPrefs = UserProfilePrefs.getInstance();
  // const userUOM = userPrefs.getUserUnitPrefs();
  const stageHousingData = stageHousingDataObj;

  // set value, only if baseline's value changed
  if (
    housingFormData.surfaceType &&
    housingFormData.surfaceType_old &&
    housingFormData.surfaceType !== housingFormData.surfaceType_old
  )
    stageHousingData.surfaceType = housingFormData.surfaceType;

  stageHousingData.manureForm = housingFormData.manureForm;

  if (housingFormData.Nreplacement || housingFormData.Nreplacement === 0)
    setInterventionFloatValue(
      stageHousingData,
      "Nreplacement",
      housingFormData,
      "Nreplacement"
    );

  if (housingFormData.Preplacement || housingFormData.Preplacement === 0)
    setInterventionFloatValue(
      stageHousingData,
      "Preplacement",
      housingFormData,
      "Preplacement"
    );

  stageHousingData.solidManureManagementSystem = [];
  stageHousingData.liquidSlurryManureManagementSystem = [];
  if ("manureSystems" in housingFormData) {
    housingFormData.manureSystems.forEach((formItem) => {
      // if mmsType contains storage time then full mmsType is set in item.mmsHoldingDuration
      // while item.mmsType contains only partial base type
      const baselineMMSType = formItem.mmsType_old;
      const interventionMMSType =
        formItem.mmsHoldingDuration && formItem.mmsHoldingDuration !== ""
          ? formItem.mmsHoldingDuration
          : formItem.mmsType;

      const outputItem = {
        // even if nothing is changed, we need to keep id to preserve order
        id: formItem.id || uuidv4(),
      };

      // set mmsType only if different than the baseline's to avoid copying baseline value
      // needed when baseline is changed and then interventions area being recalculated
      if (baselineMMSType !== interventionMMSType)
        outputItem.mmsType = interventionMMSType;

      // set data if something changed
      if (outputItem.mmsType || formItem.share || formItem.share === 0) {
        if (
          stageHousingData.Nreplacement?.value ||
          stageHousingData.Nreplacement?.value === 0
        )
          outputItem.nReplacingFertilizer = stageHousingData.Nreplacement;
        if (
          stageHousingData.Preplacement?.value ||
          stageHousingData.Preplacement?.value === 0
        )
          outputItem.pReplacingFertilizer = stageHousingData.Preplacement;

        // if share changed, set it
        if (formItem.share || formItem.share === 0)
          setInterventionFloatValue(outputItem, "share", formItem, "share");
      }

      // MMS type being used
      const mmsType = outputItem.mmsType || baselineMMSType;
      if (
        mmsType &&
        getManureFormForSingleEntry(mmsType) === ManureForm.LiquidSlurry
      )
        stageHousingData.liquidSlurryManureManagementSystem.push(outputItem);
      if (mmsType && getManureFormForSingleEntry(mmsType) === ManureForm.Solid)
        stageHousingData.solidManureManagementSystem.push(outputItem);
    });
  }
  // loop through rations arrays added in the intervention
  if (
    housingFormData.manureSystemsAdditions &&
    Array.isArray(housingFormData.manureSystemsAdditions)
  ) {
    housingFormData.manureSystemsAdditions?.forEach((addnlItem) => {
      // if mmsType contains storage time then full mmsType is set in item.mmsHoldingDuration
      // while item.mmsType contains only partial base type
      if (addnlItem.share) {
        const outputItem = {
          id: addnlItem.id || uuidv4(),
          share: addnlItem.share,
          mmsType:
            addnlItem.mmsHoldingDuration && addnlItem.mmsHoldingDuration !== ""
              ? addnlItem.mmsHoldingDuration
              : addnlItem.mmsType,
        };

        if (
          getManureFormForSingleEntry(outputItem.mmsType) ===
          ManureForm.LiquidSlurry
        ) {
          if (!stageHousingData.liquidSlurryManureManagementSystemAdditions)
            stageHousingData.liquidSlurryManureManagementSystemAdditions = [];
          stageHousingData.liquidSlurryManureManagementSystemAdditions.push(
            outputItem
          );
        }
        if (
          getManureFormForSingleEntry(outputItem.mmsType) === ManureForm.Solid
        ) {
          if (!stageHousingData.solidManureManagementSystemAdditions)
            stageHousingData.solidManureManagementSystemAdditions = [];
          stageHousingData.solidManureManagementSystemAdditions.push(
            outputItem
          );
        }
      }
    });
  }

  // if something changed, update manureForm value
  if (
    (stageHousingData.solidManureManagementSystem?.length ||
      stageHousingData.solidManureManagementSystemAdditions?.length) &&
    (stageHousingData.liquidSlurryManureManagementSystem?.length ||
      stageHousingData.liquidSlurryManureManagementSystemAdditions?.length)
  )
    stageHousingData.manureForm = ManureForm.SolidAndLiquidSlurry;
  else if (
    stageHousingData.solidManureManagementSystem?.length ||
    stageHousingData.solidManureManagementSystemAdditions?.length
  )
    stageHousingData.manureForm = ManureForm.Solid;
  else if (
    stageHousingData.liquidSlurryManureManagementSystem?.length ||
    stageHousingData.liquidSlurryManureManagementSystemAdditions?.length
  )
    stageHousingData.manureForm = ManureForm.LiquidSlurry;

  // if nothing set, these values are not needed
  if (
    !stageHousingData.solidManureManagementSystem?.length &&
    !stageHousingData.solidManureManagementSystemAdditions?.length &&
    !stageHousingData.liquidSlurryManureManagementSystem?.length &&
    !stageHousingData.liquidSlurryManureManagementSystemAdditions?.length
  ) {
    delete stageHousingData.manureForm;
    delete stageHousingData.surfaceType;
  }
};

const mapBeddingForOneStage = (beddingFormDataArray, stageBeddingDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageBeddingData = stageBeddingDataObj;

  beddingFormDataArray?.forEach((item) => {
    if (
      item.beddingType &&
      item.beddingAmount &&
      !Number.isNaN(item.beddingAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.beddingType)}Use`;

      setInterventionFloatValue(
        stageBeddingData,
        `${propName}`,
        item,
        "beddingAmount",
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
    }
  });
};

const mapBeddingForOneStageAdditions = (
  beddingFormDataArray,
  stageBeddingDataObj
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageBeddingData = stageBeddingDataObj;

  beddingFormDataArray?.forEach((item) => {
    if (
      item.beddingType &&
      item.beddingAmount &&
      !Number.isNaN(item.beddingAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.beddingType)}Use`;
      const currValue = stageBeddingData[`${propName}`] || 0;
      const value = floatValueInDefaultUnit(
        Number(item.beddingAmount),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
      stageBeddingData[`${propName}`] = currValue + value;
    }
  });
};

const mapMaterialsForOneStage = (
  materialsFormDataArray,
  stageMaterialsDataObj
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageMaterialData = stageMaterialsDataObj;

  materialsFormDataArray?.forEach((item) => {
    if (
      item.materialType &&
      item.materialAmount &&
      !Number.isNaN(item.materialAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.materialType)}Use`;
      setInterventionFloatValue(
        stageMaterialData,
        `${propName}`,
        item,
        "materialAmount",
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
    }
  });
};

const mapMaterialsForOneStageAdditions = (
  materialsFormDataArray,
  stageMaterialsDataObj
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageMaterialData = stageMaterialsDataObj;

  materialsFormDataArray?.forEach((item) => {
    if (
      item.materialType &&
      item.materialAmount &&
      !Number.isNaN(item.materialAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.materialType)}Use`;
      const currValue = stageMaterialData[`${propName}`] || 0;
      const value = floatValueInDefaultUnit(
        Number(item.materialAmount),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
      stageMaterialData[`${propName}`] = currValue + value;
    }
  });
};

const mapOutputForOneStage = (outputFormData, stageOutputDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  // weight of pigs - conversion to default units
  if (
    outputFormData.averageWeightPigs ||
    outputFormData.averageWeightPigs === 0
  )
    setInterventionFloatValue(
      stageOutputDataObj,
      "averageWeightPigs",
      outputFormData,
      "averageWeightPigs",
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  // weight of piglets - conversion to default units
  if (
    outputFormData.averageWeightPiglets ||
    outputFormData.averageWeightPiglets === 0
  )
    setInterventionFloatValue(
      stageOutputDataObj,
      "averageWeightPiglets",
      outputFormData,
      "averageWeightPiglets",
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  // number of pigs
  if (outputFormData.mortalityPigs || outputFormData.mortalityPigs === 0)
    stageOutputDataObj.mortalityPigs = {
      value: parseFloat(outputFormData.mortalityPigs),
      change_type: outputFormData.mortalityPigs_changeMetric,
    };

  // number of piglets
  if (outputFormData.mortalityPiglets || outputFormData.mortalityPiglets === 0)
    stageOutputDataObj.mortalityPiglets = {
      value: parseFloat(outputFormData.mortalityPiglets),
      change_type: outputFormData.mortalityPiglets_changeMetric,
    };

  // number of pigs
  if (outputFormData.pigsToStage || outputFormData.pigsToStage === 0)
    stageOutputDataObj.pigsToStage = {
      value: parseFloat(outputFormData.pigsToStage),
      change_type: outputFormData.pigsToStage_changeMetric,
    };

  // number of piglets
  if (outputFormData.pigletsToStage || outputFormData.pigletsToStage === 0)
    stageOutputDataObj.pigletsToStage = {
      value: parseFloat(outputFormData.pigletsToStage),
      change_type: outputFormData.pigletsToStage_changeMetric,
    };

  // number of pigs
  if (outputFormData.soldPigs || outputFormData.soldPigs === 0)
    stageOutputDataObj.soldPigs = {
      value: parseFloat(outputFormData.soldPigs),
      change_type: outputFormData.soldPigs_changeMetric,
    };

  // number of piglets
  if (outputFormData.soldPiglets || outputFormData.soldPiglets === 0)
    stageOutputDataObj.soldPiglets = {
      value: parseFloat(outputFormData.soldPiglets),
      change_type: outputFormData.soldPiglets_changeMetric,
    };

  // number of days
  if (outputFormData.averageAgePigs || outputFormData.averageAgePigs === 0)
    stageOutputDataObj.averageAgePigs = {
      value: parseFloat(outputFormData.averageAgePigs),
      change_type: outputFormData.averageAgePigs_changeMetric,
    };

  // number of days
  if (
    outputFormData.ageAtMortalityPigs ||
    outputFormData.ageAtMortalityPigs === 0
  )
    stageOutputDataObj.ageAtMortalityPigs = {
      value: parseFloat(outputFormData.ageAtMortalityPigs),
      change_type: outputFormData.ageAtMortalityPigs_changeMetric,
    };

  // price in EUR - possible future conversion needed
  if (outputFormData.priceSoldPigs || outputFormData.priceSoldPigs === 0)
    stageOutputDataObj.priceSoldPigs = {
      value: parseFloat(outputFormData.priceSoldPigs),
      change_type: outputFormData.priceSoldPigs_changeMetric,
    };

  // price in EUR - possible future conversion needed
  if (outputFormData.priceSoldPiglets || outputFormData.priceSoldPiglets === 0)
    stageOutputDataObj.priceSoldPiglets = {
      value: parseFloat(outputFormData.priceSoldPiglets),
      change_type: outputFormData.priceSoldPiglets_changeMetric,
    };

  // weight of pigs - conversion to default units
  if (
    outputFormData.weightAtMortalityPigs ||
    outputFormData.weightAtMortalityPigs === 0
  )
    setInterventionFloatValue(
      stageOutputDataObj,
      "weightAtMortalityPigs",
      outputFormData,
      "weightAtMortalityPigs",
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  // weight of piglets - conversion to default units
  if (
    outputFormData.weightAtMortalityPiglets ||
    outputFormData.weightAtMortalityPiglets === 0
  )
    setInterventionFloatValue(
      stageOutputDataObj,
      "weightAtMortalityPiglets",
      outputFormData,
      "weightAtMortalityPiglets",
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapInputForOneStage = (formStageDataInputPart, stageObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stage = stageObj;
  const { stageData } = stage;

  stage.startDate = formStageDataInputPart.startDate;
  stage.endDate = formStageDataInputPart.endDate;
  stageData.animalsInput = {};
  if (stage.type === "FATTENING" && formStageDataInputPart.stockPresent) {
    if (
      formStageDataInputPart.optionalInput?.pigsPresentAtStart ||
      formStageDataInputPart.optionalInput?.pigsPresentAtStart === 0
    )
      stageData.pigsPresentAtStart = {
        value: floatValueInDefaultUnit(
          formStageDataInputPart.optionalInput.pigsPresentAtStart
        ),
        change_type:
          formStageDataInputPart.optionalInput.pigsPresentAtStart_changeMetric,
      };

    if (
      formStageDataInputPart.optionalInput?.pigsPresentAtEnd ||
      formStageDataInputPart.optionalInput?.pigsPresentAtEnd === 0
    )
      stageData.pigsPresentAtEnd = {
        value: floatValueInDefaultUnit(
          formStageDataInputPart.optionalInput.pigsPresentAtEnd
        ),
        change_type:
          formStageDataInputPart.optionalInput.pigsPresentAtEnd_changeMetric,
      };

    if (
      formStageDataInputPart.optionalInput?.averageWeightPigsStart ||
      formStageDataInputPart.optionalInput?.averageWeightPigsStart === 0
    )
      setInterventionFloatValue(
        stageData,
        "averageWeightPigsStart",
        formStageDataInputPart.optionalInput,
        "averageWeightPigsStart",
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );

    if (
      formStageDataInputPart.optionalInput?.averageWeightPigsEnd ||
      formStageDataInputPart.optionalInput?.averageWeightPigsEnd === 0
    )
      setInterventionFloatValue(
        stageData,
        "averageWeightPigsEnd",
        formStageDataInputPart.optionalInput,
        "averageWeightPigsEnd",
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );

    if (
      formStageDataInputPart.optionalInput?.averageAgePigsStart ||
      formStageDataInputPart.optionalInput?.averageAgePigsStart === 0
    )
      stageData.averageAgePigsStart = {
        value: floatValueInDefaultUnit(
          formStageDataInputPart.optionalInput.averageAgePigsStart
        ),
        change_type:
          formStageDataInputPart.optionalInput.averageAgePigsStart_changeMetric,
      };

    if (
      formStageDataInputPart.optionalInput?.averageAgePigsEnd ||
      formStageDataInputPart.optionalInput?.averageAgePigsEnd === 0
    )
      stageData.averageAgePigsEnd = {
        value: floatValueInDefaultUnit(
          formStageDataInputPart.optionalInput.averageAgePigsEnd
        ),
        change_type:
          formStageDataInputPart.optionalInput.averageAgePigsEnd_changeMetric,
      };

    if (
      formStageDataInputPart.optionalInput?.pigsPriceAtStart ||
      formStageDataInputPart.optionalInput?.pigsPriceAtStart === 0
    )
      stageData.pricePigsStart = {
        value: floatValueInDefaultUnit(
          formStageDataInputPart.optionalInput.pigsPriceAtStart
        ),
        change_type:
          formStageDataInputPart.optionalInput.pigsPriceAtStart_changeMetric,
      };

    if (
      formStageDataInputPart.optionalInput?.pigsPriceAtEnd ||
      formStageDataInputPart.optionalInput?.pigsPriceAtEnd === 0
    )
      stageData.pigsPriceAtEnd = {
        value: floatValueInDefaultUnit(
          formStageDataInputPart.optionalInput.pigsPriceAtEnd
        ),
        change_type:
          formStageDataInputPart.optionalInput.pigsPriceAtEnd_changeMetric,
      };
  }

  if (stage.type === "BREEDING") {
    if (
      formStageDataInputPart.pigsPresentAtStart ||
      formStageDataInputPart.pigsPresentAtStart === 0
    )
      stageData.pigsPresentAtStart = {
        value: floatValueInDefaultUnit(
          formStageDataInputPart.pigsPresentAtStart
        ),
        change_type: formStageDataInputPart.pigsPresentAtStart_changeMetric,
      };

    if (
      formStageDataInputPart.pigsPresentAtEnd ||
      formStageDataInputPart.pigsPresentAtEnd === 0
    )
      stageData.pigsPresentAtEnd = {
        value: floatValueInDefaultUnit(formStageDataInputPart.pigsPresentAtEnd),
        change_type: formStageDataInputPart.pigsPresentAtEnd_changeMetric,
      };
  }

  if (
    formStageDataInputPart.internalSources &&
    formStageDataInputPart.internalSources.length > 0
  ) {
    stageData.animalsInput.incomings = [];
    formStageDataInputPart.internalSources?.forEach((item) => {
      const newSource = {
        origin: "PRODUCTION_SYSTEM",
        id: item.id || uuidv4(),
        originStageId: item.originStageId,
        originAnimalType: item.originAnimalType,
        stageType: item.stageType,
      };
      if (item.averageAgeOfPigs)
        newSource.averageAgePigs = {
          value: floatValueInDefaultUnit(item.averageAgeOfPigs),
          change_type: item.averageAgeOfPigs_changeMetric,
        };
      if (item.averageWeightOfPigs)
        newSource.averageWeightPigs = {
          value: floatValueInDefaultUnit(item.averageWeightOfPigs),
          change_type: item.averageAgeOfPigs_changeMetric,
        };
      if (item.numberPigs || item.numberPigs === 0)
        newSource.numberPigs = {
          value: floatValueInDefaultUnit(item.numberPigs),
          change_type: item.numberPigs_changeMetric,
        };

      if (item.distanceTransport || item.distanceTransport === 0) {
        // newSource.distanceTransport = {};
        setInterventionFloatValue(
          newSource,
          "distanceTransport",
          item,
          "distanceTransport",
          userUOM.unitTransportDistanceTerrestrial,
          defaultMetric.unitTransportDistanceTerrestrial
        );
      }

      stageData.animalsInput.incomings.push(newSource);
    });
  }
  if (
    formStageDataInputPart.internalSourcesAdditions &&
    formStageDataInputPart.internalSourcesAdditions.length > 0
  ) {
    stageData.animalsInput.incomingsAdditions = [];
    formStageDataInputPart.internalSourcesAdditions?.forEach((item) => {
      const newSource = {
        origin: "PRODUCTION_SYSTEM",
        id: item.id || uuidv4(),
        originAnimalType: item.originAnimalType,
        originFarmId: item.farmId,
        originFarmName: item.farmName,
        originStageId: item.originStageId,
        originStageName: item.stageName,
        averageWeightPiglets: item.averageWeightPiglets,
        averageWeightPigs: item.averageWeightPigs,
        averageAgePigs: item.averageAgePigs,
        stageType: item.stageType,
      };
      if (item.numberPigs || item.numberPigs === 0)
        newSource.numberPigs = floatValueInDefaultUnit(item.numberPigs);

      if (item.distanceTransport || item.distanceTransport === 0)
        newSource.distanceTransport = floatValueInDefaultUnit(
          item.distanceTransport,
          userUOM.unitTransportDistanceTerrestrial,
          defaultMetric.unitTransportDistanceTerrestrial
        );

      stageData.animalsInput.incomingsAdditions.push(newSource);
      if (
        newSource.originAnimalType &&
        !stageData.animalsInput.originAnimalType
      ) {
        stageData.animalsInput.originAnimalType = newSource.originAnimalType;
      }
    });
  }

  if (
    formStageDataInputPart.externalSources &&
    formStageDataInputPart.externalSources.length > 0
  ) {
    if (stageData.animalsInput.incomings === undefined)
      stageData.animalsInput.incomings = [];

    formStageDataInputPart.externalSources?.forEach((item) => {
      const newSource = {
        origin: "BACKGROUND_DATA",
        id: item.id || uuidv4(),
      };

      if (item.numberPigs || item.numberPigs === 0)
        newSource.numberPigs = {
          value: floatValueInDefaultUnit(item.numberPigs),
          change_type: item.numberPigs_changeMetric,
        };

      if (item.averageAgeOfPigs || item.averageAgeOfPigs === 0)
        newSource.averageAgePigs = {
          value: floatValueInDefaultUnit(item.averageAgeOfPigs),
          change_type: item.averageAgeOfPigs_changeMetric,
        };

      if (item.averageWeightOfPigs || item.averageWeightOfPigs === 0) {
        setInterventionFloatValue(
          newSource,
          "averageWeightPigs",
          item,
          "averageWeightOfPigs",
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
      }

      if (item.distanceTransport || item.distanceTransport === 0) {
        setInterventionFloatValue(
          newSource,
          "distanceTransport",
          item,
          "distanceTransport",
          userUOM.unitTransportDistanceTerrestrial,
          defaultMetric.unitTransportDistanceTerrestrial
        );
      }

      stageData.animalsInput.incomings.push(newSource);
    });
  }

  if (
    formStageDataInputPart.externalSourcesAdditions &&
    formStageDataInputPart.externalSourcesAdditions.length > 0
  ) {
    if (stageData.animalsInput.incomingsAdditions === undefined)
      stageData.animalsInput.incomingsAdditions = [];

    formStageDataInputPart.externalSourcesAdditions?.forEach((item) => {
      const newSource = {
        origin: "BACKGROUND_DATA",
        id: item.id || uuidv4(),
      };

      if (item.numberPigs || item.numberPigs === 0)
        newSource.numberPigs = floatValueInDefaultUnit(item.numberPigs);

      if (item.averageWeightOfPigs || item.averageWeightOfPigs === 0)
        newSource.averageWeightPigs = floatValueInDefaultUnit(
          item.averageWeightOfPigs,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );

      if (item.averageAgeOfPigs || item.averageAgeOfPigs === 0)
        newSource.averageAgePigs = floatValueInDefaultUnit(
          item.averageAgeOfPigs
        );

      if (item.distanceTransport || item.distanceTransport === 0)
        newSource.distanceTransport = floatValueInDefaultUnit(
          item.distanceTransport,
          userUOM.unitTransportDistanceTerrestrial,
          defaultMetric.unitTransportDistanceTerrestrial
        );

      stageData.animalsInput.incomingsAdditions.push(newSource);
    });
  }
};

const mapPigInterventionDataOutSustell = (formData) => {
  let payloadObj = mapCommonInterventionDataOutSustell(formData);
  payloadObj = mapResourceUseSustell(formData, payloadObj);

  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if (payloadObj.stages[index] !== undefined) {
        const formStageData = formDataForOneStage.stageData;
        if (formStageData) {
          const stageGeneralData = payloadObj.stages[index];
          const { stageData } = stageGeneralData;
          if ("housing" in formStageData) {
            stageData.housing = {};
            mapHousingForOneStage(formStageData.housing, stageData.housing);
            if ("beddingSystems" in formStageData.housing) {
              stageData.beddingUse = {};
              mapBeddingForOneStage(
                formStageData.housing.beddingSystems,
                stageData.beddingUse
              );
            }
            if ("beddingSystemsAdditions" in formStageData.housing) {
              stageData.beddingUseAdditions = {};
              mapBeddingForOneStageAdditions(
                formStageData.housing.beddingSystemsAdditions,
                stageData.beddingUseAdditions
              );
            }
            if ("materials" in formStageData.housing) {
              stageData.materialUse = {};
              mapMaterialsForOneStage(
                formStageData.housing.materials,
                stageData.materialUse
              );
            }
            if ("materialsAdditions" in formStageData.housing) {
              stageData.materialUseAdditions = {};
              mapMaterialsForOneStageAdditions(
                formStageData.housing.materialsAdditions,
                stageData.materialUseAdditions
              );
            }
          }
          if ("input" in formStageData) {
            const formStageDataInputPart = formStageData.input;
            mapInputForOneStage(formStageDataInputPart, stageGeneralData);
          }
          if ("output" in formStageData) {
            stageData.animalsOutput = {};
            mapOutputForOneStage(formStageData.output, stageData.animalsOutput);
          }
        }
      }
    });

  payloadObj.stages.forEach((item) => {
    const { stageData } = item;
    // eslint-disable-next-line no-param-reassign
    item.stageData = JSON.stringify(stageData);
  });
  // console.log("payload: ", payloadObj)
  return payloadObj;
};

export default mapPigInterventionDataOutSustell;
