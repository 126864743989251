import React, { FC, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DsmButton, DsmFieldset, DsmIcon } from '@dsm-dcs/design-system-react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Grid, Tooltip } from '@material-ui/core';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { defaultUnits } from '../../../utils/unit-utils';
import { UserProfilePrefs } from '../../../../modules/Helpers/UserProfilePrefs';
import {
  CustomIngredient,
  FormType,
  IngredientField,
  IngredientInputType,
  IngredientNamesAndOriginsV2,
  IngredientUses,
  SelectedCustomerAlias,
} from '../types';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { IconProvider } from '../../helpers/IconProvider';
import ProxyDialog from './ProxyDialog';
import { feedIngredientsStyles } from '../../../../../_metronic/layout/_core/MaterialThemeProvider';
import CustomIngredientDialog from './CustomIngredientDialog';
import {
  ADDON_INGREDIENT_SEPARATOR,
  CUSTOM_INGREDIENT_ID_SEPARATOR,
  getAddonGroup,
  getCountryLabel,
  getIngredientLabel,
  iff,
  isFeedV2CFDatabaseFoundation,
  getSortedCountryCodes,
} from '../utils';
import { Origin } from '../../../../../graphql/types';
import ControlledAutocompleteDsm2 from '../../../../modules/Helpers/ControlledAutocompleteDsm2';
import ReactHookDsmInput2 from '../../../../modules/Helpers/ReactHookDsmInput2';
import { CFDatabaseFoundation } from '../../../models/CompoundFeed';
import { getOriginOrProxyV2 } from '../apiHelpers';

interface IngredientsInputRowProps {
  formType: FormType;
  // eslint-disable-next-line react/require-default-props
  ingredientOriginCombinations?: IngredientUses[];
  // eslint-disable-next-line react/require-default-props
  ingredientOriginCombinationsCategorires?: IngredientUses[];
  field: IngredientField;
  index: number;
  removeHandler: (index: number) => void;
  updateQuantityAndPercentage: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  showProxyDialog: boolean;
  setShowProxyDialog: (show: React.SetStateAction<boolean>) => void;
  ingredientOriginsNamesV2: IngredientNamesAndOriginsV2 | undefined; // use this 2
  databaseFoundation: CFDatabaseFoundation | undefined;
  ingredientInputType: IngredientInputType;
}

const IngredientsInputRow: FC<IngredientsInputRowProps> = ({
  formType,
  ingredientOriginCombinations,
  ingredientOriginCombinationsCategorires,
  field,
  index,
  removeHandler,
  updateQuantityAndPercentage,
  showProxyDialog,
  setShowProxyDialog,
  ingredientOriginsNamesV2,
  databaseFoundation,
  ingredientInputType,
}) => {
  const intl = useIntl();
  const classes = feedIngredientsStyles();
  const selectedCustomer = useSelector(
    (state: SelectedCustomerAlias) => state.selectedCustomer?.value
  );
  const uuid = useMemo(() => uuidv4(), []);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, setValue, getValues } = useFormContext();
  // TODO: until UOM is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const values = getValues();

  const ingredientName = useWatch({
    control,
    name: `ingredient_uses.${index}.name`,
    defaultValue: (values.name as string) || '',
  });

  const ingredientProxyOrigin = useWatch({
    control,
    name: `ingredient_uses.${index}.proxyOrigin`,
    defaultValue: false,
  });

  const customIngredient = useWatch({
    control,
    name: `ingredient_uses.${index}.customIngredient`,
    defaultValue: false,
  });

  const [originCountry, setOriginCountry] = useState<string>();
  const [proxyOriginCountry, setProxyOriginCountry] = useState<string>();

  const [proxyOriginDialogVisible, setProxyOriginDialogVisible] =
    useState<boolean>(false);

  const [customIngredientDialogVisibe, setCustomIngredientDialogVisibe] =
    useState<boolean>(false);

  const selectedCustomIngredient = useRef<CustomIngredient | null>(null);
  const customIngredientFormType = useRef<FormType>(FormType.New);

  const originsV2Sorted =
    isFeedV2CFDatabaseFoundation(databaseFoundation) && ingredientOriginsNamesV2
      ? ingredientOriginsNamesV2.origins
          .sort((a, b) =>
            (a.name || '')?.toLowerCase() < (b.name || '')?.toLowerCase()
              ? -1
              : iff(
                  (a.name || '')?.toLowerCase() > (b.name || '')?.toLowerCase(),
                  1,
                  0
                )
          )
          .map((o: Origin) => o.isoCode)
      : [];

  const inputUnit = `${
    userUOM?.unitIngredientQuantityInputMass
      ? userUOM.unitIngredientQuantityInputMass
      : defaultUnits?.unitIngredientQuantityInputMass
  }`;

  const outputUnit = `${
    userUOM?.unitCompoundFeedOutputMass
      ? userUOM.unitCompoundFeedOutputMass
      : defaultUnits?.unitCompoundFeedOutputMass
  }`;

  const feedWord = intl.formatMessage({ id: 'COMPOUND_FEED.WORD.FEED' }); 
  const tonneWord = intl.formatMessage({ id: 'COMPOUND_FEED.UNIT.TONNE' }); 

  const localizedAdornment = intl.formatMessage(
    { id: 'COMPOUND_FEED.INGREDIENT.QUANTITY_ADORNMENT' },
    { inputUnit, outputUnit: tonneWord, feedWord }
  );

  const getIngredientNameLabel = (ingredientId: string) =>
    getIngredientLabel(
      ingredientId ?? '',
      intl,
      databaseFoundation,
      ingredientOriginCombinations,
      ingredientOriginCombinationsCategorires
    );

  const feedOriginChangedV1 = (
    ingredientId: string,
    origin: string,
    customFeedAdded = false
  ) => {
    const ingredient = ingredientOriginCombinations?.find(
      (item) => item.id === ingredientId
    );
    const isProxy = !ingredient?.exclusive_origins?.find(
      (item) => item.iso_code.code === origin
    );
    setValue(`ingredient_uses.${index}.proxyOrigin`, isProxy);

    // proxy dialog will be show just first time and avoid showing prxy dialog when custom feed added
    if (showProxyDialog && isProxy && !customFeedAdded) {
      setProxyOriginDialogVisible(isProxy);
      setOriginCountry(
        getCountryLabel(
          getValues(`ingredient_uses.${index}.origin`) as string,
          databaseFoundation,
          intl
        )
      );
      // no need to show proxy dialog again
      setShowProxyDialog(false);
    }
  };

  const feedOriginChangedV2 = (
    ingredientId: string,
    origin: string,
    customFeedAdded = false
  ) => {
    let ingredientIdToSend = ingredientId;
    if (ingredientId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) > -1) {
      try {
        // If custom name send proxy request using Group as ingredientId to get closest
        // origin from selected country
        [ingredientIdToSend] = ingredientIdToSend.split(
          CUSTOM_INGREDIENT_ID_SEPARATOR
        );
      } catch (err) {
        console.log('err custom ing name format', ingredientId);
      }
    }

    // Don't fetch proxy ingredients when using addons
    if (ingredientIdToSend.indexOf(ADDON_INGREDIENT_SEPARATOR) !== -1) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    getOriginOrProxyV2(
      ingredientIdToSend,
      origin,
      selectedCustomer?.id || '',
      databaseFoundation
    )
      .then((res) => {
        if (!res) {
          return;
        }

        setValue(`ingredient_uses.${index}.proxyOrigin`, res.isProxy);
        if (res.isProxy) {
          // proxy dialog will be show just first time and avoid showing prxy dialog when custom feed added
          if (
            showProxyDialog &&
            res.isProxy &&
            !customFeedAdded &&
            res.selectedIngredient?.origin
          ) {
            setOriginCountry(
              getCountryLabel(
                res.requestedIngredient?.origin || '',
                databaseFoundation,
                intl
              )
            );
            setProxyOriginCountry(
              getCountryLabel(
                res.selectedIngredient?.origin || '',
                databaseFoundation,
                intl
              )
            );
            if (
              res.requestedIngredient?.name === res.selectedIngredient?.name
            ) {
              // If returned another ingredient custom ingredient dialog will be shown
              setProxyOriginDialogVisible(res.isProxy);
            }
            // no need to show proxy dialog again
            setShowProxyDialog(false);
          }

          setValue(
            `ingredient_uses.${index}.proxyOriginCode`,
            res.selectedIngredient?.origin
          ); // Needed for saving in DB in V2

          if (res.requestedIngredient?.name !== res.selectedIngredient?.name) {
            let customIngredientName = '';
            let customIngredientId = '';
            // // In case that custom ingredient is already added set the name
            if (ingredientId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) > -1) {
              customIngredientId = ingredientId;
              customIngredientName =
                ingredientId.split(CUSTOM_INGREDIENT_ID_SEPARATOR).at(1) || '';
              customIngredientFormType.current = FormType.Edit;
            } else {
              customIngredientFormType.current = FormType.New;
            }
            selectedCustomIngredient.current = {
              categoryId: res.selectedIngredient?.name || '',
              originalIngredientName: customIngredientName
                ? ''
                : res.requestedIngredient?.name,
              name:
                customIngredientName ||
                (res.requestedIngredient?.name
                  ? `${res.requestedIngredient.name} #2`
                  : ''),
              id: customIngredientId,
            };

            setValue(`ingredient_uses.${index}.customIngredient`, true);
            setCustomIngredientDialogVisibe(true);
          } else if (
            ingredientId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) === -1
          ) {
            setValue(`ingredient_uses.${index}.customIngredient`, false);
          }
        } else {
          setValue(`ingredient_uses.${index}.proxyOriginCode`, undefined);
        }
      })
      .catch((err) => console.log(err));
  };

  const feedOrginChanged = (customFeedAdded = false) => {
    const ingredientId = getValues(`ingredient_uses.${index}.name`) as string;
    const origin = getValues(`ingredient_uses.${index}.origin`) as string;
    if (ingredientId.indexOf(ADDON_INGREDIENT_SEPARATOR) === -1) {
      setValue(`ingredient_uses.${index}.addonType`, undefined);
    }
    // if ingredientId and origin set, check is combination proxied
    if (ingredientId && origin) {
      if (!isFeedV2CFDatabaseFoundation(databaseFoundation)) {
        feedOriginChangedV1(ingredientId, origin, customFeedAdded);
      } else {
        feedOriginChangedV2(ingredientId, origin, customFeedAdded);
      }
    }
  };

  const ingredientChanged = (ingredientId: string | null) => {
    // Clear potential proxy/custom ingredient indicators
    setValue(`ingredient_uses.${index}.proxyOrigin`, false);
    setValue(`ingredient_uses.${index}.proxyOriginCode`, undefined);
    setValue(`ingredient_uses.${index}.customIngredient`, undefined);

    // Check if ingredient is addon
    let addonGroup;
    if (
      ingredientId &&
      ingredientId?.indexOf(ADDON_INGREDIENT_SEPARATOR) > -1
    ) {
      addonGroup = getAddonGroup(
        ingredientId?.split(ADDON_INGREDIENT_SEPARATOR)[0],
        databaseFoundation,
        ingredientOriginsNamesV2
      );
    }
    if (addonGroup) {
      // Remove old origin
      setValue(`ingredient_uses.${index}.origin`, undefined);
      setValue(`ingredient_uses.${index}.addonType`, addonGroup.addonType);
      return;
    }

    if (ingredientId === 'Add your own ingredient') {
      selectedCustomIngredient.current = null;
      // opening dialog in the New mode, as new custom feed is being added
      customIngredientFormType.current = FormType.New;
      setCustomIngredientDialogVisibe(true);
    } else feedOrginChanged();

    // set indicator is custom ingredient
    setValue(
      `ingredient_uses.${index}.customIngredient`,
      (ingredientId || '').indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) > -1
    );
  };

  const sortIngredientOriginCombinations = (combinations: IngredientUses[]) =>
    combinations.sort((a, b) =>
      (a.name || '')?.toLowerCase() > (b.name || '')?.toLowerCase() ? 1 : -1
    );

  const addCustomIngredient = (newIngredient: CustomIngredient) => {
    if (newIngredient?.name && newIngredient?.categoryId) {
      const customIngredientUses: IngredientUses = {
        id: `${newIngredient.categoryId}${CUSTOM_INGREDIENT_ID_SEPARATOR}${newIngredient.name}`,
        name: newIngredient.name,
        customIngredient: true,
        exclusive_origins: ingredientOriginCombinationsCategorires?.find(
          (item) => item.id === newIngredient.categoryId
        )?.exclusive_origins,
      };

      // remove Add custom option, add new custom, sort with the new custom, add new custom at end
      const addCustomOption = ingredientOriginCombinations?.pop();

      ingredientOriginCombinations?.push(customIngredientUses);

      sortIngredientOriginCombinations(ingredientOriginCombinations!);
      ingredientOriginCombinations?.push(addCustomOption!);

      setValue(`ingredient_uses.${index}.name`, customIngredientUses.id);
      setValue(`ingredient_uses.${index}.customIngredient`, true);
    }
    setCustomIngredientDialogVisibe(false);

    // triger feed or originchanged
    feedOrginChanged(true);
  };

  const updateCustomIngredient = (ingredient: CustomIngredient) => {
    if (ingredient?.name && ingredient?.categoryId) {
      // find old one
      const current = ingredientOriginCombinations?.find(
        (item) => item.id === ingredient.id!
      );

      if (current) {
        // set new data
        current.id = `${ingredient.categoryId}${CUSTOM_INGREDIENT_ID_SEPARATOR}${ingredient.name}`;
        current.name = ingredient.name;
        current.exclusive_origins =
          ingredientOriginCombinationsCategorires?.find(
            (item) => item.id === ingredient.categoryId
          )?.exclusive_origins;

        // remove Add custom option, sort with the updated custom, add new custom at end
        const addCustomOption = ingredientOriginCombinations?.pop();

        sortIngredientOriginCombinations(ingredientOriginCombinations!);
        ingredientOriginCombinations?.push(addCustomOption!);

        setValue(`ingredient_uses.${index}.name`, current.id);
        setValue(`ingredient_uses.${index}.customIngredient`, true);
      }
    }
    setCustomIngredientDialogVisibe(false);

    // triger feed or originchanged
    feedOrginChanged(true);
  };

  const addUpdateCustomIngredient = (ingredient: CustomIngredient) => {
    if (customIngredientFormType.current === FormType.New)
      addCustomIngredient(ingredient);
    else if (customIngredientFormType.current === FormType.Edit)
      updateCustomIngredient(ingredient);
  };

  const showViewEditCustomIngredientDialog = () => {
    const selectedId = getValues(`ingredient_uses.${index}.name`) as string;
    if (selectedId && selectedId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) > -1) {
      const categoryId = selectedId.substring(
        0,
        selectedId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR)
      );
      const name = selectedId.substring(
        selectedId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) +
          CUSTOM_INGREDIENT_ID_SEPARATOR.length
      );

      selectedCustomIngredient.current = {
        categoryId,
        name,
        id: selectedId,
      };

      // this should open ingredient dialog in view mode (if feed opened in view) or edit
      // as this function is called on the edit, not add new
      customIngredientFormType.current =
        formType === FormType.View ? FormType.View : FormType.Edit;

      setCustomIngredientDialogVisibe(true);
    }
  };

  // Based on V1/V2 or addons return origins
  const getOrigins = (fieldIndex: number) => {
    // For V2
    if (isFeedV2CFDatabaseFoundation(databaseFoundation)) {
      const ingId = getValues(`ingredient_uses.${fieldIndex}.name`) as
        | string
        | undefined;
      // Only addons have limited origins
      if (ingId && ingId.indexOf(ADDON_INGREDIENT_SEPARATOR) > -1) {
        const addonGroup = getAddonGroup(
          ingId?.split(ADDON_INGREDIENT_SEPARATOR)[0],
          databaseFoundation,
          ingredientOriginsNamesV2
        );
        const addonOrigins =
          addonGroup?.ingredients
            .filter(
              (add) => add.name === ingId?.split(ADDON_INGREDIENT_SEPARATOR)[0]
            )
            .map((a) => a.origin) || [];
        return addonOrigins;
      }
      return originsV2Sorted;
    }
    // For V1
    return getSortedCountryCodes()
      .filter((country) => country.value !== 'GLO')
      .map((country) => country.value);
  };

  const showProxyInfo = () => {
    let proxyCountryName;
    const proxyCountryCode = getValues(
      `ingredient_uses.${index}.proxyOriginCode`
    ) as string | undefined;
    if (proxyCountryCode) {
      proxyCountryName = getCountryLabel(
        proxyCountryCode,
        databaseFoundation,
        intl
      );
      setProxyOriginCountry(proxyCountryName);
      const originCountryName = getCountryLabel(
        getValues(`ingredient_uses.${index}.origin`) as string,
        databaseFoundation,
        intl
      );
      setOriginCountry(originCountryName);
    } else {
      setOriginCountry(undefined);
      setProxyOriginCountry(undefined);
    }
    setProxyOriginDialogVisible(true);
  };

  const handleCloseCustomIngredientDialog = (
    removeIngredient?: boolean,
    rowIndex?: number
  ) => {
    setCustomIngredientDialogVisibe(false);
    if (removeIngredient && (rowIndex || rowIndex === 0)) {
      setValue(`ingredient_uses.${index}.name`, null);
      setValue(`ingredient_uses.${index}.id`, null);
      setValue(`ingredient_uses.${index}.origin`, null);
      setValue(`ingredient_uses.${index}.proxyOrigin`, null);
      setValue(`ingredient_uses.${index}.customIngredient`, false);
      setValue(`ingredient_uses.${index}.proxyOriginCode`, null);
      setValue(`ingredient_uses.${index}.addonType`, null);
    }
  };
  return (
    <Grid container spacing={2} key={field.key}>
      <Grid item xs={4}>
        {formType === FormType.Edit && field.id && (
          <Controller
            control={control}
            defaultValue={field.id}
            name={`ingredient_uses.${index}.id`}
            render={({ value, name }) => (
              <input type="hidden" name={name} value={value} />
            )}
          />
        )}
        <Controller
          control={control}
          defaultValue={field.proxyOrigin}
          name={`ingredient_uses.${index}.proxyOrigin`}
          render={({ value, name }) => (
            <input type="hidden" name={name} value={value} />
          )}
        />
        <Controller
          control={control}
          defaultValue={field.customIngredient}
          name={`ingredient_uses.${index}.customIngredient`}
          render={({ value, name }) => (
            <input type="hidden" name={name} value={value} />
          )}
        />
        <Controller
          control={control}
          defaultValue={field.proxyOriginCode || null}
          name={`ingredient_uses.${index}.proxyOriginCode`}
          render={({ value, name }) => (
            <input type="hidden" name={name} value={value} />
          )}
        />
        <Controller
          control={control}
          defaultValue={field.addonType || null}
          name={`ingredient_uses.${index}.addonType`}
          render={({ value, name }) => (
            <input type="hidden" name={name} value={value} />
          )}
        />

        <Controller
          control={control}
          defaultValue={uuid}
          name={`ingredient_uses.${index}.localId`}
          render={({ value, name }) => (
            <input type="hidden" name={name} value={value} />
          )}
        />

        <DsmFieldset style={{ width: '96%' }}>
          <ControlledAutocompleteDsm2
            name={`ingredient_uses.${index}.name`}
            multiple={false}
            control={control}
            options={ingredientOriginCombinations?.map(
              (item: IngredientUses) => item.id
            )}
            defaultValue={field.name}
            required
            label={intl.formatMessage({ id: 'COMPOUND_FEED.INGREDIENT' })}
            placeholder="Select ingredient from list"
            disabled={formType === FormType.View}
            getOptionLabel={getIngredientNameLabel}
            tooltip={
              !customIngredient
                ? intl.formatMessage({
                    id: 'COMPOUND_FEED.INGREDIENT.TOOLTIP',
                  })
                : ''
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onChange={ingredientChanged}
          />
        </DsmFieldset>
        {customIngredient && (
          <Box mt={0} mb={0} display="flex" sx={{ height: '22px' }}>
            <Tooltip
              classes={{ tooltip: classes.proxyTooltip }}
              title={intl.formatMessage({
                id: 'COMPOUNDFEED_WIZARD.PROXY_DATA_READ_MORE',
              })}
              interactive
              PopperProps={{ onClick: () => showProxyInfo() }}
            >
              <Box display="flex" onClick={() => showProxyInfo()}>
                <IconProvider
                  iconCode="proxyIngredientIcon"
                  alt="Proxy ingredient"
                  height="20px"
                  color="#EE7203"
                  cursor="pointer"
                />
              </Box>
            </Tooltip>
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ marginLeft: 'auto' }}
            >
              <Button
                style={{
                  color: '#EE7203',
                  textTransform: 'none',
                  fontSize: '14px',
                }}
                onClick={showViewEditCustomIngredientDialog}
              >
                <EditOutlinedIcon />
                {formType !== FormType.View &&
                  intl.formatMessage({ id: 'GENERAL.EDIT' })}
                {formType === FormType.View &&
                  intl.formatMessage({ id: 'GENERAL.VIEW' })}
              </Button>
            </Box>
          </Box>
        )}
      </Grid>

      <Grid item xs={4}>
        {proxyOriginDialogVisible && (
          <ProxyDialog
            originCountry={originCountry}
            proxyCountry={proxyOriginCountry}
            formVisible={proxyOriginDialogVisible}
            handleClose={() => setProxyOriginDialogVisible(false)}
          />
        )}

        {customIngredientDialogVisibe && (
          <CustomIngredientDialog
            formVisible={customIngredientDialogVisibe}
            handleClose={handleCloseCustomIngredientDialog}
            handleConfirm={addUpdateCustomIngredient}
            data={selectedCustomIngredient.current}
            formType={customIngredientFormType.current}
            ingredientOriginCombinationsCategories={
              ingredientOriginCombinationsCategorires
            }
            rowIndex={index}
            ingredientOriginCombinations={ingredientOriginCombinations}
          />
        )}

        <DsmFieldset style={{ width: '96%' }}>
          <ControlledAutocompleteDsm2
            name={`ingredient_uses.${index}.origin`}
            multiple={false}
            control={control}
            className={ingredientProxyOrigin ? classes.proxyOriginSelect : ''}
            // options={Object.keys(countryIsoCodes)}
            options={getOrigins(index)}
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.COUNTRY_OF_ORIGIN.TOOLTIP',
            })}
            getOptionLabel={(e: string) =>
              getCountryLabel(e, databaseFoundation, intl)
            }
            defaultValue={field.origin}
            required
            label={intl.formatMessage({
              id: 'COMPOUND_FEED.COUNTRY_OF_ORIGIN',
            })}
            // eslint-disable-next-line no-nested-ternary
            placeholder={
              formType === FormType.View
                ? true
                : !ingredientName
                ? ''
                : intl.formatMessage({
                    id: 'COMPOUNDFEED_WIZARD.INGREDIENTS.ORIGIN_PLACEHOLDER',
                  })
            }
            disabled={formType === FormType.View || !ingredientName}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onChange={(_data?: string | null) => feedOrginChanged()}
          />
        </DsmFieldset>

        {ingredientProxyOrigin && (
          <Box mt={0} mb={0} display="flex" sx={{ height: '22px' }}>
            <Tooltip
              classes={{ tooltip: classes.proxyTooltip }}
              title={intl.formatMessage({
                id: 'COMPOUNDFEED_WIZARD.PROXY_DATA_READ_MORE',
              })}
              interactive
              PopperProps={{ onClick: () => showProxyInfo() }}
            >
              <Box display="flex" onClick={() => showProxyInfo()}>
                <IconProvider
                  iconCode="proxyIngredientIcon"
                  alt="Proxy ingredient"
                  height="20px"
                  color="#EE7203"
                  cursor="pointer"
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Grid>
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={11}>
            <DsmFieldset style={{ width: '96%' }}>
              <>
                <ReactHookDsmInput2
                  InputProps={{
                    width: '384px',
                    height: '32px',
                  }}
                  style={{
                    display:
                      ingredientInputType === IngredientInputType.ABSOLUTE
                        ? ''
                        : 'none',
                  }}
                  name={`ingredient_uses.${index}.quantity`}
                  required
                  label={intl.formatMessage({
                    id: 'COMPOUND_FEED.INGREDIENT.QUANTITY',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'COMPOUND_FEED.INGREDIENT.QUANTITY',
                  })}
                  disabled={formType === FormType.View ? true : !ingredientName}
                  adornment={localizedAdornment}
                  onFocusHandler={(e: { target: { select: () => void } }) =>
                    e.target.select()
                  }
                  type="number"
                  changeHandler={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => updateQuantityAndPercentage(e, index)}
                  size="medium"
                  defaultValue={field.quantity || ''}
                />
                <ReactHookDsmInput2
                  InputProps={{
                    width: '384px',
                    height: '32px',
                  }}
                  style={{
                    display:
                      ingredientInputType === IngredientInputType.ABSOLUTE
                        ? 'none'
                        : '',
                  }}
                  name={`ingredient_uses.${index}.quantityPercentage`}
                  required
                  label={intl.formatMessage({
                    id: 'COMPOUND_FEED.INGREDIENT.QUANTITY_PERCENTAGE',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'COMPOUND_FEED.INGREDIENT.QUANTITY_PERCENTAGE',
                  })}
                  disabled={formType === FormType.View ? true : !ingredientName}
                  adornment={`%/${outputUnit} feed`}
                  onFocusHandler={(e: { target: { select: () => void } }) =>
                    e.target.select()
                  }
                  type="number"
                  changeHandler={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => updateQuantityAndPercentage(e, index)}
                  size="medium"
                  defaultValue={field.quantityPercentage || ''}
                />
              </>
            </DsmFieldset>
          </Grid>
          <Grid
            item
            xs={1}
            style={{ display: 'flex', alignItems: 'center', paddingBottom: 5 }}
          >
            <Tooltip
              aria-label={intl.formatMessage({
                id: 'COMPOUND_FEED.INGREDIENT.REMOVE',
              })}
              title={intl.formatMessage({
                id: 'COMPOUND_FEED.INGREDIENT.REMOVE',
              })}
            >
              <DsmButton
                variant="text"
                iconOnly
                destructive
                onClick={() => removeHandler(index)}
                disabled={formType === FormType.View}
              >
                <DsmIcon
                  slot="before"
                  name="general/trash-01"
                  style={{ height: 24, width: 24 }}
                />
              </DsmButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IngredientsInputRow;
