// returns array of correct enum keys/display_name values with translations if available.
import { IntlShape } from 'react-intl';
import { translateKeyMapping, dashToSpacesString, dashToSpacesStringLowerCase } from './string-utils';
import { masterDataSorter } from '../helpers/sortingFunctions';
import esIngredients from '../../../_metronic/i18n/ingredients/es.json'
import ptIngredients from '../../../_metronic/i18n/ingredients/pt.json'

interface LookupValuesProps {
  value: string;
  display_name: string;
}

const getTranslatedEnumOrDefaultLookupValues = (
  enumValues: LookupValuesProps[],
  correspondingLookupValues: LookupValuesProps[],
  intl: IntlShape,
  baseKey = 'MASTERDATA.ENUM',
  sort = true,
  copyAdditionalDataFromLookup = false
) => {
  const translatedEnumValues: {
    value: string;
    display_name: string;
    mandatory?: boolean;
    unit?: string;
  }[] = [];
  // console.log("ENUM VALUES: ",enumValues);
  if (enumValues && Array.isArray(enumValues) && enumValues.length > 0) {
    // enumerated values are received - one item is in the form of {value, display_name}
    enumValues.forEach((item) => {
      let newValue = {
        value: item.value,
        display_name: item.display_name || dashToSpacesString(item.value),
      };

      if (copyAdditionalDataFromLookup) {
        const matchedLookupValue = correspondingLookupValues?.find(
          (lookupItem) => lookupItem.value === item.value
        );
        if (matchedLookupValue) {
          // lookup values contains matching object - copy other properties, but retain orginal display_name if existed, if needed for missing translations
          newValue = { ...matchedLookupValue };
          if (item.display_name) newValue.display_name = item.display_name;
        }
      }
      // console.log("TRANSLATION KEY: ", `${baseKey}.${translateKeyMapping(newValue.value)}`);
      // now try to find enum translations if existing
      newValue.display_name = intl.formatMessage({
        id: `${baseKey}.${translateKeyMapping(newValue.value)}`,
        defaultMessage: newValue.display_name,
      });
      translatedEnumValues.push(newValue);
    });
  } else if (
    correspondingLookupValues &&
    Array.isArray(correspondingLookupValues)
  ) {
    // no enums were supplied, use lookupValues only
    // console.log("no enums")
    correspondingLookupValues.forEach((item) => {
      const itemCopy = item;
      itemCopy.display_name = intl.formatMessage({
        id: `${baseKey}.${translateKeyMapping(item.value)}`,
        defaultMessage: item.display_name,
      });
      translatedEnumValues.push(itemCopy);
    });
  }
  if (sort) translatedEnumValues.sort(masterDataSorter);
  return translatedEnumValues;
};

export const getSingleEnumEntryTranslation = (key: string, intl: IntlShape, prefix = '') =>
  intl.formatMessage({ id: translateKeyMapping(`${prefix}.${key}`), defaultMessage: dashToSpacesStringLowerCase(key) });

interface Ingredient {
  isMilk?: boolean,
  isSillage?: boolean,
  text: string,
  value: string
}

export const translateIngredients = (ingredients: Ingredient[], language: string) => {
  const getCorrespondentIngredientList = (language: string) => {
    switch (language) {
      case 'pt':
        return ptIngredients;
      case 'es':
        return esIngredients;
      default:
        undefined;
    }
  }

  const translatedIngredientsList = getCorrespondentIngredientList(language);

  if (translatedIngredientsList === undefined)
    return;

  ingredients.forEach((item) => {
    type ObjectKey = keyof typeof translatedIngredientsList;
    const ing = item.value as ObjectKey;
    const translatedText = translatedIngredientsList[ing] ?? item.text;
    item.text = translatedText;
  });
}

export default getTranslatedEnumOrDefaultLookupValues;
