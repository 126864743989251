import {
  DairyV2Baseline,
  DairyV2ContinuousGrowingStageData,
  DairyV2DairyCowStageData,
  DairyV2Emissions,
  DairyV2InputContinuousGrowing,
  DairyV2Input,
  DairyV2OutputContinuousGrowing,
  DairyV2OutputDairyCow,
  DairyV2Stage,
} from '../../../../sustell_15/models/Baseline/DairyV2Baseline';
import { ProcessingStage } from '../../../../sustell_15/models/Facility/Processing';
import {
  StageDBModel,
  StageType,
} from '../../../../sustell_15/models/TempBackendModels/TempCattleDB';
import {
  CommonStageInputDBModel,
  ContinousGrowingStageInputDBModel,
  ContinuousGrowingOutputDBModel,
  DairyCowCalvesOutputDBModel,
  DairyCowOutputDBModel,
  DairyV2ContinuousGrowingStageDataDBModel,
  DairyV2DairyCowStageDataDBModel,
  DairyV2StageDBModel,
  EmissionNOPDBModel,
  EmissionsDBModel,
} from '../../../../sustell_15/models/TempBackendModels/TempDairyDB';
import { ProcessingStageDBModel } from '../../../../sustell_15/models/TempBackendModels/TempProcessingDB';
import {
  getStartDateFromStage,
  mapCattleBeddingSystems,
  mapCattleEmissionsAndMitigations,
  mapCattleManureSystems,
  mapCattleStockChange,
  mapCattleTimeSpend,
  mapExternalSource,
  mapFeedingProgramWithMothersMilk,
  mapInternalSource,
} from './Helpers/outCattleMapperHelpers';
import {
  createCorePayloadSustell,
  mapResourceUseSustell,
} from './outCommonMapperSustellV2';
import { formatMassInput } from "./outMapperHelpers";

function mapDairyCowInput(
  incomingData: DairyV2Input,
): CommonStageInputDBModel {
  const internalSources = mapInternalSource(incomingData?.internalSources);
  const externalSources = mapExternalSource(incomingData?.externalSources);

  return {
    animals: [...internalSources, ...externalSources],
    averageWeight: formatMassInput(incomingData?.averageWeightNewAnimals),
  };
}

function mapDairyCowOutputCalves(
  incomingData: DairyV2OutputDairyCow,
): DairyCowCalvesOutputDBModel {
  return {
    animalsOutgoing: Number(incomingData.weanedCalvesToAnotherStage),
    soldExternally: Number(incomingData.weanedCalvesSold),
  };
}

function mapDairyCowStageOutputCows(
  incomingData: DairyV2OutputDairyCow | DairyV2OutputContinuousGrowing,
): DairyCowOutputDBModel {
  return {
    animalsOutgoing: Number(incomingData.cowsToAnotherStage),
    averageWeight: formatMassInput(
      incomingData.averageWeightOfCowsLeavingTheStage,
    ),
    mortalities: Number(incomingData.numberOfMortalitiesCows),
    soldExternally: Number(incomingData.cowsSold),
    weightAtMortality: formatMassInput(
      incomingData.averageWeightAtMortalityCows,
    ),
  };
}

function mapEmissionsNOP(
  incomingEmissions?: DairyV2Emissions,
): EmissionNOPDBModel {
  return {
    NOP: incomingEmissions?.NOP,
    NOPdose: incomingEmissions?.NOPdose,
    averageFatDMI: incomingEmissions?.averageFatDMI,
    cowsFedSupplement: incomingEmissions?.cowsFedSupplement,
    calculatedMethaneFermentation: incomingEmissions?.calculatedMethaneFermentation,
    totalMethaneFermentation: incomingEmissions?.totalMethaneFermentation,
  };
}

function mapDairyEmissions(
  incomingData: DairyV2ContinuousGrowingStageData | DairyV2DairyCowStageData,
): EmissionsDBModel {
  return {
    emissionMitigations: mapCattleEmissionsAndMitigations(
      incomingData.emissions?.emissionMitigations,
    ),
    emissionNOP: mapEmissionsNOP(incomingData.emissions)
  };
}

// Similar to Breeding
function mapDairyCowStageData(
  incomingData: DairyV2DairyCowStageData,
): DairyV2DairyCowStageDataDBModel {
  return {
    bedding: mapCattleBeddingSystems(incomingData.housing?.beddingSystems),
    emissions: mapDairyEmissions(incomingData),
    mms: mapCattleManureSystems(incomingData.housing.manureSystems),
    outputCalves: mapDairyCowOutputCalves(incomingData.output),
    outputCows: mapDairyCowStageOutputCows(incomingData.output),
    input: mapDairyCowInput(incomingData.input),
    timeSpent: mapCattleTimeSpend(incomingData.housing),
    stockChange: mapCattleStockChange(incomingData.input),
    feedingProgram: mapFeedingProgramWithMothersMilk(incomingData.feed),
    calvesBirthWeight: incomingData.output.averageWeightAtBirth
      ? formatMassInput(Number(incomingData.output.averageWeightAtBirth))
      : undefined,
    animalProductivity: incomingData.input.cattleProductivity,
    milkProduction: {
      fatContent: Number(incomingData.output.averageMilkFat),
      amount: Number(incomingData.output.yearlyMilkProduction),
      proteinContent: Number(incomingData.output.averageMilkProtein),
    },
  };
}

function mapContinouGrowingStageOutput(
  incomingData: DairyV2OutputContinuousGrowing,
): ContinuousGrowingOutputDBModel {
  return {
    ...mapDairyCowStageOutputCows(incomingData),
    averageAge: Number(incomingData.averageAge),
  };
}

function mapContinuousGrowingInput(
  incomingData: DairyV2InputContinuousGrowing,
): ContinousGrowingStageInputDBModel {
  const internalSources = mapInternalSource(incomingData?.internalSources);
  const externalSources = mapExternalSource(incomingData?.externalSources);

  return {
    animals: [...internalSources, ...externalSources],
    averageWeight: formatMassInput(incomingData?.averageWeightNewAnimals),
    averageAge: Number(incomingData?.averageAge),
  };
}

function mapContinuousGrowingStageData(
  incomingData: DairyV2ContinuousGrowingStageData,
): DairyV2ContinuousGrowingStageDataDBModel {
  const stageData: DairyV2ContinuousGrowingStageDataDBModel = {
    feedingProgram: mapFeedingProgramWithMothersMilk(incomingData.feed),
    bedding: mapCattleBeddingSystems(incomingData.housing?.beddingSystems),
    outputCows: mapContinouGrowingStageOutput(incomingData.output),
    input: mapContinuousGrowingInput(incomingData.input),
    timeSpent: mapCattleTimeSpend(incomingData.housing),
    animalProductivity: incomingData.input.cattleProductivity,
    emissions: mapDairyEmissions(incomingData),
    mms: mapCattleManureSystems(incomingData.housing.manureSystems),
    stockChange: mapCattleStockChange(incomingData.input),
  };

  return stageData;
}

function mapStageData(
  incomingData: DairyV2Stage | ProcessingStage,
): DairyV2StageDBModel | ProcessingStageDBModel {
  const { stageData } = incomingData;
  if (incomingData.type === StageType.Processing) {
    return {} as ProcessingStageDBModel;
  }

  const commonStageData: Omit<StageDBModel, 'stageData'> = {
    id: incomingData.id,
    name: incomingData.name,
  };

  // TODO - To check which stageTypes are being used
  if (incomingData.type === StageType.ContinuousGrowing) {
    return {
      ...commonStageData,
      stageData: mapContinuousGrowingStageData(stageData as DairyV2ContinuousGrowingStageData),
      type: StageType.ContinuousGrowing,
      startDate: getStartDateFromStage(stageData as DairyV2ContinuousGrowingStageData)
    };
  }

  return {
    ...commonStageData,
    stageData: mapDairyCowStageData(
      stageData as DairyV2DairyCowStageData,
    ),
    type: StageType.DairyCow,
    startDate: getStartDateFromStage(stageData as DairyV2DairyCowStageData)
  };
}

const mapDairyProductionDataOutSustellV2 = (
  formData: DairyV2Baseline,
  isDraft: boolean,
) => {
  try {
    let mappedData = createCorePayloadSustell(formData, isDraft);
    // TODO - Map resource is not typed, so it's not possible to fix this issue yet.
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    mappedData = mapResourceUseSustell(formData, mappedData);

    if (formData?.stages && !Array.isArray(formData.stages)) return mappedData;

    return formData.stages.reduce(
      (payload, stage) => {
        if (!stage?.stageData) return mappedData;

        const mappedStage = mapStageData(stage);

        return {
          ...payload,
          stages: [
            ...payload.stages,
            {
              ...mappedStage,
              stageData: JSON.stringify(mappedStage.stageData),
            },
          ],
        };
      },
      { ...mappedData },
    );
  } catch (err) {
    console.error('Error on mapBeefProductionDataOutSustell: ', err);
  }

  return {};
};

export default mapDairyProductionDataOutSustellV2;
