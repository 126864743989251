import * as Yup from 'yup';
import { StageType } from '../../../../../graphql/types';
import { usedResourcesPart } from './baselineValidationSchemaGeneralPart';
import {
  numericOptionalWithGreaterThanMin,
  numericOptionalWithMin,
  numericOptionalWithMinMax,
  numericRequiredWithGreaterThanMin,
  numericRequiredWithMax,
  numericRequiredWithMin,
  numericRequiredWithMinMax,
} from './validationObjectBuilderFunctions';
import {
  availableMmsTypeForMonthlyStoragePeriods,
  availableMmsTypeForOverOrUnderStoragePeriod,
} from '../../../../sustell_15/models/Baseline/DairyV2Baseline';
import { IntlShape } from 'react-intl';

const outputCowSumValidation = (intl: IntlShape) =>
  numericRequiredWithMin(intl, 0).test(
    'outputCowSum',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { parent } = testContext;

      const cowSum = (isNaN(parent.cowsToAnotherStage) ? 0 : Number(parent.cowsToAnotherStage))
        + (isNaN(parent.cowsSold) ? 0 : Number(parent.cowsSold));

      if (cowSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.OUTPUT.SUM_OF_COW.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const outputCalvesSumValidation = (intl: IntlShape) =>
  numericRequiredWithMin(intl, 0).test(
    'outputCalvesSum',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { parent } = testContext;

      const cowSum = (isNaN(parent.weanedCalvesToAnotherStage) ? 0 : Number(parent.weanedCalvesToAnotherStage))
        + (isNaN(parent.weanedCalvesSold) ? 0 : Number(parent.weanedCalvesSold));

      if (cowSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.OUTPUT.SUM_OF_CALVES.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const averageWeightOutputValidation = (intl: IntlShape) =>
  numericRequiredWithGreaterThanMin(intl, 0).test(
    'averageWeightOutput',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { from }: any = testContext;

      const averageWeightInput = from[1].value.input.averageWeightNewAnimals ?? 0;

      if (value && (value <= averageWeightInput)) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT.GREATER_THAN_AVERAGE_WEIGHT_INPUT',
          }),
        });
      }

      return true;
    }
  );

const averageAgeOutputValidation = (intl: IntlShape) =>
  numericRequiredWithGreaterThanMin(intl, 0).test(
    'averageAgeOutput',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { from }: any = testContext;

      const averageAgeInput = from[1].value.input.averageAge ?? 0;

      if (value && (value <= averageAgeInput)) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_AGE.GREATER_THAN_AVERAGE_AGE_INPUT',
          }),
        });
      }

      return true;
    }
  );

const cowMortalityValidation = (intl: IntlShape) =>
  numericRequiredWithMin(intl, 0).test(
    'cowMortality',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { from }: any = testContext;

      const internalSources = from[1].value.input.internalSources;
      const externalSources = from[1].value.input.externalSources;

      let animalsSum = 0;

      if (internalSources) {
        internalSources.forEach((source: any) => {
          animalsSum += source?.numberAnimals ? Number(source.numberAnimals) : 0;
        })
      }

      if (externalSources) {
        externalSources.forEach((source: any) => {
          animalsSum += source?.numberAnimals ? Number(source.numberAnimals) : 0;
        })
      }

      const animalsStartDate = from[1].value.input.animalsPresentAtStart
        ? Number(from[1].value.input.animalsPresentAtStart)
        : 0;

      animalsSum += animalsStartDate;

      if (value && (value >= animalsSum)) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.OUTPUT.COW_MORTALITY.LESS_THAN_NEW_ANIMALS',
          }),
        });
      }

      return true;
    }
  );

const averageWeightMortalityOutputValidation = (intl: IntlShape) =>
  numericRequiredWithMin(intl, 0).test(
    'averageWeightMortalityOutput',
    '',
    function (value, testContext) {
      const { path, createError } = this;
      const { parent, from }: any = testContext;

      const averageWeightOutput = parent.averageWeightOfCowsLeavingTheStage ?? 0;
      const numberOfMortalitiesCows = parent.numberOfMortalitiesCows ?? 0;
      const averageWeightInput = from[1].value.input.averageWeightNewAnimals ?? 0;

      if (value && numberOfMortalitiesCows > 0 && (value < averageWeightInput || value > averageWeightOutput)) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_MORTALITY.BETWEEN_AVERAGE_WEIGHTS',
          }),
        });
      }

      if (numberOfMortalitiesCows > 0 && value === 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.OUTPUT.AVERAGE_WEIGHT_MORTALITY.CANNOT_BE_ZERO',
          }),
        });
      }

      return true;
    }
  );

const feedSumValidation = (intl: IntlShape) =>
  numericOptionalWithMin(intl, 0).test(
    'feedSum',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from }: any = testContext;

      const compoundFeeds = from[1].value.compoundFeeds;
      const singleIngredients = from[1].value.singleIngredients;
      const freshGrass = from[1].value.freshGrass;

      let feedSum = 0;

      if (compoundFeeds) {
        compoundFeeds.forEach((feed: any) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (singleIngredients) {
        singleIngredients.forEach((feed: any) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (freshGrass) {
        freshGrass.forEach((feed: any) => {
          feedSum += feed?.amount ? Number(feed.amount) : 0;
        })
      }

      if (feedSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.FEED.SUM_OF_FEED.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const feedRequiredSumValidation = (intl: IntlShape) =>
  numericRequiredWithMin(intl, 0).test(
    'feedRequiredSum',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from }: any = testContext;

      const compoundFeeds = from[1].value.compoundFeeds;
      const singleIngredients = from[1].value.singleIngredients;
      const freshGrass = from[1].value.freshGrass;

      let feedSum = 0;

      if (compoundFeeds) {
        compoundFeeds.forEach((feed: any) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (singleIngredients) {
        singleIngredients.forEach((feed: any) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (freshGrass) {
        freshGrass.forEach((feed: any) => {
          feedSum += feed?.amount ? Number(feed.amount) : 0;
        })
      }

      if (feedSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.FEED.SUM_OF_FEED.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const feedSumGrowingValidation = (intl: IntlShape) =>
  numericOptionalWithMin(intl, 0).test(
    'feedSumGrowing',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from }: any = testContext;

      const compoundFeeds = from[1].value.compoundFeeds;
      const singleIngredients = from[1].value.singleIngredients;
      const freshGrass = from[1].value.freshGrass;
      const mothersMilk = from[1].value.mothersMilk;

      let feedSum = 0;

      if (compoundFeeds) {
        compoundFeeds.forEach((feed: any) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (singleIngredients) {
        singleIngredients.forEach((feed: any) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (freshGrass) {
        freshGrass.forEach((feed: any) => {
          feedSum += feed?.amount ? Number(feed.amount) : 0;
        })
      }

      if (mothersMilk) {
        feedSum += mothersMilk.amount ? Number(mothersMilk.amount) : 0;
      }

      if (feedSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.FEED.SUM_OF_FEED.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const feedRequiredSumGrowingValidation = (intl: IntlShape) =>
  numericRequiredWithMin(intl, 0).test(
    'feedRequiredSumGrowing',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from }: any = testContext;

      const compoundFeeds = from[1].value.compoundFeeds;
      const singleIngredients = from[1].value.singleIngredients;
      const freshGrass = from[1].value.freshGrass;
      const mothersMilk = from[1].value.mothersMilk;

      let feedSum = 0;

      if (compoundFeeds) {
        compoundFeeds.forEach((feed: any) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (singleIngredients) {
        singleIngredients.forEach((feed: any) => {
          feedSum += feed?.kgPerAnimal ? Number(feed.kgPerAnimal) : 0;
        })
      }

      if (freshGrass) {
        freshGrass.forEach((feed: any) => {
          feedSum += feed?.amount ? Number(feed.amount) : 0;
        })
      }

      if (mothersMilk) {
        feedSum += mothersMilk.amount ? Number(mothersMilk.amount) : 0;
      }

      if (feedSum <= 0) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.FEED.SUM_OF_FEED.GREATER_THAN_ZERO',
          }),
        });
      }

      return true;
    }
  );

const housingTimeValidation = (intl: IntlShape) =>
  numericRequiredWithMinMax(intl, 0, 100).test(
    'housingTime',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { parent } = testContext;

      const timeSum = (Number.isNaN(parent.timeInHousing) ? 0 : Number(parent.timeInHousing))
        + (Number.isNaN(parent.timeInFeedlot) ? 0 : Number(parent.timeInFeedlot))
        + (Number.isNaN(parent.timeGrazingLargeAreas) ? 0 : Number(parent.timeGrazingLargeAreas))
        + (Number.isNaN(parent.timeGrazingPastures) ? 0 : Number(parent.timeGrazingPastures))

      if (timeSum !== 100) {
        return createError({
          path,
          message: intl.formatMessage({
            id: 'SUSTELL.STAGE.DAIRY.HOUSING.TIME_SUM_MUST_BE_100',
          }),
        });
      }

      return true;
    }
  );

const emissionsCalculateValidation = () =>
  Yup.string()
    .test(
      'emissionsCalculate',
      '',
      function (value, testContext) {
        const { path, createError } = this;
        const { parent } = testContext;

        if ((parent.NOPdose
          && parent.averageFatDMI
          && parent.cowsFedSupplement)
          && !value) {
          return createError({
            path,
            message: '',
          });
        }

        return true;
      }
    );

const isStoragePeriodRequired = (selectedMmsType: any) =>
  availableMmsTypeForMonthlyStoragePeriods.some(
    (mmsType) => mmsType === selectedMmsType
  ) ||
  availableMmsTypeForOverOrUnderStoragePeriod.some(
    (mmsType) => mmsType === selectedMmsType
  );

const haveMoreThanOneMms = (from: any) => {
  if (from) {
    return from[1].value.manureSystems.length > 1;
  }

  return false;
};

const manureShareValidation = (intl: IntlShape) =>
  numericRequiredWithMin(intl, 0).test(
    'manureShare',
    '',
    function (_, testContext) {
      const { path, createError } = this;
      const { from }: any = testContext;

      if (from) {
        let shareSum = 0;

        from[1].value.manureSystems?.forEach((mms: any) => {
          shareSum += mms?.share ? Number(mms.share) : 0;
        });

        if (shareSum !== 100) {
          return createError({
            path,
            message: intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.HOUSING.SHARE_SUM_MUST_BE_100',
            }),
          });
        }
      }

      return true;
    }
  );

const internalSourcesContinuousGrowingValidation = (intl: IntlShape) =>
  Yup.array().of(
    Yup.object({
      farmId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      originStageId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      animalType: Yup.string()
        .when('stageType', {
          is: (value: StageType) => value === StageType.DairyCow,
          then: Yup.string().required(
            intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
          ),
          otherwise: Yup.string()
        }),
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      shrinkingRate: numericOptionalWithMinMax(intl, 0, 100)
    })
  ).min(1);

const externalSourcesValidation = (intl: IntlShape) =>
  Yup.array().of(
    Yup.object({
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      shrinkingRate: numericOptionalWithMinMax(intl, 0, 100)
    })
  ).min(1);

const internalSourcesDairyCowValidation = (intl: IntlShape) =>
  Yup.array().of(
    Yup.object({
      farmId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      originStageId: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
      numberAnimals: numericRequiredWithGreaterThanMin(intl, 0),
      shrinkingRate: numericOptionalWithMinMax(intl, 0, 100)
    })
  ).min(1);

export const stageInputDairyCow = (intl: IntlShape) =>
  Yup.object().shape({
    animalsPresentAtStart: numericRequiredWithMin(intl, 0),
    animalsPresentAtEnd: numericRequiredWithMin(intl, 0),
    averageWeightAtStart: Yup.number()
      .when('animalsPresentAtStart', {
        is: (value: number) => value > 0,
        then: numericRequiredWithGreaterThanMin(intl, 0),
        otherwise: numericRequiredWithMin(intl, 0)
          .when('animalsPresentAtStart', {
            is: (value: number) => value === 0,
            then: numericRequiredWithMax(intl, 0),
            otherwise: numericRequiredWithMin(intl, 0)
          }),
      }),
    averageWeightNewAnimals: numericRequiredWithGreaterThanMin(intl, 0),
    internalSources: Yup.array().when('externalSources', {
      is: (externalSources: any) =>
        externalSources === undefined ||
        (Array.isArray(externalSources) &&
          !externalSources.some(
            (item) => !Number.isNaN(item.numberAnimals) && item.numberAnimals > 0
          )),
      then: internalSourcesDairyCowValidation(intl),
    }),
    externalSources: Yup.array().when('internalSources', {
      is: (internalSources: any) =>
        internalSources === undefined ||
        (Array.isArray(internalSources) &&
          !internalSources.some(
            (item) => !Number.isNaN(item.numberAnimals) && item.numberAnimals > 0
          )),
      then: externalSourcesValidation(intl),
    }),
  },
    [['internalSources', 'externalSources']]
  ); // to avoid circular dependency error

export const stageInputContinuousGrowing = (intl: IntlShape) =>
  Yup.object().shape({
    animalsPresentAtStart: numericRequiredWithGreaterThanMin(intl, 0),
    animalsPresentAtEnd: numericRequiredWithGreaterThanMin(intl, 0),
    averageWeightAtStart: Yup.number()
      .when('animalsPresentAtStart', {
        is: (value: number) => value > 0,
        then: numericRequiredWithGreaterThanMin(intl, 0),
        otherwise: numericRequiredWithMin(intl, 0)
      }),
    averageWeightNewAnimals: numericRequiredWithGreaterThanMin(intl, 0),
    averageAge: numericRequiredWithMin(intl, 0),
    internalSources: Yup.array().when('externalSources', {
      is: (externalSources: any) =>
        externalSources === undefined ||
        (Array.isArray(externalSources) &&
          !externalSources.some(
            (item) => !Number.isNaN(item.numberAnimals) && item.numberAnimals > 0
          )),
      then: internalSourcesContinuousGrowingValidation(intl),
    }),
    externalSources: Yup.array().when('internalSources', {
      is: (internalSources: any) =>
        internalSources === undefined ||
        (Array.isArray(internalSources) &&
          !internalSources.some(
            (item) => !Number.isNaN(item.numberAnimals) && item.numberAnimals > 0
          )),
      then: externalSourcesValidation(intl),
    }),
  },
    [['internalSources', 'externalSources']]
  ); // to avoid circular dependency error

export const stageFeedDairyCow = (intl: IntlShape) =>
  Yup.object().shape({
    compoundFeeds: Yup.array().of(
      Yup.object({
        feedType: Yup.string(),
        kgPerAnimal: Yup.number()
          .when('feedType', {
            is: (value: any) => value,
            then: feedRequiredSumValidation(intl),
            otherwise: feedSumValidation(intl)
          })
      }),
    ),
    singleIngredients: Yup.array().of(
      Yup.object({
        feedType: Yup.string(),
        kgPerAnimal: Yup.number()
          .when('feedType', {
            is: (value: any) => value,
            then: feedRequiredSumValidation(intl),
            otherwise: feedSumValidation(intl),
          }),
        origin: Yup.string()
          .when('feedType', {
            is: (value: any) => value,
            then: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
            otherwise: Yup.string(),
          })
      }),
    ),
    freshGrass: Yup.array().of(
      Yup.object({
        type: Yup.string(),
        amount: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: feedRequiredSumValidation(intl),
            otherwise: feedSumValidation(intl)
          }),
        digestibility: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
        crudeProteinContent: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
        dryMatterContent: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
        neutralDetergentFiber: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
      }),
    )
  });

export const stageFeedContinuousGrowing = (intl: IntlShape) =>
  Yup.object().shape({
    dietCharacterisation: Yup.string()
      .required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
    compoundFeeds: Yup.array().of(
      Yup.object({
        feedType: Yup.string(),
        kgPerAnimal: Yup.number()
          .when('feedType', {
            is: (value: any) => value,
            then: feedRequiredSumGrowingValidation(intl),
            otherwise: feedSumGrowingValidation(intl)
          })
      }),
    ),
    singleIngredients: Yup.array().of(
      Yup.object({
        feedType: Yup.string(),
        kgPerAnimal: Yup.number()
          .when('feedType', {
            is: (value: any) => value,
            then: feedRequiredSumGrowingValidation(intl),
            otherwise: feedSumGrowingValidation(intl),
          }),
        origin: Yup.string()
          .when('feedType', {
            is: (value: any) => value,
            then: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })),
            otherwise: Yup.string(),
          })
      }),
    ),
    freshGrass: Yup.array().of(
      Yup.object({
        type: Yup.string(),
        amount: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: feedRequiredSumGrowingValidation(intl),
            otherwise: feedSumGrowingValidation(intl)
          }),
        digestibility: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
        crudeProteinContent: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
        dryMatterContent: Yup.number()
          .when('type', {
            is: (value: any) => value,
            then: numericRequiredWithMin(intl, 0),
            otherwise: numericOptionalWithMin(intl, 0)
          }),
      }),
    ),
    mothersMilk: Yup.object({
      isMothersMilk: Yup.string(),
      amount: Yup.number()
        .when('isMothersMilk', {
          is: (value: string) => value === 'true',
          then: feedRequiredSumGrowingValidation(intl),
          otherwise: feedSumGrowingValidation(intl),
        }),
      digestibility: Yup.number()
        .when('isMothersMilk', {
          is: (value: string) => value === 'true',
          then: numericRequiredWithMin(intl, 0),
          otherwise: numericOptionalWithMin(intl, 0)
        }),
      crudeProteinContent: Yup.number()
        .when('isMothersMilk', {
          is: (value: string) => value === 'true',
          then: numericRequiredWithMin(intl, 0),
          otherwise: numericOptionalWithMin(intl, 0)
        }),
      dryMatterContent: Yup.number()
        .when('isMothersMilk', {
          is: (value: string) => value === 'true',
          then: numericRequiredWithMin(intl, 0),
          otherwise: numericOptionalWithMin(intl, 0)
        }),
    })
  });

export const stageHousingDairyCow = (intl: IntlShape) =>
  Yup.object({
    timeInHousing: housingTimeValidation(intl),
    timeInFeedlot: housingTimeValidation(intl),
    timeGrazingLargeAreas: housingTimeValidation(intl),
    timeGrazingPastures: housingTimeValidation(intl),
    manureSystems: Yup.array().when('timeInHousing', {
      is: (value: number) => value > 0,
      then: Yup.array()
        .of(
          Yup.object({
            mmsType: Yup.string().required(
              intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
            ),
            storagePeriod: Yup.string().when('mmsType', {
              is: (val: string) => isStoragePeriodRequired(val),
              then: Yup.string().required(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
              otherwise: Yup.string(),
            }),
            share: Yup.lazy((_, { from }: any) =>
              haveMoreThanOneMms(from)
                ? manureShareValidation(intl)
                : numericOptionalWithMin(intl, 0)
            ),
          })
        )
        .min(1, intl.formatMessage({ id: 'SUSTELL.HOUSING.MMS.MIN1.ERROR' })),
    }),
    beddingSystems: Yup.array().of(
      Yup.object({
        beddingType: Yup.string(),
        beddingAmount: Yup.number().when('beddingType', {
          is: (val: any) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
    timeInHousingTied: Yup.number()
      .when('timeInHousing', {
        is: (value: number) => value > 0,
        then: numericRequiredWithMinMax(intl, 0, 100),
        otherwise: numericOptionalWithMinMax(intl, 0, 100)
      }),
  });

export const stageHousingContinuousGrowing = (intl: IntlShape) =>
  Yup.object({
    timeInHousing: housingTimeValidation(intl),
    timeInFeedlot: housingTimeValidation(intl),
    timeGrazingLargeAreas: housingTimeValidation(intl),
    timeGrazingPastures: housingTimeValidation(intl),
    manureSystems: Yup.array().when('timeInHousing', {
      is: (value: number) => value > 0,
      then: Yup.array()
        .of(
          Yup.object({
            mmsType: Yup.string().required(
              intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
            ),
            storagePeriod: Yup.string().when('mmsType', {
              is: (val: string) => isStoragePeriodRequired(val),
              then: Yup.string().required(
                intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
              ),
              otherwise: Yup.string(),
            }),
            share: Yup.lazy((_, { from }: any) =>
              haveMoreThanOneMms(from)
                ? manureShareValidation(intl)
                : numericOptionalWithMin(intl, 0)
            ),
          })
        )
        .min(1, intl.formatMessage({ id: 'SUSTELL.HOUSING.MMS.MIN1.ERROR' })),
    }),
    beddingSystems: Yup.array().of(
      Yup.object({
        beddingType: Yup.string(),
        beddingAmount: Yup.number().when('beddingType', {
          is: (val: any) => val,
          then: numericRequiredWithGreaterThanMin(intl, 0),
          otherwise: numericOptionalWithGreaterThanMin(intl, 0),
        }),
      })
    ),
  });

export const stageOutputDairyCow = (intl: IntlShape) =>
  Yup.object({
    yearlyMilkProduction: numericRequiredWithMin(intl, 0),
    averageMilkFat: numericRequiredWithMinMax(intl, 0, 100),
    averageMilkProtein: numericRequiredWithMinMax(intl, 0, 100),
    cowsToAnotherStage: outputCowSumValidation(intl),
    averageWeightOfCowsLeavingTheStage: averageWeightOutputValidation(intl),
    cowsSold: outputCowSumValidation(intl),
    numberOfMortalitiesCows: cowMortalityValidation(intl),
    averageWeightAtMortalityCows: averageWeightMortalityOutputValidation(intl),
    averageWeightAtBirth: numericRequiredWithMin(intl, 0),
    weanedCalvesToAnotherStage: outputCalvesSumValidation(intl),
    weanedCalvesSold: outputCalvesSumValidation(intl),
  });

export const stageOutputContinuousGrowing = (intl: IntlShape) =>
  Yup.object({
    cowsToAnotherStage: outputCowSumValidation(intl),
    averageWeightOfCowsLeavingTheStage: averageWeightOutputValidation(intl),
    cowsSold: outputCowSumValidation(intl),
    averageAge: averageAgeOutputValidation(intl),
    numberOfMortalitiesCows: cowMortalityValidation(intl),
    averageWeightAtMortalityCows: averageWeightMortalityOutputValidation(intl),
  });

export const stageEmissions = (intl: IntlShape) =>
  Yup.object({
    emissionMitigations: Yup.object().shape({
      methaneEntericFermentation: numericOptionalWithMinMax(intl, -100, 100),
      nmvoc: numericOptionalWithMinMax(intl, -100, 100),
      methaneMms: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideLeaching: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideDirect: numericOptionalWithMinMax(intl, -100, 100),
      nitrousOxideVolatilization: numericOptionalWithMinMax(intl, -100, 100),
      nitrogenOxidesStorage: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaStorage: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaHousing: numericOptionalWithMinMax(intl, -100, 100),
      ammoniaYard: numericOptionalWithMinMax(intl, -100, 100),
      pm2_5: numericOptionalWithMinMax(intl, -100, 100),
      pm10: numericOptionalWithMinMax(intl, -100, 100),
      tsp: numericOptionalWithMinMax(intl, -100, 100),
    }),
    NOP: Yup.string(),
    NOPdose: Yup.number()
      .when('NOP', {
        is: (value: string) => value === 'true',
        then: numericRequiredWithMin(intl),
        otherwise: numericOptionalWithMin(intl)
      }),
    averageFatDMI: Yup.number()
      .when('NOP', {
        is: (value: string) => value === 'true',
        then: numericRequiredWithMin(intl),
        otherwise: numericOptionalWithMin(intl)
      }),
    cowsFedSupplement: Yup.number()
      .when('NOP', {
        is: (value: string) => value === 'true',
        then: numericRequiredWithMin(intl),
        otherwise: numericOptionalWithMin(intl)
      }),
    calculatedMethaneFermentation: emissionsCalculateValidation(),
  });

// stage fields validation rules
export const stageDataPartDairy = (confObj: { intl: IntlShape; userUOM?: any; }) =>
  Yup.object({
    stages: Yup.array()
      .of(
        Yup.object({
          id: Yup.string(),
          name: Yup.string()
            .required(confObj.intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
            .min(
              3,
              confObj.intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                { count: 3 }
              )
            ),
          type: Yup.string()
            .oneOf([
              StageType.DairyCow,
              StageType.ContinuousGrowing,
            ])
            .required(),
          stageData: Yup.object()
            .when('type', {
              is: StageType.DairyCow,
              then: Yup.object({
                input: stageInputDairyCow(confObj.intl),
                housing: stageHousingDairyCow(confObj.intl),
                feed: stageFeedDairyCow(confObj.intl),
                output: stageOutputDairyCow(confObj.intl),
                emissions: stageEmissions(confObj.intl),
              }),
            })
            .when('type', {
              is: StageType.ContinuousGrowing,
              then: Yup.object({
                input: stageInputContinuousGrowing(confObj.intl),
                housing: stageHousingContinuousGrowing(confObj.intl),
                feed: stageFeedContinuousGrowing(confObj.intl),
                output: stageOutputContinuousGrowing(confObj.intl),
                emissions: stageEmissions(confObj.intl),
              }),
            }),
        })
      )
      .required()
      .min(1, confObj.intl.formatMessage({ id: 'SUSTELL.STAGE.MIN.REQUIRED' })),
  });

// merge all necessary parts to baseSchema
const assembleValidationSchemaSustellDairyV2 = (
  baseSchema: any,
  confObj: { intl: IntlShape; userUOM?: any; }
) => {
  const infoObject = baseSchema;
  const combinedSchema = Yup.object({ info: infoObject })
    .concat(Yup.object({ resourceUse: usedResourcesPart(confObj) }))
    .concat(stageDataPartDairy(confObj));
  return combinedSchema;
};

export default assembleValidationSchemaSustellDairyV2;
