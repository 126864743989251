/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  IngredientUsesV2Input,
  IngredientV2Input,
  CompoundFeedDatabase,
  CreateCompoundFeedInputV2,
  UpdateCompoundFeedInputV2,
  Transport,
  OnSiteActivity,
  ResourceUseType,
  NutritionalAnalysis,
  NutritionalAnalysisType,
  TransportInput,
  TransportMode,
  FeedFootprintType,
} from '../../../../../graphql/types';
import {
  defaultUnitsV2,
  explicitConvertValue,
} from '../../../utils/unit-utils';
import {
  convertIngredientInputMassV2,
  convertOnSiteActivityUnitsV2,
} from './UnitsConverterV2';
import {
  IngredientNamesAndOriginsV2,
  CompoundFeedFormExtended,
  IngredientsUsesExtended,
  ResourceItem,
} from '../types';
import {
  CUSTOM_INGREDIENT_ID_SEPARATOR,
  ADDON_INGREDIENT_SEPARATOR,
  isPoultrySystem,
} from '../utils';
import lookupValues from '../../../helpers/lookupValues';
import { floatValueInDefaultUnit } from '../../../../modules/Farms/Baseline/mapper/floatHelperFunctions';

const getTransportModeUnits = (
  mode: TransportMode | undefined,
  userUOM: any
): { defaultUnit: any; userUnit: any } | undefined => {
  switch (mode) {
    case TransportMode.InlandShip:
      return {
        defaultUnit: defaultUnitsV2.unitTransportDistanceInlandWaterways,
        userUnit: userUOM.unitTransportDistanceInlandWaterways,
      };
    case TransportMode.SeaShip:
      return {
        defaultUnit: defaultUnitsV2.unitTransportDistanceSea,
        userUnit: userUOM.unitTransportDistanceSea,
      };
    case TransportMode.Train:
      return {
        defaultUnit: defaultUnitsV2.unitTransportDistanceTerrestrial,
        userUnit: userUOM.unitTransportDistanceTerrestrial,
      };
    case TransportMode.Truck:
      return {
        defaultUnit: defaultUnitsV2.unitTransportDistanceTerrestrial,
        userUnit: userUOM.unitTransportDistanceTerrestrial,
      };
    default:
      return undefined;
  }
};

const getTransportModeFromFieldName = (
  name: string
): TransportMode | undefined => {
  switch (name) {
    case 'inland_ship':
      return TransportMode.InlandShip;
    case 'sea_ship':
      return TransportMode.SeaShip;
    case 'train':
      return TransportMode.Train;
    case 'truck':
      return TransportMode.Truck;
    default:
      return undefined;
  }
};

const mapTransportObjectToV2 = (
  transportObj: any,
  userUOM: any
): TransportInput[] => {
  const retVal: TransportInput[] = [];
  if (!transportObj) {
    return retVal;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.keys(transportObj).forEach((key) => {
    const mode = getTransportModeFromFieldName(key);
    const units = getTransportModeUnits(mode, userUOM);
    const value = transportObj[key];

    if (units && value && mode) {
      const convertedValue = explicitConvertValue(
        value,
        units.userUnit,
        units.defaultUnit
      );

      if (convertedValue) {
        retVal.push({
          mode,
          distance: convertedValue,
        });
      }
    }
  });

  return retVal;
};

const getResorceTypeFromFieldName = (
  name: string
): ResourceUseType | undefined => {
  const truncName = name.replace('_use', '').replace('Use', '');
  let resourceType;
  if (truncName === 'electricity') {
    resourceType = ResourceUseType.Electricity;
  } else if (truncName === 'gas') {
    resourceType = ResourceUseType.NaturalGas;
  } else if (truncName === 'heat') {
    resourceType = ResourceUseType.Propane;
  } else if (truncName === 'water') {
    resourceType = ResourceUseType.Water;
  } else if (truncName === 'electricityPhotovoltaic') {
    resourceType = ResourceUseType.ElectricityPhotovoltaic;
  } else if (truncName === 'electricityWind') {
    resourceType = ResourceUseType.ElectricityWind;
  } else if (truncName === 'diesel') {
    resourceType = ResourceUseType.Diesel;
  } else if (truncName === 'gasoline') {
    resourceType = ResourceUseType.Gasoline;
  } else if (truncName === 'heavyFuelOil') {
    resourceType = ResourceUseType.HeavyFuelOil;
  } else if (truncName === 'propaneOrLpg') {
    resourceType = ResourceUseType.PropaneOrLpg;
  } else if (truncName === 'coal') {
    resourceType = ResourceUseType.Coal;
  }
  return resourceType;
};

const mapOnSiteAcitiviesToV2 = (
  onSiteActivitiesObj: any,
  userUOM: any
): OnSiteActivity[] => {
  const retVal: OnSiteActivity[] = [];
  if (!onSiteActivitiesObj) {
    return retVal;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.keys(onSiteActivitiesObj).forEach((key) => {
    const value = onSiteActivitiesObj[key];
    if (key === 'energyTypes' || key === 'selfGeneratedRenewables') {
      value?.forEach((item: ResourceItem) => {
        const type = getResorceTypeFromFieldName(item.type);
        if (item.value && type && !Number.isNaN(item.value)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const convertedValue = convertOnSiteActivityUnitsV2(
            Number(item.value),
            type,
            userUOM,
            1,
            true
          )?.value;

          retVal.push({
            resourceType: type,
            amount: convertedValue,
          });
        }
      });
    } else {
      const type = getResorceTypeFromFieldName(key);
      if (value && type && !Number.isNaN(value)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const convertedValue = convertOnSiteActivityUnitsV2(
          Number(value),
          type,
          userUOM,
          1,
          true
        )?.value;
        retVal.push({
          resourceType: type,
          amount: convertedValue,
        });
      }
    }
  });

  return retVal;
};

const processIngredientUses = (
  ingredient_uses: IngredientsUsesExtended[] | [] | undefined,
  userUOM: any,
  ingredientOriginsData: IngredientNamesAndOriginsV2,
  submitData: CompoundFeedFormExtended
): IngredientUsesV2Input[] => {
  if (!ingredient_uses || ingredient_uses.length === 0) {
    return [];
  }

  const ingredientUses: IngredientUsesV2Input[] = [];
  (ingredient_uses || []).forEach((ing: IngredientsUsesExtended) => {
    if (!ing || ing === null) {
      return;
    }

    const quantity =
      convertIngredientInputMassV2(ing?.quantity, userUOM, 1) || 0;
    const inboundTransportV2 = mapTransportObjectToV2(
      ing?.inbound_transport_input,
      userUOM
    );

    if (!quantity) {
      // eslint-disable-next-line no-console
      console.warn('error in quantity for ing: ', ing);
    }

    if (!ing.name || ing.name === null || !ing.origin || ing.origin === null) {
      // eslint-disable-next-line no-console
      console.warn('No value for ingredient/origin:', ing);
      return;
    }

    // Here put infor to indicate proxy, and to present ingredient names in proper format
    const tags = [];

    // eslint-disable-next-line prefer-const
    let { name, origin } = ing;
    let originalOrigin;
    if (ing.proxyOrigin && ing.proxyOriginCode) {
      originalOrigin = origin;
      origin = ing.proxyOriginCode;
      tags.push({
        name: 'originalOriginCode',
        value: originalOrigin,
      });
    }
    // Create tag with lookup name to be used for footprints
    let lookupName = name;

    let customName;
    if (
      ing.customIngredient &&
      ing.name.split(CUSTOM_INGREDIENT_ID_SEPARATOR)?.length > 1
    ) {
      const split = ing.name.split(CUSTOM_INGREDIENT_ID_SEPARATOR);
      name = split.at(0) || '';
      lookupName = name;
      customName = split.at(1) || 'custom name';
      tags.push({
        name: 'customName',
        value: customName,
      });
      lookupName = `${customName} (${name})`;
    }

    if (ing.name.indexOf(ADDON_INGREDIENT_SEPARATOR) > -1) {
      lookupName = `${name.replace(ADDON_INGREDIENT_SEPARATOR, ` - Addon (`)})`;
      // eslint-disable-next-line prefer-destructuring
      name = ing.name.split(ADDON_INGREDIENT_SEPARATOR)[0];
    }

    const feedmillLocationName =
      ingredientOriginsData?.origins.find(
        (o) => o.isoCode === submitData.feedmill_location.iso_code
      )?.name || 'unknown';
    const originName =
      ingredientOriginsData?.origins.find((o) => o.isoCode === origin)?.name ||
      'unknown';

    lookupName += ` from ${originName} to ${feedmillLocationName}`;
    tags.push({
      name: 'lookupValue',
      value: lookupName,
    });

    const ingredient: IngredientV2Input = {
      name,
      origin,
    };

    if (ing.addonType) {
      ingredient.addonType = ing.addonType;
      tags.push({
        name: 'addonType',
        value: ing.addonType,
      });
    }

    ingredient.tags = tags;

    ingredientUses.push({
      ingredient,
      originalOrigin,
      isProxy: ing.proxyOrigin,
      customIngredient: ing.customIngredient,
      customName,
      quantity: Number(quantity),
      inboundTransport: inboundTransportV2,
    });
  });

  return ingredientUses;
};

const mapCompoundFeedDataV2Out = (
  ingredientOriginsData: IngredientNamesAndOriginsV2,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userUOM: any,
  submitData: CompoundFeedFormExtended,
  databaseFoundation: CompoundFeedDatabase,
  oldName: string | undefined,
  isUpdate = false,
  targetSpecies: string
): CreateCompoundFeedInputV2 | UpdateCompoundFeedInputV2 => {
  const outboundTransport: Transport[] = mapTransportObjectToV2(
    submitData.outbound_transport_input,
    userUOM
  );
  const onSiteActivities: OnSiteActivity[] = mapOnSiteAcitiviesToV2(
    submitData.on_site_activities_input,
    userUOM
  );

  const userEnergyUOM = `${userUOM.unitNutritionalGrossEnergy}/${userUOM.unitIngredientQuantityInputMass}`;
  const defaultEnergyUOM = `${defaultUnitsV2.unitNutritionalGrossEnergy}/${defaultUnitsV2.unitIngredientQuantityInputMass}`;

  const nutritionalAnalysisData: NutritionalAnalysis[] = [];

  if (
    targetSpecies === lookupValues.animalTypeFeedAnimalSystem.LITOPENAEUS_VANNAMEI &&
    (databaseFoundation === CompoundFeedDatabase.Gfli ||
      databaseFoundation === CompoundFeedDatabase.Afp_6_3)
  ) {
    const nutAnalysis = submitData.nutritional_analysis_data;

    if (
      nutAnalysis?.crudeProteinContent &&
      !Number.isNaN(nutAnalysis?.crudeProteinContent)
    ) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.CrudeProteinContent,
        value: Number(nutAnalysis?.crudeProteinContent),
        unit: 'percentage', // Change if needed
      });
    }
  }

  if (
    isPoultrySystem(targetSpecies) &&
    (databaseFoundation === CompoundFeedDatabase.Gfli ||
      databaseFoundation === CompoundFeedDatabase.Afp_6_3)
  ) {
    const nutAnalysis = submitData.nutritional_analysis_data;

    if (
      nutAnalysis?.crudeProteinContent &&
      !Number.isNaN(nutAnalysis?.crudeProteinContent)
    ) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.CrudeProteinContent,
        value: Number(nutAnalysis?.crudeProteinContent),
        unit: 'percentage', // Change if needed
      });
    }

    if (
      nutAnalysis?.ashContent != null &&
      nutAnalysis?.ashContent !== '' &&
      !Number.isNaN(nutAnalysis?.ashContent)
    ) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.AshContent,
        value: Number(nutAnalysis?.ashContent),
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.pContent != null &&
      nutAnalysis?.pContent !== '' &&
      !Number.isNaN(nutAnalysis?.pContent)
    ) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.PContent,
        value: Number(nutAnalysis?.pContent),
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.dryMatterContent != null &&
      nutAnalysis?.dryMatterContent !== '' &&
      !Number.isNaN(nutAnalysis?.dryMatterContent)
    ) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.DryMatterContent,
        value: Number(nutAnalysis?.dryMatterContent),
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.metabolizableEnergyContent != null &&
      nutAnalysis?.metabolizableEnergyContent !== '' &&
      !Number.isNaN(nutAnalysis?.metabolizableEnergyContent)
    ) {
      const numVal = floatValueInDefaultUnit(
        Number(nutAnalysis?.metabolizableEnergyContent),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        userEnergyUOM,
        defaultEnergyUOM
      );
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.MetabolizableEnergyContent,
        value: numVal,
        unit: 'MJ/kg',
      });
    }

    if (
      nutAnalysis?.grossEnergyContent != null &&
      nutAnalysis?.grossEnergyContent !== '' &&
      !Number.isNaN(nutAnalysis?.grossEnergyContent)
    ) {
      const numVal = floatValueInDefaultUnit(
        Number(nutAnalysis?.grossEnergyContent),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        userEnergyUOM,
        defaultEnergyUOM
      );
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.GrossEnergyContent,
        value: numVal,
        unit: 'MJ/kg',
      });
    }
  }

  if (
    targetSpecies === lookupValues.animalTypeFeedAnimalSystem.PIG &&
    (databaseFoundation === CompoundFeedDatabase.Gfli ||
      databaseFoundation === CompoundFeedDatabase.Afp_6_3)
  ) {
    const nutAnalysis = submitData.nutritional_analysis_data;

    if (
      nutAnalysis?.crudeProteinContent &&
      !Number.isNaN(nutAnalysis?.crudeProteinContent)
    ) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.CrudeProteinContent,
        value: Number(nutAnalysis?.crudeProteinContent),
        unit: 'percentage', // Change if needed
      });
    }

    // all other nutritional data have default values,
    // if default set, ingore it, don't save it
    if (
      nutAnalysis?.ashContent != null &&
      nutAnalysis?.ashContent !== '' &&
      !Number.isNaN(nutAnalysis?.ashContent)
    ) {
      const numVal = Number(nutAnalysis?.ashContent);
      if (
        numVal !==
        lookupValues.compoundFeedNutrientsDefault?.[
          targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault
        ]?.ashContent
      )
        nutritionalAnalysisData.push({
          type: NutritionalAnalysisType.AshContent,
          value: numVal,
          unit: 'percentage',
        });
    }

    if (
      nutAnalysis?.phosphorusContent != null &&
      nutAnalysis?.phosphorusContent !== '' &&
      !Number.isNaN(nutAnalysis?.phosphorusContent)
    ) {
      const numVal = Number(nutAnalysis?.phosphorusContent);
      if (
        numVal !==
        lookupValues.compoundFeedNutrientsDefault?.[
          targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault
        ]?.phosphorusContent
      )
        nutritionalAnalysisData.push({
          type: NutritionalAnalysisType.PhosphorusContent,
          value: numVal,
          unit: 'percentage',
        });
    }

    if (
      nutAnalysis?.dryMatterContent != null &&
      nutAnalysis?.dryMatterContent !== '' &&
      !Number.isNaN(nutAnalysis?.dryMatterContent)
    ) {
      const numVal = Number(nutAnalysis?.dryMatterContent);
      if (
        numVal !==
        lookupValues.compoundFeedNutrientsDefault?.[
          targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault
        ]?.dryMatterContent
      )
        nutritionalAnalysisData.push({
          type: NutritionalAnalysisType.DryMatterContent,
          value: numVal,
          unit: 'percentage',
        });
    }

    if (
      nutAnalysis?.digestibleEnergyContent != null &&
      nutAnalysis?.digestibleEnergyContent !== '' &&
      !Number.isNaN(nutAnalysis?.digestibleEnergyContent)
    ) {
      const numVal = floatValueInDefaultUnit(
        Number(nutAnalysis?.digestibleEnergyContent),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        userEnergyUOM,
        defaultEnergyUOM
      );
      if (
        numVal !==
        lookupValues.compoundFeedNutrientsDefault?.[
          targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault
        ]?.digestibleEnergyContent
      )
        nutritionalAnalysisData.push({
          type: NutritionalAnalysisType.DigestibleEnergyContent,
          value: numVal,
          unit: 'MJ/kg',
        });
    }

    if (
      nutAnalysis?.grossEnergyContent != null &&
      nutAnalysis?.grossEnergyContent !== '' &&
      !Number.isNaN(nutAnalysis?.grossEnergyContent)
    ) {
      const numVal = floatValueInDefaultUnit(
        Number(nutAnalysis?.grossEnergyContent),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        userEnergyUOM,
        defaultEnergyUOM
      );
      if (
        numVal !==
        lookupValues.compoundFeedNutrientsDefault?.[
          targetSpecies as keyof typeof lookupValues.compoundFeedNutrientsDefault
        ]?.grossEnergyContent
      )
        nutritionalAnalysisData.push({
          type: NutritionalAnalysisType.GrossEnergyContent,
          value: numVal,
          unit: 'MJ/kg',
        });
    }

    const numVal = floatValueInDefaultUnit(
      Number(lookupValues.compoundFeedNutrientsDefault['Pig system'].urinaryEnergyContent),
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
      userEnergyUOM,
      defaultEnergyUOM
    );

    nutritionalAnalysisData.push({
      type: NutritionalAnalysisType.UrinaryEnergyContent,
      value: numVal,
      unit: 'MJ/kg',
    });
  }

  if (
    targetSpecies === lookupValues.animalTypeFeedAnimalSystem.BEEF &&
    (databaseFoundation === CompoundFeedDatabase.Gfli ||
      databaseFoundation === CompoundFeedDatabase.Afp_6_3)
  ) {
    const nutAnalysis = submitData.nutritional_analysis_data;

    if (
      nutAnalysis?.crudeProteinContent &&
      !Number.isNaN(nutAnalysis?.crudeProteinContent)
    ) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.CrudeProteinContent,
        value: Number(nutAnalysis?.crudeProteinContent),
        unit: 'percentage', // Change if needed
      });
    }

    if (
      nutAnalysis?.ashContent != null &&
      nutAnalysis?.ashContent !== '' &&
      !Number.isNaN(nutAnalysis?.ashContent)
    ) {
      const numVal = Number(nutAnalysis?.ashContent);
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.AshContent,
        value: numVal,
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.dryMatterContent != null &&
      nutAnalysis?.dryMatterContent !== '' &&
      !Number.isNaN(nutAnalysis?.dryMatterContent)
    ) {
      const numVal = Number(nutAnalysis?.dryMatterContent);
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.DryMatterContent,
        value: numVal,
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.digestibleEnergyContent != null &&
      nutAnalysis?.digestibleEnergyContent !== '' &&
      !Number.isNaN(nutAnalysis?.digestibleEnergyContent)
    ) {
      const numVal = Number(nutAnalysis?.digestibleEnergyContent);
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.DigestibleEnergyContent,
        value: numVal,
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.grossEnergyContent != null &&
      nutAnalysis?.grossEnergyContent !== '' &&
      !Number.isNaN(nutAnalysis?.grossEnergyContent)
    ) {
      const numVal = floatValueInDefaultUnit(
        Number(nutAnalysis?.grossEnergyContent),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        userEnergyUOM,
        defaultEnergyUOM
      );
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.GrossEnergyContent,
        value: numVal,
        unit: 'MJ/kg',
      });
    }

    nutritionalAnalysisData.push({
      type: NutritionalAnalysisType.UrinaryEnergyContent,
      value:
        lookupValues.compoundFeedNutrientsDefault[
          lookupValues.animalTypeFeedAnimalSystem.BEEF as keyof typeof 
          lookupValues.compoundFeedNutrientsDefault
        ].urinaryEnergyContent,
      unit: 'percentage',
    });

    if (nutAnalysis?.containsMilk) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.ContainsMilk,
        value: nutAnalysis?.containsMilk === 'Yes' ? 1 : 0,
        unit: '', // Change if needed
      });
    }

    if (nutAnalysis?.milk && !Number.isNaN(nutAnalysis?.milk)) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.Milk,
        value: Number(nutAnalysis?.milk),
        unit: 'percentage', // Change if needed
      });
    }
  }

  if (
    targetSpecies === lookupValues.animalTypeFeedAnimalSystem.DAIRY_V2 &&
    (databaseFoundation === CompoundFeedDatabase.Gfli ||
      databaseFoundation === CompoundFeedDatabase.Afp_6_3)
  ) {
    const nutAnalysis = submitData.nutritional_analysis_data;

    if (
      nutAnalysis?.crudeProteinContent &&
      !Number.isNaN(nutAnalysis?.crudeProteinContent)
    ) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.CrudeProteinContent,
        value: Number(nutAnalysis?.crudeProteinContent),
        unit: 'percentage', // Change if needed
      });
    }

    if (
      nutAnalysis?.ashContent != null &&
      nutAnalysis?.ashContent !== '' &&
      !Number.isNaN(nutAnalysis?.ashContent)
    ) {
      const numVal = Number(nutAnalysis?.ashContent);
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.AshContent,
        value: numVal,
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.dryMatterContent != null &&
      nutAnalysis?.dryMatterContent !== '' &&
      !Number.isNaN(nutAnalysis?.dryMatterContent)
    ) {
      const numVal = Number(nutAnalysis?.dryMatterContent);
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.DryMatterContent,
        value: numVal,
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.digestibleEnergyContent != null &&
      nutAnalysis?.digestibleEnergyContent !== '' &&
      !Number.isNaN(nutAnalysis?.digestibleEnergyContent)
    ) {
      const numVal = Number(nutAnalysis?.digestibleEnergyContent);
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.DigestibleEnergyContent,
        value: numVal,
        unit: 'percentage',
      });
    }

    if (
      nutAnalysis?.grossEnergyContent != null &&
      nutAnalysis?.grossEnergyContent !== '' &&
      !Number.isNaN(nutAnalysis?.grossEnergyContent)
    ) {
      const numVal = floatValueInDefaultUnit(
        Number(nutAnalysis?.grossEnergyContent),
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        userEnergyUOM,
        defaultEnergyUOM
      );
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.GrossEnergyContent,
        value: numVal,
        unit: 'MJ/kg',
      });
    }

    nutritionalAnalysisData.push({
      type: NutritionalAnalysisType.UrinaryEnergyContent,
      value:
        lookupValues.compoundFeedNutrientsDefault[
          lookupValues.animalTypeFeedAnimalSystem.DAIRY_V2 as keyof typeof 
          lookupValues.compoundFeedNutrientsDefault
        ].urinaryEnergyContent,
      unit: 'percentage',
    });

    if (
      nutAnalysis?.neutralDetergentFiber != null &&
      nutAnalysis?.neutralDetergentFiber !== '' &&
      !Number.isNaN(nutAnalysis?.neutralDetergentFiber)
    ) {
      const numVal = Number(nutAnalysis?.neutralDetergentFiber);
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.NeutralDetergentFiber,
        value: numVal,
        unit: 'percentage',
      });
    }

    if (nutAnalysis?.containsMilk) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.ContainsMilk,
        value: nutAnalysis?.containsMilk === 'Yes' ? 1 : 0,
        unit: '', // Change if needed
      });
    }

    if (nutAnalysis?.milk && !Number.isNaN(nutAnalysis?.milk)) {
      nutritionalAnalysisData.push({
        type: NutritionalAnalysisType.Milk,
        value: Number(nutAnalysis?.milk),
        unit: 'percentage', // Change if needed
      });
    }
  }

  const ingredientUses = processIngredientUses(
    submitData.ingredient_uses,
    userUOM,
    ingredientOriginsData,
    submitData
  );

  let retVal: CreateCompoundFeedInputV2 = {
    name: submitData.name,
    year: submitData.year.value || 0,
    feedmillLocation: submitData.feedmill_location.iso_code,
    targetSpecies: submitData.target_species,
    databaseFoundation,
    type: FeedFootprintType.Legacy,
    ingredientUses,
    nutritionalAnalysisData:
      databaseFoundation === CompoundFeedDatabase.Gfli ||
      databaseFoundation === CompoundFeedDatabase.Afp_6_3
        ? nutritionalAnalysisData
        : undefined,
    onSiteActivities,
    outboundTransport,
  };

  if (isUpdate) {
    retVal = {
      ...retVal,
      oldName,
    } as UpdateCompoundFeedInputV2;
  }
  return retVal;
};

export default mapCompoundFeedDataV2Out;
