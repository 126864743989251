/* eslint-disable jsx-a11y/anchor-is-valid */
import { Authenticator, translations } from '@aws-amplify/ui-react';
import { Card } from '@material-ui/core';
import { Amplify, Auth, I18n } from 'aws-amplify';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';

import '../../../../_metronic/_assets/sass/pages/login/classic/login-2.scss';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { LanguageSelectorDropdown } from '../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';
import awsExports from '../../../../aws-exports';
import '../AuthStyle.css';
import { addtnlAmplifyTranslations } from './translations';

Amplify.configure(awsExports);

export function AuthPage() {
  const intl = useIntl();
  I18n.setLanguage(intl.locale);
  I18n.putVocabularies(translations);
  I18n.putVocabularies(addtnlAmplifyTranslations);

  const [resetPasswordEmail, setResetPasswordEmail] = useState('');

  const services = {
    async handleForgotPassword(username) {
      const usernameLower = username?.toLowerCase();
      setResetPasswordEmail(usernameLower);
      return Auth.forgotPassword(usernameLower);
    },

    async handleForgotPasswordSubmit(formData) {
      let { username, code, password } = formData;
      return Auth.forgotPasswordSubmit(username?.toLowerCase(), code, password);
    },

    async handleSignIn(formData) {
      let { username, password } = formData;
      username = username.toLowerCase();
      Auth.forgotPasswordSubmit();
      return Auth.signIn({
        username,
        password,
      });
    },
  };

  return (
    <>
      <div
        className="d-flex flex-root bgi-size-cover bgi-position-center "
        style={{
          backgroundImage: `url(${toAbsoluteUrl(
            '/media/sustell_15/bkgs/sustainable_farm_login.png'
          )})`,
          padding: '90px',
        }}
      >
        {/*begin::Login*/}
        <div
          className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid "
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative"
            style={{ width: '40%' }}
          >
            {/*begin: Aside Container*/}
            <div
              id="aside-container"
              className="d-flex flex-column justify-content-center align-items-center"
            >
              {/* begin::Aside body */}
              <Card
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '2rem',
                  paddingLeft: '90px',
                  paddingRight: '90px',
                  borderRadius: '12px',
                  maxWidth: '570px',
                }}
              >
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ paddingBottom: '24px' }}
                >
                  <img
                    alt="Sustell"
                    className="w-180"
                    style={{ padding: '5px 0', marginLeft: '-11px', width: '180px' }}
                    src={toAbsoluteUrl(
                      '/media/sustell_15/logos/Sustell_logo_in_black.png'
                    )}
                  />
                </div>
                <h2>
                  <FormattedMessage id="LOGIN.WELCOME" />
                </h2>
                <div className="d-flex flex-column align-items-start">
                  <div
                    style={{
                      paddingBottom: '14px',
                      lineHeight: '24px',
                      color: '#808080',
                      fontSize: '16px',
                    }}
                  >
                    <FormattedMessage id="LOGIN.CHOOSE.LANGUAGE" />
                  </div>
                  <LanguageSelectorDropdown />
                </div>
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginTop: '24px' }}
                >
                  {!resetPasswordEmail && (
                    <div
                      style={{
                        lineHeight: '24px',
                        color: '#808080',
                        fontSize: '16px',
                      }}
                    >
                      <FormattedMessage id="LOGIN.CREDENTIALS" />
                    </div>
                  )}
                  <Authenticator
                    loginMechanisms={['email']}
                    hideSignUp={true}
                    services={services}
                    components={{
                      ResetPassword: {
                        Header() {
                          return <></>;
                        },
                      },
                      ConfirmResetPassword: {
                        Header() {
                          return (
                            <div
                              style={{
                                lineHeight: '24px',
                                color: '#808080',
                                fontSize: '16px',
                              }}
                            >
                              {intl.formatMessage(
                                { id: 'LOGIN.PASSWORD.RESET.INFO' },
                                { email: resetPasswordEmail }
                              )}
                            </div>
                          );
                        },
                      },
                    }}
                  ></Authenticator>
                </div>
                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{ marginTop: '1.5rem' }}
                >
                  <div
                    style={{
                      lineHeight: '24px',
                      color: '#808080',
                      fontSize: '13px',
                      paddingRight: '1rem',
                    }}
                  >
                    <FormattedMessage id="LOGIN.NO.ACCOUNT" />
                  </div>
                  <a
                    href="https://www.sustell.com/en_US/contact-us.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      lineHeight: '24px',
                      color: '#007E4B',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    {intl.formatMessage({ id: 'LOGIN.REQUEST.ACCESS' })}
                  </a>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <a
                    href="https://www.sustell.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Bright science. Brigter living."
                      className="h-45px mt-10 mb-15"
                      style={{ marginLeft: '-12px' }}
                      src={toAbsoluteUrl(
                        '/media/sustell_15/logos/DSM-Firmenich_Logo_Primary_Digital_RGB_Positive.png'
                      )}
                    />
                  </a>
                </div>
              </Card>
              {/*end::Aside body*/}
            </div>

            {/*end: Aside Container*/}
          </div>
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}

export default AuthPage;
