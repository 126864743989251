/* eslint-disable react/require-default-props */
import { DsmIcon } from '@dsm-dcs/design-system-react';
import {
  InputAdornment,
  InputBaseProps,
  PropTypes,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { CSSProperties, FC } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { resolvePath } from './resolvePathFn';
import { Maybe } from '../../../graphql/types';
import handleTypeNumber from './handleTypeNumber';

const inlineTooltipClass = {
  color: 'var(--dsm-color-neutral-darker)',
  height: '16px',
  width: '16px',
  top: '28px',
  marginLeft: '3px',
  marginBottom: '6px',
};

const useStyles = makeStyles({
  root: {
    minHeight: '74px',
    '& input': {
      marginTop: '-4px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '24px',
      fontSize: '13px',
      lineHeight: '28px',
    },
    '& label': {
      position: 'relative',
      width: '125% !important',
      marginBottom: '9px',
      fontSize: '16px',
      fontWeight: '500',
    },
    '& legend': {
      display: 'none',
    },
    '& label.Mui-focused': {
      color: 'var(--dsm-color-primary)',
    },
    '& label.Mui-error': {
      color: 'var(--dsm-color-error)',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      'padding-right': '0px !important',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& div.MuiInputAdornment-positionEnd': {
        '& p': {
          fontSize: '13px',
          marginBottom: '3px',
        },
      },
      '& fieldset': {
        border: '1px var(--dsm-color-neutral-darker) solid',
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        border: '1px var(--dsm-color-primary) solid',
      },
      '&.Mui-error fieldset': {
        border: '1px var(--dsm-color-error) solid',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0px !important',
    },
    '& textarea.MuiInputBase-inputMultiline': {
      height: 'auto !important',
      minHeight: '128px !important',
      overflow: 'auto !important',
    },
    '& .MuiFormHelperText-contained': {
      marginRight: '-20px !important'
    },
  },
});

interface ReactHookDsmInputProps {
  name: string;
  label: string;
  defaultValue?: string | number;
  margin?: PropTypes.Margin;
  type?: React.InputHTMLAttributes<unknown>['type'];
  tooltip?: string;
  disabled?: boolean;
  placeholder?: string;
  adornment?: string;
  readonly?: boolean;
  required?: boolean;
  multiline?: boolean;
  changeHandler?: Maybe<Function>;
  onFocusHandler?: Maybe<Function>;
  focusLostHandler?: () => void;
  size?: 'small' | 'medium';
  style?: CSSProperties;
  InputProps?: any; // this one should be removed after check
  helperText?: string;
  showHelperText?: boolean;
}

const ReactHookDsmInput: FC<ReactHookDsmInputProps> = ({
  name,
  label,
  defaultValue,
  margin = 'none',
  type = '',
  tooltip = '',
  placeholder = '',
  size = 'small',
  adornment = '',
  readonly = false,
  required = false,
  multiline = false,
  disabled = false,
  changeHandler = undefined,
  onFocusHandler = undefined,
  focusLostHandler = undefined,
  InputProps = {},
  style = {},
  helperText,
  showHelperText = true,
}) => {
  const { errors, control, formState } = useFormContext();
  const localError: FieldError | null = resolvePath(
    errors,
    name,
    null
  ) as FieldError | null;
  const labelId = `${name}-label`;
  // defaultValue when '' force replacement of saved 0,
  // but without it, label is not working => loading value from reset to check
  const currResetValue: string | number | null = resolvePath(
    control.defaultValuesRef.current,
    name,
    null
  ) as string | number | null;
  // but must not set 0 if user deletes it => checking if field is touched
  const isTouched: boolean = resolvePath(
    formState.dirtyFields,
    name,
    false
  ) as boolean;
  const classes = useStyles();
  const endAdornmentProps: InputBaseProps = {};

  const inputElStyle: CSSProperties = {
    height: multiline ? 'auto' : '28px',
    lineHeight: '28px',
    minHeight: multiline ? '128px' : '28px',
  };

  const filterValue = (value: string) => {
    if (type === 'number' && value) {
      return handleTypeNumber(value);
    }

    return value;
  };
  if (adornment) {
    endAdornmentProps.endAdornment = (
      <InputAdornment
        position="end"
        style={{
          paddingRight: tooltip ? '7px' : '0px',
          color: 'red !important',
          marginLeft: '5px',
        }}
      >
        <p
          style={{ paddingRight: tooltip ? '0px' : '14px' }}
          className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary"
        >
          {adornment}
        </p>
        {tooltip && (
          <Tooltip title={tooltip} placement="left">
            <DsmIcon
              name="general/help-circle"
              style={inlineTooltipClass}
              title=""
            />
          </Tooltip>
        )}
      </InputAdornment>
    );
    endAdornmentProps.readOnly = readonly;
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        ...style,
      }}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value, ref }) => {
          // allow zero to be displayed when needed
          if (
            (value === '' || value === undefined) &&
            (currResetValue === 0 || currResetValue === '0') &&
            !isTouched
          )
            onChange(0);
          if (value === '' && !isTouched)
            value = defaultValue;
          // adornment is dynamic, if value exist
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField
              id={labelId}
              className={classes.root}
              fullWidth
              label={`${label} ${required ? '*' : ''}`}
              margin={margin}
              variant="outlined"
              inputRef={ref}
              onChange={(e) => {
                onChange(filterValue(e.target.value));
                if (changeHandler) changeHandler(e);
              }}
              size={size}
              // Allowing to supply a onBlur function so anything code can be triggered on focus lost
              onBlur={focusLostHandler ? focusLostHandler : onBlur}
              value={value || value === 0 || value === '0' ? value : ''}
              type={type === 'number' ? 'text' : type}
              multiline={multiline}
              minRows={4}
              maxRows={4}
              error={!!localError}
              helperText={localError && showHelperText ? localError.message : helperText || ''}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              InputProps={{
                style: inputElStyle,
                ...endAdornmentProps,
                ...InputProps,
              }}
              disabled={disabled}
              InputLabelProps={{
                shrink: true,
                variant: 'standard',
                disableAnimation: true,
                style: {
                  width: '100%',
                },
              }}
              placeholder={placeholder}
              onFocus={(e) => {
                if (onFocusHandler) onFocusHandler(e);
              }}
            />
          );
        }}
      />
    </div>
  );
};
export default ReactHookDsmInput;