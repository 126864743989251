import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import { useFormContext } from "react-hook-form";
import { DsmGrid } from "@dsm-dcs/design-system-react";
import { Box, Tab, Tabs } from "@material-ui/core";

import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import { BaselineDialogProps, FormType } from "../../common";
import {
  beefTabsStyles,
  processAndStageStylesV2,
} from "../../../../../../_metronic/layout";
import { UserProfilePrefs } from "../../../../../modules/Helpers/UserProfilePrefs";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import { enumToOptionsArrayWithTranslatedStrings } from "../../../../utils/obj-utils";
import { unitLong } from "../../../../utils/unit-utils";
import { DialogContainer } from "../../CommonDataParts/DialogContainer2";
import { StageType } from "../../../../../../graphql/types";
import { BeddingType } from "../../../../../../graphql/generated/blonk/cattle";
import {
  BeefBaseline,
  BeefBreedingStageData,
  BeefGrowingStageData,
} from "../../../../models/Baseline/BeefBaseline";
import DsmButtonControlGroup from "../../../helpers/DsmButtonControlGroup";

import BeddingInputComponent from "../../CommonDataParts/BeddingInputComponent";
import BeefHousingComponent from "./BeefHousingComponent";
import BeefMMSComponent from "./BeefMMSComponent";
import DefinitionsDialog from "../../CommonDataParts/v2.0/DefinitionsDialog";
import lookupValues from "../../../../helpers/lookupValues";
import ErrorMessageComponent from "../../../helpers/ErrorMessageComponent";
import BeefTabLabelComponent from "./BeefTabLabelComponent";
import { CattleHousing, CattleManureManagementSystem, CattleManureManagementSystemType, StoragePeriod, TargetAnimal } from "../../../../models/Baseline/CattleBaseline";

interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`beef-breeding-mms-${index}`}
      aria-labelledby={`beef-breeding-mms-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
export interface BeefHousingAndManureDialogProps extends BaselineDialogProps {
  stageType: string;
  formErrors?: Record<string, unknown>;
}

type FormError = {
  [TargetAnimal.CALVES]: boolean;
  [TargetAnimal.COWS]: boolean;
};

const BeefHousingAndManureDialog: FC<BeefHousingAndManureDialogProps> = ({
  formVisible,
  itemIndex = 0,
  formType = FormType.Add,
  handleCancel,
  stageType,
  formErrors,
}) => {
  const intl = useIntl();
  const [stageFormError, setStageFormError] = useState<FormError>({
    calves: false,
    cows: false,
  });
  const [currentTab, setCurrentTab] = useState(0);
  const [isDescriptionDialogVisible, setIsDescriptionDialogVisible] =
    useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : "kg";
  const formTitle = intl.formatMessage(
    { id: "SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE" },
    { br: " " }
  );
  const classesTabs = beefTabsStyles();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const currResetValue = useRef<CattleHousing>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.housing`;
  const formContext = useFormContext<BeefBaseline>();

  const isBreedingStage = () => stageType === StageType.Breeding;

  const setFormErrors = () => {
    if (!isBreedingStage()) return;

    const calvesErrors =
      formErrors &&
      (formErrors[TargetAnimal.CALVES] as Record<string, unknown>);
    const cowsErrors =
      formErrors && (formErrors[TargetAnimal.COWS] as Record<string, unknown>);

    if (!calvesErrors && !cowsErrors) return;

    const isCalvesTabError = Boolean(
      calvesErrors && Object.keys(calvesErrors).length
    );
    const isCowsTabError = Boolean(
      cowsErrors && Object.keys(cowsErrors).length
    );
    setStageFormError((prev) => ({
      ...prev,
      calves: isCalvesTabError,
      cows: isCowsTabError,
    }));
  };

  const showFormError = () => stageFormError.calves || stageFormError.cows;

  const isCalvesTabError = () => stageFormError.calves;

  const isCowsTabError = () => stageFormError.cows;

  const closeFormError = () =>
    setStageFormError((prev) => ({
      ...prev,
      calves: false,
      cows: false,
    }));

  const getErrorMessages = () => {
    const errorMessages: Array<string> = [];

    const cowTabErrorMessage = intl.formatMessage({
      id: `SUSTELL.STAGE.BEEF.STEPS.COWS.ERROR`,
    });
    const calvesTabErrorMessage = intl.formatMessage({
      id: `SUSTELL.STAGE.BEEF.STEPS.CALVES.ERROR`,
    });

    if (stageFormError.calves) errorMessages.push(calvesTabErrorMessage);
    if (stageFormError.cows) errorMessages.push(cowTabErrorMessage);

    return errorMessages;
  };

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep<CattleHousing>(
        formContext.getValues(fieldItemPrefix)
      );

      setFormErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as BeefBaseline;
      const beefStageData = resetObject.stages[itemIndex]?.stageData as
        | BeefGrowingStageData
        | BeefBreedingStageData;
      if (beefStageData?.housing) {
        beefStageData.housing = currResetValue.current;
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel("reset");
  };

  const getBeddingOptions = () => {
    const beddings = enumToOptionsArrayWithTranslatedStrings(
      BeddingType,
      intl,
      "SUSTELL.BEEF.ENUMS.BEDDING_TYPE"
    );
    const defaultOption = { text: "No bedding", value: "" };
    return [defaultOption].concat(beddings);
  };

  const isMMSTypeSelectionDisabled = (targetAnimal?: TargetAnimal): boolean => {
    const housingFormValuesPrefix = targetAnimal
      ? `${fieldItemPrefix}.${targetAnimal}.timeInHousing`
      : `${fieldItemPrefix}.timeInHousing`;
    const mmsTypeFormValuesPrefix = targetAnimal
      ? `${fieldItemPrefix}.${targetAnimal}.manureSystems`
      : `${fieldItemPrefix}.manureSystems`;
    const mmsTypeValues: Array<CattleManureManagementSystem> = formContext.getValues(
      mmsTypeFormValuesPrefix
    );
    const mmsIsNotEmpty = mmsTypeValues.every((mms) => Boolean(mms.mmsType));
    const timeInHousing: number = formContext.watch(housingFormValuesPrefix);
    const hasNoHousingTime = timeInHousing <= 0;

    if (mmsIsNotEmpty && hasNoHousingTime)
      formContext.setValue(mmsTypeFormValuesPrefix, []);
    return hasNoHousingTime || formType === FormType.View;
  };

  const getMMSTypeOptions = () =>
    enumToOptionsArrayWithTranslatedStrings(
      CattleManureManagementSystemType,
      intl,
      "SUSTELL.BEEF.ENUMS.MMS_TYPE"
    );

  const getMMSStorageOptions = () =>
    enumToOptionsArrayWithTranslatedStrings(
      StoragePeriod,
      intl,
      "SUSTELL.BEEF.ENUMS.MMS_STORAGE"
    );

  const closeDescriptionDialog = () => setIsDescriptionDialogVisible(false);
  const openDescriptionDialog = () => setIsDescriptionDialogVisible(true);
  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const renderBreedingTabsContent = () => (
    <>
      <TabPanel value={currentTab} index={0}>
        <DsmGrid className={classes.dsmGridTwoColumn2nd45NoGap}>
          <BeefHousingComponent
            target={TargetAnimal.COWS}
            stageIndex={itemIndex}
            isViewMode={formType === FormType.View}
          />
          <div className={classes.MMSlightBlueBoxContainer}>
            <p className={classes.subHeadingTitleVariant}>
              {intl.formatMessage({
                id: "SUSTELL.STAGE.BEEF.MMS.TIME_SPENT_IN_HOUSING.DESCRIPTION",
              })}
            </p>
            <BeefMMSComponent
              stageIndex={itemIndex}
              mmsOptions={getMMSTypeOptions()}
              storageOptions={getMMSStorageOptions()}
              openDescriptionDialog={openDescriptionDialog}
              isViewMode={isMMSTypeSelectionDisabled(TargetAnimal.COWS)}
              target={TargetAnimal.COWS}
            />
          </div>
        </DsmGrid>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <DsmGrid className={classes.dsmGridTwoColumn2nd45NoGap}>
          <BeefHousingComponent
            target={TargetAnimal.CALVES}
            stageIndex={itemIndex}
            isViewMode={formType === FormType.View}
          />
          <div className={classes.MMSlightBlueBoxContainer}>
            <p className={classes.subHeadingTitleVariant}>
              {intl.formatMessage({
                id: "SUSTELL.STAGE.BEEF.MMS.TIME_SPENT_IN_HOUSING.DESCRIPTION",
              })}
            </p>
            <BeefMMSComponent
              stageIndex={itemIndex}
              mmsOptions={getMMSTypeOptions()}
              storageOptions={getMMSStorageOptions()}
              openDescriptionDialog={openDescriptionDialog}
              isViewMode={isMMSTypeSelectionDisabled(TargetAnimal.CALVES)}
              target={TargetAnimal.CALVES}
            />
          </div>
        </DsmGrid>
      </TabPanel>
    </>
  );

  return (
    <DialogContainer
      formVisible={formVisible}
      variant="ultrawide"
      handleClose={handleResetClick}
      iconCode="general/building-06"
      formTitle={formTitle}
      introText={intl.formatMessage({
        id: "SUSTELL.STAGE.BEEF.HOUSING_MMS.DESCRIPTION",
      })}
    >
      {isDescriptionDialogVisible && (
        <DefinitionsDialog
          open
          prefix="BASELINE.FORM.INPUT.CATTLE.MMS"
          itemHeader="BASELINE.FORM.BARN.MANURE_MANAGEMENT_SYSTEM"
          items={lookupValues.cattleMMSDefinitions}
          handleClose={() => closeDescriptionDialog()}
        />
      )}

      {showFormError() && (
        <div style={{ paddingBottom: "var(--dsm-spacing-px-5)" }}>
          <ErrorMessageComponent
            onClose={closeFormError}
            messages={getErrorMessages()}
          />
        </div>
      )}

      <BeddingInputComponent
        isViewMode={formType === FormType.View}
        beddingAmoutWeightUnit={barnOutputMassUnit}
        beddingOptions={getBeddingOptions()}
        stageIndex={itemIndex}
        beddingAmountTooltipId="SUSTELL.STAGE.BEEF.BEDDING.AMOUNT.TOOLTIP"
      />

      {isBreedingStage() && (
        <Box className={classesTabs.tabsBox}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="beef-breeding-feed"
            className={classesTabs.tabs}
          >
            <Tab
              label={
                <BeefTabLabelComponent
                  targetAnimal={TargetAnimal.COWS}
                  isTabError={isCowsTabError()}
                />
              }
            />
            <Tab
              label={
                <BeefTabLabelComponent
                  targetAnimal={TargetAnimal.CALVES}
                  isTabError={isCalvesTabError()}
                />
              }
            />
          </Tabs>
        </Box>
      )}

      <p className={classes.headingTitleVariant}>
        {intl.formatMessage({
          id: "SUSTELL.STAGE.BEEF.MMS.DESCRIPTION",
        })}
      </p>

      {isBreedingStage() ? (
        renderBreedingTabsContent()
      ) : (
        <DsmGrid className={classes.dsmGridTwoColumn2nd45NoGap}>
          <BeefHousingComponent
            stageIndex={itemIndex}
            isViewMode={formType === FormType.View}
          />
          <div className={classes.MMSlightBlueBoxContainer}>
            <p className={classes.subHeadingTitle}>
              {intl.formatMessage({
                id: "SUSTELL.STAGE.BEEF.MMS.TIME_SPENT_IN_HOUSING.DESCRIPTION",
              })}
            </p>
            <BeefMMSComponent
              stageIndex={itemIndex}
              mmsOptions={getMMSTypeOptions()}
              storageOptions={getMMSStorageOptions()}
              openDescriptionDialog={openDescriptionDialog}
              isViewMode={isMMSTypeSelectionDisabled()}
            />
          </div>
        </DsmGrid>
      )}

      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(`stages.${itemIndex}.stageData.housing`);
          setFormErrors();
          handleCancel("confirm");
        }}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

BeefHousingAndManureDialog.defaultProps = {
  formErrors: undefined,
};

export default BeefHousingAndManureDialog;
