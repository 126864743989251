import objectPath from 'object-path';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { headerMenusStyles } from '../../../../_metronic/layout/_core/MaterialThemeProvider';
import { useHtmlClassService } from '../../../../_metronic/layout/_core/MetronicLayout';
import { LanguageSelectorDropdown } from '../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';
import useAdobeDataLayer from '../../../sustell_15/analytics/adobeDataLayer';
import { MainMenu } from './MainMenu';

export const Header = () => {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const intl = useIntl();
  const { ctaClickEvent } = useAdobeDataLayer();
  const selectedCustomer = useSelector((state) => state.selectedCustomer);

  const layoutProps = useMemo(() => {
    const page = location.pathname.split('/')[1];
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay:
        objectPath.get(
          uiService.config,
          `header.menu.self.locations.${page}.display`
        ) || objectPath.get(uiService.config, 'header.menu.self.display'),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        'extras.languages.display'
      ),
    };
  }, [uiService, location]);

  const classes = headerMenusStyles();

  return (
    <>
      {/* begin::Header */}
      <div
        className={`flex-column align-items-center justify-content-start `}
        id="kt_header"
        // {...layoutProps.headerAttributes}
      >
        <div className={`container ${classes.companyMenu}`}>
          <div style={{ marginRight: 'auto' }}>
            Selected customer:<strong> {selectedCustomer.value?.name}</strong>
          </div>
          {/* {process.env.REACT_APP_ENVIRONMENT_TITLE && ( */}
          {/*   <div style={{ marginRight: 'auto' }}> */}
          {/*     <h2 */}
          {/*       className="font-weight-bold my-2 mr-15 mt-5" */}
          {/*       style={{ fontSize: '16px', color: 'red' }} */}
          {/*     > */}
          {/*       {process.env.REACT_APP_ENVIRONMENT_TITLE} */}
          {/*     </h2> */}
          {/*   </div> */}
          {/* )} */}
          <div>
            <Link
              to="/helpcenter"
              onClick={() =>
                ctaClickEvent(
                  `/helpcenter`,
                  'link',
                  'Help Center',
                  'Top Menu',
                  'Header'
                )
              }
              className={classes.companyMenuItem}
            >
              <InfoOutlinedIcon style={{paddingBottom: '0.1em' }} />
              {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER' })}
            </Link>
          </div>
          <div>
            <a
              href="https://www.sustell.com/en_US/home.html"
              className={classes.companyMenuItem}
              target="_blank"
              rel="noreferrer"
            >
              <OpenInNew style={{ height: '0.85em', paddingBottom: '0.1em' }} />
              {intl.formatMessage({ id: 'SUSTELL.WEBSITE' })}
            </a>
          </div>
          <div>
            <LanguageSelectorDropdown variant="small" />
          </div>
        </div>

        <Divider style={{ width: '100%' }} />

        <div
          className={` ${layoutProps.headerContainerClasses} ${classes.mainMenuContainer}`}
        >
          {/* begin::Left */}
          <div className="d-flex align-items-stretch mr-3">
            {/* begin::Header Logo */}
            <div className="header-logo">
              <div className="d-flex flex-row align-items-center pt-0">
                <Link to="/homepage">
                  <img
                    alt="Sustell"
                    onClick={() =>
                      ctaClickEvent(
                        `/homepage`,
                        'link',
                        'Logo',
                        'Main Menu',
                        'Header'
                      )
                    }
                    className="w-180"
                    style={{ padding: '5px 0', marginLeft: '-11px', width: '180px'}}
                    src={toAbsoluteUrl(
                      '/media/sustell_15/logos/Sustell_logo_in_black.png'
                    )}
                  />
                </Link>
              </div>
            </div>
            {/* end::Header Logo */}
            {/* {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />} */}
          </div>
          {/* end::Left */}
          {/* begin::Topbar */}
          <MainMenu />
          {/* end::Topbar */}
        </div>
        {/* end::Container */}
        <Divider style={{ width: '100%' }} />
      </div>
      {/* end::Header */}
    </>
  );
}
