export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK',
}

export enum AnimalOutputType {
  Broiler = 'BROILER',
  BroilerTurkey = 'BROILER_TURKEY',
  ConsumptionEgg = 'CONSUMPTION_EGG',
  HatchingEgg = 'HATCHING_EGG',
  SpentHen = 'SPENT_HEN',
  SpentRooster = 'SPENT_ROOSTER',
}

export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  Hdpe = 'HDPE',
  Ldpe = 'LDPE',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE',
}

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX',
}

export enum TertiaryPackagingType {
  WoodPallet = 'WOOD_PALLET',
  PlasticFilm = 'PLASTIC_FILM',
}

export enum StageType {
  Breeding = 'BREEDING',
  Fattening = 'FATTENING',
  Growing = 'GROWING',
  Hatching = 'HATCHING',
  Laying = 'LAYING',
  Nursery = 'NURSERY',
  Processing = 'PROCESSING',
}

export interface TransportDBModel {
  transportMode: TransportMode;
  distance: number;
}

export interface ProcessingInputDBModel {
  originStageId: string;
  originStageType?: StageType | null;
  quantity: number;
  name?: AnimalOutputType | null;
  averageWeight: number;
  transport: TransportDBModel[];
  animalType?: 'COW' | 'CALF' | null;
  shrinkingRate?: number | null;
}

export interface ProcessingOutputItemDBModel {
  weight: number;
  price: number;
  calculate?: boolean | undefined;
}

export interface ProcessingOutputDBModel {
  freshMeatAndEdibleOffal?: ProcessingOutputItemDBModel | null;
  foodGradeBones?: ProcessingOutputItemDBModel | null;
  foodGradeFat?: ProcessingOutputItemDBModel | null;
  cat1And2MaterialAndWaste?: ProcessingOutputItemDBModel | null;
  cat3SlaughterByProducts?: ProcessingOutputItemDBModel | null;
  cat3HidesAndSkins?: ProcessingOutputItemDBModel | null;
}

export interface ProcessingPackagingItemDBModel<EnumType> {
  type: EnumType;
  amount: number;
}

export interface PackagingDBModel {
  primary?: ProcessingPackagingItemDBModel<PrimaryPackagingType>[] | null;
  secondary?: ProcessingPackagingItemDBModel<SecondaryPackagingType>[] | null;
  tertiary?: ProcessingPackagingItemDBModel<TertiaryPackagingType>[] | null;
}

export interface ProcessingPackagingDBModel {
  freshMeatAndEdibleOffal?: PackagingDBModel | null;
  foodGradeBones?: PackagingDBModel | null;
  foodGradeFat?: PackagingDBModel | null;
  cat1And2MaterialAndWaste?: PackagingDBModel | null;
  cat3SlaughterByProducts?: PackagingDBModel | null;
  cat3HidesAndSkins?: PackagingDBModel | null;
}

export interface ProcessingStageDataDBModel {
  processingInput: ProcessingInputDBModel;
  processingOutput: ProcessingOutputDBModel;
  processingPackaging: ProcessingPackagingDBModel;
}

export interface StageDBModel {
  name: string;
  id: string;
  // This is adding as enrichment during mapping, it is not a given the field will be present
  farmId?: string;
  stageData: any;
}

export interface ProcessingStageDBModel extends StageDBModel {
  facilityType?: string;
  facilityId: string;
  type: StageType;
  originAnimalType?: string | null;
  stageData: ProcessingStageDataDBModel;
}
