/* eslint-disable react/require-default-props */
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { TableCell, Checkbox, Tooltip, Typography } from '@material-ui/core';
import { flexRender, Cell, Row } from '@tanstack/react-table';
import { ReactElement, Dispatch, SetStateAction, MouseEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TableActions from './TableActions';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import Can from '../../../modules/Auth/Can';
import featureFlags from '../../utils/featureFlags';

interface TableRowProps<T> {
  cell: Cell<T, unknown>;
  row: Row<T>;
  itemId: string;
  rowSelection: object;
  isBenchmark: boolean;
  canSubject: string;
  editDisabled?: boolean;
  handleView: (event: MouseEvent, item: T) => Promise<void>;
  handleEdit?: (item: T) => Promise<void>;
  isDuplicateLoading?: boolean;
  handleDuplicate?: (item: T) => Promise<void>;
  isDeleteLoading?: boolean;
  handleDelete?: Dispatch<SetStateAction<string>>;
  moreActions?: ReactElement;
  standAloneMoreActions?: ReactElement;
  shouldRedirectFeed? : boolean;
}

const TableRowItem = <T,>({
  cell,
  row,
  itemId,
  rowSelection,
  isBenchmark,
  canSubject,
  editDisabled = false,
  handleView,
  handleEdit = () => Promise.resolve(),
  isDuplicateLoading = false,
  handleDuplicate = () => Promise.resolve(),
  isDeleteLoading = false,
  handleDelete = () => {},
  moreActions,
  standAloneMoreActions,
  shouldRedirectFeed = false,
}: TableRowProps<T>) => {
  const intl = useIntl();

  const handleViewClick = (
    event: MouseEvent<HTMLDsmButtonElement, globalThis.MouseEvent>,
    item: T
  ) => {
    event.preventDefault();
    handleView(event, item).catch((e) => console.error(e));
  };

  const getClickableName = () => {
    if (featureFlags.feedV2Routing && shouldRedirectFeed && Can('read', 'Footprint')) {
      return (
        <a href={`/v2/myfeeds/footprint/upload/${itemId}`}>
          <DsmButton
            variant="text"
          >
            {isBenchmark &&
              cell
                .getContext()
                .column.columnDef.header?.toString()
                .endsWith('name') && (
                <DsmIcon
                  name="general/pin-02"
                  style={{
                    width: '18px',
                    color: 'black',
                  }}
                />
              )}
            <Typography
              style={{
                fontSize: '12px !important',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: isBenchmark ? 182 : 200,
                minWidth: isBenchmark ? 182 : 200,
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Typography>
          </DsmButton>
        </a>
      );
    } else {
      return (
        <DsmButton
          variant="text"
          onClick={(event) => handleViewClick(event, row.original)}
        >
          {isBenchmark &&
            cell
              .getContext()
              .column.columnDef.header?.toString()
              .endsWith('name') && (
              <DsmIcon
                name="general/pin-02"
                style={{
                  width: '18px',
                  color: 'black',
                }}
              />
            )}
          <Typography
            style={{
              fontSize: '12px !important',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: isBenchmark ? 182 : 200,
              minWidth: isBenchmark ? 182 : 200,
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Typography>
        </DsmButton>
      );
    }
  }

  if (cell.id.includes('_checkbox')) {
    return (
      <TableCell
        style={{
          padding: '16px 0',
        }}
      >
        <Tooltip title={intl.formatMessage({ id: 'COMPOUNDFEEDS.COMPARE' })}>
          <Checkbox
            disabled={
              Object.keys(rowSelection).length >= 2 && !row.getIsSelected()
            }
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            style={{
              color:
                Object.keys(rowSelection).length >= 2 && !row.getIsSelected()
                  ? 'inherit'
                  : '#0070BA',
            }}
          />
        </Tooltip>
      </TableCell>
    );
  }

  if (cell.getContext().column.columnDef.header?.toString().endsWith('name')) {
    return (
      <TableCell
        key={uuidv4()}
        style={{
          cursor: 'pointer',
          maxWidth: 250,
          borderBottom: '1px solid #f0f0f0 !important',
          padding: cell.column.depth === 0 ? '16px 8px 16px 16px' : '16px 8px',
          height: 37.5,
        }}
      >
        <Tooltip
          title={`${intl.formatMessage({
            id: 'GENERAL.VIEW',
          })} ${cell.getValue() as string}`}
        >
          {getClickableName()}
        </Tooltip>
      </TableCell>
    );
  }

  if (cell.id.includes('_id')) {
    return (
      <TableCell
        key={uuidv4()}
        style={{
          cursor: 'pointer',
          borderBottom: '1px solid #f0f0f0 !important',
          padding: '16px 8px',
          height: 37.5,
        }}
      >
        <Tooltip title={intl.formatMessage({ id: 'GENERAL.VIEW' })}>
          <DsmButton
            variant="text"
            style={{ marginLeft: 0 }}
            onClick={(event) => handleViewClick(event, row.original)}
          >
            <DsmIcon
              slot="after"
              name="arrows/chevron-right"
              style={{ color: 'black' }}
            />
          </DsmButton>
        </Tooltip>
      </TableCell>
    );
  }

  if (cell.id.includes('_actions')) {
    if (standAloneMoreActions) {
      return (
        <TableCell
          key={uuidv4()}
          style={{
            cursor: 'pointer',
            minWidth: '200px',
            borderBottom: '1px solid #f0f0f0 !important',
            padding: '16px 8px',
            height: 37.5,
          }}
        >
          {standAloneMoreActions}
        </TableCell>
      );
    }
    return (
      <TableCell
        key={uuidv4()}
        style={{
          cursor: 'pointer',
          minWidth: moreActions ? '200px' : '150px',
          borderBottom: '1px solid #f0f0f0 !important',
          padding: '16px 8px',
          height: 37.5,
        }}
      >
        <TableActions<T>
          canSubject={canSubject}
          editDisabled={editDisabled}
          item={row.original}
          itemId={itemId}
          handleEdit={handleEdit}
          isDuplicateLoading={isDuplicateLoading}
          handleDuplicate={handleDuplicate}
          isDeleteLoading={isDeleteLoading}
          handleDelete={handleDelete}
          moreActions={moreActions}
        />
      </TableCell>
    );
  }

  return (
    <TableCell
      key={cell.id}
      style={{
        borderBottom: '1px solid #f0f0f0 !important',
        padding: '16px 8px',
        height: 37.5,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 155,
      }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </TableCell>
  );
};

export default TableRowItem;
