import {
  BaselineFeed,
  BaselineFeedWithOrigin,
} from "../../../../../sustell_15/models/Baseline";
import {
  CattleBeddingSystem,
  CattleBeddingType,
  CattleEmission,
  CattleFeed,
  CattleHousingAndManure,
  CattleManureManagementSystem,
  CattleManureManagementSystemType,
  ExternalSource,
  FreshGrass,
  InternalSource,
  ManurePhase,
} from "../../../../../sustell_15/models/Baseline/CattleBaseline";
import {
    CattleAnimalInputDBModel,
  CattleBeddingDBModel,
  CattleFeedingProgramDBModel,
  CattleManureManagementSystemDBModel,
  CompoundFeedInfoDBModel,
  EmissionMitigationDBModel,
  FeedingProgramCalfDBModel,
  FreshGrassDBModel,
  SingleIngredientUseDBModel,
  TimeSpentDBModel,
} from "../../../../../sustell_15/models/TempBackendModels/TempCattleDB";
import { formatInMassInput, formatInTerrestrialTransportInput } from "../inMapperHelpers";

export function mapFreshGrass(
  incomingData: Array<FreshGrassDBModel>
): Array<FreshGrass> {
  if (!incomingData || !incomingData?.length) {
    const emptyFreshGrass = {
      amount: "",
      type: "",
    } as FreshGrass;

    return [emptyFreshGrass];
  }
  return incomingData.map((grass) => ({
    amount: formatInMassInput(Number(grass.amount)),
    type: grass.type,
    crudeProteinContent: grass.crudeProteinContent,
    digestibility: grass.digestibility,
    dryMatterContent: grass.dryMatterContent,
    ashContent: grass.ashContent,
    grossEnergyContent: grass.grossEnergyContent,
    urinaryEnergy: grass.urinaryEnergy,
    neutralDetergentFiber: grass.neutralDetergentFiber
  }));
}

function mapSingleIngredients(
  incomingData?: Array<SingleIngredientUseDBModel>
): Array<BaselineFeedWithOrigin> {
  if (!incomingData || !incomingData.length) {
    return [
      {
        feedType: "",
        kgPerAnimal: "",
        origin: "",
        id: "",
        isSilage: undefined,
      },
    ];
  }

  return incomingData.map((singleIngredient) => ({
    id: singleIngredient.ingredient.id || "",
    feedType: singleIngredient.ingredient.name,
    origin: singleIngredient.ingredient.origin,
    kgPerAnimal: formatInMassInput(singleIngredient?.quantity),
    isSilage: singleIngredient?.silage || false,
  }));
}

function mapCompoundFeeds(
  incomingData?: Array<CompoundFeedInfoDBModel>
): Array<BaselineFeed> {
  if (!incomingData || !incomingData.length) {
    return [{ feedType: "", kgPerAnimal: "" }];
  }

  return incomingData.map((compoundFeed) => ({
    feedType: compoundFeed.compoundFeedId,
    kgPerAnimal: formatInMassInput(compoundFeed?.quantity),
  }));
}

function mapCalfFeedingProgram(
  incomingData: FeedingProgramCalfDBModel
): Pick<CattleFeed, "averageMilkFat" | "mothersMilk"> {
  return {
    averageMilkFat: Number(incomingData?.averageMilkFat),
    mothersMilk: incomingData?.mothersMilk
      ? {
          isMothersMilk: Boolean(incomingData.mothersMilk.amount),
          amount: formatInMassInput(Number(incomingData.mothersMilk.amount)),
          crudeProteinContent: incomingData.mothersMilk.crudeProteinContent,
          digestibility: incomingData.mothersMilk.digestibility,
          dryMatterContent: incomingData.mothersMilk.dryMatterContent,
        }
      : undefined,
  };
}

export function mapFeedingProgram(
  incomingData: CattleFeedingProgramDBModel | FeedingProgramCalfDBModel
): Pick<
  CattleFeed,
  | "freshGrass"
  | "dietCharacterisation"
  | "singleIngredients"
  | "compoundFeeds"
  | "databaseFoundation"
> {
  return {
    databaseFoundation: String(incomingData?.databaseFoundation),
    dietCharacterisation: incomingData?.dietCharacterisation,
    freshGrass: mapFreshGrass(incomingData?.freshGrass),
    ...mapCalfFeedingProgram(incomingData),
    singleIngredients: mapSingleIngredients(incomingData?.singleIngredients),
    compoundFeeds: mapCompoundFeeds(incomingData.compoundFeeds),
  };
}

export const mapCattleMmsFromDBModel = (
  mmsSystem: CattleManureManagementSystemDBModel
): CattleManureManagementSystem => {
  const storagePeriod = mmsSystem?.storagePeriod as string;
  const phase = mmsSystem?.manurePhase;

  const baseMms: Omit<CattleManureManagementSystem, "storagePeriod"> = phase
    ? {
        id: mmsSystem.id,
        share: Number(mmsSystem.share),
        mmsType: mmsSystem.mmsViewModelType as CattleManureManagementSystemType,
        manurePhase: phase as ManurePhase,
      }
    : {
        id: mmsSystem.id,
        share: Number(mmsSystem.share),
        mmsType: mmsSystem.mmsViewModelType as CattleManureManagementSystemType,
      };

  if (storagePeriod) {
    return {
      ...baseMms,
      storagePeriod,
    } as CattleManureManagementSystem;
  }

  return baseMms;
};

export function mapCattleManureSystems(
  incomingData?: Array<CattleManureManagementSystemDBModel>
): Array<CattleManureManagementSystem> {
  if (!incomingData?.length) return [];
  return incomingData.map(mapCattleMmsFromDBModel);
}

export function mapCattleTimeSpent(
  incomingData: TimeSpentDBModel
): Omit<CattleHousingAndManure, "beddingSystems" | "manureSystems"> {
  return {
    timeInFeedlot: incomingData?.timeInFeedlot,
    timeInHousing: incomingData?.timeInHousing,
    timeGrazingLargeAreas: incomingData?.timeOnLargeGrazingAreas,
    timeGrazingPastures: incomingData?.timeOnSmallGrazingAreas,
    timeInHousingTied: Number(incomingData?.timeInHousingTied)
  };
}

export function mapCattleBeddingSystems(
  incomingData?: Array<CattleBeddingDBModel>
): Array<CattleBeddingSystem> {
  if (!incomingData || !incomingData?.length) {
    return [{ beddingAmount: "", beddingType: "" as CattleBeddingType }];
  }
  return incomingData.map((beddingSystem) => ({
    beddingAmount: formatInMassInput(beddingSystem.amount),
    beddingType: beddingSystem.type as CattleBeddingType,
  }));
}

export function mapCattleEmissionsAndMitigations(
  incomingData?: Array<EmissionMitigationDBModel>
): CattleEmission {
  if (incomingData && incomingData.length) {
    return incomingData.reduce(
      (mappedData, emission) =>
        ({
          ...mappedData,
          [emission.emissionType]: emission.change,
        } as CattleEmission),
      {}
    );
  }
  return {};
}

type AnimalSources = {
    internalSources: Array<InternalSource>,
    externalSources: Array<ExternalSource>
  }

const getInternalSourceAnimalSystemType = (animalSystemType?: string) => {
  if (animalSystemType) return String(animalSystemType);
  return "BEEF";
}

export function mapCattleAnimalSources(
    incomingData: Array<CattleAnimalInputDBModel>,
    isGrowingStage: boolean = false
  ): AnimalSources {
    return incomingData?.reduce(
      (mappedData: AnimalSources, animalSource) => {
        const isInternalSource = animalSource.origin === "PRODUCTION_SYSTEM";
        const commonAttributes: Pick<
          InternalSource,
          "id" | "distanceTruck" | "numberAnimals" | "origin" | "shrinkingRate"
        > = {
          id: animalSource.id,
          distanceTruck: formatInTerrestrialTransportInput(
            Number(animalSource.transportDistance)
          ),
          numberAnimals: animalSource.numberOfAnimals,
          origin: animalSource.origin,
          shrinkingRate: animalSource?.shrinkingRate ? Number(animalSource.shrinkingRate) : 0,
        };
  
        if (isInternalSource) {
          mappedData.internalSources.push({
            ...commonAttributes,
            farmId: String(animalSource.originFarmId!),
            farmName: String(animalSource.originFarmName!),
            originStageId: String(animalSource.originStageId!),
            stageName: String(animalSource.originStageName!),
            stageType: String(animalSource.stageType),
            animalType: String(animalSource.animalType),
            animalSystemType: isGrowingStage 
              ? getInternalSourceAnimalSystemType(animalSource.animalSystemType) 
              : undefined,
            baselineRef: String(animalSource.baselineRef),
          });
  
          return mappedData;
        }
  
        mappedData.externalSources.push(commonAttributes);
        return mappedData;
      },
      {
        internalSources: [],
        externalSources: [],
      }
    );
  }