import { v4 as uuidv4 } from "uuid";
import {
  ManureForm,
  AnimalType,
} from "../../../../../graphql/generated/blonk/pigs";
import { StageType } from "../../../../../graphql/types";
import { getManureFormForSingleEntry } from "../../../../sustell_15/helpers/manureManagementSystemsLookups";
import { dashToCamelCaseString } from "../../../../sustell_15/utils/string-utils";
import { defaultUnitsV2 as defaultMetric } from "../../../../sustell_15/utils/unit-utils";
import { UserProfilePrefs } from "../../../Helpers/UserProfilePrefs";
import { floatValueInDefaultUnit } from "./floatHelperFunctions";
import {
  mapCommonProductionDataOutSustell,
  mapResourceUseSustell,
} from "./outCommonMapperSustellV2";

const FATTENING_AVG_WEIGHT_START_DEFAULT = 5; // minimum without warning
const FATTENING_AVG_WEIGHT_END_DEFAULT = 250;
const FATTENING_AVG_AGE_START_DEFAULT = 10; // minimum without warning
const BREEDING_PIGLET_AVG_AGE_END_DEFAULT = 60; // taken from doc example
const BREEDING_SOWS_AVG_AGE_END_DEFAULT = 120; // taken from doc example
// const FATTENING_AVG_AGE_END_DEFAULT = 150;
const FATTENING_AVG_AGE_MAX_DEFAULT = 300;

const mapHousingForOneStage = (housingFormData, stageHousingDataObj) => {
  const stageHousingData = stageHousingDataObj;

  stageHousingData.surfaceType = housingFormData.surfaceType;
  stageHousingData.manureForm = housingFormData.manureForm;
  stageHousingData.Nreplacement = housingFormData.Nreplacement;
  stageHousingData.Preplacement = housingFormData.Preplacement;
  stageHousingData.solidManureManagementSystem = [];
  stageHousingData.liquidSlurryManureManagementSystem = [];
  // console.log("housingFormData",housingFormData);
  if ("manureSystems" in housingFormData) {
    housingFormData.manureSystems.forEach((item, index) => {
      // if mmsType contains storage time then full mmsType is set in item.mmsHoldingDuration
      // while item.mmsType contains only partial base type
      // console.log('map bed out');

      const outputItem = {
        // if ID not set, generate it
        id: item.id || uuidv4(),
        share: item.share && item.share !== "" ? item.share : 100,
        mmsType:
          item.mmsHoldingDuration && item.mmsHoldingDuration !== ""
            ? item.mmsHoldingDuration
            : item.mmsType,
      };
      if (stageHousingData.Nreplacement)
        outputItem.nReplacingFertilizer = stageHousingData.Nreplacement;
      if (stageHousingData.Preplacement)
        outputItem.pReplacingFertilizer = stageHousingData.Preplacement;

      if (
        getManureFormForSingleEntry(outputItem.mmsType) ===
        ManureForm.LiquidSlurry
      )
        stageHousingData.liquidSlurryManureManagementSystem.push(outputItem);
      if (getManureFormForSingleEntry(outputItem.mmsType) === ManureForm.Solid)
        stageHousingData.solidManureManagementSystem.push(outputItem);
    });
    // console.log(stageHousingData.solidManureManagementSystem.length, );
    if (
      stageHousingData.solidManureManagementSystem.length > 0 &&
      stageHousingData.liquidSlurryManureManagementSystem.length > 0
    )
      stageHousingData.manureForm = ManureForm.SolidAndLiquidSlurry;
    else if (stageHousingData.solidManureManagementSystem.length > 0)
      stageHousingData.manureForm = ManureForm.Solid;
    else if (stageHousingData.liquidSlurryManureManagementSystem.length > 0)
      stageHousingData.manureForm = ManureForm.LiquidSlurry;

    // console.log("manureForm: ",stageHousingData.manureForm);
  }
  // console.log("stageHousingData",stageHousingData)
};
const mapBeddingForOneStage = (beddingFormDataArray, stageBeddingDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageBeddingData = stageBeddingDataObj;

  beddingFormDataArray?.forEach((item) => {
    if (
      item.beddingType &&
      item.beddingAmount &&
      !Number.isNaN(item.beddingAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.beddingType)}Use`;
      const currValue = stageBeddingData[`${propName}`] || 0;
      const value = floatValueInDefaultUnit(
        Number(item.beddingAmount),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
      stageBeddingData[`${propName}`] = currValue + value;
    }
  });
};

const mapMaterialsForOneStage = (
  materialsFormDataArray,
  stageMaterialsDataObj
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageMaterialData = stageMaterialsDataObj;

  materialsFormDataArray?.forEach((item) => {
    if (
      item.materialType &&
      item.materialAmount &&
      !Number.isNaN(item.materialAmount)
    ) {
      const propName = `${dashToCamelCaseString(item.materialType)}Use`;
      const currValue = stageMaterialData[`${propName}`] || 0;
      const value = floatValueInDefaultUnit(
        Number(item.materialAmount),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
      stageMaterialData[`${propName}`] = currValue + value;
    }
  });
};

const mapOutputForOneStage = (outputFormData, stageObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stage = stageObj;
  const { stageData } = stageObj;
  const stageOutputData = stageData.animalsOutput;

  // weight of pigs - conversion to default units
  if (outputFormData.averageWeightPigs)
    stageOutputData.averageWeightPigs = floatValueInDefaultUnit(
      outputFormData.averageWeightPigs,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  // weight of piglets - conversion to default units
  if (outputFormData.averageWeightPiglets)
    stageOutputData.averageWeightPiglets = floatValueInDefaultUnit(
      outputFormData.averageWeightPiglets,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  // number of pigs
  if (outputFormData.mortalityPigs || outputFormData.mortalityPigs === 0)
    stageOutputData.mortalityPigs = Number(outputFormData.mortalityPigs);

  // number of piglets
  if (outputFormData.mortalityPiglets || outputFormData.mortalityPiglets === 0)
    stageOutputData.mortalityPiglets = Number(outputFormData.mortalityPiglets);

  // number of pigs
  if (outputFormData.pigsToStage)
    stageOutputData.pigsToStage = Number(outputFormData.pigsToStage);

  // number of piglets
  if (outputFormData.pigletsToStage)
    stageOutputData.pigletsToStage = Number(outputFormData.pigletsToStage);

  // number of pigs
  if (outputFormData.soldPigs)
    stageOutputData.soldPigs = Number(outputFormData.soldPigs);

  // number of piglets
  if (outputFormData.soldPiglets)
    stageOutputData.soldPiglets = Number(outputFormData.soldPiglets);

  // number of days
  if (outputFormData.averageAgePigs)
    stageOutputData.averageAgePigs = Number(outputFormData.averageAgePigs);

  // number of days
  if (outputFormData.ageAtMortalityPigs)
    stageOutputData.ageAtMortalityPigs = Number(
      outputFormData.ageAtMortalityPigs
    );

  // price in EUR - possible future conversion needed
  if (outputFormData.priceSoldPigs)
    stageOutputData.priceSoldPigs = Number(outputFormData.priceSoldPigs);

  // price in EUR - possible future conversion needed
  if (outputFormData.priceSoldPiglets)
    stageOutputData.priceSoldPiglets = Number(outputFormData.priceSoldPiglets);

  // weight of pigs - conversion to default units
  if (outputFormData.weightAtMortalityPigs)
    stageOutputData.weightAtMortalityPigs = floatValueInDefaultUnit(
      outputFormData.weightAtMortalityPigs,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  // weight of piglets - conversion to default units
  if (outputFormData.weightAtMortalityPiglets)
    stageOutputData.weightAtMortalityPiglets = floatValueInDefaultUnit(
      outputFormData.weightAtMortalityPiglets,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );

  if (
    stageOutputData.averageAgePigs &&
    stageData.averageAgePigsEnd > stageOutputData.averageAgePigs
  )
    stageData.averageAgePigsEnd = stageOutputData.averageAgePigs;

  if (
    stageOutputData.averageWeightPigs &&
    stageData.averageWeightPigsEnd > stageOutputData.averageWeightPigs
  )
    stageData.averageWeightPigsEnd = stageOutputData.averageWeightPigs;

  if (stage.type === "BREEDING") {
    stageData.averageWeightPigsEnd = stageOutputData.averageWeightPigs;
  }
};

const mapInputForOneStage = (formStageDataInputPart, stageObj, formData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stage = stageObj;
  const { stageData } = stage;

  stage.startDate = formStageDataInputPart.startDate;
  stage.endDate = formStageDataInputPart.endDate;
  stageData.animalsInput = {};

  if (stage.type === StageType.Fattening) {
    if (formStageDataInputPart.stockPresent) {
      stageData.stockPresent = true;
      stageData.pigsPresentAtStart = Number(
        formStageDataInputPart.optionalInput?.pigsPresentAtStart
      );
      stageData.pigsPresentAtEnd = Number(
        formStageDataInputPart.optionalInput?.pigsPresentAtEnd
      );

      stageData.averageWeightPigsStart = floatValueInDefaultUnit(
        formStageDataInputPart.optionalInput?.averageWeightPigsStart,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );

      stageData.averageWeightPigsEnd = floatValueInDefaultUnit(
        formStageDataInputPart.optionalInput?.averageWeightPigsEnd,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );

      stageData.averageAgePigsStart = Number(
        formStageDataInputPart.optionalInput?.averageAgePigsStart
      );
      stageData.averageAgePigsEnd = Number(
        formStageDataInputPart.optionalInput?.averageAgePigsEnd
      );

      if (formStageDataInputPart.optionalInput?.pigsPriceAtStart)
        stageData.pricePerWeightPigsStart = Number(
          formStageDataInputPart.optionalInput.pigsPriceAtStart
        );

      if (formStageDataInputPart.optionalInput?.pigsPriceAtEnd)
        stageData.pricePerWeightPigsEnd = Number(
          formStageDataInputPart.optionalInput.pigsPriceAtEnd
        );
    }

    // add default values
    // console.log("stageData.pigsPresentAtStart",stageData.pigsPresentAtStart)
    if (!stageData.pigsPresentAtStart) stageData.pigsPresentAtStart = 0;
    if (!stageData.pigsPresentAtEnd) stageData.pigsPresentAtEnd = 0;
    if (!stageData.averageWeightPigsStart)
      stageData.averageWeightPigsStart = FATTENING_AVG_WEIGHT_START_DEFAULT;
    if (!stageData.averageWeightPigsEnd)
      stageData.averageWeightPigsEnd = FATTENING_AVG_WEIGHT_END_DEFAULT;
    if (!stageData.averageAgePigsStart)
      stageData.averageAgePigsStart = FATTENING_AVG_AGE_START_DEFAULT;
    if (!stageData.averageAgePigsEnd)
      stageData.averageAgePigsEnd = FATTENING_AVG_AGE_MAX_DEFAULT;
  }

  const totalInternalInputs =
    formStageDataInputPart.internalSources?.length || 0;
  const totalExternalInputs =
    formStageDataInputPart.externalSources?.length || 0;
  let totalInternalAverageWeight = 0;
  let totalExternalAverageWeight = 0;
  let totalAverageAge = 0;

  if (formStageDataInputPart.internalSources?.length > 0) {
    stageData.animalsInput.incomings = [];
    formStageDataInputPart.internalSources?.forEach((item) => {
      const newSource = {
        origin: "PRODUCTION_SYSTEM",
        id: item.id || uuidv4(),
        originFarmId: item.farmId,
        originFarmName: item.farmName,
        originStageId: item.originStageId,
        originStageName: item.stageName,
        stageType: item.stageType,
        numberPigs: item.numberPigs,
        originAnimalType: item.originAnimalType,
        distanceTransport: floatValueInDefaultUnit(
          item.distanceTransport,
          userUOM.unitTransportDistanceTerrestrial,
          defaultMetric.unitTransportDistanceTerrestrial
        ),
      };
      if (item.baselineRef) {
        newSource.baselineRef = item.baselineRef;
      }
      if (item.averageWeightPiglets) {
        newSource.averageWeightPiglets = item.averageWeightPiglets;
      }
      if (item.averageWeightPigs) {
        newSource.averageWeightPigs = item.averageWeightPigs;
      }
      if (item.averageAgePigs) {
        newSource.averageAgePigs = item.averageAgePigs;
      }
      stageData.animalsInput.incomings.push(newSource);
      if (
        newSource.originAnimalType &&
        !stageData.animalsInput.originAnimalType
      ) {
        stageData.animalsInput.originAnimalType = newSource.originAnimalType;
      }
      if (stage.id !== item.originStageId) {
        let otherStageFromSameFarm = formData?.stages.filter(
          (otherStageItem) => otherStageItem.id === item.originStageId
        );
        let averageWeightPiglets = 0;
        let averageWeightPigs = 0;
        let averageAgePigs = 0;
        let sourceStage;
        let isBreedingStage;
        let isPigletSource;

        if (otherStageFromSameFarm && otherStageFromSameFarm.length > 0) {
          sourceStage = otherStageFromSameFarm[0];
          isBreedingStage = sourceStage.type === StageType.Breeding;
          isPigletSource =
            isBreedingStage && item.originAnimalType === AnimalType.Piglet;
          averageWeightPiglets = Number(
            sourceStage?.stageData?.output?.averageWeightPiglets || 0
          );
          averageWeightPigs = Number(
            sourceStage?.stageData?.output?.averageWeightPigs || 0
          );
          averageAgePigs = Number(
            sourceStage?.stageData?.output?.averageAgePigs || 0
          );
        } else {
          isBreedingStage = item.stageType === StageType.Breeding;
          isPigletSource =
            isBreedingStage && item.originAnimalType === AnimalType.Piglet;
          averageWeightPiglets = Number(item?.averageWeightPiglets || 0);
          averageWeightPigs = Number(item?.averageWeightPigs || 0);
          averageAgePigs = Number(item?.averageAgePigs || 0);
        }
        const sourceOutputWeightToThisStageInputWeight = isPigletSource
          ? averageWeightPiglets
          : averageWeightPigs;
        totalInternalAverageWeight += sourceOutputWeightToThisStageInputWeight;
        let averageAge = averageAgePigs;

        if (isBreedingStage) {
          // Baselines were created without the input field averageAgePigs, so we apply the defaults in case user has not defined it
          let inputAverageAge = isPigletSource
            ? BREEDING_PIGLET_AVG_AGE_END_DEFAULT
            : BREEDING_SOWS_AVG_AGE_END_DEFAULT;
          averageAge += inputAverageAge;
        }
        totalAverageAge += averageAge;
      }
    });
  }

  if (totalExternalInputs > 0) {
    if (stageData?.animalsInput?.incomings === undefined) {
      stageData.animalsInput.incomings = [];
    }

    formStageDataInputPart.externalSources?.forEach((item) => {
      totalAverageAge += Number(item.averageAgeOfPigs);
      totalExternalAverageWeight += Number(item.averageWeightOfPigs);
      const newSource = {
        origin: "BACKGROUND_DATA",
        id: item.id || uuidv4(),
        numberPigs: item.numberPigs,
        averageWeightPigs: floatValueInDefaultUnit(
          item.averageWeightOfPigs || 0,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        ),
        averageAgePigs: item.averageAgeOfPigs,
        distanceTransport: floatValueInDefaultUnit(
          item.distanceTransport,
          userUOM.unitTransportDistanceTerrestrial,
          defaultMetric.unitTransportDistanceTerrestrial
        ),
      };

      stageData.animalsInput.incomings.push(newSource);
    });
  }

  if (totalExternalInputs + totalInternalInputs > 0) {
    const totalAccumulatedAverageWeight =
      totalInternalAverageWeight + totalExternalAverageWeight;
    stageData.animalsInput.averageWeightPigs =
      floatValueInDefaultUnit(
        totalAccumulatedAverageWeight /
          (totalExternalInputs + totalInternalInputs),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      ) || 0;
    stageData.animalsInput.averageAgePigs =
      totalAverageAge / (totalExternalInputs + totalInternalInputs);
    if (stageData.animalsInput.averageAgePigs === 0) {
      stageData.animalsInput.averageAgePigs = FATTENING_AVG_AGE_START_DEFAULT;
    }
  } else {
    stageData.animalsInput.averageWeightPigs = 0;
    stageData.animalsInput.averageAgePigs = 0;
  }

  if (stage.type === "BREEDING") {
    stageData.pigsPresentAtStart = formStageDataInputPart.pigsPresentAtStart
      ? Number(formStageDataInputPart.pigsPresentAtStart)
      : 0;
    stageData.pigsPresentAtEnd = formStageDataInputPart.pigsPresentAtEnd
      ? Number(formStageDataInputPart.pigsPresentAtEnd)
      : 0;
    stageData.averageWeightPigsStart = stageData.animalsInput.averageWeightPigs;
  }
};

const setAverageWeightForProcessingStage = (formData, dbDataForOneStage) => {
  if (dbDataForOneStage?.stageData?.processingInput) {
    const originStage = formData.stages.find(
      (st) =>
        st.id === dbDataForOneStage.stageData.processingInput?.originStageId
    );
    // if processing input -> origin stage is set (needed when data is saved to local storage as a draft)
    if (originStage)
      if (originStage.type === StageType.Fattening) {
        const averageWeightOrigin =
          originStage?.stageData?.output?.averageWeightPigs;
        // eslint-disable-next-line no-param-reassign
        dbDataForOneStage.stageData.processingInput.averageWeight =
          averageWeightOrigin;
        // For processing stage the avgWeight is the freshMeatAndEdibles output of the linked slaughtering stage, and quantity set to 1
      } else if (originStage.type === StageType.Processing) {
        const averageWeightOrigin =
          originStage?.stageData?.processingOutput?.freshMeatAndEdibleOffal
            ?.weight;
        // eslint-disable-next-line no-param-reassign
        dbDataForOneStage.stageData.processingInput.averageWeight =
          averageWeightOrigin;
        // eslint-disable-next-line no-param-reassign
        dbDataForOneStage.stageData.processingInput.quantity = 1;
        // TODO keep for now commented code until confirmed that footrpint for cutting is correct
        // const numberOfAnimals = originStage?.stageData?.processingInput?.quantity;
        // if(numberOfAnimals)
        //   // eslint-disable-next-line no-param-reassign
        //   dbDataForOneStage.stageData.processingInput.averageWeight = averageWeightOrigin / Number(numberOfAnimals);
        // // eslint-disable-next-line no-param-reassign
        // dbDataForOneStage.stageData.processingInput.quantity = 1;
      }
  }
};

const mapPigOutSustell = (formData) => {
  let payloadObj;
  try {
    payloadObj = mapCommonProductionDataOutSustell(formData);
    payloadObj = mapResourceUseSustell(formData, payloadObj);
    if (formData.stages)
      formData.stages.forEach((formDataForOneStage, index) => {
        if (payloadObj.stages[index] !== undefined) {
          if (formDataForOneStage.type !== StageType.Processing) {
            const formStageData = formDataForOneStage?.stageData;
            const stageGeneralData = payloadObj.stages[index];
            const { stageData } = stageGeneralData;
            if (formStageData && "housing" in formStageData) {
              stageData.housing = {};
              mapHousingForOneStage(formStageData.housing, stageData.housing);
              if ("beddingSystems" in formStageData.housing) {
                stageData.beddingUse = {};
                mapBeddingForOneStage(
                  formStageData.housing.beddingSystems,
                  stageData.beddingUse
                );
              }
              if ("materials" in formStageData.housing) {
                stageData.materialUse = {};
                mapMaterialsForOneStage(
                  formStageData.housing.materials,
                  stageData.materialUse
                );
              }
            }
            if (formStageData && "input" in formStageData) {
              const formStageDataInputPart = formStageData.input;
              mapInputForOneStage(
                formStageDataInputPart,
                stageGeneralData,
                formData
              );
            }
            if (formStageData && "output" in formStageData) {
              stageData.animalsOutput = {};
              mapOutputForOneStage(formStageData.output, stageGeneralData);
            }
          } else {
            setAverageWeightForProcessingStage(
              formData,
              payloadObj.stages[index]
            );
          }
        }
      });
    payloadObj.stages.forEach((item) => {
      const { stageData } = item;
      // eslint-disable-next-line no-param-reassign
      item.stageData = JSON.stringify(stageData);
    });
  } catch (err) {
    console.error("Error on outPigMapperSusstellV2: ", err);
  }

  return payloadObj;
};

export default mapPigOutSustell;
