/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { API } from 'aws-amplify';
// eslint-disable-next-line import/no-named-as-default
import { DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { getUserUnitsSettings } from '../../../graphql/queries';
import { updateUserSettings } from '../../../graphql/mutations';
import dsmTheme from '../MUITheme';
import lookupValues from '../../sustell_15/helpers/lookupValues';
import { intitalUserUnits } from '../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../Helpers/UserProfilePrefs';
import { useIsMounted } from '../Helpers/use-is-mounted';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import { UserSettings } from '../../../graphql/types';
import useStyles from './profileStyles/myProfileStyles';
import { DsmButtonV2 } from '../../sustell_15/components/helpers/DsmButton';
import ReactHookDsmSelect from '../Helpers/ReactHookDsmSelect2';
import useAdobeDataLayer from '../../sustell_15/analytics/adobeDataLayer';

const UnitsOfMeasurementV2: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [msg, setMsg] = useState('');
  const [loadData, setLoadData] = useState(true);
  const [cancelPressed, setCancelPressed] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isInError, setIsInError] = useState(false);
  const [dialogActive, setDialogActive] = useState(false);
  const [touched, setTouched] = useState(false);
  const isMounted = useIsMounted();
  const { formSubmissionEvent } = useAdobeDataLayer();

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: intitalUserUnits,
  });

  const history = useHistory();

  useEffect(() => {
    const loadUserProfile = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (loadData) {
        (
          API.graphql({
            query: getUserUnitsSettings,
          }) as Promise<{ data: { getUserSettings: UserSettings } }>
        ).then(
          (response) => {
            setTouched(false);
            setLoadData(false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (response.data.getUserSettings && isMounted()) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              Object.entries(response.data.getUserSettings).map(
                ([key, value]) => {
                  if (value) {
                    methods.setValue(
                      key as keyof typeof intitalUserUnits,
                      value
                    );
                  }
                  return null;
                }
              );
            }
          },
          (reason: any) => {
            console.log('Error fetching units of measurement: ', reason);
          }
        );
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadUserProfile();
    if (cancelPressed) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (
    submitData:
      | {
          unitElectricityUse?: string | undefined;
          unitNaturalGasUse?: string | undefined;
          unitDieselUse?: string | undefined;
          unitGasolineUse?: string | undefined;
          unitHeavyFuelOilUselUse?: string | undefined;
          unitFarmSize?: string | undefined;
          unitAverageAnnualTemperature?: string | undefined;
          unitOutputMass?: string | undefined;
          unitWaterUse?: string | undefined;
          unitHeatUse?: string | undefined;
          unitBarnOutputMass?: string | undefined;
          unitBarnSmallQuantityUnits?: string | undefined;
          unitEnvImpactPer?: string | undefined;
          unitIngredientQuantityInputMass?: string | undefined;
          unitCompoundFeedOutputMass?: string | undefined;
          unitCompoundFeedElectricity?: string | undefined;
          unitTransportDistanceTerrestrial?: string | undefined;
          unitTransportDistanceSea?: string | undefined;
          unitTransportDistanceInlandWaterways?: string | undefined;
          unitCompoundFeedWaterUse?: string | undefined;
          unitCompoundFeedNutritionalDataContent?: string | undefined;
          unitNutritionalGrossEnergy?: string | undefined;
        }
      | undefined
  ) => {
    (async () => {
      formSubmissionEvent('Edit Units of measurements', 'edit');
      try {
        await (API.graphql({
          query: updateUserSettings,
          variables: {
            input: submitData,
            segment: 'UNITS', // updating untis segment of user inputs
          },
        }) as Promise<{
          data: {
            updateUserSettings: UserSettings | null;
          };
        }>);
        setIsInError(false);
        setMsg(intl.formatMessage({ id: 'PROFILE.UNITS.PREF_SAVED' }));
        setDialogActive(true);
        // data is saved with submitData so methods.reset the form to clear isDirty property and prevent Prompt
        methods.reset(submitData);
        // reload profile to be in sync with DynamoDB and visible to other components
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        UserProfilePrefs.getInstance().setUserUnitPrefs(submitData);
      } catch (err) {
        if (err.errors?.length > 0) {
          const error = err.errors[0];
          if (error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setMsg(error.message);
            setIsInError(true);
            setDialogActive(true);
          }
        }
      }
    })()
      .then(() => {})
      .catch(() => {});
  };

  const closeDialog = () => {
    setDialogActive(false);
    setMsg('');
    setTouched(false);
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const resetToDefault = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setTouched(false);
    // I need a field by field set to enable isDirty to prompt before leaving
    Object.entries(intitalUserUnits).forEach(([key, value]) => {
      if (value) {
        const currValue = methods.getValues(key);
        // in intitalUserUnits there are some fields not shown on the form - unitCompoundFeedElectricity
        // skip those filed when reseting to default units to avoid incorect (false) setting of the resetChangedValues to true
        if (currValue) {
          const shouldDirtyForm = currValue !== value;
          methods.setValue(key as keyof typeof intitalUserUnits, value, {
            shouldDirty: shouldDirtyForm,
          });
          setTouched((prev: boolean) => shouldDirtyForm || prev);
        }
      }
    });
  };

  const cancelChanges = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (touched) {
      setUnsavedChanges(true);
    } else {
      setCancelPressed(true);
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push('/');
      }
    }
    setLoadData(true);
  };

  const close = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  const handleTouched = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  const updateDependantMassFields = (e: { target: { value: string } }) => {
    const selValue = e.target.value;
    if (selValue === 'lb') {
      methods.setValue('unitCompoundFeedOutputMass', 'ton');
      methods.setValue('unitCompoundFeedNutritionalDataContent', 'ozperlb');
      methods.setValue('unitCompoundFeedWaterUse', 'usgal');
    } else if (selValue === 'kg') {
      methods.setValue('unitCompoundFeedOutputMass', 'tonne');
      methods.setValue('unitCompoundFeedNutritionalDataContent', 'gperkg');
      methods.setValue('unitCompoundFeedWaterUse', 'm3');
    }
  };

  return (
    <>
      <Dialog open={unsavedChanges} aria-labelledby="draft-dialog">
        <DialogTitle id="draft-dialog">
          {intl.formatMessage({ id: 'GENERAL.UNSAVED_CHANGES' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'UNSAVEDSTATE.PROMPT.CONFIRM' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            {intl.formatMessage({ id: 'GENERAL.YES' })}
          </Button>
          <Button
            autoFocus
            onClick={() => setUnsavedChanges(false)}
            color="default"
          >
            {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={dsmTheme}>
        <Dialog
          open={dialogActive}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {isInError && (
              <>
                <ErrorOutlineIcon fontSize="large" color="error" />
                &nbsp;&nbsp;Error
              </>
            )}
            {!isInError && (
              <>
                <CheckCircleOutlineIcon
                  fontSize="large"
                  style={{ color: 'green' }}
                />
                &nbsp;&nbsp;Success
              </>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {isInError && (
              <Button onClick={closeDialog} color="primary">
                {intl.formatMessage({ id: 'GENERAL.CLOSE' })}
              </Button>
            )}
            {!isInError && (
              <Button onClick={closeDialog} color="primary">
                {intl.formatMessage({ id: 'GENERAL.OK' })}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...methods}
      >
        <form
          className={classes.formContainer}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {/* begin::Body */}
          <div className="card-body p-0">
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({ id: 'PROFILE.UNITS.FARM_PARAMETERS' })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="unitFarmSize"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.FARM_SIZE',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.FARM_SIZE',
              })}
              options={Object.entries(lookupValues.units.area).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
            />
          </DsmGrid>
          <div className={classes.formHeader}>
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({ id: 'PROFILE.UNITS.DATASET_PARAMS' })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="unitAverageAnnualTemperature"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.AVG_TEMP',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({ id: 'PROFILE.UNITS.AVG_TEMP' })}
              options={Object.entries(lookupValues.units.temperature).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitOutputMass"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.OUTPUT_MASS',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.OUTPUT_MASS',
              })}
              options={Object.entries(lookupValues.units.mass).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
          </DsmGrid>
          <div className={classes.formHeader}>
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({
                  id: 'PROFILE.UNITS.RESOURCE_CONSUMPTION',
                })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="unitElectricityUse"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.ELECTRICITY',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.ELECTRICITY',
              })}
              options={Object.entries(lookupValues.units.electricityUse).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitNaturalGasUse"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.GAS',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({ id: 'PROFILE.UNITS.GAS' })}
              options={Object.entries(lookupValues.units.naturalGasUse).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitDieselUse"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.DIESEL',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({ id: 'PROFILE.UNITS.DIESEL' })}
              options={Object.entries(lookupValues.units.dieselUse).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
            />
          </DsmGrid>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              marginTop: '20px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="unitWaterUse"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.WATER',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({ id: 'PROFILE.UNITS.WATER' })}
              options={Object.entries(lookupValues.units.waterUse).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitHeatUse"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.HEAT_USE',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({ id: 'PROFILE.UNITS.HEAT_USE' })}
              options={Object.entries(lookupValues.units.heatUse).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitGasolineUse"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.GASOLINE',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({ id: 'PROFILE.UNITS.GASOLINE' })}
              options={Object.entries(lookupValues.units.gasolineUse).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
            />
          </DsmGrid>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              marginTop: '20px',
              alignItems: 'start',
            }}
          >
          <ReactHookDsmSelect
              name="unitHeavyFuelOilUse"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.HEAVY_FUEL_OIL',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({ id: 'PROFILE.UNITS.HEAVY_FUEL_OIL' })}
              options={Object.entries(lookupValues.units.heavyFuelOilUse).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
            />
          </DsmGrid>
          <div className={classes.formHeader}>
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({ id: 'PROFILE.UNITS.BARN_PARAMS' })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="unitBarnOutputMass"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.OUTPUT_MASS',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.OUTPUT_MASS',
              })}
              options={Object.entries(lookupValues.units.mass).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
          </DsmGrid>

          <div className={classes.formHeader}>
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({
                  id: 'PROFILE.UNITS.ENVIRONMENTAL_IMPACT',
                })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="unitBarnSmallQuantityUnits"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.OUTPUT_MASS_SMALL',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.OUTPUT_MASS_SMALL',
              })}
              options={Object.entries(lookupValues.units.massSmall).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitEnvImpactPer"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.IMPACT_PER',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.IMPACT_PER',
              })}
              options={Object.entries(lookupValues.units.impactCalcMass).map(
                ([i, unit]) => ({
                  value: `${i}`,
                  text: `${unit.label}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
          </DsmGrid>

          <div className={classes.formHeader}>
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({
                  id: 'PROFILE.UNITS.COMPOUND_FEED_INPUT_PARAMS',
                })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="unitIngredientQuantityInputMass"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.INGREDIENT_INPUT_MASS',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.INGREDIENT_INPUT_MASS',
              })}
              options={Object.entries(
                lookupValues.units.unitIngredientQuantityInputMass
              ).map(([i, unit]) => ({
                value: `${i}`,
                text: `${unit.label}`,
              }))}
              changeHandler={(e: { target: { value: string } }) => {
                handleTouched();
                updateDependantMassFields(e);
              }}
              required
            />
            <ReactHookDsmSelect
              name="unitCompoundFeedOutputMass"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.COMPOUND_FEED_OUTPUT_MASS',
              })}`}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.COMPOUND_FEED_OUTPUT_MASS',
              })}
              options={Object.entries(
                lookupValues.units.unitCompoundFeedOutputMass
              ).map(([i, unit]) => ({
                value: `${i}`,
                text: `${unit.label}`,
              }))}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitTransportDistanceTerrestrial"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.TRANSPORT_TERRESTRIAL',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.TRANSPORT_TERRESTRIAL',
              })}
              options={Object.entries(
                lookupValues.units.unitTransportDistanceTerrestrial
              ).map(([i, unit]) => ({
                value: `${i}`,
                text: `${unit.label}`,
              }))}
              changeHandler={handleTouched}
              required
            />
          </DsmGrid>

          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              marginTop: '20px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="unitTransportDistanceSea"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.TRANSPORT_SEA',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.TRANSPORT_SEA',
              })}
              options={Object.entries(
                lookupValues.units.unitTransportDistanceSea
              ).map(([i, unit]) => ({
                value: `${i}`,
                text: `${unit.label}`,
              }))}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitTransportDistanceInlandWaterways"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.TRANSPORT_INLAND_WATER',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.TRANSPORT_INLAND_WATER',
              })}
              options={Object.entries(
                lookupValues.units.unitTransportDistanceInlandWaterways
              ).map(([i, unit]) => ({
                value: `${i}`,
                text: `${unit.label}`,
              }))}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitCompoundFeedWaterUse"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.WATER_USE_CF',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.WATER_USE_CF',
              })}
              options={Object.entries(
                lookupValues.units.unitCompoundFeedWaterUse
              ).map(([i, unit]) => ({
                value: `${i}`,
                text: `${unit.label}`,
              }))}
              changeHandler={handleTouched}
              required
            />
            <ReactHookDsmSelect
              name="unitNutritionalGrossEnergy"
              label={`${intl.formatMessage({
                id: 'PROFILE.UNITS.NUTRITION_ENERGY',
              })}`}
              disabled={false}
              placeholder={intl.formatMessage({
                id: 'PROFILE.UNITS.NUTRITION_ENERGY',
              })}
              options={Object.entries(
                lookupValues.units.unitNutritionalGrossEnergy
              ).map(([i, unit]) => ({
                value: `${i}`,
                text: `${unit.label}`,
              }))}
              changeHandler={handleTouched}
              required
            />
          </DsmGrid>

          <div className={classes.btnDiv}>
            <DsmButtonV2
              variant="secondary"
              type="button"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                cancelChanges(e);
              }}
              className={classes.cancelBtn}
              style={
                {
                  background: '#FFFFFF',
                  color: '#0070BA',
                  '::hover': {
                    backgroundColor: '#FFFFFF !important',
                  },
                }
              }
            >
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </DsmButtonV2>

            <div className="text-right">
              <DsmButtonV2
                type="button"
                variant="contained"
                disableElevation
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  resetToDefault(e);
                }}
                className={classes.saveBtnTxt}
                style={{ marginRight: '10px' }}
              >
                <span className={classes.saveBtnTxt}>
                  {intl.formatMessage({ id: 'PROFILE.UNITS.RESET' })}
                </span>
              </DsmButtonV2>
              <DsmButtonV2
                variant="contained"
                disableElevation
                type="submit"
                disabled={
                  methods.formState.isSubmitting ||
                  (methods.formState.isDirty && !methods.formState.isValid) ||
                  !touched
                }
              >
                <span className={classes.saveBtnTxt}>
                  {intl.formatMessage({ id: 'GENERAL.SAVE' })}
                </span>
                <DsmIcon
                  slot="after"
                  name="arrows/arrow-right"
                  style={{
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    height: '25px',
                    width: '25px',
                    color: 'var(--neutrals-dsm-white, #FFF)',
                  }}
                />
              </DsmButtonV2>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default UnitsOfMeasurementV2;
