import { FC, useEffect, useRef, useState } from "react";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import { DsmButton, DsmGrid, DsmIcon } from "@dsm-dcs/design-system-react";
import { cloneDeep } from "lodash";
import { UserProfilePrefs } from "../../../../../../modules/Helpers/UserProfilePrefs";
import {
  unitLong,
  defaultUnits,
  explicitConvertValue,
} from "../../../../../utils/unit-utils";
import {
  avgLiveWeightMaxs,
  avgLiveWeightMins,
} from "../../../../../../modules/Farms/validationSchemaRestrictions";
import { useIntl } from "../../../../../../../_metronic/i18n/customUseIntl";
import { FormType } from "../../../common";
import {
  PigBaseline,
  PigFatteningInput,
  PigFatteningStageData,
} from "../../../../../models/Baseline/PigBaseline";
import {
  DSM_DISABLED_LABEL,
  processAndStageStylesV2,
} from "../../../../../../../_metronic/layout";
import { DialogContainer } from "../../../CommonDataParts/DialogContainer2";
import ReactHookDsmInput from "../../../../../../modules/Helpers/ReactHookDsmInput2";
import InternalSourcesInput from "./InternalSourcesInput";
import ExternalSourcesInput from "./ExternalSourcesInput";
import DsmButtonControlGroup from "../../../../helpers/DsmButtonControlGroup";
import { StageType } from "../../../../../../../graphql/types";
import ReactHookDsmPicker from "../../../../../../modules/Helpers/ReactHookDsmDatePicker";
import { v4 as uuidv4 } from "uuid";
import { AnimalType } from "../../../../../../../graphql/generated/blonk/pigs";
import { CSSClassesList } from "../../../../../helpers/helperTypes";
import { PigInputDialogProps } from "./PBreadingAnimalInputFormDialog";

const PBAnimalInputFormDialog: FC<PigInputDialogProps> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  handleCancel,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible = false,
  variant = "wide",
}) => {
  const classes = processAndStageStylesV2() as CSSClassesList;
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : "kg";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;

  let minAvgLiveWeightMortality = avgLiveWeightMins["Pig fattening"];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let maxAvgLiveWeightMortality: number | undefined =
    avgLiveWeightMaxs["Pig fattening"]; // No max provided

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (userUOM.unitBarnOutputMass !== defaultUnits.unitBarnOutputMass) {
    if (minAvgLiveWeightMortality)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      minAvgLiveWeightMortality = Number(
        explicitConvertValue(
          minAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        )?.toFixed(precision)
      );
    if (maxAvgLiveWeightMortality)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      maxAvgLiveWeightMortality = Number(
        explicitConvertValue(
          maxAvgLiveWeightMortality,
          defaultUnits.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        )?.toFixed(precision)
      );
  }
  const formTitle = intl.formatMessage({
    id: "SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE",
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;

  const currResetValue = useRef<PigFatteningInput>();
  const formContext = useFormContext<PigBaseline>();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, getValues, setValue } = formContext;
  const {
    fields: internalSources,
    append: appendInternal,
    remove: removeInternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSources`,
    control,
    keyName: "keyId",
  });
  const {
    fields: externalSources,
    append: appendExternal,
    remove: removeExternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSources`,
    control,
    keyName: "keyId",
  });

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as PigBaseline;
      const stageData = resetObject.stages[itemIndex]
        ?.stageData as PigFatteningStageData;
      // console.log("reset object:",resetObject);
      if (stageData?.input) {
        stageData.input = { ...currResetValue.current } as PigFatteningInput;
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel("reset");
  };

  const addInternalSource = () => {
    appendInternal({
      id: uuidv4(),
      farmId: "",
      numberOfNewPigs: "",
      originStageId: "",
      distanceTransport: "",
      averageAgeOfPigs: "",
    });
  };

  const removeInternalSource = (index: number) => {
    removeInternal(index);
  };

  const addExternalSource = () => {
    appendExternal({
      id: uuidv4(),
      numberOfNewPigs: "",
      averageAgeOfPigs: "",
      averageWightOfPigs: "",
      distanceTransport: "",
    });
  };

  const removeExternalSource = (index: number) => {
    removeExternal(index);
    // console.log(getValues(`${fieldItemPrefix}.externalSources`));
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const pigsPresentAtStart = useWatch({
    name: `stages.${itemIndex}.stageData.input.optionalInput.pigsPresentAtStart`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue:
      formContext.getValues(
        `stages.${itemIndex}.stageData.input.optionalInput.pigsPresentAtStart`
      ) || "",
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const pigsPresentAtEnd = useWatch({
    name: `stages.${itemIndex}.stageData.input.optionalInput.pigsPresentAtEnd`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue:
      formContext.getValues(
        `stages.${itemIndex}.stageData.input.optionalInput.pigsPresentAtEnd`
      ) || "",
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const averageWeightPigsStart = useWatch({
    name: `stages.${itemIndex}.stageData.input.optionalInput.averageWeightPigsStart`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue:
      formContext.getValues(
        `stages.${itemIndex}.stageData.input.optionalInput.averageWeightPigsStart`
      ) || "",
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const averageWeightPigsEnd = useWatch({
    name: `stages.${itemIndex}.stageData.input.optionalInput.averageWeightPigsEnd`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue:
      formContext.getValues(
        `stages.${itemIndex}.stageData.input.optionalInput.averageWeightPigsEnd`
      ) || "",
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const averageAgePigsStart = useWatch({
    name: `stages.${itemIndex}.stageData.input.optionalInput.averageAgePigsStart`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue:
      formContext.getValues(
        `stages.${itemIndex}.stageData.input.optionalInput.averageAgePigsStart`
      ) || "",
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const averageAgePigsEnd = useWatch({
    name: `stages.${itemIndex}.stageData.input.optionalInput.averageAgePigsEnd`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    defaultValue:
      formContext.getValues(
        `stages.${itemIndex}.stageData.input.optionalInput.averageAgePigsEnd`
      ) || "",
  });

  if (
    pigsPresentAtStart ||
    pigsPresentAtEnd ||
    averageWeightPigsStart ||
    averageWeightPigsEnd ||
    averageAgePigsStart ||
    averageAgePigsEnd
  ) {
    formContext.setValue(
      `stages.${itemIndex}.stageData.input.stockPresent`,
      true
    );
  } else {
    formContext.setValue(
      `stages.${itemIndex}.stageData.input.stockPresent`,
      false
    );
  }

  const allStages = formContext.getValues("stages") || [];
  let stages = allStages
    .filter((item) => item.type !== StageType.Processing)
    .map((item) => ({ text: item.name, value: item.id }));
  const breedingStagesList = allStages
    .filter((item) => item.type === StageType.Breeding)
    .map((item) => item.id);

  useEffect(() => {
    if (internalSources.length === 0 && externalSources.length === 0) {
      addInternalSource();
      addExternalSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelDisabled =
    formType === FormType.View ? { color: DSM_DISABLED_LABEL as string } : {};

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="general/check-heart"
      variant="wide"
      formTitle={formTitle}
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.startDate`}
          label={intl.formatMessage({ id: "BASELINE.FORM.FIELD.START_DATE" })}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.START_DATE.TOOLTIP",
          })}
          disabled={formType === FormType.View}
          required
          defaultValue={formContext.getValues(`${fieldItemPrefix}.startDate`)}
        />
        <ReactHookDsmPicker
          name={`${fieldItemPrefix}.endDate`}
          label={intl.formatMessage({ id: "BASELINE.FORM.FIELD.END_DATE" })}
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.END_DATE.TOOLTIP",
          })}
          required
          defaultValue={formContext.getValues(`${fieldItemPrefix}.endDate`)}
        />
      </DsmGrid>
      <h4>
        {intl.formatMessage({
          id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.STOCK",
        })}
      </h4>
      <p>
        {intl.formatMessage({
          id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.STOCK.DESCRIPTION",
        })}
      </p>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.optionalInput.pigsPresentAtStart`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_START",
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.PLACEHOLDER",
          })}
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.optionalInput.pigsPresentAtStart`
          )}
          required={formContext.getValues(
            `stages.${itemIndex}.stageData.input.stockPresent`
          )}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.optionalInput.pigsPresentAtEnd`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.AT_END",
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NUMBER_OF_ANIMALS.PLACEHOLDER",
          })}
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.optionalInput.pigsPresentAtEnd`
          )}
          required={formContext.getValues(
            `stages.${itemIndex}.stageData.input.stockPresent`
          )}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.optionalInput.averageWeightPigsStart`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_WEIGHT.START",
          })}
          disabled={formType === FormType.View}
          adornment={barnOutputMassUnit}
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.optionalInput.averageWeightPigsStart`
          )}
          required={formContext.getValues(
            `stages.${itemIndex}.stageData.input.stockPresent`
          )}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.optionalInput.averageWeightPigsEnd`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_WEIGHT.END",
          })}
          disabled={formType === FormType.View}
          adornment={barnOutputMassUnit}
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.optionalInput.averageWeightPigsEnd`
          )}
          required={formContext.getValues(
            `stages.${itemIndex}.stageData.input.stockPresent`
          )}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.optionalInput.averageAgePigsStart`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.START",
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.AGE.PLACEHOLDER",
          })}
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.optionalInput.averageAgePigsStart`
          )}
          required={formContext.getValues(
            `stages.${itemIndex}.stageData.input.stockPresent`
          )}
          type="number"
        />
        <ReactHookDsmInput
          name={`${fieldItemPrefix}.optionalInput.averageAgePigsEnd`}
          label={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.FATTENING.AVG_AGE.END",
          })}
          disabled={formType === FormType.View}
          adornment={intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.AGE.PLACEHOLDER",
          })}
          defaultValue={formContext.getValues(
            `${fieldItemPrefix}.optionalInput.averageAgePigsEnd`
          )}
          required={formContext.getValues(
            `stages.${itemIndex}.stageData.input.stockPresent`
          )}
          type="number"
        />
      </DsmGrid>
      <h4>
        {intl.formatMessage({
          id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NEW.ANIMALS",
        })}
      </h4>
      <p>
        {intl.formatMessage({
          id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.NEW.ANIMALS.DESCRIPTION",
        })}
      </p>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <h4 style={labelDisabled}>
          {intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.INTERNAL_SOURCE",
          })}
        </h4>
        <h4 style={labelDisabled}>
          {intl.formatMessage({
            id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.EXTERNAL_SOURCE",
          })}
        </h4>
        <div>
          {internalSources &&
            internalSources?.map((item, index) => (
              <InternalSourcesInput
                farms={farms}
                farmId={farmId}
                farmName={farmName}
                allowedStagesForFarm={[StageType.Fattening, StageType.Breeding]}
                productionProcessName={productionProcessName}
                key={item.keyId} // internally assigned id from useFieldArray
                stageIndex={itemIndex}
                itemIndex={index}
                formType={formType}
                stages={stages}
                stageOriginAnimalType={AnimalType.Piglet}
                breedingStagesList={breedingStagesList}
                stageType={StageType.Fattening}
                distanceUnit={userUOM.unitTransportDistanceTerrestrial}
                removeHandler={removeInternalSource}
                barnOutputMassUnit={barnOutputMassUnit}
              />
            ))}
          <DsmButton
            variant="text"
            onClick={addInternalSource}
            disabled={
              internalSources.length >= stages.length ||
              formType === FormType.View
            }
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ADDITIONAL_INTERNAL",
            })}
          </DsmButton>
        </div>
        <div>
          {externalSources &&
            externalSources?.map((item, index) => (
              <ExternalSourcesInput
                key={item.keyId} // internally assigned id from useFieldArray
                stageIndex={itemIndex}
                itemIndex={index}
                formType={formType}
                stageType={StageType.Fattening}
                removeHandler={removeExternalSource}
                distanceUnit={userUOM.unitTransportDistanceTerrestrial}
                barnOutputMassUnit={barnOutputMassUnit}
              />
            ))}
          <DsmButton
            variant="text"
            onClick={addExternalSource}
            disabled={formType === FormType.View}
          >
            <DsmIcon slot="before" name="general/plus-circle" />
            {intl.formatMessage({
              id: "SUSTELL.STAGE.PIGS.ANIMAL_INPUT.ADDITIONAL_EXTERNAL",
            })}
          </DsmButton>
        </div>
      </DsmGrid>

      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(`stages.${itemIndex}.stageData.input`);
          handleCancel("confirm");
        }}
        saveLabel={intl.formatMessage({ id: "GENERAL.CONFIRM" })}
      />
    </DialogContainer>
  );
};

export default PBAnimalInputFormDialog;
