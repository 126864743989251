export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AnimalInput = {
  /**  # Specify the origin facility ID of the animals that are coming from another facility in the system. */
  originId: Scalars['ID'];
  /**  # The number of animals. */
  quantity: Scalars['Float'];
  /**  # The transport of the animal input to go to the facility. */
  transport: Array<Transport>;
};

export type AnimalProcessing = {
  /**  # A list of facilities for processing the animals. */
  facilities: Array<Facility>;
};

export enum AnimalProductivity {
  High = 'HIGH',
  Low = 'LOW'
}

export enum AnimalType {
  Piglet = 'PIGLET',
  Sow = 'SOW'
}

/**   PIG MODELS */
export type AnimalsIncoming = {
  /**  # The distance of transport by truck, in kilometer. Default: 0 km. */
  distanceTransport?: InputMaybe<Scalars['Float']>;
  /**  # The number of pigs entering the stage */
  numberPigs: Scalars['Float'];
  /**  # The type of origin of the animals. */
  origin: InputAnimalOrigin;
  /**  # Specify an origin stage ID if the pigs are coming from another stage in the system, otherwise a background product is chosen. */
  originStageId?: InputMaybe<Scalars['ID']>;
};

export type AuxiliaryInputs = {
  /**  # A list of chemicals that are used to clean the facility. */
  chemicals?: InputMaybe<Array<Chemical>>;
  /**  # A list of refrigerants that are used to cool the facility. */
  refrigerants?: InputMaybe<Array<Refrigerant>>;
  /**  # The amount of wastewater that will be discharged to the sewage treatment plant. In cubic meter per year. */
  wastewaterAmount: Scalars['Float'];
};

export type Bedding = {
  /**  # Total of the whole herd over the whole duration of the modelled period. In kilogram. */
  amount: Scalars['Float'];
  type: BeddingType;
};

export enum BeddingType {
  /**  With a default nitrogen content of 5.5 g/kg and phosphorous content of 1.0 g/kg */
  BarleyStraw = 'BARLEY_STRAW',
  /**  With a default nitrogen content of 5.8 g/kg and phosphorous content of 0.8 g/kg */
  MaizeStover = 'MAIZE_STOVER',
  /**  With a default nitrogen content of 5.2 g/kg and phosphorous content of 1.2 g/kg */
  OatStraw = 'OAT_STRAW',
  /**  With a default nitrogen content of 1.0 g/kg and phosphorous content of 0.1 g/kg */
  SawDust = 'SAW_DUST',
  /**  With a default nitrogen content of 1.12 g/kg and phosphorous content of 1.3 g/kg */
  SunflowerHusk = 'SUNFLOWER_HUSK',
  /**  With a default nitrogen content of 6.1 g/kg and phosphorous content of 0.7 g/kg */
  WheatStraw = 'WHEAT_STRAW',
  /**  With a default nitrogen content of 1.0 g/kg and phosphorous content of 0.1 g/kg */
  WoodShavings = 'WOOD_SHAVINGS'
}

export type BreedingStage = {
  /**  # This needs to be specified for IPCC emission calculation rules if the farm is in Asia or South America. */
  animalProductivity?: InputMaybe<AnimalProductivity>;
  /**  # The average weight per pig at the end of the stage period in kilograms. */
  averageWeightPigsEnd: Scalars['Float'];
  /**  # The average weight per pig at the start of the stage period in kilograms. */
  averageWeightPigsStart: Scalars['Float'];
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # A list of all the stage emissions that need to be changed by intervention. */
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # The total duration of completely empty stage time during the reporting period, in days, default = 0. */
  emptyTime?: InputMaybe<Scalars['Float']>;
  /**  # End date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  endDate: Scalars['String'];
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgram: FeedingProgram;
  /**  # The housing and the manure handling. */
  housing: Housing;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the stage during the stage period. This only includes pigs, since piglets are born within the stage. */
  input: StageInput;
  materials?: InputMaybe<Array<InputMaybe<Material>>>;
  name?: InputMaybe<Scalars['String']>;
  /**  # A description of all the animals leaving the stage during the stage period. */
  output: BreedingStageOutput;
  /**  # The number of pigs present at the end of the stage period. */
  pigsPresentAtEnd: Scalars['Float'];
  /**  # The number of pigs present at the start of the stage period. */
  pigsPresentAtStart: Scalars['Float'];
  /**  # The price of the pigs present at the end of the stage period, in euro per kg live weight. */
  pricePerWeightPigsEnd?: InputMaybe<Scalars['Float']>;
  /**  # The price of the pigs present at the start of the stage period, in euro per kg live weight. */
  pricePerWeightPigsStart?: InputMaybe<Scalars['Float']>;
  /**  # Start date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  startDate: Scalars['String'];
  /**  # Each utility, bedding material and other material may be specified on either farm or stage level, where stage level is preferred. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type BreedingStageOutput = {
  /**  # In kilogram */
  averageWeightPiglets: Scalars['Float'];
  /**  # In kilogram */
  averageWeightPigs: Scalars['Float'];
  /**  # Indicate if an impact result is needed, even if there are no sold piglets in this stage. */
  calculateLcaPiglets?: InputMaybe<Scalars['Boolean']>;
  /**  # Indicate if an impact result is needed, even if there are no sold pigs in this stage. */
  calculateLcaPigs?: InputMaybe<Scalars['Boolean']>;
  /**  # The total number of piglet mortalities over the whole duration of the stage. */
  mortalityPiglets: Scalars['Float'];
  /**  # The total number of pig mortalities over the whole duration of the stage. */
  mortalityPigs: Scalars['Float'];
  /**
   *  # The number of piglets that move to another stage in the system for growing.
   * # Specify either sold piglets, piglets to another stage or both as the output of this stage.
   */
  pigletsToStage?: InputMaybe<Scalars['Float']>;
  /**
   *  # The number of pigs that move to another stage in the system, for growing or sowing.
   * # Specify either sold pigs, pigs to another stage or both as the output of this stage.
   */
  pigsToStage?: InputMaybe<Scalars['Float']>;
  /**  # In euro per kilogram liveweight */
  priceSoldPiglets?: InputMaybe<Scalars['Float']>;
  /**  # In euro per kilogram liveweight */
  priceSoldPigs?: InputMaybe<Scalars['Float']>;
  /**
   *  # The number of piglets sold externally. This is an output of the production system for which a climate impact will be calculated.
   * # Specify either sold piglets, piglets to another stage or both as the output of this stage.
   */
  soldPiglets?: InputMaybe<Scalars['Float']>;
  /**
   *  # The number of pigs sold externally. This is an output of the production system for which a climate impact will be calculated.
   * # Specify either sold pigs, pigs to another stage or both as the output of this stage.
   */
  soldPigs?: InputMaybe<Scalars['Float']>;
  /**  # The average weight of piglets at mortality, in kilogram. Default: half the output weight */
  weightAtMortalityPiglets?: InputMaybe<Scalars['Float']>;
  /**  # The average weight of pigs at mortality, in kilogram. Default: average between input and output weight */
  weightAtMortalityPigs?: InputMaybe<Scalars['Float']>;
};

export type Chemical = {
  /**  # The used amount of the chemical. In kilogram per year. */
  amount: Scalars['Float'];
  type: ChemicalType;
};

export enum ChemicalType {
  ChemicalOrganic = 'CHEMICAL_ORGANIC',
  EthanolFromMaize = 'ETHANOL_FROM_MAIZE',
  Glycerine = 'GLYCERINE',
  HydrochloricAcid = 'HYDROCHLORIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  Isopropanol = 'ISOPROPANOL',
  NitrogenLiquid = 'NITROGEN_LIQUID',
  OxygenLiquid = 'OXYGEN_LIQUID',
  PhosphoricAcid = 'PHOSPHORIC_ACID',
  PotassiumHydroxide = 'POTASSIUM_HYDROXIDE',
  SodiumChlorate = 'SODIUM_CHLORATE',
  SodiumChlorideAsBrineSolution = 'SODIUM_CHLORIDE_AS_BRINE_SOLUTION',
  SodiumHydroxide = 'SODIUM_HYDROXIDE',
  SodiumHypochlorite = 'SODIUM_HYPOCHLORITE',
  SulfuricAcid = 'SULFURIC_ACID'
}

export enum ClimateType {
  BorealDry = 'BOREAL_DRY',
  BorealMoist = 'BOREAL_MOIST',
  CoolTemperateDry = 'COOL_TEMPERATE_DRY',
  CoolTemperateMoist = 'COOL_TEMPERATE_MOIST',
  TropicalDry = 'TROPICAL_DRY',
  TropicalMoist = 'TROPICAL_MOIST',
  /**  climate types as specified in in the IPCC */
  TropicalMontane = 'TROPICAL_MONTANE',
  TropicalWet = 'TROPICAL_WET',
  WarmTemperateDry = 'WARM_TEMPERATE_DRY',
  WarmTemperateMoist = 'WARM_TEMPERATE_MOIST'
}

export type CompoundFeed = {
  compoundFeedId: Scalars['ID'];
  energyCarriers?: InputMaybe<Array<EnergyCarrier>>;
  feedmillLocation: Scalars['String'];
  ingredientUses: Array<IngredientUse>;
  name: Scalars['String'];
  outboundTransport?: InputMaybe<Array<Transport>>;
  water?: InputMaybe<Array<Water>>;
};

export type CompoundFeedInfo = {
  /**  # Weight percentage ash content as a fraction of the feed weight. Default: 6% */
  ashContent?: InputMaybe<Scalars['Float']>;
  /**  # ID to find compound feed in compound feed API. */
  compoundFeedId: Scalars['ID'];
  /**  # Weight percentage crude protein content as a fraction of the feed weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # Digestible energy content, in MJ/kg. Default: 13.284 */
  digestibleEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage dry matter content as a fraction of the feed weight. Default: 90% */
  dryMatterContent?: InputMaybe<Scalars['Float']>;
  /**  # Gross energy content, in MJ/kg. Default: 16.605 */
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  /**  # Weight percentage phosphorus content as a fraction of the feed weight. Default: 0% */
  phosphorusContent?: InputMaybe<Scalars['Float']>;
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram. */
  quantity: Scalars['Float'];
  /**  # Urinary energy content, in MJ/kg. Default: 0.26568 */
  urinaryEnergyContent?: InputMaybe<Scalars['Float']>;
};

/**   APS ENUMS */
export enum DatabaseFoundation {
  Afp6 = 'AFP6',
  Gfli = 'GFLI'
}

export type EmissionMitigation = {
  /**  # Increase in %, use a negative value to decrease the calculated emission value. */
  change: Scalars['Float'];
  emissionType: EmissionType;
};

/**   RESULT MODELS */
export type EmissionResult = {
  __typename?: 'EmissionResult';
  magnitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
};

/**   PIG ENUMS */
export enum EmissionType {
  AmmoniaHousing = 'AMMONIA_HOUSING',
  AmmoniaStorage = 'AMMONIA_STORAGE',
  DinitrogenStorage = 'DINITROGEN_STORAGE',
  MethaneEntericFermentation = 'METHANE_ENTERIC_FERMENTATION',
  MethaneMms = 'METHANE_MMS',
  NitrogenOxidesStorage = 'NITROGEN_OXIDES_STORAGE',
  NitrousOxideDirect = 'NITROUS_OXIDE_DIRECT',
  NitrousOxideLeaching = 'NITROUS_OXIDE_LEACHING',
  NitrousOxideVolatilization = 'NITROUS_OXIDE_VOLATILIZATION',
  NonMethaneVolatileOrganicCompounds = 'NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS',
  Pm2_5 = 'PM2_5',
  Pm10 = 'PM10',
  Tsp = 'TSP'
}

export type EnergyCarrier = {
  /**  # The amount of the energy carrier. The unit is based on the EnergyCarrierTypeAps per year. */
  amount: Scalars['Float'];
  type: EnergyCarrierType;
};

export enum EnergyCarrierType {
  /**  The burning of coal, in Mega Joule. */
  Coal = 'COAL',
  /**  The burning of diesel, in Mega Joule. */
  Diesel = 'DIESEL',
  /**  The electricity use from the grid, in kWh. */
  Electricity = 'ELECTRICITY',
  /**  The electricity use from the solar panels, in kWh. */
  ElectricityPhotovoltaic = 'ELECTRICITY_PHOTOVOLTAIC',
  /**  The electricity use from the wind, in kWh. */
  ElectricityWind = 'ELECTRICITY_WIND',
  /**  The burning of gasoline, in Mega Joule. */
  Gasoline = 'GASOLINE',
  HeavyFuelOil = 'HEAVY_FUEL_OIL',
  /**  The burning of natural gas, in Mega Joule. */
  NaturalGas = 'NATURAL_GAS',
  /**  The burning of propane or Liquefied Petroleum Gas, in Mega Joule. */
  PropaneOrLpg = 'PROPANE_OR_LPG'
}

export type Facility = {
  /**  # The auxiliary inputs for the entire facility. */
  auxiliaryInputs: AuxiliaryInputs;
  /**  # The utilities used in the facility, divided in Energy Carriers and Water */
  energyCarriers: Array<EnergyCarrier>;
  /**  # The location of the facility, typically the country. */
  geography: Scalars['String'];
  /**  # A UUID that should be provided for each facility, in order to link the facilities between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the facility. */
  inputs: FacilityInputs;
  name?: InputMaybe<Scalars['String']>;
  /**  # A description of all the animals leaving the facility. */
  outputs: FacilityOutputs;
  water: Array<Water>;
};

export type FacilityInputs = {
  /**  # A list of animal inputs to go into the facility. */
  animalInputs: Array<AnimalInput>;
  /**  # The average weight of all animals that go into the facility. In kilogram. */
  averageWeight: Scalars['Float'];
  /**  # Required when origin stage is a BreedingStage */
  name?: InputMaybe<AnimalType>;
  /**  # The total live weight entering the entire facility, this can be more than the target animal input flow. In kilogram per year. */
  totalLiveWeightEntering: Scalars['Float'];
};

export type FacilityOutputs = {
  cat1And2MaterialAndWaste: OutputSpecification;
  cat3HidesAndSkins: OutputSpecification;
  cat3SlaughterByProducts: OutputSpecification;
  foodGradeBones: OutputSpecification;
  foodGradeFat: OutputSpecification;
  freshMeatAndEdibleOffal: OutputSpecification;
};

export type Farm = {
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # At least one breeding or fattening stage is required. */
  breedingStages?: InputMaybe<Array<InputMaybe<BreedingStage>>>;
  /**  # The IPCC climate type, it can be found on the map of IPCC climate types */
  climateType: ClimateType;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  fatteningStages?: InputMaybe<Array<InputMaybe<FatteningStage>>>;
  /**  # The location of the farm, typically the country. */
  geography: Scalars['String'];
  materials?: InputMaybe<Array<InputMaybe<Material>>>;
  /**
   *  # Each utility, bedding material and other material may be specified on either farm or stage level, where stage level is preferred. If not specified a default level of 0 is used.
   * # If a utility is specified on farm level, it is allocated to the stages where it is not specified, based on the feed intake.
   */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

/**
 *  # Each type of water, energy carrier, bedding and material can be specified on stage level or on farm level.
 * # The farm level property is allocated to the stages, that do not already have the property defined.
 * # After subtracting the stages where the property is specified it is allocated to the remaining stages using the feed intake.
 * # If there is an impossible mismatch between the farm and stage amounts an error is generated.
 * # For easy implementation: only allow input at farm level.
 */
export type FatteningStage = {
  /**  # By default, it is set to high productivity. If known, enter. It will affect emission calculations if the farm is in Asia or South America. */
  animalProductivity?: InputMaybe<AnimalProductivity>;
  /**  # The average age of the pigs at the end of the stage period in days. */
  averageAgePigsEnd: Scalars['Float'];
  /**  # The average age of the pigs at the start of the stage period in days. */
  averageAgePigsStart: Scalars['Float'];
  /**  # The average weight per pig at the end of the stage period in kilograms. */
  averageWeightPigsEnd: Scalars['Float'];
  /**  # The average weight per pig at the start of the stage period in kilograms. */
  averageWeightPigsStart: Scalars['Float'];
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # A list of all the stage emissions that need to be changed by intervention. */
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgram: FeedingProgram;
  /**  # The housing and the manure handling. */
  housing: Housing;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the stage during the stage period. */
  input: StageInput;
  materials?: InputMaybe<Array<InputMaybe<Material>>>;
  name?: InputMaybe<Scalars['String']>;
  /**  # A description of all the animals leaving the stage during the stage period. */
  output: FatteningStageOutput;
  /**  # The pigs present at the end of the stage period. */
  pigsPresentAtEnd: Scalars['Float'];
  /**  # The number of pigs present at the start of the stage period. */
  pigsPresentAtStart: Scalars['Float'];
  /**  # The price of the pigs present at the end of the stage period, in euro per kg live weight. */
  pricePerWeightPigsEnd?: InputMaybe<Scalars['Float']>;
  /**  # The price of the pigs present at the start of the stage period, in euro per kg live weight. */
  pricePerWeightPigsStart?: InputMaybe<Scalars['Float']>;
  /**  # Each utility, bedding material and other material may be specified on either farm or stage level, where stage level is preferred. */
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type FatteningStageOutput = {
  /**  # The average age of pigs at mortality, in days. Default: average between input and output age */
  ageAtMortalityPigs?: InputMaybe<Scalars['Float']>;
  /**  # In days */
  averageAgePigs: Scalars['Float'];
  /**  # In kilogram */
  averageWeightPigs: Scalars['Float'];
  /**  # Indicate if an impact result is needed, even if there are no sold pigs in this stage. */
  calculateLcaPigs?: InputMaybe<Scalars['Boolean']>;
  /**  # The total number of mortalities over the whole duration of the stage. */
  mortalityPigs: Scalars['Float'];
  /**
   *  # The number of pigs that move to another stage in the system, for growing or sowing.
   * # Specify either sold pigs, pigs to another stage or both as the output of this stage.
   */
  pigsToStage?: InputMaybe<Scalars['Float']>;
  /**  # In euro per kilogram liveweight */
  priceSoldPigs?: InputMaybe<Scalars['Float']>;
  /**
   *  # The number of pigs sold externally. This is an output of the production system for which a climate impact will be calculated.
   * # Specify either sold pigs, pigs to another stage or both as the output of this stage.
   */
  soldPigs?: InputMaybe<Scalars['Float']>;
  /**  # The average weight of pigs at mortality, in kilogram. Default: average between input and output weight */
  weightAtMortalityPigs?: InputMaybe<Scalars['Float']>;
};

export type FeedingProgram = {
  /**  # List of used compound feeds. At least one of compound feed or single ingredient is required. */
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedInfo>>>;
  /**  # List of used single ingredients. At least one of compound feed or single ingredient is required. */
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export type ForegroundTreeResult = {
  __typename?: 'ForegroundTreeResult';
  /**  # Emissions occurring in this production system */
  emissionResults?: Maybe<Array<Maybe<StageEmissionResult>>>;
  /**  # json dump of BlonkSchema Dataset */
  lci?: Maybe<Scalars['String']>;
  /**  # None code terminating warnings */
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type Housing = {
  /**
   *  # A list of all the different manure management systems that handle liquid or slurry manure in the stage.
   * # The systems are used in parallel (not consecutively) and should add up to 100 %.
   */
  liquidSlurryMms?: InputMaybe<Array<InputMaybe<Mms>>>;
  /**  # The form of the manure in the stage. Default = LIQUID_SLURRY. */
  manureForm?: InputMaybe<ManureForm>;
  /**
   *  # A list of all the different manure management systems that handle solid manure in the stage.
   * # The systems are used in parallel (not consecutively) and should add up to 100 %.
   */
  solidMms?: InputMaybe<Array<InputMaybe<Mms>>>;
  /**  # The surface type of the housing system. The surface type effects the possible manure forms that are possible. Default = SLATTED_FLOOR */
  surfaceType?: InputMaybe<SurfaceType>;
};

export type Impact = {
  category: Scalars['String'];
  quantity: Scalars['Float'];
  unit: Scalars['String'];
};

export type ImpactAssessmentResult = {
  __typename?: 'ImpactAssessmentResult';
  category?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit?: Maybe<Scalars['String']>;
};

export type ImportedImpact = {
  id: Scalars['ID'];
  impacts: Array<Impact>;
  productName: Scalars['String'];
};

export type Ingredient = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type IngredientUse = {
  inboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  ingredient: Ingredient;
  /**  # Unit: kg */
  quantity: Scalars['Float'];
};

export enum InputAnimalOrigin {
  /**  Animals bought externally, the impact of these animals is derived from background data. */
  BackgroundData = 'BACKGROUND_DATA',
  /**  Animals coming from a stage modelled in the same production system. */
  ProductionSystem = 'PRODUCTION_SYSTEM'
}

export type Mms = {
  /**  # A list of all the manure emissions that need to be changed by intervention. */
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # The type of manure management system. */
  mmsType: ManureManagementSystemType;
  /**  # The percentage of inorganic nitrogen in fertilizer that is replaced by manure from the farm. Default: 50%. */
  nReplacingFertilizer?: InputMaybe<Scalars['Float']>;
  /**  # The percentage of inorganic phosphorous in fertilizer that is replaced by manure from the farm. Default: 50%. */
  pReplacingFertilizer?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage of the total manure of a form (solid or liquid/slurry) that goes into this manure management system. */
  share: Scalars['Float'];
  /**  # Percentage of the manure, applied to cultivation replacing fertilizer application outside the production system (not related to on-farm production). Default: 100% */
  shareManureFertilizerNotForOwnAnimalSystemFeed?: InputMaybe<Scalars['Float']>;
};

export type MmsEmissionResult = {
  __typename?: 'MMSEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsType?: Maybe<Scalars['String']>;
};

export enum ManureForm {
  /**
   *  All the manure of the stage is liquid or slurry and there is no separately handled solid manure.
   * # Specify one or multiple liquid/slurry manure management systems and no solid manure management systems.
   */
  LiquidSlurry = 'LIQUID_SLURRY',
  /**
   *  All the manure of the stage is solid and there is no separately handled liquid/slurry manure.
   * # Specify one or multiple solid manure management systems and no liquid/slurry manure management systems.
   */
  Solid = 'SOLID',
  /**
   *  There is both solid manure and liquid and/or slurry manure that is handled separately.
   * # Specify one or multiple liquid/slurry manure management systems and one or multiple solid manure management systems.
   */
  SolidAndLiquidSlurry = 'SOLID_AND_LIQUID_SLURRY'
}

export enum ManureManagementSystemType {
  /**  Aerobic treatment; Forced aeration systems */
  AerobicForced = 'AEROBIC_FORCED',
  /**  Aerobic treatment; Natural aeration systems */
  AerobicNatural = 'AEROBIC_NATURAL',
  /**  Anaerobic digester; High leakage, low quality technology, high quality gastight storage technology */
  AnaerobicHighLeakHighGastight = 'ANAEROBIC_HIGH_LEAK_HIGH_GASTIGHT',
  /**  Anaerobic digester; High leakage, low quality technology, low quality gastight storage technology */
  AnaerobicHighLeakLowGastight = 'ANAEROBIC_HIGH_LEAK_LOW_GASTIGHT',
  /**  Anaerobic digester; High leakage, low quality technology, open storage */
  AnaerobicHighLeakOpen = 'ANAEROBIC_HIGH_LEAK_OPEN',
  /**  Anaerobic digester; Low leakage, High quality gastight storage, best complete industrial technology */
  AnaerobicLowLeakHighGastight = 'ANAEROBIC_LOW_LEAK_HIGH_GASTIGHT',
  /**  Anaerobic digester; Low leakage, High quality industrial technology, low quality gastight storage technology */
  AnaerobicLowLeakLowGastight = 'ANAEROBIC_LOW_LEAK_LOW_GASTIGHT',
  /**  Anaerobic digester; Low leakage, High quality industrial technology, open storage */
  AnaerobicLowLeakOpen = 'ANAEROBIC_LOW_LEAK_OPEN',
  /**  Composting; Intensive Windrow (Frequent turning) */
  CompostingIntensiveWindrow = 'COMPOSTING_INTENSIVE_WINDROW',
  CompostingInVessel = 'COMPOSTING_IN_VESSEL',
  /**  Composting; Passive windrow (infrequent turning) */
  CompostingPassiveWindrow = 'COMPOSTING_PASSIVE_WINDROW',
  /**  Composting; Static Pile (Forced aeration) */
  CompostingStaticPile = 'COMPOSTING_STATIC_PILE',
  /**  Daily spread */
  DailySpread = 'DAILY_SPREAD',
  DeepBeddingActiveMixingOver_1Month = 'DEEP_BEDDING_ACTIVE_MIXING_OVER_1MONTH',
  DeepBeddingActiveMixingUnder_1Month = 'DEEP_BEDDING_ACTIVE_MIXING_UNDER_1MONTH',
  /**  Cattle and swine deep bedding */
  DeepBeddingNoMixingOver_1Month = 'DEEP_BEDDING_NO_MIXING_OVER_1MONTH',
  DeepBeddingNoMixingUnder_1Month = 'DEEP_BEDDING_NO_MIXING_UNDER_1MONTH',
  /**  Liquid/Slurry; Cover */
  LiquidCover_1Month = 'LIQUID_COVER_1MONTH',
  LiquidCover_3Month = 'LIQUID_COVER_3MONTH',
  LiquidCover_4Month = 'LIQUID_COVER_4MONTH',
  LiquidCover_6Month = 'LIQUID_COVER_6MONTH',
  LiquidCover_12Month = 'LIQUID_COVER_12MONTH',
  /**  Liquid/Slurry; With natural crust cover */
  LiquidCrust_1Month = 'LIQUID_CRUST_1MONTH',
  LiquidCrust_3Month = 'LIQUID_CRUST_3MONTH',
  LiquidCrust_4Month = 'LIQUID_CRUST_4MONTH',
  LiquidCrust_6Month = 'LIQUID_CRUST_6MONTH',
  LiquidCrust_12Month = 'LIQUID_CRUST_12MONTH',
  /**  Liquid/Slurry; Without natural crust cover */
  LiquidNoCrust_1Month = 'LIQUID_NO_CRUST_1MONTH',
  LiquidNoCrust_3Month = 'LIQUID_NO_CRUST_3MONTH',
  LiquidNoCrust_4Month = 'LIQUID_NO_CRUST_4MONTH',
  LiquidNoCrust_6Month = 'LIQUID_NO_CRUST_6MONTH',
  LiquidNoCrust_12Month = 'LIQUID_NO_CRUST_12MONTH',
  /**  Pit storage below animal confinements */
  Pit_1Month = 'PIT_1MONTH',
  Pit_3Month = 'PIT_3MONTH',
  Pit_4Month = 'PIT_4MONTH',
  Pit_6Month = 'PIT_6MONTH',
  Pit_12Month = 'PIT_12MONTH',
  /**  Solid storage; Additives */
  SolidAdditives = 'SOLID_ADDITIVES',
  /**  Solid storage; Bulking agent addition */
  SolidBulking = 'SOLID_BULKING',
  /**  Solid storage; Covered/compacted */
  SolidCovered = 'SOLID_COVERED',
  /**  Solid storage */
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON'
}

export type Material = {
  /**  # Total of the whole herd over the whole duration of the modelled period. In kilogram. */
  amount: Scalars['Float'];
  type: MaterialType;
};

export enum MaterialType {
  Detergent = 'DETERGENT',
  Disinfectant = 'DISINFECTANT',
  SulfuricAcid = 'SULFURIC_ACID'
}

export type OutputSpecification = {
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Packaging>;
  /**
   *  # The price of the specific output. Used for economic allocation. In euro.
   * # Default values are available for pigs and cattle, it is a required field for the other animal types.
   */
  price?: InputMaybe<Scalars['Float']>;
  /**  # The weight of the specific output. In kilogram. */
  weight: Scalars['Float'];
};

export type Packaging = {
  primaryPackaging?: InputMaybe<Array<PrimaryPackaging>>;
  secondaryPackaging?: InputMaybe<Array<SecondaryPackaging>>;
  tertiaryPackaging?: InputMaybe<Array<TertiaryPackaging>>;
};

/**   ANIMAL PROCESSING MODELS */
export type PrimaryPackaging = {
  /**  # The amount of primary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: PrimaryPackagingType;
};

/**   ANIMAL PROCESSING ENUMS */
export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  Hdpe = 'HDPE',
  Ldpe = 'LDPE',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE'
}

/**  # The impacts and emissions associated with a product */
export type ProductResultNode = {
  __typename?: 'ProductResultNode';
  /**  # Summation of impacts occurring in supply chain (i.e. LCA results) */
  aggregatedImpactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  /**  # Impacts occurring in this node */
  impactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  productName?: Maybe<Scalars['String']>;
  /**  # A list of tags that can be used for grouping of the results */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /**  # Product nodes feeding into this node */
  upstreamProductNodes?: Maybe<Array<ProductResultNode>>;
};

/**   PRODUCTION SYSTEM INPUT */
export type ProductionSystem = {
  /**  # When the animals in the modelled production system will be processed too, the animal processing can be defined. */
  animalProcessing?: InputMaybe<AnimalProcessing>;
  /**  # A list of farms in the modelled production system. A farm is in a single location. */
  farms: Array<InputMaybe<Farm>>;
};

export type ProductionSystemInput = {
  /**  # A list of compound feeds that are used in the production system */
  compoundFeeds?: InputMaybe<Array<CompoundFeed>>;
  /**  # The foundational database that must be used for the impact calculations */
  databaseFoundation: DatabaseFoundation;
  /**  # Precalculated impacts to be used in LCA calculation */
  importedImpacts?: InputMaybe<Array<InputMaybe<ImportedImpact>>>;
  /**  # The pigs production system */
  productionSystem: ProductionSystem;
  /**  # Split ecotoxicity in EF3.1. Default is False. */
  splitEcotoxicity?: InputMaybe<Scalars['Boolean']>;
};

export type ProductionSystemResult = {
  __typename?: 'ProductionSystemResult';
  /**  # Emissions occurring in this production system */
  emissionResults?: Maybe<Array<StageEmissionResult>>;
  /**  # A list of climate impact assessment results for all stages with an external output */
  impactResults?: Maybe<Array<ProductResultNode>>;
  /**  # None code terminating warnings */
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

/**   QUERY AND SCHEMA */
export type Query = {
  __typename?: 'Query';
  getLciResultByProductionSystemInput?: Maybe<ForegroundTreeResult>;
  /**  # A list of climate impact assessment results for all stages with an external output */
  getResultByProductionSystemInput?: Maybe<ProductionSystemResult>;
  getValidationErrorCodes?: Maybe<Array<Maybe<ValidationErrorCode>>>;
};


/**   QUERY AND SCHEMA */
export type QueryGetLciResultByProductionSystemInputArgs = {
  productionSystemInput?: InputMaybe<ProductionSystemInput>;
};


/**   QUERY AND SCHEMA */
export type QueryGetResultByProductionSystemInputArgs = {
  productionSystemInput?: InputMaybe<ProductionSystemInput>;
};

export type Refrigerant = {
  /**  # The used amount of the refrigerant. In kilogram per year. */
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export enum RefrigerantType {
  Ammonia = 'AMMONIA',
  CarbonDioxide = 'CARBON_DIOXIDE',
  Propane = 'PROPANE',
  RefrigerantHfc = 'REFRIGERANT_HFC'
}

export type SecondaryPackaging = {
  /**  # The amount of secondary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: SecondaryPackagingType;
};

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX'
}

export type SingleIngredientUse = {
  /**  # Weight percentage ash content as a fraction of the feed weight. */
  ashContent: Scalars['Float'];
  /**  # Weight percentage crude protein content as a fraction of the feed weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # Digestible energy content, in MJ/kg. */
  digestibleEnergyContent: Scalars['Float'];
  /**  # Weight percentage dry matter content as a fraction of the feed weight. */
  dryMatterContent: Scalars['Float'];
  /**  # Gross energy content, in MJ/kg. */
  grossEnergyContent: Scalars['Float'];
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  /**  # ID to find single ingredient in compound feed API. */
  ingredient: Ingredient;
  /**  # Weight percentage phosphorus content as a fraction of the feed weight. */
  phosphorusContent: Scalars['Float'];
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram. */
  quantity: Scalars['Float'];
  /**  # A list of transports used to move the feed to the farm. */
  transport: Array<InputMaybe<Transport>>;
  /**  # Urinary energy content, in MJ/kg. Default: 0.26568 */
  urinaryEnergyContent?: InputMaybe<Scalars['Float']>;
};

export type StageEmissionResult = {
  __typename?: 'StageEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsEmissionResults?: Maybe<Array<MmsEmissionResult>>;
  stageId?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
};

export type StageInput = {
  /**  # A list of the pigs coming into this stage. For each group of animals coming from a certain origin (a stage or background) specify an AnimalsIncomingInput. Piglets are not an input but are born in a breeding stage. */
  animalsIncoming: Array<AnimalsIncoming>;
  /**  # In days, required in fattening stages */
  averageAgePigs?: InputMaybe<Scalars['Float']>;
  /**  # In kilogram */
  averageWeightPigs: Scalars['Float'];
  /**  # Required when origin stage is a BreedingStage */
  name?: InputMaybe<AnimalType>;
};

export enum SurfaceType {
  /**  The deep bedding surface can only be combined with a solid manure form and a deep bedding manure management system. */
  DeepBedding = 'DEEP_BEDDING',
  /**  The slatted floor surface can only be combined with a liquid/slurry or mixed manure form. */
  SlattedFloor = 'SLATTED_FLOOR',
  /**  The solid floor surface can only be combined with a liquid/slurry or mixed manure form. */
  SolidFloor = 'SOLID_FLOOR'
}

export type Tag = {
  __typename?: 'Tag';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/**   COMPOUND FEED MODELS */
export type TagInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TertiaryPackaging = {
  /**  # The amount of tertiary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: TertiaryPackagingType;
};

export enum TertiaryPackagingType {
  PlasticFilm = 'PLASTIC_FILM',
  WoodPallet = 'WOOD_PALLET'
}

export type Transport = {
  /**  # In kilometer. */
  distance: Scalars['Float'];
  mode: TransportMode;
};

export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK'
}

export type ValidationErrorCode = {
  __typename?: 'ValidationErrorCode';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Warning = {
  __typename?: 'Warning';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/**   APS MODELS */
export type Water = {
  /**  # The amount water. In cubic meter per year. */
  amount: Scalars['Float'];
  type: WaterType;
};

export enum WaterType {
  /**  The water use, in cubic meter. */
  Water = 'WATER'
}
