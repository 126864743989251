import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { DsmIcon, DsmLoadingIndicator } from '@dsm-dcs/design-system-react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  StepLabelProps,
  StepProps,
  Stepper,
  Tabs,
  Typography,
} from '@material-ui/core';
import { API } from 'aws-amplify';
import { GraphQLError } from 'graphql';
import { isEmpty } from 'lodash';
import * as math from 'mathjs';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import {
  compoundFeedsStyles,
  footprintTabsStyles,
} from '../../../../_metronic/layout';
import {
  createCompoundFeed,
  createCompoundFeedV2,
  updateCompoundFeed,
  updateCompoundFeedV2,
} from '../../../../graphql/mutations';
import {
  getCompoundFeedData,
  getCompoundFeedInputAndData,
  getCompoundFeedInputComponentData,
  getCompoundFeedV2,
  listIngredientsAndOriginsV2,
} from '../../../../graphql/queries';
import {
  CompoundFeed,
  CompoundFeedAddonsV2,
  CompoundFeedDatabase,
  CompoundFeedIngredientName,
  CompoundFeedInputComponentsData,
  CompoundFeedV2,
  CreateCompoundFeedInput,
  CreateCompoundFeedInputV2,
  IngredientUsesInput,
  InputMaybe,
  Maybe,
  MutationUpdateCompoundFeedArgs,
  Origin,
  TypeValueFloatInput,
  UpdateCompoundFeedInputV2,
} from '../../../../graphql/types';
import Can from '../../../modules/Auth/Can';
import { ButtonControlGroupCompoundFeed } from '../../../modules/Helpers/ButtonControlGroup';
import {
  convertDistanceUnits,
  convertOnSiteActivityUnits,
} from '../../../modules/Helpers/UnitConverter';
import { UserProfilePrefs } from '../../../modules/Helpers/UserProfilePrefs';
import getFeedDraftByKey from '../../../state/drafts/additional';
import {
  clearDraftAction,
  setFeedDraftAction,
} from '../../../state/drafts/feedDraftsSlice';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import { ApiCallErrorMessage } from '../../commonTypes/apiErrors';
import { getStorage, setStorage } from '../../helpers/draft-storage';
import { CFDatabaseFoundation } from '../../models/CompoundFeed';
import {
  getDatabase,
  getUIDatabaseFoundation,
} from '../../utils/compound-feed-utils';
import {
  defaultUnits,
  explicitConvertValue,
  unitIngredientPercentage,
} from '../../utils/unit-utils';
import assembleFootprintURL from '../../utils/url-utils';
import { DsmButtonV2, useButtonStyles } from '../helpers/DsmButton';
import CompoundFeedBaseData from './CompounFeedBaseData';
import InboundTransport from './InboundTransport';
import FeedIngredients from './Ingredients';
import Nutrition from './Nutrition';
import OnSiteActivities from './OnSiteActivities';
import OutboundTransport from './OutboundTransport';
import { convertDistanceUnitsV2 } from './mapper_v2/UnitsConverterV2';
import { getOriginOrProxyV2 } from './apiHelpers';
import getIngredientMappingV1toV2 from './ingredientMappings';
import lookupValues from '../../helpers/lookupValues';
import {
  CommonCFEntry,
  CompoundFeedFormExtended,
  ErrorResponseFeeds,
  FeedFormFields,
  FeedMillLocation,
  FormType,
  InboundTransportKey,
  IngredientNamesAndOriginsV2,
  IngredientUses,
  IngredientsUsesExtended,
  OnSiteActivitiesKey,
  OutboundTransportKey,
  SelectedCustomerAlias,
  TargetSpeciesEntry,
  TransportType,
} from './types';
import {
  ADDON_INGREDIENT_SEPARATOR,
  BASESUM,
  CUSTOM_INGREDIENT_ID_SEPARATOR,
  StepStatus,
  StyledTab,
  WizardStepsLanguageKeys,
  emptyFormDefaults,
  getAddonGroup,
  getFeedVersion,
  isFeedV2CFDatabaseFoundation,
  joinCustomerIngredeintsAddonsWithDefault,
  mapOnSiteActivitiesInput,
  masterDataV2,
  prepareIngredientsV2,
  remapInputToArrayOfTypeValueObj,
  totalSteps,
  useStyles,
} from './utils';
import compoundFeedValidationSchema from './validationSchema';
import validateIngredientOrigins, {
  ErrorType,
  IngredientValidationCorretion,
} from './ingredientsValidation';
import mapCompoundFeedDataV2Out from './mapper_v2/outCompoundFeedMapperV2';
import mapCompoundFeedDataV2In from './mapper_v2/inCompoundFeedMapperV2';

interface CompoundFeedWizardProps {
  location: {
    search: {
      match: (regExp: RegExp) => string[] | null;
    };
    state: {
      feedID?: string;
      feedName?: string;
      formValueFeedName?: string;
      newDuplicateFeed?: boolean;
      copyV1ToV2Db?: CompoundFeedDatabase;
    };
  };
  match: {
    params: {
      customerID: string;
      feedID?: string;
      formType: FormType;
    };
  };
  subHeader: {
    setBreadcrumbs: (breadcrumbs: object) => void;
  };
}

const CompoundFeedWizard: FC<CompoundFeedWizardProps> = ({
  location,
  match,
  subHeader,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const classesTabs = footprintTabsStyles();
  const classesFeed = compoundFeedsStyles();
  const [copyV1ToV2Db, setCopyV1ToV2Db] = useState('');
  const [copyFeed, setCopyFeed] = useState(false);
  const [ingredientsCache, setIngredientsCache] = useState({
    [CFDatabaseFoundation.AFP_5]: {
      ingredientOriginCombinations: [],
      ingredientOriginCombinationsCategories: [],
      ingredientOriginsNamesV2: {
        origins: [],
        ingredientNames: [],
        addons: [],
      },
    },
    [CFDatabaseFoundation.AFP_6_3]: {
      ingredientOriginCombinations: [],
      ingredientOriginCombinationsCategories: [],
      ingredientOriginsNamesV2: {
        origins: [],
        ingredientNames: [],
        addons: [],
      },
    },
    [CFDatabaseFoundation.GFLI]: {
      ingredientOriginCombinations: [],
      ingredientOriginCombinationsCategories: [],
      ingredientOriginsNamesV2: {
        origins: [],
        ingredientNames: [],
        addons: [],
      },
    },
  } as any);

  const updateIngredientsCache = (
    databaseFoundation: CFDatabaseFoundation | undefined
  ) => {
    if (databaseFoundation) {
      const updatedCache = {
        ...ingredientsCache,
        [databaseFoundation]: {
          ingredientOriginCombinationsCategories: [
            ...ingredientOriginCombinationsCategories.current,
          ],
          ingredientOriginCombinations: [
            ...ingredientOriginCombinations.current,
          ],
          ingredientOriginsNamesV2: { ...ingredientOriginsNamesV2.current },
        },
      };
      setIngredientsCache(updatedCache);
    }
  };

  const areIngredientsForDbEmpty = () => {
    if (databaseFoundation)
      return (
        ingredientsCache[databaseFoundation].ingredientOriginCombinations
          ?.length === 0
      );
    return false;
  };

  const { customerID, feedID } = match.params;
  const {
    formType: requestedFormType = feedID ? FormType.View : FormType.New,
  } = match.params;
  const [formType, setFormType] = useState<FormType>(requestedFormType);
  const [openDraftDialog, setOpenDraftDialog] = useState<boolean>(false);
  const [msg, setMsg] = useState<string | null | JSX.Element[]>(null);
  const [changedPrefs, setChangedPrefs] = useState<boolean>(false);
  const [compoundFeed, setCompoundFeed] = useState<CompoundFeedFormExtended>();
  const [compoundFeedInputData, setCompoundFeedInputData] =
    useState<CompoundFeedInputComponentsData>();
  const [loadingData, setLoadingData] = useState(false);
  const inputComponentsLoadingFinsihed = useRef(false);
  const dispatch = useDispatch();
  const [databaseFoundation, setDatabaseFoundation] =
    useState<CFDatabaseFoundation>();
  const databaseFoundationCache = useMemo(() => (databaseFoundation || ''),
    [databaseFoundation]
  );
  const selectedCustomer = useSelector(
    (state: SelectedCustomerAlias) => state.selectedCustomer?.value
  );
  const userProfile = UserProfilePrefs.getInstance();
  const userUOM = userProfile.getUserUnitPrefs();
  const INPUTUNIT = userUOM?.unitIngredientQuantityInputMass
    ? userUOM?.unitIngredientQuantityInputMass
    : defaultUnits?.unitIngredientQuantityInputMass;
  const OUTPUTUNIT = userUOM?.unitCompoundFeedOutputMass
    ? userUOM?.unitCompoundFeedOutputMass
    : defaultUnits?.unitCompoundFeedOutputMass;
  const maxSumValue = math.unit(1, OUTPUTUNIT).toNumber(INPUTUNIT);
  const currCustomer = useRef<string>();
  const { ctaClickEvent, formErrorsEvent, formSubmissionEvent, myFeedsEvent } =
    useAdobeDataLayer();

  const [showProxyDialog, setShowProxyDialog] = useState(true);

  const ingredientOriginCombinations = useRef([] as IngredientUses[]);
  const ingredientOriginCombinationsCategories = useRef([] as IngredientUses[]);
  const [draftPrompted, setDraftPrompted] = useState<boolean>(false);
  const [restoreDraftErrorMessage, setRestoreDraftErrorMessage] = useState('');

  const methods = useForm<FeedFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      compoundFeedValidationSchema({ intl, maxSumValue, unit: INPUTUNIT })
    ),
    defaultValues: emptyFormDefaults,
  });

  const selectedSpecies = methods.getValues('target_species') as string[];
  const targetSpecies = selectedSpecies ? selectedSpecies[0] : '';

  const feedName = location?.state?.feedName || '';

  const { formState } = methods;

  const reset = (
    arg0: Parameters<typeof methods.reset>[0],
    arg1?: Parameters<typeof methods.reset>[1]
  ): void => methods.reset(arg0, arg1);

  const getValues = (): ReturnType<typeof methods.getValues> =>
    methods.getValues();
  const setValue = (
    arg0: Parameters<typeof methods.setValue>[0],
    arg1: Parameters<typeof methods.setValue>[1]
  ): ReturnType<typeof methods.setValue> => methods.setValue(arg0, arg1);
  const trigger = (
    arg0: Parameters<typeof methods.trigger>[0]
  ): Promise<boolean> => methods.trigger(arg0);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepStatues, setStepStatues] = useState<Map<number, StepStatus>>(
    new Map()
  );
  const [openDatabaseWarning, setOpenDatabaseWarning] =
    useState<CFDatabaseFoundation | null>(null);

  const completedSteps = () =>
    [...stepStatues.values()]?.filter(
      (val) => val === StepStatus.Completed || val === StepStatus.Valid
    ).length;
  const isLastStep = () => activeStep === totalSteps(targetSpecies) - 1;
  const lastStepIndex = totalSteps(targetSpecies) - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps(targetSpecies);

  const ingredientOriginsNamesV2 = useRef({
    origins: [],
    ingredientNames: [],
    addons: [],
  } as IngredientNamesAndOriginsV2);
  const [compoundFeedV2, setCompoundFeedV2] = useState<CompoundFeedV2>();

  const ingredientUsesFields = useFieldArray({
    control: methods.control,
    name: 'ingredient_uses', // unique name for your Field Array
    keyName: 'key',
  });

  // Get field names for tab at index
  const formFieldsStepMapping = (tabIndex: number): string[] => {
    switch (tabIndex) {
      case 0:
        return ['name', 'feedmill_location', 'target_species', 'year'];
      case 1: {
        const validateFieldNames = [
          'ingredient_uses.0.name',
          'ingredient_uses.0.origin',
          'ingredient_uses.0.quantity',
        ];
        const ingredientUseList = getValues().ingredient_uses || [];
        if (ingredientUseList?.length > 1) {
          for (let i = 1; i < ingredientUseList.length; i += 1) {
            validateFieldNames.push(`ingredient_uses.${i}.name`);
            validateFieldNames.push(`ingredient_uses.${i}.origin`);
            validateFieldNames.push(`ingredient_uses.${i}.quantity`);
          }
        }
        return validateFieldNames;
      }
      case 2: {
        const validateFieldNames = [
          'outbound_transport_input.truck',
          'outbound_transport_input.inland_ship',
          'ingredient_uses.0.inbound_transport_input.truck',
          'ingredient_uses.0.inbound_transport_input.train',
          'ingredient_uses.0.inbound_transport_input.sea_ship',
          'ingredient_uses.0.inbound_transport_input.inland_ship',
        ];
        const ingredientUseList = getValues().ingredient_uses || [];
        if (ingredientUseList.length > 1) {
          for (let i = 1; i < ingredientUseList.length; i += 1) {
            validateFieldNames.push(
              `ingredient_uses.${i}.inbound_transport_input.truck`
            );
            validateFieldNames.push(
              `ingredient_uses.${i}.inbound_transport_input.train`
            );
            validateFieldNames.push(
              `ingredient_uses.${i}.inbound_transport_input.inland_ship`
            );
            validateFieldNames.push(
              `ingredient_uses.${i}.inbound_transport_input.sea_ship`
            );
          }
        }
        return validateFieldNames;
      }
      case 3:
        return [
          'on_site_activities_input.electricity_use',
          'on_site_activities_input.gas_use',
          'on_site_activities_input.heat_use',
          'on_site_activities_input.water_use',
        ];
      case 4:
        return ['nutritional_analysis_data.crudeProteinContent'];
      default:
        return [];
    }
  };

  const handleStepChange = (step: number, previousStep: number) => async () => {
    if (step === previousStep) {
      return;
    }
    const fieldsToValidate = formFieldsStepMapping(previousStep);
    const stepValid = await trigger(fieldsToValidate);
    // Changed to indicate any step that does not have validation errors to complete
    const newStatusOfStep = stepValid
      ? StepStatus.Completed
      : StepStatus.Failed;
    const newStepStatueses = stepStatues;
    newStepStatueses.set(previousStep, newStatusOfStep);
    if (newStepStatueses.get(previousStep) !== stepStatues.get(previousStep)) {
      setStepStatues(newStepStatueses);
    }

    history.push({
      search: `?step=${step}&database=${databaseFoundation || CFDatabaseFoundation.AFP_5
        }`,
      state: {
        // TODO: will be resloved when redux state is converted to TS
        customerName: selectedCustomer?.name,
      },
    });
  };

  const handleNext = async () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step containing errors
        WizardStepsLanguageKeys.findIndex(
          (_step, i) => stepStatues.get(i) === StepStatus.Failed
        )
        : activeStep + 1;
    await handleStepChange(newActiveStep, activeStep)();
  };

  const handlePrevious = async () => {
    const newActiveStep = activeStep > 0 ? activeStep - 1 : 0;
    await handleStepChange(newActiveStep, activeStep)();
  };

  const getDatabaseFromQuery = (): CFDatabaseFoundation | undefined => {
    const urlParams = location?.search.match(/.*/);
    if (urlParams && urlParams.length > 0) {
      const searchParams = new URLSearchParams(urlParams[0]);
      if (searchParams.has('database')) {
        // For some reason wizard steps aren't being mounted correctly into the URL params 
        // to avoid errors getting database information from it we check the value with
        // a regex to remove the step from DB value
        const db = searchParams.get('database')?.replace(/\?step=[0-5]/, '');
        return db as CFDatabaseFoundation;
      }
    }
    return undefined;
  };
  const [mappedV1toV2, setMappedV1ToV2] = useState(false);

  // 1. On copy V1 to V2 feed trigger this
  useEffect(() => {
    if (
      location.state?.copyV1ToV2Db &&
      compoundFeed &&
      compoundFeedInputData &&
      ingredientOriginsNamesV2 &&
      !mappedV1toV2
    ) {
      setCopyV1ToV2Db(location.state?.copyV1ToV2Db);
      (async () => {
        setLoadingData(true);

        const cfCopy = JSON.parse(
          JSON.stringify(compoundFeed)
        ) as CompoundFeedFormExtended;
        cfCopy.name += ` - COPY ${location.state.copyV1ToV2Db!}`;
        cfCopy.databaseFoundation = getUIDatabaseFoundation(
          location.state.copyV1ToV2Db
        );
        cfCopy.target_species = [];

        if (!cfCopy.ingredient_uses) {
          return;
        }

        // Use standard for loop instead forEach becasuse of async/await concerns
        for (let i = 0; i < cfCopy.ingredient_uses.length; i += 1) {
          const ing = cfCopy.ingredient_uses[i];

          // 1. Get ing name/origin list
          let name = ing.requested_ingredient.ingredient_name?.name || '';
          let mappedName: string | undefined;

          // First find mapping from static list provided
          mappedName = getIngredientMappingV1toV2(name)?.newName;

          // Check if it's custom ingredient
          if (name.startsWith('Other ')) {
            name = name.substring(6);
            mappedName =
              ingredientOriginsNamesV2.current?.ingredientNames.find(
                (ingr) => ingr.group === name
              )?.group || undefined;
          }
          // Validate that ingredint name exists in the ingredients
          let existInIngredients = ingredientOriginCombinations.current.some(
            (ing2) => ing2.id === mappedName
          );
          let addonGroup;
          // Sometimes we have same ingredient name as addon name, single ingredient will always have priority
          if (!existInIngredients) {
            addonGroup = getAddonGroup(
              mappedName || '',
              databaseFoundation,
              ingredientOriginsNamesV2.current
            );
          }

          let requestedOrigin = ing.origin;
          // For unknown origin set to global
          if (ing.origin === 'NaN') {
            requestedOrigin = 'GLO';
          }

          // If standard ingredient check proxy origin and map to proxy data
          if (!ing.requested_ingredient.custom_name && !addonGroup) {
            try {
              // eslint-disable-next-line no-await-in-loop, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              const res = await getOriginOrProxyV2(
                mappedName || 'Other',
                requestedOrigin || 'GLO',
                selectedCustomer?.id || '',
                databaseFoundation
              );
              if (res) {
                if (res.isProxy) {
                  ing.proxyOrigin = true;
                  ing.proxyOriginCode = res.selectedIngredient?.origin;
                  ing.origin = res.requestedIngredient?.origin;
                } else {
                  ing.origin = requestedOrigin;
                }
                // Always set selected ingredient as resulting/ if not exist set Other as generic type
                ing.id = res?.selectedIngredient?.name || 'Other';
                ing.name = res?.selectedIngredient?.name || 'Other';
              } else {
                ing.id = 'Other';
                ing.name = 'Other';
              }
            } catch (e) {
              console.log('error reading proxy: ', e);
              ing.id = 'Other';
              ing.name = 'Other';
            }
            // Otherwise check if addon or custom ingredient and set
          } else {
            if (addonGroup && ingredientOriginsNamesV2.current) {
              ing.addonType = addonGroup.addonType;
              existInIngredients = ingredientOriginsNamesV2.current.addons
                .flatMap((add) => add.ingredients)
                .some((add) => add.name === mappedName);

              mappedName += `${ADDON_INGREDIENT_SEPARATOR}${addonGroup.addonType}`;
            } else if (ing.requested_ingredient.custom_name) {
              mappedName += `${CUSTOM_INGREDIENT_ID_SEPARATOR}${ing.requested_ingredient.custom_name}`;
            }

            // If does not exist leave it blank
            ing.id = existInIngredients ? mappedName : undefined;
            ing.name = existInIngredients ? mappedName : undefined;
          }

          // If not found ingredient remove possible custom ingredient indicator
          if (!ing.id && !ing.name) {
            ing.customIngredient = false;
          }

          // If there is no origin remove possible proxy origin indicator
          if (!ing.origin) {
            ing.proxyOrigin = false;
          }

          // If origin does not exist on colletion of origins for V2 clear all origin values
          if (
            requestedOrigin &&
            ingredientOriginsNamesV2.current?.origins.findIndex(
              (or) => or.isoCode === requestedOrigin
            ) === -1
          ) {
            ing.origin = undefined;
            ing.proxyOriginCode = undefined;
            ing.proxyOrigin = false;
          }

          // If no origin from original is available for the addon, set the one that is
          if (addonGroup) {
            const addonId = mappedName?.split(ADDON_INGREDIENT_SEPARATOR).at(0);
            ing.origin = addonGroup.ingredients.find(
              (addon) => addon.name === addonId
            )?.origin;
            ing.proxyOriginCode = undefined;
            ing.proxyOrigin = false;
          }
        }

        reset(cfCopy as FeedFormFields);
        setMappedV1ToV2(true);
        setCompoundFeed(cfCopy);

        // Trigger to enforce validation errors if blank ingredients/origins exist
        await trigger(['target_species', `ingredient_uses`]);
      })()
        .then(() => {
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.state?.copyV1ToV2Db,
    compoundFeed,
    compoundFeedInputData,
    ingredientOriginsNamesV2,
    mappedV1toV2,
  ]);

  // 2. When step is changed the query param will be changed first then change the active step/tab
  useEffect(() => {
    if (location?.search) {
      const queryStep = location?.search.match(/step=([^&]*)/);
      if (queryStep !== null && queryStep.length >= 2 && !msg) {
        const stepNum = parseInt(queryStep[1], 10);
        if (stepNum !== activeStep) {
          setActiveStep(stepNum);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search]);

  const createDraftKey = () => {
    let key = null;
    if (feedID && (formType === 'edit' || formType === 'view')) {
      key = `CF_${feedID}`;
    } else {
      key = 'CF_new';
    }
    return key;
  };

  const draftKey = createDraftKey();
  const discardDraftFlag = useRef(false);
  const savedFlag = useRef(false);

  const sortIngredientOriginCombinations = (combinations: IngredientUses[]) =>
    combinations.sort((a, b) =>
      (a.name || '')?.toLowerCase() > (b.name || '')?.toLowerCase() ? 1 : -1
    );

  const updateIngredientOriginCombinations = (
    ingredientCategoryId: string,
    customName: string
  ): void => {
    // check if custom ingredient allready added
    let customIngredient = ingredientOriginCombinations.current?.find(
      (item) =>
        item.id ===
        `${ingredientCategoryId}${CUSTOM_INGREDIENT_ID_SEPARATOR}${customName}`
    );

    // doesn't exist, add new
    if (!customIngredient) {
      const category = ingredientOriginCombinationsCategories.current?.find(
        (item) => item.id === ingredientCategoryId
      );
      // create new
      customIngredient = {
        id: `${ingredientCategoryId}${CUSTOM_INGREDIENT_ID_SEPARATOR}${customName}`,
        name: customName,
        customIngredient: true,
        exclusive_origins: category?.exclusive_origins,
      } as IngredientUses;
      // remove last one = Add new ingredient option
      const addNewOption = ingredientOriginCombinations.current.pop();
      // add new and sort list
      ingredientOriginCombinations.current.push(customIngredient);
      sortIngredientOriginCombinations(ingredientOriginCombinations.current);
      // return the last one
      if (addNewOption) ingredientOriginCombinations.current.push(addNewOption);
      updateIngredientsCache(databaseFoundation);
    }
  };

  const postLoadHandler = (loadedCompoundFeedIn: CompoundFeedFormExtended) => {
    const loadedCompoundFeed: CompoundFeedFormExtended = {
      ...loadedCompoundFeedIn,
    };
    // post load handler
    // this will set all same-named fields, but not dynamically added or
    // fields that match by type / the useEffect is fixing this
    // also ingedient_uses lacks name so it needs to be set properly
    // outbound transport needs to be matched by type
    if (loadedCompoundFeed?.outbound_transport) {
      loadedCompoundFeed.outbound_transport_input = {};

      loadedCompoundFeed.outbound_transport.forEach(
        (outboundTransport: CommonCFEntry) => {
          const distance =
            outboundTransport.value || outboundTransport.distance;
          // TODO: remove typecast on next line and 240 after convertDistanceUnits is in TS
          const { value } = convertDistanceUnits(
            distance,
            outboundTransport.type,
            userUOM,
            outboundTransport.unit
          ) as { value: number; unit: string };
          const key = outboundTransport.type
            .toString()
            .replace(/ /g, '_') as OutboundTransportKey;
          if (loadedCompoundFeed?.outbound_transport_input) {
            loadedCompoundFeed.outbound_transport_input[key] = value;
          }
        }
      );
    }
    delete loadedCompoundFeed.outbound_transport;

    // on site activities also matched by type
    if (loadedCompoundFeed?.on_site_activities) {
      loadedCompoundFeed.on_site_activities_input = {
        selfGeneratedRenewables: [],
        energyTypes: [],
      };

      loadedCompoundFeed.on_site_activities?.forEach(
        (onSiteActivity: CommonCFEntry) => {
          const { value } = convertOnSiteActivityUnits(
            onSiteActivity?.value,
            onSiteActivity.type,
            userUOM
          ) as { value: number; unit: string };

          if (value || value === 0) {
            if (
              lookupValues.selfGeneratedRenewablesList
                .map((el) => el.fieldName)
                .includes(`${onSiteActivity.type}Use`)
            ) {
              loadedCompoundFeed?.on_site_activities_input?.selfGeneratedRenewables.push(
                {
                  type: `${onSiteActivity.type}_use`,
                  value,
                }
              );
            } else if (
              lookupValues.energyTypesList
                .map((el) => el.fieldName)
                .includes(`${onSiteActivity.type}Use`)
            ) {
              loadedCompoundFeed?.on_site_activities_input?.energyTypes.push({
                type: `${onSiteActivity.type}_use`,
                value,
              });
            } else if (loadedCompoundFeed?.on_site_activities_input) {
              const key = `${onSiteActivity.type
                ?.toString()
                .replace(/ /g, '_')}_use` as OnSiteActivitiesKey;
              loadedCompoundFeed.on_site_activities_input[key] = value;
            }
          }
        }
      );
    }
    delete loadedCompoundFeed.on_site_activities;

    // set ingredient name by matching ingredient source by id - name is not present in the loaded data
    loadedCompoundFeed.ingredient_uses = (
      loadedCompoundFeed.ingredient_uses || []
    ).map((ingredientIn: IngredientsUsesExtended) => {
      const ingredient: IngredientsUsesExtended = { ...ingredientIn };
      ingredient.name = ingredient?.requested_ingredient?.ingredient_name?.id;
      ingredient.origin = ingredient?.requested_ingredient?.origin;
      ingredient.proxyOrigin = false;
      // check is proxy, values of requested_ingredient and ingredient should be different
      if (ingredient?.requested_ingredient && ingredient?.ingredient) {
        ingredient.proxyOrigin =
          ingredient?.requested_ingredient?.ingredient_name?.id !==
          ingredient?.ingredient?.ingredient_name?.id ||
          ingredient?.requested_ingredient?.origin !==
          ingredient?.ingredient?.origin;
        // if (loadedCompoundFeed.databaseFoundation === CFDatabaseFoundation.GFLI)
        if (isFeedV2CFDatabaseFoundation(loadedCompoundFeed.databaseFoundation))
          ingredient.proxyOriginCode = ingredient?.requested_ingredient?.origin;
      }

      // check is custom feed name
      if (ingredient?.requested_ingredient?.custom_name) {
        // update name - ID of custom ingredient
        ingredient.name += `${CUSTOM_INGREDIENT_ID_SEPARATOR}${ingredient?.requested_ingredient?.custom_name}`;
        ingredient.customIngredient = true;
        ingredient.proxyOrigin =
          ingredient?.requested_ingredient?.origin !==
          ingredient?.ingredient?.origin;
        // update list of available ingdedients with the custom one
        updateIngredientOriginCombinations(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
          ingredient.requested_ingredient?.ingredient_name?.id!,
          ingredient?.requested_ingredient?.custom_name
        );
      }

      if (ingredient.inbound_transport) {
        ingredient.inbound_transport_input = {
          truck:
            ingredientIn?.inbound_transport?.find(
              ({ type }) => type === TransportType.Truck
            )?.value ?? 0,
          sea_ship:
            ingredientIn?.inbound_transport?.find(
              ({ type }) => type === TransportType.SeaShip
            )?.value ?? 0,
          train:
            ingredientIn?.inbound_transport?.find(
              ({ type }) => type === TransportType.Train
            )?.value ?? 0,
          inland_ship:
            ingredientIn?.inbound_transport?.find(
              ({ type }) => type === TransportType.InlandShip
            )?.value ?? 0,
        };
      }

      return ingredient;
    });
    loadedCompoundFeed.quantitySum = 0;
    // If there isn't a databaseFoundation on the loaded compound feed we get it from the URL and set it
    if (!loadedCompoundFeed?.databaseFoundation) {
      loadedCompoundFeed.databaseFoundation = databaseFoundationCache || getDatabaseFromQuery()
    }
    reset(loadedCompoundFeed as FeedFormFields);
    setCompoundFeed(loadedCompoundFeed);
  };

  const loadCompoundFeedV1 = async (customerId: string, feedId?: string) => {
    // now only used when reseting after draft removal, since InputData is already loaded
    (
      API.graphql({
        query: getCompoundFeedData,
        variables: {
          customerId,
          feedId,
        },
      }) as Promise<{ data: { getCompoundFeedData: CompoundFeed } }>
    )
      .then((response) => {
        setLoadingData(false);
        if (
          formType === FormType.Edit &&
          response.data.getCompoundFeedData.is_reference === true
        )
          // to prevent editing of reference if someone manually type edit in URL
          setFormType(FormType.View);
        const cfForm = response.data
          .getCompoundFeedData as CompoundFeedFormExtended;
        // setDatabaseFoundation(cfForm.databaseFoundation || CFDatabaseFoundation.AFP_5);
        postLoadHandler(cfForm);
      })
      .catch((reason: { errors?: GraphQLError[] }) => {
        setLoadingData(false);
        const error = reason.errors ? reason.errors[0] : { message: 'No data' };
        if (error) {
          setMsg(error.message);
        }
      });
  };

  const loadV2Ingredients = async (
    customerId: string,
    dbf: CFDatabaseFoundation
  ) =>
    (
      API.graphql({
        query: listIngredientsAndOriginsV2,
        variables: {
          customerId: customerID,
          databaseFoundation: getDatabase(dbf),
        },
      }) as Promise<{
        data: {
          getOrigins?: Maybe<Origin[]>;
          getCompoundFeedIngredientNames?: Maybe<CompoundFeedIngredientName[]>;
          getCompoundFeedV2CustomerAddons: Maybe<CompoundFeedAddonsV2[]>;
        };
      }>
    )
      .then((response) => {
        ingredientOriginCombinations.current = prepareIngredientsV2(
          response.data.getCompoundFeedIngredientNames || [],
          joinCustomerIngredeintsAddonsWithDefault(
            response.data.getCompoundFeedV2CustomerAddons
          ) || []
        );
        ingredientOriginCombinationsCategories.current = [
          ...new Set(
            (response.data.getCompoundFeedIngredientNames || [])
              .map((i) => i.group || '')
              .filter((c) => !!c)
          ),
        ]
          .sort((a, b) => (a < b ? -1 : 1))
          .map((c) => ({
            id: c || '',
            name: c || '',
            exclusive_origins: [
              {
                name: 'Global',
                iso_code: {
                  code: 'GLO', // TODO: check this
                },
              },
            ],
          }));
        ingredientOriginsNamesV2.current = {
          origins: response.data.getOrigins || [],
          ingredientNames: response.data.getCompoundFeedIngredientNames || [],
          addons:
            joinCustomerIngredeintsAddonsWithDefault(
              response.data.getCompoundFeedV2CustomerAddons
            ) || [],
        };
        updateIngredientsCache(dbf);
      })
      .catch(() => console.log('error loading ing names and origins v2'));

  const loadV2Feed = async (customerId: string, feedId?: string) => {
    let feedIdVar = feedId;
    if (location?.state?.feedID) {
      feedIdVar = location?.state?.feedID;
    }
    setDatabaseFoundation(CFDatabaseFoundation.GFLI);
    // now only used when reseting after draft removal, since InputData is already loaded
    return (
      API.graphql({
        query: getCompoundFeedV2,
        variables: {
          customerId,
          feedId: feedIdVar,
        },
      }) as Promise<{ data: { getCompoundFeedV2: CompoundFeedV2 } }>
    )
      .then((response) => {
        if (formType === FormType.Edit) {
          // to prevent editing of reference if someone manually type edit in URL
          setFormType(FormType.View);
        }
        const cfForm = mapCompoundFeedDataV2In(
          response.data.getCompoundFeedV2,
          userUOM
        );
        if (!cfForm) {
          console.log('error mapping to form fields');
          return;
        }
        setDatabaseFoundation(cfForm.databaseFoundation);
        cfForm.ingredient_uses?.forEach(
          (ingredient: IngredientsUsesExtended) => {
            if (
              ingredient.customIngredient &&
              ingredient?.requested_ingredient?.custom_name
            ) {
              updateIngredientOriginCombinations(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-unsafe-argument
                ingredient.requested_ingredient?.ingredient_name?.id!,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                ingredient.requested_ingredient.custom_name
              );
            }
          }
        );
        reset(cfForm as FeedFormFields);
        setCompoundFeedV2(response.data.getCompoundFeedV2);
        if (
          location?.state?.formValueFeedName &&
          location?.state?.newDuplicateFeed
        ) {
          setValue('name', location?.state?.formValueFeedName);
          setFormType(FormType.New);
          setCopyFeed(true);
        }
        if (formType === FormType.Edit) {
          // to prevent editing of reference if someone manually type edit in URL
          setFormType(FormType.Edit);
        }
      })
      .catch((reason: { errors?: ApiCallErrorMessage[] }) => {
        // setLoadingData(false);
        const error = reason.errors ? reason.errors[0] : { message: 'No data' };
        if (reason.errors && reason.errors[0]?.errorType === 'Unauthorized') {
          error.message = intl.formatMessage({ id: 'GENERAL.UNAUTHORIZED' });
        }
        if (error) {
          setMsg(error.message);
        }
      });
  };

  // 3. listen for database change
  useEffect(() => {
    if (
      // this should trigger only when form type is new and wheh user can change database type
      areIngredientsForDbEmpty() &&
      customerID &&
      formType === FormType.New &&
      !location.state.copyV1ToV2Db &&
      (isFeedV2CFDatabaseFoundation(databaseFoundation) ||
        isFeedV2CFDatabaseFoundation(compoundFeed?.databaseFoundation))
    ) {
      setLoadingData(true);
      if (formType === FormType.New)
        loadV2Ingredients(customerID, databaseFoundation!)
          .then(() => {
            setLoadingData(false);
          })
          .catch(() => {
            setLoadingData(false);
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseFoundation]);

  // 4. if customer ID changes reload component data
  // (new customer - possible after routing, otherwise called only ones)
  useEffect(() => {
    const prepareIngredientOriginCombinations = (
      combinations?: IngredientUses[] | null
    ) => {
      const retVal = sortIngredientOriginCombinations(
        (combinations || [])?.filter((item) => !item.name?.startsWith('Other'))
      );

      // at the end, option for add new item
      retVal.push({
        ingredient_id: 'Add your own ingredient',
        id: 'Add your own ingredient',
        name: '\u2A01 Add your own ingredient',
      });

      return retVal;
    };

    const prepareIngredientOriginCombinationsCategories = (
      combinations?: IngredientUses[] | null
    ) => {
      // filter only items starting with Othre -> categories
      let categories = (combinations || [])?.filter((item) =>
        item.name?.startsWith('Other')
      );

      // remove Other from name
      categories?.forEach((item) => {
        const substring = 'Other ';
        // eslint-disable-next-line no-param-reassign
        item.name = item.name?.substring(substring.length);
      });

      categories = sortIngredientOriginCombinations(categories);

      // move element with name Other to the end
      const otherIndex = categories.findIndex((item) => item.name === 'Other');

      if (otherIndex > -1) {
        const item = categories[otherIndex];
        categories = [
          ...categories.slice(0, otherIndex),
          ...categories.slice(otherIndex + 1),
        ];
        // add at end
        categories.push(item);
      }

      return categories;
    };

    const db = getDatabaseFromQuery();
    const version = getFeedVersion(db);

    const copyV1ToV2Db = location?.state?.copyV1ToV2Db;
    if (copyV1ToV2Db) {
      setDatabaseFoundation(getUIDatabaseFoundation(copyV1ToV2Db));
    }

    if (currCustomer.current !== customerID) {
      currCustomer.current = customerID;

      if (version !== 2 || copyV1ToV2Db) {
        const loadCFInputComponentAndData = async (): Promise<void> => {
          setLoadingData(true);
          let query = !feedID
            ? getCompoundFeedInputComponentData
            : getCompoundFeedInputAndData;
          let feedId = feedID;
          if (location?.state?.feedID) {
            feedId = location?.state?.feedID;
            query = getCompoundFeedInputAndData;
          }
          let databaseFoundation: CFDatabaseFoundation | undefined = undefined;
          if (copyV1ToV2Db) {
            databaseFoundation = getUIDatabaseFoundation(copyV1ToV2Db);
          } else {
            databaseFoundation = CFDatabaseFoundation.AFP_5;
          }
          setDatabaseFoundation(databaseFoundation);
          (
            API.graphql({
              query,
              variables: {
                customerId: customerID,
                feedId,
              },
            }) as Promise<{
              data: {
                getCompoundFeedInputComponentData?: CompoundFeedInputComponentsData;
                getCompoundFeedData?: CompoundFeedFormExtended;
              };
            }>
          ).then(
            (response) => {
              if (formType === FormType.View || formType === FormType.Edit) {
                myFeedsEvent(
                  response.data.getCompoundFeedData?.name || '',
                  String(response.data.getCompoundFeedData?.year?.value) || '',
                  response.data.getCompoundFeedData?.feedmill_location
                    ?.iso_code || '',
                  (response.data.getCompoundFeedData?.target_species ||
                    []) as string[],
                  0
                );
              }
              inputComponentsLoadingFinsihed.current = true;
              setCompoundFeedInputData(
                response.data.getCompoundFeedInputComponentData
              );

              // If copying V1 to V2 feed V2 ingredients/origins will be used
              if (!copyV1ToV2Db) {
                ingredientOriginCombinations.current =
                  prepareIngredientOriginCombinations(
                    (response.data.getCompoundFeedInputComponentData
                      ?.ingredient_origin_combination as IngredientUses[]) || []
                  );
                ingredientOriginCombinationsCategories.current =
                  prepareIngredientOriginCombinationsCategories(
                    (response.data.getCompoundFeedInputComponentData
                      ?.ingredient_origin_combination as IngredientUses[]) || []
                  );
                ingredientOriginsNamesV2.current = {
                  origins: [],
                  ingredientNames: [],
                  addons: [],
                };
                updateIngredientsCache(databaseFoundation);
              }

              setLoadingData(false);
              if (response?.data?.getCompoundFeedData) {
                if (
                  formType === FormType.Edit &&
                  response.data.getCompoundFeedData.is_reference === true
                ) {
                  // to prevent editing of reference if someone manually type edit in URL
                  setFormType(FormType.View);
                }
                // pass the currently loaded input data to avoid using the empty state as setCompoundFeedInputData may not have finished yet.
                postLoadHandler(response.data.getCompoundFeedData);
              }

              if (
                location?.state?.formValueFeedName &&
                location?.state?.newDuplicateFeed
              ) {
                setValue('name', location?.state?.formValueFeedName);
                setFormType(FormType.New);
                setCopyFeed(true);
              }

              const existingDraft = getFeedDraftByKey(draftKey);
              if (existingDraft && !draftPrompted && !copyV1ToV2Db) {
                setOpenDraftDialog(true);
                setDraftPrompted(true);
              }
            },
            (reason: { errors?: GraphQLError[] }) => {
              inputComponentsLoadingFinsihed.current = true;
              setLoadingData(false);
              const error =
                reason.errors && reason?.errors.length > 0
                  ? reason.errors[0]
                  : { message: 'Error' };
              if (error) {
                setMsg(error.message);
              }
            }
          );
        };

        // If we copy V1 feed to new DB foundation load ingredients/origins etc
        if (copyV1ToV2Db) {
          setLoadingData(true);
          (async () => {
            await loadV2Ingredients(
              customerID,
              getUIDatabaseFoundation(copyV1ToV2Db)
            );
          })()
            .then(() => { })
            .catch(() => { });
        }

        loadCFInputComponentAndData()
          .then(() => { })
          .catch(() => { });
      } else {
        setLoadingData(true);
        (async () => {
          await loadV2Ingredients(customerID, db!);
          if (feedID) await loadV2Feed(customerID, feedID);
          const existingDraft = getFeedDraftByKey(draftKey);
          if (existingDraft && !draftPrompted) {
            setOpenDraftDialog(true);
            setDraftPrompted(true);
          }
        })()
          .then(() => {
            setLoadingData(false);
          })
          .catch(() => {
            setLoadingData(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID, feedID]);

  // 5. refresh breadcrumbs when necessary
  useEffect(() => {
    const breadcrumbs = [];

    breadcrumbs.push({
      pathname: `/customers/${customerID}/myfeeds`,
      title: intl.formatMessage({ id: 'SUSTELL.MY_FEEDS' }),
    });

    breadcrumbs.push({
      // TODO: maybe change this path part 'compoundfeeds' to 'myfeeds' to match
      pathname: `/customers/${customerID}/compoundfeeds/${feedID || FormType.New
        }`,
      title:
        formType === FormType.New
          ? intl.formatMessage({ id: 'COMPOUNDFEEDS.NEW_FEED' })
          : feedName,
    });
    subHeader.setBreadcrumbs(breadcrumbs);
    // dependency asks for subheader, MUST NOT be added, cause it is updated inside this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID, formType, selectedCustomer]);

  const prepareDataForSending = (
    formData: CompoundFeedFormExtended
  ): CreateCompoundFeedInput => {
    const onSiteActivitiesInput = mapOnSiteActivitiesInput(
      formData.on_site_activities_input
    );
    const onSiteActivities = remapInputToArrayOfTypeValueObj(
      'on_site_activities',
      userUOM,
      onSiteActivitiesInput
    ) as InputMaybe<Array<InputMaybe<TypeValueFloatInput>>>;

    const ingredientUses: Array<InputMaybe<IngredientUsesInput>> = [];
    // remap outbound transport
    const outboundTransport = remapInputToArrayOfTypeValueObj(
      'outbound_transport',
      userUOM,
      formData.outbound_transport_input
    ) as InputMaybe<Array<InputMaybe<TypeValueFloatInput>>>;
    (formData.ingredient_uses || []).forEach(
      (ingredient: IngredientsUsesExtended) => {
        const quantity =
          explicitConvertValue(
            ingredient?.quantity,
            defaultUnits.unitIngredientQuantityInputMass,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            userUOM?.unitIngredientQuantityInputMass as string,
            true
          ) ?? 0;
        const inboundTransport = remapInputToArrayOfTypeValueObj(
          'inbound_transport',
          userUOM,
          ingredient?.inbound_transport_input
        );
        // name contains ID of ingredient

        if (ingredient.name) {
          let ingredientId = ingredient.name;
          if (
            ingredientId &&
            ingredientId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) > -1
          )
            ingredientId = ingredientId.substring(
              0,
              ingredientId.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR)
            );

          const customName = ingredient.customIngredient
            ? ingredient.name.substring(
              ingredient.name.indexOf(CUSTOM_INGREDIENT_ID_SEPARATOR) +
              CUSTOM_INGREDIENT_ID_SEPARATOR.length
            )
            : undefined;

          const item: IngredientUsesInput = {
            quantity,
            inbound_transport: inboundTransport,
            id: ingredient?.id,
            requested_ingredient: {
              origin: ingredient.origin!,
              custom_name: customName,
              ingredient_name: {
                id: ingredientId,
              },
            },
          };
          ingredientUses.push(item);
        }
      }
    );

    const retVal: CreateCompoundFeedInput = {
      on_site_activities: onSiteActivities,
      outbound_transport: outboundTransport,
      ingredient_uses: ingredientUses,
      feedmill_location: formData.feedmill_location,
      name: formData.name,
      year: formData.year,
      target_species: formData.target_species,
    };

    return retVal;
  };

  const saveDraft = (key: string) => {
    // saving the data in the format for sending
    const formValues = getValues();
    const toSave = prepareDataForSending(
      formValues as CompoundFeedFormExtended
    );
    if (key) {
      dispatch(
        setFeedDraftAction({
          key,
          formValues: toSave as CompoundFeedFormExtended,
          beforeConversionFormFields: formValues,
        })
      );
      const currPrefs = UserProfilePrefs.getInstance().getUserUnitPrefs();
      // save my current prefs
      if (currPrefs) setStorage(`${key}_PREFS`, currPrefs, 'compound_feed');
    }
  };

  // TODO: same goes for userUOM
  const ING_INPUTUNIT =
    userUOM?.unitIngredientQuantityInputMass as unknown as string;
  const ING_OUTPUTUNIT =
    userUOM?.unitCompoundFeedOutputMass as unknown as string;

  const validateTotalIngredientSum = (quantitySum?: number): boolean => {
    if (!quantitySum) {
      return true;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const convSum = math
      .unit(quantitySum, ING_INPUTUNIT)
      .toNumber(ING_OUTPUTUNIT);
    const roundedConvSum = Number(convSum.toFixed(5));

    if (roundedConvSum !== BASESUM) {
      setMsg(
        intl.formatMessage(
          { id: 'COMPOUNDFEED_WIZARD.INGREDIENTS_SUM_ERROR' },
          { maxVal: BASESUM, unit: ING_OUTPUTUNIT }
        )
      );
      return false;
    }
    return true;
  };

  // Execute V2 API call
  const createUpdateCFV2 = async (
    inputData: CreateCompoundFeedInputV2 | UpdateCompoundFeedInputV2,
    submitData: FeedFormFields,
    warningsMessages: JSX.Element[] | undefined = undefined
  ) => {
    try {
      formSubmissionEvent(
        formType === FormType.New ? 'Create Feed' : 'Edit Feed',
        formType,
        inputData?.targetSpecies[0] as string
      );
      setLoadingData(true);
      const vars: {
        customerId: string;
        input: CreateCompoundFeedInputV2 | UpdateCompoundFeedInputV2;
        feedId?: string;
      } = {
        customerId: customerID,
        input: inputData,
      };
      if (formType === FormType.Edit) {
        vars.input = {
          ...inputData,
          oldName: compoundFeedV2?.name,
        } as UpdateCompoundFeedInputV2;
        vars.feedId = feedID;
      }

      await API.graphql({
        query:
          formType === FormType.Edit
            ? updateCompoundFeedV2
            : createCompoundFeedV2, // updateCompoundFeed : createCompoundFeed
        variables: vars,
      });

      if (warningsMessages && warningsMessages.length > 0) {
        warningsMessages.push(
          <>
            <br />
            {intl.formatMessage({ id: 'COMPOUND_FEED.SAVED' })}
          </>
        );
        setMsg(warningsMessages);
      } else {
        setMsg(intl.formatMessage({ id: 'COMPOUND_FEED.SAVED' }));
      }

      // reset is needed to clear the isDirty and allow page to transition to the customer page after save
      methods.reset(submitData);

      // remove draft on sucesful save
      dispatch(clearDraftAction({ key: draftKey }));
      savedFlag.current = true;
      setLoadingData(false);
    } catch (err) {
      const err1: { errors?: GraphQLError[] } = err;
      if (err1.errors && err1.errors?.length > 0) {
        const error = err1.errors[0];
        if (error) {
          setMsg(error.message);
        }
      }
    }
  };

  const prepareV2SendData = (submitData: FeedFormFields) => {
    const v2SendData = mapCompoundFeedDataV2Out(
      ingredientOriginsNamesV2.current,
      userUOM,
      submitData as CompoundFeedFormExtended,
      getDatabase(databaseFoundation),
      compoundFeed?.name,
      formType === FormType.Edit,
      targetSpecies
    );
    return v2SendData;
  };

  const submitV2Feed = async (submitData: FeedFormFields) => {
    let v2SendData = prepareV2SendData(submitData);
    const validationErrors = await validateIngredientOrigins(
      v2SendData,
      getDatabase(databaseFoundation),
      ingredientOriginsNamesV2.current,
      customerID,
      intl
    );

    // For warnings that could be fixed apply them to the form
    if (validationErrors.messages.some((item) => item.correction)) {
      validationErrors.messages
        .filter((item) => item.correction)
        .forEach(({ correction }) => {
          if (
            !correction ||
            (!correction.rowIndex && correction.rowIndex !== 0)
          ) {
            return;
          }
          const ingUse = v2SendData.ingredientUses?.at(correction.rowIndex);
          if (!ingUse) {
            return;
          }
          setValue(
            `ingredient_uses.${correction.rowIndex}.proxyOrigin`,
            correction.ingredientResponse.isProxy
          );
          setValue(
            `ingredient_uses.${correction.rowIndex}.name`,
            correction.ingredientResponse.selectedIngredient!.name
          );
          setValue(
            `ingredient_uses.${correction.rowIndex}.id`,
            correction.ingredientResponse.selectedIngredient!.name
          );
          if (correction.ingredientResponse.isProxy) {
            setValue(
              `ingredient_uses.${correction.rowIndex}.proxyOriginCode`,
              correction.ingredientResponse.selectedIngredient!.origin
            );
            setValue(
              `ingredient_uses.${correction.rowIndex}.origin`,
              correction.ingredientResponse.requestedIngredient!.origin
            );
          }
        });

      // After corrections create new sendData object
      v2SendData = prepareV2SendData(getValues());
    }

    const warnings = validationErrors.messages
      .filter(
        (mess: IngredientValidationCorretion) => mess.type === ErrorType.WARNING
      )
      .map((err) => (
        <>
          {err.message} <br />
        </>
      ));
    if (warnings.length > 0) {
      warnings.unshift(
        <>
          {intl.formatMessage({ id: 'GENERAL.WARNINGS' })} <br />
        </>
      );
    }

    const errors = validationErrors.messages
      .filter(
        (mess: IngredientValidationCorretion) => mess.type === ErrorType.ERROR
      )
      .map((err) => (
        <>
          {err.message} <br />
        </>
      ));
    if (errors.length > 0) {
      errors.unshift(
        <>
          <br />
          {intl.formatMessage({ id: 'GENERAL.ERRORS' })} <br />
        </>
      );
    }

    const showWarningsErrros = warnings.concat(errors);

    if (validationErrors.messages.length > 0) {
      setMsg(showWarningsErrros);
    }
    if (!validationErrors.hasErrors) {
      try {
        await createUpdateCFV2(v2SendData, submitData, showWarningsErrros);
        dispatch(clearDraftAction({ key: draftKey }));
      } catch (err) {
        console.log('error sending data: ', err);
      }
    }
  };

  const submitV1Feed = async (submitData: FeedFormFields) => {
    const sendData = prepareDataForSending(
      submitData as CompoundFeedFormExtended
    );
    try {
      formSubmissionEvent(
        formType === FormType.New ? 'Create Feed' : 'Edit Feed',
        formType,
        sendData?.target_species[0] as string
      );
      const vars: {
        customerId: string;
        input: CreateCompoundFeedInput | MutationUpdateCompoundFeedArgs;
        feedId?: string;
      } = {
        customerId: customerID,
        input: sendData,
      };
      if (formType === FormType.Edit) {
        vars.feedId = feedID;
      }

      await API.graphql({
        query:
          formType === FormType.Edit ? updateCompoundFeed : createCompoundFeed,
        variables: vars,
      });

      setMsg(intl.formatMessage({ id: 'COMPOUND_FEED.SAVED' }));
      // reset is needed to clear the isDirty and allow page to transition to the customer page after save
      methods.reset(submitData);
      // remove draft on sucesful save
      dispatch(clearDraftAction({ key: draftKey }));
      savedFlag.current = true;
    } catch (err) {
      const err1: { errors?: GraphQLError[] } = err;
      if (err1.errors && err1.errors?.length > 0) {
        const error = err1.errors[0];
        if (error) {
          setMsg(error.message);
        }
      }
    }
  };

  // called after successfull validation
  const onSubmit = (submitData: FeedFormFields): void => {
    if (!validateTotalIngredientSum(submitData?.quantitySum)) {
      return;
    }
    setLoadingData(true);

    if (isFeedV2CFDatabaseFoundation(databaseFoundation)) {
      submitV2Feed(submitData)
        .then(() => setLoadingData(false))
        .catch(() => setLoadingData(false));
    } else {
      submitV1Feed(submitData)
        .then(() => setLoadingData(false))
        .catch(() => setLoadingData(false));
    }
  };

  // 6. set dynamic field values ex. quantitySum
  useEffect(() => {
    // dynamically added fields needs to be set correctly / cause they will form on reset,
    // but reset(newValue) will not be able to immediatelly set the values in them, cause component won't be mounted yet
    if (compoundFeed?.ingredient_uses) {
      let quantitySummary = 0;
      for (
        let index = 0;
        index < compoundFeed.ingredient_uses.length;
        index += 1
      ) {
        let quantity;
        if (compoundFeed.ingredient_uses[index]?.quantity) {
          quantity = explicitConvertValue(
            compoundFeed.ingredient_uses[index]?.quantity,
            defaultUnits.unitIngredientQuantityInputMass,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            userUOM?.unitIngredientQuantityInputMass as string,
            true
          );
        }
        // we need to match inbound transport by type, not by its position(index)
        const inboundTransports =
          compoundFeed.ingredient_uses[index]?.inbound_transport || [];
        inboundTransports.forEach((inboundTransport: CommonCFEntry) => {
          const distance = inboundTransport.value || inboundTransport.distance;
          const { value } = convertDistanceUnits(
            distance,
            inboundTransport.type,
            userUOM,
            inboundTransport.unit
          ) as { value: number; unit: string };
          setValue(
            `ingredient_uses.${index}.inbound_transport_input.${inboundTransport.type.replace(
              / /g,
              '_'
            )}`,
            value
          );
        });

        setValue(`ingredient_uses.${index}.quantity`, quantity);
        setValue(
          `ingredient_uses.${index}.quantityPercentage`,
          unitIngredientPercentage(
            Number(quantity ?? 0),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            userUOM?.unitIngredientQuantityInputMass as string
          )
        );
        setValue(
          `ingredient_uses.${index}.ingredient_id`,
          // compoundFeed.ingredient_uses[index]?.ingredient_id
          compoundFeed.ingredient_uses[index]?.requested_ingredient
            ?.ingredient_name?.id
        );
        quantitySummary += Number(quantity ?? 0);
      }
      const roundedSummary = Number(quantitySummary.toFixed(5));
      setValue(`quantitySum`, roundedSummary);
    } else if (compoundFeedV2?.ingredientUses) {
      let quantitySummary = 0;
      for (
        let index = 0;
        index < compoundFeedV2.ingredientUses.length;
        index += 1
      ) {
        let quantity;
        if (compoundFeedV2.ingredientUses[index]?.quantity) {
          quantity =
            explicitConvertValue(
              compoundFeedV2?.ingredientUses[index]?.quantity,
              defaultUnits.unitIngredientQuantityInputMass,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              userUOM?.unitIngredientQuantityInputMass as string,
              true
            ) ?? 0;
        }
        quantitySummary += Number(quantity || 0);
        // we need to match inbound transport by type, not by its position(index)
        const inboundTransports =
          (compoundFeedV2?.ingredientUses || []).at(index)?.inboundTransport ||
          [];
        const atIndexInboundTransports = convertDistanceUnitsV2(
          inboundTransports,
          userUOM
        );
        if (atIndexInboundTransports) {
          Object.keys(atIndexInboundTransports || {}).forEach((key) => {
            setValue(
              `ingredient_uses.${index}.inbound_transport_input.${key}`,
              atIndexInboundTransports[key as InboundTransportKey]
            );
          });
        }

        setValue(`ingredient_uses.${index}.quantity`, quantity);
        setValue(
          `ingredient_uses.${index}.quantityPercentage`,
          unitIngredientPercentage(
            Number(quantity ?? 0),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            userUOM?.unitIngredientQuantityInputMass as string
          )
        );
        setValue(
          `ingredient_uses.${index}.ingredient_id`,
          // compoundFeed.ingredient_uses[index]?.ingredient_id
          compoundFeedV2.ingredientUses[index]?.ingredient.name // TODO: check requested ingredient
        );
      }
      const roundedSummary = Number(quantitySummary.toFixed(5));
      setValue(`quantitySum`, roundedSummary);
    }
    // setValue must be removed from dependencies since it always triggers the effect, userUOM is dependant direct from userProfile
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compoundFeed, compoundFeedV2, userProfile]);

  // 7. Save draft on every formstate change
  useEffect(() => {
    if (
      Object.keys(formState.touched).length > 0 &&
      !discardDraftFlag.current
    ) {
      saveDraft(createDraftKey());
    }
    if (discardDraftFlag.current) discardDraftFlag.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const restoreDraft = () => {
    try {
      // when drafts activated
      if (draftKey) {
        const currPrefs = UserProfilePrefs.getInstance().getUserUnitPrefs();
        // TODO will be set type when user profile is in TS
        const changedPrefsValues: any = {};

        const draft = getFeedDraftByKey(draftKey);
        if (!draft) {
          setOpenDraftDialog(false);
          setLoadingData(false);
          return;
        }
        // first remove all form vals
        // methods.reset(emptyFormDefaults);
        setDatabaseFoundation(draft.databaseFoundation);
        const savedPrefs = getStorage(`${draftKey}_PREFS`, 'compound_feed');
        let prefsChanging = false; // need this locally, probably before state update.
        if (currPrefs && savedPrefs && typeof savedPrefs?.value === 'object') {
          Object.entries(currPrefs).forEach(([prop, value]) => {
            if (
              value !== savedPrefs.value[prop as unknown as number] &&
              prop !== 'lastLogin'
            ) {
              changedPrefsValues[prop] = value;
            }
          });
          if (!isEmpty(changedPrefsValues)) {
            prefsChanging = true;
            setChangedPrefs(prefsChanging);
          }
        }
        // then set all from draft
        if (formType === FormType.New) {
          (
            API.graphql({
              query: getCompoundFeedInputComponentData,
              variables: {
                customerId: customerID,
              },
            }) as Promise<{
              data: {
                getCompoundFeedInputComponentData?: CompoundFeedInputComponentsData;
                getCompoundFeedData?: CompoundFeedFormExtended;
              };
            }>
          ).then((response) => {
            setCompoundFeedInputData(
              response.data.getCompoundFeedInputComponentData
            );
          }).catch((err: Error) => {
            setRestoreDraftErrorMessage(
              `${intl.formatMessage({ id: "SUSTELL.ERROR.RESTORING_DRAFT" })}${err?.message
              }`
            );
            console.error(err);
          })
        }
        postLoadHandler(draft);
        setOpenDraftDialog(false);
        setLoadingData(false);
      }
    } catch (err) {
      setRestoreDraftErrorMessage(
        `${intl.formatMessage({ id: "SUSTELL.ERROR.RESTORING_DRAFT" })}${err?.message
        }`
      );
      console.error(err);
    }
  };

  const loadDataForEditOrView = () => {
    if (formType === FormType.Edit || formType === FormType.View) {
      setLoadingData(true);
      if (!isFeedV2CFDatabaseFoundation(databaseFoundation)) {
        loadCompoundFeedV1(customerID, feedID)
          .then(() => {
            setLoadingData(false);
          })
          .catch(() => {
            setLoadingData(false);
          });
      } else if (feedID) {
        loadV2Feed(customerID, feedID)
          .then(() => {
            setLoadingData(false);
          })
          .catch(() => {
            setLoadingData(false);
          });
      }
    }
  };

  const closeDraftDialog = () => {
    const key = createDraftKey();
    dispatch(clearDraftAction({ key }));

    setOpenDraftDialog(false);
    setRestoreDraftErrorMessage('');
    if (formType !== FormType.New || copyFeed) {
      loadDataForEditOrView();
    } else {
      reset(emptyFormDefaults);
    }
  };

  const toggleViewEditMode = (mode: FormType) => {
    setFormType(mode || FormType.Edit);
    if (!feedID) {
      return;
    }

    history.push({
      pathname: `/customers/${customerID}/compoundfeeds/${feedID}/${mode}?step=${activeStep}&database=${databaseFoundation || CFDatabaseFoundation.AFP_5
        }`,
      state: {
        // TODO: will be resolved when customer state is in TS
        customerName: selectedCustomer.name,
        feedName: location.state?.feedName,
        formType: mode,
        editAfterView: mode === FormType.Edit,
      },
    });
  };

  const handleCancel = () => {
    dispatch(clearDraftAction({ key: draftKey }));

    if (formType !== FormType.Edit) {
      history.push(`/customers/${customerID}/myfeeds`);
      return;
    }
    if (!isFeedV2CFDatabaseFoundation(databaseFoundation)) {
      loadCompoundFeedV1(customerID, feedID)
        .then(() => {
          toggleViewEditMode(FormType.View);
        })
        .catch(() => { });
    } else if (feedID) {
      loadV2Feed(customerID, feedID)
        .then(() => {
          toggleViewEditMode(FormType.View);
        })
        .catch(() => { });
    }
  };

  // When in edit/view mode the tabs are shown instead of steps
  const handleTabChange = (_e: ChangeEvent, i: number) => {
    handleStepChange(i, activeStep)()
      .then(() => { })
      .catch(() => { });
  };

  // On backend error switch to first tab that contains an error
  const onErrors = (errors: ErrorResponseFeeds) => {
    console.log('Errors:', JSON.stringify(errors, null, 2));
    let step = null;
    if (
      errors?.name ||
      errors?.feedmill_location ||
      errors?.target_species ||
      errors?.year
    ) {
      step = 0;
    } else if (errors?.ingredient_uses) {
      step = 1;
    } else if (errors?.outbound_transport_input) {
      step = 2;
    } else if (errors?.on_site_activities_input) {
      step = 3;
    } else if (errors?.nutritional_analysis_data) {
      step = 4;
    }

    if (step !== null) {
      history.push({
        search: `?step=${step}&database=${databaseFoundation || CFDatabaseFoundation.AFP_5
          }`,
        state: {
          customerName: selectedCustomer.name,
        },
      });
    }
    const formErrors = Object.keys(methods.formState.errors).length;
    formErrorsEvent(
      formType === FormType.New ? 'Create Feed' : 'Edit Feed',
      formType,
      formErrors
    );
  };

  const stepperClasses = useStyles();
  const buttonClasses = useButtonStyles();

  const closeAlertDialog = () => {
    setMsg(null);
    if (savedFlag.current) {
      history.push({
        pathname: `/customers/${customerID}/myfeeds`,
        state: {
          customerName: selectedCustomer.name,
        },
      });
    }
  };

  const getTargetSpecies = (dbFoundation?: CFDatabaseFoundation) => {
    // this needs to change when AFP 5 is thrown out as currently all target species are initially fetched
    // like all the other ingredients for AFP 5
    if (dbFoundation) {
      let targetSpecies: TargetSpeciesEntry[] = [];
      if (
        lookupValues.databaseFoundationsForFeedsV2.includes(dbFoundation) &&
        (formType === FormType.Edit ||
          formType === FormType.View ||
          copyV1ToV2Db ||
          copyFeed)
      ) {
        targetSpecies =
          masterDataV2.target_species[
          dbFoundation as keyof typeof masterDataV2.target_species
          ];
      } else {
        // GFLI 2.0 only in edit
        targetSpecies = (compoundFeedInputData?.target_species ||
          []) as TargetSpeciesEntry[]; // AFP 5 and take all target_species
      }

      if (formType === FormType.Edit || formType === FormType.View) {
        const targetSpeciesForDatabase =
          lookupValues.speciesPerDatabaseFoundation[dbFoundation];

        if (dbFoundation === CFDatabaseFoundation.AFP_5) {
          // Legacy AFP 5 feeds need to support view and edition mode for all target species
          // therefore we need to include the target species from the compound feed in targetSpeciesForDatabase filter
          const targetSpeciesFromCompoundFeed = compoundFeed?.target_species.length ? compoundFeed?.target_species[0] : ''
          return targetSpecies.filter(
            (targetSpecies) =>
              targetSpeciesForDatabase.includes(String(targetSpecies?.value)) ||
              targetSpecies.value === targetSpeciesFromCompoundFeed
          );
        }

        return targetSpecies.filter((target_specie) =>
          targetSpeciesForDatabase.includes(String(target_specie?.value))
        );
      }

      return targetSpecies;
    }

    return (compoundFeedInputData?.target_species ||
      []) as TargetSpeciesEntry[];
  };

  // TODO: uncomment when need to enable afp6
  const isCheckFourthStep = (index: number) =>
    index !== 4 ||
    (index === 4 &&
      targetSpecies !== 'Marine fish system' &&
      (databaseFoundation === CFDatabaseFoundation.GFLI ||
        databaseFoundation === CFDatabaseFoundation.AFP_6_3));

  const isCompoundFeedPigV2 = () =>
    (databaseFoundation === CFDatabaseFoundation.GFLI ||
      databaseFoundation === CFDatabaseFoundation.AFP_6_3) &&
    targetSpecies !== 'Marine fish system';

  const handleEditFeedInfo = async () => {
    await ctaClickEvent(
      window.location.href,
      'button',
      'Edit Feed',
      'My Feeds',
      'Feed Details'
    );
    toggleViewEditMode(FormType.Edit);
  };

  return (
    <>
      <Dialog open={openDatabaseWarning != null} aria-labelledby="alert-dialog">
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({ id: 'GENERAL.INFO' })}
        </DialogTitle>
        <DialogContent style={{ minWidth: '400px' }}>
          <DialogContentText>
            {openDatabaseWarning === CFDatabaseFoundation.GFLI ||
              openDatabaseWarning === CFDatabaseFoundation.AFP_6_3
              ? intl.formatMessage({
                id: 'COMPOUNDFEED_WIZARD.DB_WARNING.GFLI_2_0_OR_AFP_6_3',
              })
              : intl.formatMessage({
                id: 'COMPOUNDFEED_WIZARD.DB_WARNING_AFP_6_3',
              })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DsmButtonV2
            onClick={() => setOpenDatabaseWarning(null)}
            color="primary"
          >
            {intl.formatMessage({ id: 'GENERAL.CLOSE' })}
          </DsmButtonV2>
        </DialogActions>
      </Dialog>

      <Dialog open={openDraftDialog} aria-labelledby="draft-dialog">
        <DialogTitle id="draft-dialog">
          {intl.formatMessage({ id: 'GENERAL.UNSAVED_CHANGES' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'GENERAL.DRAFT.INFO.MESSAGE' })}
          </DialogContentText>
          {restoreDraftErrorMessage &&
            <DialogContentText style={{ color: 'var(--dsm-color-error)' }}>
              {restoreDraftErrorMessage}
            </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <DsmButtonV2 onClick={restoreDraft} color="primary">
            {intl.formatMessage({ id: 'GENERAL.DRAFT.ACTION.RESTORE' })}
          </DsmButtonV2>
          <DsmButtonV2
            autoFocus
            onClick={closeDraftDialog}
            color="default"
            className={buttonClasses.secondaryButton}
          >
            {intl.formatMessage({ id: 'GENERAL.DRAFT.ACTION.DISCARD' })}
          </DsmButtonV2>
        </DialogActions>
      </Dialog>
      <Dialog open={!!msg} aria-labelledby="alert-dialog">
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({ id: 'GENERAL.INFO' })}
        </DialogTitle>
        <DialogContent style={{ minWidth: '400px' }}>
          <DialogContentText>{msg || ''}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <DsmButtonV2 onClick={closeAlertDialog} color="primary">
            {intl.formatMessage({ id: 'GENERAL.CLOSE' })}
          </DsmButtonV2>
        </DialogActions>
      </Dialog>
      <Dialog
        open={loadingData}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'visible',
          },
        }}
      >
        <DsmLoadingIndicator />
      </Dialog>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h4" className={classesFeed.title}>
            {formType === FormType.New
              ? intl.formatMessage({ id: 'COMPOUNDFEEDS.NEW_FEED' })
              : feedName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {Can('read', 'Footprint') && formType === 'view' && (
            <DsmButtonV2
              slot="actions"
              variant="primary"
              onClick={() => {
                ctaClickEvent(
                  window.location.href,
                  'button',
                  'Open Feeds Footprint',
                  'My Feeds',
                  'Feed Details'
                );
                history.push(
                  assembleFootprintURL(
                    customerID,
                    feedID,
                    'COMPOUND_FEED',
                    null,
                    null,
                    'f',
                    null
                  ),
                  {
                    databaseName: databaseFoundation,
                  }
                );
              }}
              style={{ float: 'right', marginTop: '20px' }}
            >
              <DsmIcon
                name="maps-travel/globe-02"
                style={{ paddingRight: '5px', height: '24px', width: '24px' }}
              ></DsmIcon>
              {intl.formatMessage({
                id: 'COMPOUNDFEED_WIZARD.FEED_FOOTPRINT',
              })}
            </DsmButtonV2>
          )}
        </Grid>
      </Grid>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent style={{ padding: '16px 0' }}>
            {formType === FormType.New && (
              <Stepper
                activeStep={activeStep}
                className={stepperClasses.root}
                alternativeLabel
              >
                {WizardStepsLanguageKeys.filter(
                  (el, index) => !(index === 4 && !isCompoundFeedPigV2())
                ).map((i18nKey, index) => {
                  const stepProps: StepProps = {
                    style: { cursor: 'pointer' },
                  };
                  const labelProps: StepLabelProps = {};
                  if (stepStatues.get(index) === StepStatus.Failed) {
                    labelProps.optional = (
                      <Typography variant="caption" color="error">
                        {intl.formatMessage({
                          id: 'COMPOUNDFEEDS.CARD.ERROR_ON_STEP',
                        })}
                      </Typography>
                    );
                    labelProps.error = true;
                  } else if (stepStatues.get(index) === StepStatus.Completed) {
                    stepProps.completed = true;
                  }
                  return (
                    <Step
                      key={intl.formatMessage({ id: i18nKey })}
                      onClick={() => handleStepChange(index, activeStep)()}
                      style={stepProps.style}
                      completed={stepProps.completed}
                    >
                      <StepLabel
                        error={labelProps.error}
                        optional={labelProps.optional}
                        className={
                          !stepProps.completed
                            ? stepperClasses.stepLabel
                            : stepperClasses.stepLabelCompleted
                        }
                        icon={
                          !stepProps.completed ? (
                            <DsmIcon
                              name="general/placeholder"
                              className={stepperClasses.stepIcon}
                            />
                          ) : (
                            <DsmIcon
                              name="general/check"
                              className={stepperClasses.stepIcon}
                              style={{ color: '#FFFFFF' }}
                            />
                          )
                        }
                      >
                        <Typography
                          style={{
                            fontWeight: '500',
                            textDecoration: 'underline',
                          }}
                        >{`${intl.formatMessage({
                          id: 'COMPOUNDFEEDS.STEP',
                        })} ${index + 1}`}</Typography>
                        <Typography style={{ fontWeight: '400' }}>
                          {intl.formatMessage({ id: i18nKey })}
                        </Typography>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
            {/* For view/edit mode show tabs instead of steps */}
            {activeStep === 0 && (
              <>
                <Typography variant="h6" className={classesFeed.tabTitle}>
                  {intl.formatMessage({
                    id: 'COMPOUNDFEED_WIZARD.STEP.FEED_INFO',
                  })}
                </Typography>
                <Typography variant="body1" className={classesFeed.description}>
                  {intl.formatMessage(
                    {
                      id: 'COMPOUNDFEED_WIZARD.STEP.FEED_INFO.DESCRIPTION',
                    },
                    { br: <br /> }
                  )}
                </Typography>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Typography variant="h6" className={classesFeed.tabTitle}>
                  {intl.formatMessage({
                    id: 'COMPOUNDFEED_WIZARD.STEP.INGREDIENTS_INFORMATION',
                  })}
                </Typography>
                <Typography variant="body1" className={classesFeed.description}>
                  {intl.formatMessage(
                    {
                      id: 'COMPOUNDFEED_WIZARD.STEP.INGREDIENT.DESCRIPTION',
                    },
                    { br: <br /> }
                  )}
                </Typography>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Typography variant="h6" className={classesFeed.tabTitle}>
                  {intl.formatMessage({
                    id: 'COMPOUNDFEED_WIZARD.STEP.INBOUND_TRANSPORTATION',
                  })}
                </Typography>
                <Typography variant="body1" className={classesFeed.description}>
                  {intl.formatMessage(
                    {
                      id: 'COMPOUNDFEED_WIZARD.STEP.INBOUND_TRANSPORTATION_DESCRIPTION',
                    },
                    { br: <br /> }
                  )}
                </Typography>
              </>
            )}
            {activeStep === 3 && (
              <>
                <Typography variant="h6" className={classesFeed.tabTitle}>
                  {intl.formatMessage({
                    id: 'COMPOUNDFEED_WIZARD.STEP.ON_SITE_RESOURCES',
                  })}
                </Typography>
                <Typography variant="body1" className={classesFeed.description}>
                  {intl.formatMessage(
                    {
                      id: 'COMPOUNDFEED_WIZARD.STEP.ON_SITE_RESOURCES.DESCRIPTION',
                    },
                    { br: <br /> }
                  )}
                </Typography>
              </>
            )}
            {isCompoundFeedPigV2() && activeStep === 4 && (
              <>
                <Typography variant="h6" className={classesFeed.tabTitle}>
                  {intl.formatMessage({
                    id: 'COMPOUNDFEED_WIZARD.STEP.NUTRITIONAL',
                  })}
                </Typography>
                <Typography variant="body1" className={classesFeed.description}>
                  {intl.formatMessage({
                    id: targetSpecies === 'Beef system' || targetSpecies === 'DairyV2 system'
                      ? 'COMPOUNDFEED_WIZARD.STEP.NUTRITION.CATTLE.DESCRIPTION'
                      : 'COMPOUNDFEED_WIZARD.STEP.NUTRITION.DESCRIPTION',
                  })}
                </Typography>
              </>
            )}
            {(formType === FormType.View || formType === FormType.Edit) && (
              <Box
                className={classesTabs.tabsBox}
                style={{ paddingTop: '0px' }}
              >
                <Tabs
                  value={activeStep}
                  onChange={handleTabChange}
                  className={classesTabs.tabs}
                >
                  {WizardStepsLanguageKeys.filter((el, index) =>
                    isCheckFourthStep(index)
                  ).map((i18nKey, index) => (
                    <StyledTab
                      label={intl.formatMessage({ id: i18nKey })}
                      value={index}
                      id={`tab-compoundfeeds-${index}`}
                      key={`tab-compoundfeeds-${i18nKey}-key`}
                    />
                  ))}
                </Tabs>
              </Box>
            )}
            <Box display={activeStep !== 0 ? 'none' : ''}>
              <CompoundFeedBaseData
                formType={formType}
                activeStep={activeStep}
                customerName={selectedCustomer?.name}
                feedmill_locations={
                  !isFeedV2CFDatabaseFoundation(databaseFoundation)
                    ? ((compoundFeedInputData?.feedmill_locations || [
                      { iso_code: '', name: '' },
                    ]) as [FeedMillLocation])
                    : (ingredientOriginsNamesV2?.current?.origins.map((el) => ({
                      // {
                      iso_code: el.isoCode || '',
                      name: el.name || '',
                      // }
                    })) as [FeedMillLocation])
                }
                target_species={getTargetSpecies(databaseFoundation)}
                setDatabaseFoundation={setDatabaseFoundation}
                databaseFoundation={databaseFoundation}
                isUpdateMode={!!feedID}
                copyV1toV2={location.state?.copyV1ToV2Db}
                copyV2={copyFeed}
              />
            </Box>

            <Box display={activeStep !== 1 ? 'none' : ''}>
              <FeedIngredients
                formType={formType}
                ingredientOriginCombinations={
                  ingredientsCache[databaseFoundationCache]
                    ?.ingredientOriginCombinations
                }
                ingredientOriginCombinationsCategories={
                  ingredientsCache[databaseFoundationCache]
                    ?.ingredientOriginCombinationsCategories
                }
                ingredientUsesFields={ingredientUsesFields}
                ingredientOriginsNamesV2={
                  ingredientsCache[databaseFoundationCache]
                    ?.ingredientOriginsNamesV2
                }
                userProfile={userProfile}
                databaseFoundation={databaseFoundation}
                showProxyDialog={showProxyDialog}
                setShowProxyDialog={setShowProxyDialog}
              />
            </Box>
            <Box display={activeStep !== 2 ? 'none' : ''}>
              <InboundTransport
                formType={formType}
                compoundFeedInputData={compoundFeedInputData || {}}
                ingredientOriginCombinations={
                  ingredientsCache[databaseFoundationCache]
                    ?.ingredientOriginCombinations
                }
                ingredientOriginCombinationsCategories={
                  ingredientsCache[databaseFoundationCache]
                    ?.ingredientOriginCombinationsCategories
                }
                setMsg={setMsg}
                userProfile={userProfile}
                ingredientOriginsNamesV2={
                  ingredientsCache[databaseFoundationCache]
                    ?.ingredientOriginsNamesV2
                }
                convertDistanceUnits={convertDistanceUnits}
                setLoadingData={setLoadingData}
                databaseFoundation={databaseFoundation}
                customerID={customerID}
              />
              <OutboundTransport
                formType={formType}
                compoundFeedInputData={compoundFeedInputData || {}}
                databaseFoundation={databaseFoundation}
                userProfile={userProfile}
              />
            </Box>
            <Box display={activeStep !== 3 ? 'none' : ''}>
              <OnSiteActivities
                formType={formType}
                databaseFoundation={databaseFoundation}
              />
            </Box>

            {isCompoundFeedPigV2() && (
              <Box display={activeStep !== 4 ? 'none' : ''}>
                <Nutrition formType={formType} targetSpecies={targetSpecies} />
              </Box>
            )}
            <Box>
              <ButtonControlGroupCompoundFeed
                cancelHandler={handleCancel}
                // TODO: draft save instead of discard
                editFeedHandler={
                  ((compoundFeedV2 && !compoundFeedV2?.benchmark) ||
                    (compoundFeed && !compoundFeed?.benchmark)) &&
                    Can('update', 'Compound Feed')
                    ? handleEditFeedInfo
                    : null
                }
                // draftHandler={ draftVisible ? handleDiscardDraft : null }
                isLastStep={lastStepIndex === activeStep}
                isFirstStep={activeStep === 0}
                saveHandler={
                  formType !== FormType.View
                    ? methods.handleSubmit(onSubmit, onErrors)
                    : null
                }
                nextHandler={handleNext}
                prevHandler={handlePrevious}
                formType={formType}
              />
            </Box>
          </CardContent>
        </form>
      </FormProvider>
    </>
  );
};

export default CompoundFeedWizard;
