import { cloneDeep } from 'lodash';
import { useEffect, useRef, FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps } from '../../common';
import { DatabaseFoundation } from '../../../../../../graphql/generated/blonk/pigs';
import { CompoundFeedNameDatabase, Origin, SingleIngredient } from '../types';
import { Maybe, StageType } from '../../../../../../graphql/types';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { BaselineFeed } from '../../../../models/Baseline';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import DairyV2FeedComponent from './DairyV2FeedComponent';
import {
  DairyV2Baseline,
  DairyV2DairyCowStageData,
  DairyV2ContinuousGrowingStageData,
  DairyV2Stage,
  DairyV2CompoundFeedNameDatabase,
} from '../../../../models/Baseline/DairyV2Baseline';
import { getInitialDatabaseValue } from '../../../../helpers/initialValues';
import { CattleFeed } from '../../../../models/Baseline/CattleBaseline';
import { unitLong } from '../../../../utils/unit-utils';

interface DairyV2FeedDairyCowDialogProps extends BaselineDialogProps {
  compoundFeeds: Maybe<Array<DairyV2CompoundFeedNameDatabase>>;
  singleIngredients: Maybe<Array<SingleIngredient>>;
  origins: Maybe<Array<Origin>>;
}

const DairyV2FeedDairyCowDialog: FC<DairyV2FeedDairyCowDialogProps> = ({
  formVisible,
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
  compoundFeeds,
  singleIngredients,
  origins,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const formContext = useFormContext();
  const currResetValue = useRef<{
    feed: CattleFeed;
    databaseFoundation: DatabaseFoundation;
  }>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;

  const handleResetClick = useCallback(() => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Baseline;
      const dairyCowStageData = resetObject.stages[itemIndex]?.stageData as DairyV2DairyCowStageData
      if (dairyCowStageData?.feed) {
        dairyCowStageData.feed = {
          ...currResetValue.current.feed,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  }, [formContext, handleCancel, itemIndex]);

  const intl = useIntl();
  const baselineDatabaseFoundation =
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      fieldItemPrefix,
      userProfile
    );
  const initialDatabase = useRef<DatabaseFoundation>(
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      fieldItemPrefix,
      userProfile
    )
  );

  const updateContinuosGrowingFeeds = (stage: DairyV2Stage, index: number) => {
    const feeds = (stage.stageData as DairyV2ContinuousGrowingStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined = feeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedFeeds
    );
  };

  const updateFeeds = (stage: DairyV2Stage, index: number) => {
    const feeds = (stage.stageData as DairyV2DairyCowStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined = feeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedFeeds
    );
  };

  const handleSaveClick = async () => {
    const currFormValues = formContext.getValues();
    formContext.reset(currFormValues, { errors: true });
    const stages = currFormValues?.stages as Array<DairyV2Stage> || [];
    stages.forEach((stage: DairyV2Stage, i: number) => {
      if (
        i !== itemIndex &&
        initialDatabase.current !== baselineDatabaseFoundation
      ) {
        if (stage.type === StageType.ContinuousGrowing) updateContinuosGrowingFeeds(stage, i);
        else updateFeeds(stage, i);
      }
    });
    if (handleSave) {
      await formContext.trigger(`stages.${itemIndex}.stageData.feed`);
      handleSave('confirm');
    }
  };

  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const barnOutputMassUnitLong = userUOM?.unitBarnOutputMass ? unitLong(userUOM.unitBarnOutputMass) : null;

  useEffect(() => {
    if (formVisible) {
      const serializedData = formContext.getValues(
        `stages.${itemIndex}.stageData.feed`
      ) as CattleFeed;
      currResetValue.current = cloneDeep({
        feed: serializedData,
        databaseFoundation: baselineDatabaseFoundation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      formTitle={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
      })}
      variant="ultrawide"
      introText={
        <>
          {intl.formatMessage({ id: "SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.FEED.DESCRIPTION" })}
          <br /><br />
          {intl.formatMessage({ id: "SUSTELL.STAGE.DAIRY.FEED.DAIRY_COW.DESCRIPTION" },
            {
              longUnit: barnOutputMassUnitLong === 'kg' ? 'kilograms' : barnOutputMassUnitLong === 'lbs' ? 'pounds' : 'kilograms',
              unit: barnOutputMassUnitLong === 'kg' ? 'kg DM' : barnOutputMassUnitLong === 'lbs' ? 'lbs DM' : 'kg DM'
            }
          )}
        </>
      }
    >
      <DairyV2FeedComponent
        formType={formType}
        fieldItemPrefix={fieldItemPrefix}
        stageIndex={itemIndex}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
        formVisible={formVisible}
        origins={origins}
        stageType={StageType.DairyCow}
      />
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleSaveClick}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default DairyV2FeedDairyCowDialog;
