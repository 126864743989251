export const listCustomers = /* GraphQL */ `
  query listCustomers($limit: Int, $nextToken: String) {
    listCustomers(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        token
        reference
        feedsVisible
        SAPCustomerId
        type
        license
        licenseStartDate
        licenseEndDate
        contractNumber
        farmsNumberLimit
        partnerApiAccess
        canAccessPartner
        allowedPartnerMutations {
          partnerId
          mutations
        }
        allowedPartnerQueries {
          partnerId
          queries
        }
        farms {
          items {
            id
            name
            # farmingMethod
            # animalType
            city
            country
            # baselines {
            #   items {
            #     productionData
            #     validFrom
            #   }
            # }
            size
            # barns {
            #   id
            #   name
            #   productionSystem
            #   housingType
            # }
          }
        }
        # projects {
        #   items {
        #     id
        #     name
        #     animalType
        #     description
        #     updatedAt
        #   }
        # }
      }
      nextToken
    }
  }
`;

export const listCustomersShallowInner = /* GraphQL */ `
  listCustomers {
    items {
      id
      name
      token
      reference
      feedsVisible
    }
  }
`;

export const getCustomerIngredients = (variableName = '$id') => /* GraphQL */ `
query getCustomerIngredients($id: ID!) {
  getCustomerIngredients(id: ${variableName}) {
    compoundFeedIngredients 
    singleIngredients
    compoundFeedIngredientsAddons
  }
}
`;

export const listUsers = /* GraphQL */ `
  query listUsers(
    $filterAttribute: String
    $filterValue: String
    $limit: Int
    $nextToken: String
  ) {
    listUsers(
      filterAttribute: $filterAttribute
      filterValue: $filterValue
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        enabled
        status
        groups
      }
      nextToken
    }
  }
`;

export const listFarms = /* GraphQL */ `
  query listFarms($customerId: ID!) {
    listFarms(customerId: $customerId) {
      id
      name
      benchmark
      animalSystems {
        items {
          id
          name
          animalType
          baselines {
            items {
              reference
              name
              databaseFoundation
              stages {
                id
                name
                type
                stageData
              }
              createdAt
              updatedAt
              validFrom
              validTo
            }
          }
        }
      }
    }
  }
`;

export const getFarm = /* GraphQL */ `
  query getFarm($farmId: ID!) {
    getFarm(id: $farmId) {
      id
      name
      city
      country
      size
      createdAt
      updatedAt
      lastUpdatedBy
      climateType
      benchmark
      animalSystems {
        items {
          id
          name
          animalType
          farmingMethod
          productionType
          createdAt
          updatedAt
          baselines {
            items {
              validFrom
              validTo
              updatedAt
              createdAt
              impactSummary
              year
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getFarmBaseData = /* GraphQL */ `
  query getFarm($farmId: ID!) {
    getFarm(id: $farmId) {
      id
      name
      city
      country
      size
      createdAt
      updatedAt
      lastUpdatedBy
      climateType
      benchmark
    }
  }
`;

export const getFarmSinglePPWithBaselineInfo = /* GraphQL */ `
  query getFarm($farmId: ID!, $productionProcessId: ID!) {
    getFarm(id: $farmId) {
      id
      name
      city
      country
      size
      createdAt
      updatedAt
      lastUpdatedBy
      benchmark
      animalSystems(productionProcessId: $productionProcessId) {
        items {
          id
          name
          animalType
          farmingMethod
          productionType
          createdAt
          updatedAt
          baselines {
            items {
              id: reference
              reference
              name
              createdAt
              updatedAt
              validFrom
              validTo
              lastUpdatedBy
              impactSummary
              target
              calculationInProgress
              year
            }
          }
          interventions {
            items {
              id: reference
              reference
              baseline
              name
              createdAt
              updatedAt
              lastUpdatedBy
              impactSummary
              target
              calculationInProgress
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getFarmWithBaselines = /* GraphQL */ `
  query getFarm($farmId: ID!) {
    getFarm(id: $farmId) {
      id
      name
      animalType
      city
      country
      size
      updatedAt
      lastUpdatedBy
      benchmark
    }
  }
`;

export const getFarmWithAllBaselineInfo = /* GraphQL */ `
  query getFarm($farmId: ID!) {
    getFarm(id: $farmId) {
      id
      name
      city
      country
      size
      createdAt
      updatedAt
      lastUpdatedBy
      climateType
      benchmark
      animalSystems {
        items {
          id
          name
          animalType
          farmingMethod
          productionType
          createdAt
          updatedAt
          baselines {
            items {
              id: reference
              reference
              name
              createdAt
              updatedAt
              validFrom
              validTo
              lastUpdatedBy
              impactSummary
              target
              calculationInProgress
              year
              stages {
                type
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getProjectInner = (variableName = '$projectId') => /* GraphQL */ `
  getProject(id: ${variableName}) {
    id
    name
    animalType
    description
    farms
    customerId
    updatedAt
    lastUpdatedBy
  }
`;

export const getOriginsInner = (varName1 = `$customerId`) => /* GraphQL */ `
  getOrigins(customerId: ${varName1}) {
        isoCode
        name
        region
    }
  `;

export const getOrigins = /* GraphQL */ `
  query getOrigins($customerId: ID!) { ${getOriginsInner()} }
`;
export const getCompoundFeedV2Inner = (
  varName1 = `$customerId`,
  varName2 = `$nextToken`
) => /* GraphQL */ `
  listCompoundFeedsV2(customerId: ${varName1}, nextToken: ${varName2}) {
    nextToken
    items{
        value:id
        display_name:name
        databaseFoundation
        targetSpecies
        year
        type
      }
    }
  `;

export const listFacilitiesShallowInner = (
  varName1 = `$customerId`
) => /* GraphQL */ `
listFacilities(customerId: ${varName1}) {
  items {
      id
      name
      targetSpecies
      location
      type
      currency
      lastUpdatedBy
      updatedAt
      benchmark
    }
  }
`;

export const listFacilitiesBrief = /* GraphQL */ `
  query listFacilitiesBrief($customerId: ID!) {
    ${listFacilitiesShallowInner()}
  }
`;

export const getFacilityInner = (
  varName1 = `$facilityId`,
  varName2 = `$customerId`
) => /* GraphQL */ `
  getFacility(facilityId: ${varName1}, customerId: ${varName2}) {
    auxiliaryInputs {
      wastewaterAmount
      chemicals {
        amount
        type
      }
      refrigerants {
        amount
        type
      }
    }
    createdAt
    customerId
    resourceUse {
      electricityUse
      gasUse
      gasolineUse
      propaneOrLpgUse
      coalUse
      waterUse
      dieselUse
      electricityPhotovoltaicUse
      electricityWindUse
    }
    id
    lastUpdatedBy
    totalLiveweight
    currency
    location
    name
    targetSpecies
    type
    updatedAt
    benchmark
  }
`;

export const getFacility = /* GraphQL */ `
  query getFacility($facilityId: ID!, $customerId: ID!) { ${getFacilityInner()} }
`;

export const getProject = /* GraphQL */ `
  query getProject($projectId: ID!) { ${getProjectInner()} }
`;

export const listProjectFarmInterventions = /* GraphQL */ `
  query listProjectFarmInterventions(
    $customerId: ID!
    $projectId: ID!
    $farmId: ID!
  ) {
    listProjectFarmInterventions(
      customerId: $customerId
      projectId: $projectId
      farmId: $farmId
    ) {
      items {
        # customerId
        name
      }
    }
  }
`;

export const getFarmBaseline = /* GraphQL */ `
  query getFarmBaseline($farmId: ID!, $name: String!) {
    getFarmBaseline(farmId: $farmId, name: $name) {
      farmId
      name
      validFrom
      validTo
      description
      productionData
      reference
      updatedAt
      lastUpdatedBy
    }
  }
`;

export const getFarmBaselineByReferenceInner = (
  farmIdVariableName = '$farmId',
  referenceVariableName = '$reference'
) => /* GraphQL */ `
  getFarmBaselineByReference(farmId: ${farmIdVariableName}, reference: ${referenceVariableName}) {
    id: reference
    farmId
    productionProcessId
    name
    age
    description
    year
    validFrom
    validTo
    databaseFoundation
    target
    averageAnnualTemperature
    reportingPeriod
    roundsPerYear
    impactSummary
    resourceUse {
      electricityUse
      gasUse
      waterUse
      waterSource
      dieselUse
    }
    stages {
      id
      name
      stageData
      pondName
    }
    calculationInProgress
    updatedAt
    lastUpdatedBy
  }
`;

export const getFarmBaselineByReferenceInnerV2 = (
  farmIdVariableName = '$farmId',
  referenceVariableName = '$reference'
) => /* GraphQL */ `
  getFarmBaselineByReference(farmId: ${farmIdVariableName}, reference: ${referenceVariableName}) {
    id: reference
    farmId
    productionProcessId
    name
    description
    year
    age
    validFrom
    validTo
    reportingPeriod
    roundsPerYear
    impactSummary
    databaseFoundation
    averageAnnualTemperature
    resourceUse {
      electricityUse
      gasUse
      waterUse
      waterSource
      dieselUse
      coalUse
      propaneOrLpgUse
      gasolineUse
      heavyFuelOilUse
      electricityPhotovoltaicUse
      electricityWindUse
    }
    stages {
      id
      name
      type
      pondName
      startDate
      endDate
      facilityId
      stageDuration
      stageData
    }
    calculationInProgress
    updatedAt
    lastUpdatedBy
  }
`;

export const getFarmBaselineByReference = /* GraphQL */ `
  query getFarmBaselineByReference($farmId: ID!, $reference: String!) { ${getFarmBaselineByReferenceInner()} }
`;

export const getFarmBaselineByReferenceV2 = /* GraphQL */ `
  query getFarmBaselineByReference($farmId: ID!, $reference: String!) { ${getFarmBaselineByReferenceInnerV2()} }
`;

export const getIntervention = /* GraphQL */ `
  query getIntervention(
    $customerId: ID!
    $projectId: ID!
    $farmId: ID!
    $name: String!
  ) {
    getIntervention(
      customerId: $customerId
      projectId: $projectId
      farmId: $farmId
      name: $name
    ) {
      name
      baseline
      description
      interventionData
      updatedAt
      lastUpdatedBy
    }
  }
`;

export const getInterventionByReferenceInner = (
  farmIdVariableName = '$farmId',
  referenceVariableName = '$reference'
) => /* GraphQL */ `
  getInterventionByReference(farmId: ${farmIdVariableName}, reference: ${referenceVariableName}) {
    id: reference
    reference
    customerId
    farmId
    productionProcessId
    baseline
    name
    description
    category
    target
    impactSummary
    resourceUse {
      electricityUse {
        value
        change_type
      }
      gasUse {
        value
        change_type
      }
      waterUse {
        value
        change_type
      }
      waterSource {
        value
        # change_type
      }
      dieselUse {
        value
        change_type
      }
      coalUse {
        value
        change_type
      }
      gasolineUse {
        value
        change_type
      }
      heavyFuelOilUse {
        value
        change_type
      }
      propaneOrLpgUse {
        value
        change_type
      }
      electricityPhotovoltaicUse {
        value
        change_type
      }
      electricityWindUse {
        value
        change_type
      }
    }
    stages {
      id
      name
      startDate
      endDate
      type
      stageData
    }
    calculationInProgress
    updatedAt
    lastUpdatedBy
  }
`;

export const getInterventionByReference = /* GraphQL */ `
  query getInterventionByReference($farmId: ID!, $reference: String!) { ${getInterventionByReferenceInner()} }
`;

export const getInterventionByReferenceBaselineAndInterventions = /* GraphQL */ `
  query getInterventionByReference($farmId: ID!, $reference: String!) { ${getInterventionByReferenceInner()} }
`;

export const getCustomer = /* GraphQL */ `
  query getCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      token
      feedsVisible
      reference
      farmsNumberLimit
      partnerApiAccess
      canAccessPartner
      allowedPartnerMutations {
        partnerId
        mutations
      }
      allowedPartnerQueries {
        partnerId
        queries
      }
      farms {
        items {
          id
          name
          # animalType
          city
          country
          # farmingMethod
          size
          climateType
          updatedAt
          benchmark
          animalSystems {
            items {
              id
              animalType
            }
          }
        }
      }
    }
  }
`;

export const getCustomerBriefBaselinesInterventions = /* GraphQL */ `
  query getCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      token
      feedsVisible
      reference
      farmsNumberLimit
      farms {
        items {
          id
          name
          # animalType
          city
          country
          # farmingMethod
          size
          climateType
          updatedAt
          benchmark
          animalSystems {
            items {
              id
              animalType
              baselines {
                items {
                  name
                }
              }
              interventions {
                items {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getCustomerDashboardTargets = /* GraphQL */ `
  query getCustomer($id: ID!) {
    getCustomer(id: $id) {
      dashboardTargets
    }
  }
`;

export const getCustomerWithBaselinesInner = (
  variableName = '$id'
) => /* GraphQL */ `
  getCustomer(id: ${variableName}) {
    id
    name
    reference
    dashboardTargets
    farms {
      items {
        id
        name
        city
        country
        size
        benchmark
        animalSystems {
          items {
            id
            farmId
            name
            animalType
            farmingMethod
            baselines {
              items {
                name
                description
                calculationInProgress
                reference
                validFrom
                validTo
                createdAt
                updatedAt
                target
              }
            }
            interventions {
              items {
                name
                description
                calculationInProgress
                baseline
                reference
                createdAt
                updatedAt
                target
              }
            }
          }
        }
      }
    }
  }`;

export const getCustomerWithBaselines = /* GraphQL */ `
  query getCustomer($id: ID!) { ${getCustomerWithBaselinesInner()} }
`;

export const getCustomerWithBaselinesAndStageTypesInner = (
  variableName = '$id'
) => /* GraphQL */ `
  getCustomer(id: ${variableName}) {
    id
    name
    reference
    dashboardTargets
    farms {
      items {
        id
        name
        city
        country
        size
        benchmark
        animalSystems {
          items {
            id
            farmId
            name
            animalType
            farmingMethod
            baselines {
              items {
                name
                description
                calculationInProgress
                reference
                validFrom
                validTo
                createdAt
                updatedAt
                target
                stages {
                  type
                }
              }
            }
            interventions {
              items {
                name
                description
                calculationInProgress
                baseline
                reference
                createdAt
                updatedAt
                target
              }
            }
          }
        }
      }
    }
  }`;

export const getCustomerWithBaselinesAndStageTypes = /* GraphQL */ `
  query getCustomer($id: ID!) { ${getCustomerWithBaselinesAndStageTypesInner()} }
`;

export const getUser = /* GraphQL */ `
  query getUser($userId: ID!) {
    getUser(userId: $userId) {
      firstName
      lastName
      email
      phoneNumber
      status
      enabled
      groups
      customerAccess {
        id
      }
    }
  }
`;

export const getMasterDataInner = (
  varName1 = '$customerId',
  varName2 = '$animalType'
) => /* GraphQL */ `
  getMasterData(customerId: ${varName1}, animalType: ${varName2}) {
    compound_feed_uses {
      display_name
      value
    }
    farming_method {
      display_name
      value
    }
    iso_code {
      display_name
      value
    }
    manure_management_system {
      display_name
      value
    }
    single_ingredient_uses {
      display_name
      value
    }
    enumerations{
      enumName
      enumValues{
        display_name
        value
      }
    }
  }
`;

export const getMasterData = /* GraphQL */ ` query getMasterData($customerId: ID!, $animalType: AnimalType!) { ${getMasterDataInner()} }`;

export const getAllAnimalTypesCountries = /* GraphQL */ `
  query getAllAnimalTypesCountries($customerId: ID!) {
    BROILER: getMasterData(customerId: $customerId, animalType: BROILER) {
      iso_code {
        display_name
        value
      }
    }
    DAIRY: getMasterData(customerId: $customerId, animalType: DAIRY) {
      iso_code {
        display_name
        value
      }
    }
    LAYING_HENS: getMasterData(
      customerId: $customerId
      animalType: LAYING_HENS
    ) {
      iso_code {
        display_name
        value
      }
    }
    PIG_FATTENING: getMasterData(
      customerId: $customerId
      animalType: PIG_FATTENING
    ) {
      iso_code {
        display_name
        value
      }
    }
    SALMON: getMasterData(customerId: $customerId, animalType: SALMON) {
      iso_code {
        display_name
        value
      }
    }
  }
`;

const getMasterDataWithCompoundFeedsInner = (
  varName1 = '$customerId',
  varName2 = '$animalType'
) => /* GraphQL */ `
  getMasterData(customerId: ${varName1}, animalType: ${varName2}) {
    farming_method {
      display_name
      value
    }
    iso_code {
      display_name
      value
    }
    manure_management_system {
      display_name
      value
    }
    single_ingredient_uses {
      display_name
      value
    }
    enumerations{
      enumName
      enumValues{
        display_name
        value
      }
    }
  }
  getCompoundFeedList(customerId: ${varName1}) {
    items {
      value:id
      display_name: name
      target_species
    }
  }
`;

export const getMasterDataWithCompoundFeeds = /* GraphQL */ `
  query getMasterDataWithCompoundFeeds($customerId: ID!, $animalType: AnimalType!) { 
    ${getMasterDataWithCompoundFeedsInner('$customerId', '$animalType')} 
  }
`;

export const getMasterDataAndCFAccessWithCompoundFeeds = /* GraphQL */ `
  query getMasterDataAndCFAccessWithCompoundFeeds($customerId: ID!, $animalType: AnimalType!) { 
    ${getMasterDataWithCompoundFeedsInner('$customerId', '$animalType')},
    getCustomer(id: $customerId) {
      id
      feedsVisible
      reference
    }
  }
`;

const getMasterDataWithCompoundFeedsV2Inner = (
  varName1 = '$customerId',
  varName2 = '$animalType'
) => /* GraphQL */ `
  getMasterData(customerId: ${varName1}, animalType: ${varName2}) {
    farming_method {
      display_name
      value
    }
    iso_code {
      display_name
      value
    }
    manure_management_system {
      display_name
      value
    }
    single_ingredient_uses {
      display_name
      value
    }
    enumerations{
      enumName
      enumValues{
        display_name
        value
      }
    }
  }
  ${getCompoundFeedV2Inner()}
`;

export const getMasterDataAndCFAccessWithCompoundFeedsV2 = /* GraphQL */ `
  query getMasterDataAndCFAccessWithCompoundFeedsV2($customerId: ID!, $animalType: AnimalType!, $nextToken: String) { 
    ${getMasterDataWithCompoundFeedsV2Inner('$customerId', '$animalType')},
    getCustomer(id: $customerId) {
      id
      feedsVisible
      reference
    }
  }
`;

export const getUserUnitsSettings = /* GraphQL */ `
  query getUserSettings($setLastLogin: Boolean) {
    getUserSettings(setLastLogin: $setLastLogin) {
      unitFarmSize
      unitAverageAnnualTemperature
      unitOutputMass
      unitElectricityUse
      unitNaturalGasUse
      unitDieselUse
      unitGasolineUse
      unitHeavyFuelOilUse
      unitHeatUse
      unitWaterUse
      unitBarnOutputMass
      unitBarnSmallQuantityUnits
      unitEnvImpactPer
      unitIngredientQuantityInputMass
      unitCompoundFeedOutputMass
      unitTransportDistanceTerrestrial
      unitTransportDistanceSea
      unitTransportDistanceInlandWaterways
      unitCompoundFeedWaterUse
      unitCompoundFeedNutritionalDataContent
      unitNutritionalGrossEnergy
      lastLogin
    }
  }
`;

export const getDatabaseSettings = /* GraphQL */ `
  query getUserSettings($setLastLogin: Boolean) {
    getUserSettings(setLastLogin: $setLastLogin) {
      databaseType
      lastLogin
    }
  }
`;

export const getLoginSettings = /* GraphQL */ `
  query getUserSettings($setLastLogin: Boolean) {
    getUserSettings(setLastLogin: $setLastLogin) {
      numberOfLoginTimes
      sampleDataAccessPopup
    }
  }
`;

export const getDefaultCustomer = /* GraphQL */ `
  query getUserSettings($setLastLogin: Boolean) {
    getUserSettings(setLastLogin: $setLastLogin) {
      defaultCustomer
    }
  }
`;

export const getUserSettings = /* GraphQL */ `
  query getUserSettings($setLastLogin: Boolean) {
    getUserSettings(setLastLogin: $setLastLogin) {
      unitFarmSize
      unitAverageAnnualTemperature
      unitOutputMass
      unitElectricityUse
      unitNaturalGasUse
      unitDieselUse
      unitGasolineUse
      unitHeavyFuelOilUse
      unitHeatUse
      unitWaterUse
      unitBarnOutputMass
      unitBarnSmallQuantityUnits
      unitEnvImpactPer
      unitIngredientQuantityInputMass
      unitCompoundFeedOutputMass
      unitTransportDistanceTerrestrial
      unitTransportDistanceSea
      unitTransportDistanceInlandWaterways
      unitCompoundFeedWaterUse
      unitCompoundFeedNutritionalDataContent
      unitNutritionalGrossEnergy
      lastLogin
      footprint
      databaseType
      numberOfLoginTimes
      sampleDataAccessPopup
      defaultCustomer
    }
  }
`;

export const listAllNotifications = /* GraphQL */ `
  query listAllNotifications($limit: Int, $nextToken: String) {
    listAllNotifications(limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        message
        active
        isWelcome
        startDateTime
        expireDateTime
        notificationType
        targetGroups
        attachementKeys
      }
    }
  }
`;

export const listMyNotifications = /* GraphQL */ `
  query listMyNotifications(
    $notificationType: String
    $limit: Int
    $nextToken: String
  ) {
    listMyNotifications(
      notificationType: $notificationType
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        message
        isWelcome
        startDateTime
        expireDateTime
        notificationType
        attachementKeys
      }
    }
  }
`;

export const getNotification = /* GraphQL */ `
  query getNotification($id: ID) {
    getNotification(id: $id) {
      items {
        id
        title
        message
        active
        startDateTime
        expireDateTime
        notificationType
      }
    }
  }
`;

export const getIncidentSignedUrl = /* GraphQL */ `
  query getIncidentSignedUrl($key: String!, $method: String!) {
    getIncidentSignedUrl(key: $key, method: $method) {
      # authorized
      key
      signedUrl
    }
  }
`;

export const getSignedUrl = /* GraphQL */ `
  query getSignedUrl($key: String!, $method: String!) {
    getSignedUrl(key: $key, method: $method) {
      # authorized
      key
      signedUrl
    }
  }
`;

export const getDashboardEmbedURL = /* GraphQL */ `
  query getDashboardEmbedURL($dashboardName: String!) {
    getDashboardEmbedURL(dashboardName: $dashboardName) {
      url
    }
  }
`;

export const getCompoundFeedInputComponentDataInner = (
  varName = '$customerId'
) => /* GraphQL */ `
  getCompoundFeedInputComponentData(customerId: ${varName}) {
    feedmill_locations {
      iso_code
      name
    }
    inbound_transport_types
    ingredient_origin_combination {
      id
      name
      exclusive_origins {
        iso_code {
          code
        }
        name
      }
    }
    # nutritional_analysis_data_types {
    #   type
    #   unit
    # }
    on_site_activities_types {
      type
      unit
    }
    outbound_transport_types
    target_species {
      display_name
      value
    }
  }
`;

export const getCompoundFeedInputComponentData = /* GraphQL */ `query getCompoundFeedInputComponentData($customerId: ID!) {${getCompoundFeedInputComponentDataInner()}}`;

export const getCompoundFeedIngredientNamesInner = (
  var1 = '$customerId'
) => /* GraphQL */ `
  getCompoundFeedIngredientNames(customerId: ${var1}) {
    group
    name
}
`;

export const getCompoundFeedIngredientNames = /* GraphQL */ `
  query getCompoundFeedIngredientNames($customerId: ID!) { ${getCompoundFeedIngredientNamesInner()} }
`;

export const getCompoundFeedIngredientOrProxyInner = (
  var1 = '$customerId',
  var2 = '$databaseFoundation',
  var3 = '$requestedIngredients'
) => /* GraphQL */ `
  getCompoundFeedIngredientOrProxy(customerId: ${var1}, databaseFoundation: ${var2}, requestedIngredients: ${var3}) {
      isProxy
      requestedIngredient {
          name
          origin
      }
      selectedIngredient {
          name
          origin
      }
  }`;

export const getCompoundFeedIngredientOrProxy = /* GraphQL */ `
  query getCompoundFeedIngredientOrProxy($customerId: ID!, $databaseFoundation: CompoundFeedDatabase, $requestedIngredients: [RequestedIngredientV2Input]) {
    ${getCompoundFeedIngredientOrProxyInner()}
  }`;

export const getIngredientTransportDistanceInner = (
  var1 = '$customerId',
  var2 = '$databaseFoundation',
  var3 = '$feedmillLocationIsoCode',
  var4 = '$ingredient'
) => /* GraphQL */ `
  getIngredientTransportDistance(customerId: ${var1}, databaseFoundation: ${var2}, feedmillLocationIsoCode: ${var3}, ingredient: ${var4}) {
      distance
      mode
  }`;

export const getIngredientTransportDistance = /* GraphQL */ `
  query getIngredientTransportDistance($customerId: ID!, $databaseFoundation: CompoundFeedDatabase!, $feedmillLocationIsoCode: String!, $ingredient: IngredientInput!) {
    ${getIngredientTransportDistanceInner()}
  }`;

export const retrieveDefaultDistances = /* GraphQL */ `
  query retrieveDefaultDistances(
    $ingredientID: ID!
    $ingredientOrigin: String!
    $feedMillLocation: String!
    $customerId: ID!
  ) {
    retrieveDefaultDistances(
      ingredientID: $ingredientID
      ingredientOrigin: $ingredientOrigin
      feedMillLocation: $feedMillLocation
      customerId: $customerId
    ) {
      status
      message
      items {
        type
        value
        unit
      }
    }
  }
`;

const getCompoundFeedAddonsInner = (
  databaseFoundationVarName = '$databaseFoundation'
) => `
  getCompoundFeedV2Addons(databaseFoundation: ${databaseFoundationVarName}) {
    addonType
    ingredients {
        name
        origin
    }
  }
`;

const getCompoundFeedCustomerAddonsInner = (
  customerIdVarName = '$customerId',
  databaseFoundationVarName = '$databaseFoundation'
) => `
  getCompoundFeedV2CustomerAddons(customerId: ${customerIdVarName}, databaseFoundation: ${databaseFoundationVarName}) {
    addonType
    ingredients {
        name
        origin
    }
  }
`;

export const getCompoundFeedV2Addons = /* GraphQL */ `
  query getCompoundFeedV2Addons($databaseFoundation: CompoundFeedDatabase!) {
    ${getCompoundFeedAddonsInner()}
  }
`;

export const listIngredientsAndOriginsV2 = /* GraphQL */ `
  query listIngredientsAndOriginsV2($customerId: ID!, $databaseFoundation: CompoundFeedDatabase!) {
    ${getOriginsInner()}
    ${getCompoundFeedIngredientNamesInner()}
    ${getCompoundFeedCustomerAddonsInner()}
  }
`;

export const getCompoundFeedV2 = /* GraphQL */ `
  query getCompoundFeedV2($feedId: ID!, $customerId: ID!) {
    getCompoundFeedV2(feedId: $feedId, customerId: $customerId) {
      ingredientUses {
        customName
        originalOrigin
        customIngredient
        isProxy
        quantity
        ingredient {
          name
          origin
          addonType
        }
        inboundTransport {
          distance
          mode
        }
      }
      id
      feedmillLocation
      databaseFoundation
      customerId
      createdAt
      name
      nutritionalAnalysisData {
        type
        unit
        value
      }
      onSiteActivities {
        amount
        resourceType
      }
      outboundTransport {
        distance
        mode
      }
      targetSpecies
      updatedAt
      year
      benchmark
      type
    }
  }
`;

export const listCompoundFeedsV2 = /* GraphQL */ `
  query listCompoundFeedsV2($customerId: ID!, $nextToken: String) {
    listCompoundFeedsV2(customerId: $customerId, nextToken: $nextToken) {
      nextToken
      items {
        id
        databaseFoundation
        customerId
        databaseFoundation
        updatedAt
        createdAt
        year
        name
        feedmillLocation
        targetSpecies
        lastUpdatedBy
        benchmark
        type
      }
    }
  }
`;

export const getSingleIngredientNamesInner = (
  var1 = '$customerId',
  var2 = '$animalType'
) => /* GraphQL */ `
  getSingleIngredientNames(customerId: ${var1}, animalType: ${var2}) {
    group
    name
    targetSpecies
    isSilage
    isMilk
}
`;

export const listOriginsAndSingleIngredients = /* GraphQL */ `
  query listOriginsAndSingleIngredients($customerId: ID!, $animalType: AnimalType!) {
    ${getOriginsInner()}
    ${getSingleIngredientNamesInner()}
  }
`;

export const listFeedsV2 = /* GraphQL */ `
  query listFeedsV2($customerId: ID!, $nextToken: String) {
    listCompoundFeedsV2(customerId: $customerId, nextToken: $nextToken) {
    nextToken
    items{
        value:id
        display_name:name
        databaseFoundation
        targetSpecies
        year
        type
      }
    }
  }
`;

export const listFeedsDairyV2 = /* GraphQL */ `
  query listFeedsV2($customerId: ID!, $nextToken: String) {
    listCompoundFeedsV2(customerId: $customerId, nextToken: $nextToken) {
    nextToken
    items{
        value:id
        display_name:name
        databaseFoundation
        targetSpecies
        nutritionalAnalysisData {
          type
          value
        }
        year
        type
      }
    }
  }
`;

export const getCompoundFeedListInner = (
  variableName = '$customerId'
) => /* GraphQL */ `
  getCompoundFeedList(customerId: ${variableName}) {
    items {
      id
      name
      year {
        value
      }
      feedmill_location {
        name
        iso_code
      }
      is_reference
      calculationInProgress
      target_species
      modified_date
      benchmark
    }
  }
`;

export const getCompoundFeedListShortInner = (
  variableName = '$customerId'
) => /* GraphQL */ `
  getCompoundFeedList(customerId: ${variableName}) {
    items {
      value: id
      display_name: name
      target_species
      year {
        value
      }
    }
  }
`;

export const listFeedsV1andV2 = /* GraphQL */ `
query listFeedsV2($customerId: ID!, $nextToken: String) {
  ${getCompoundFeedListShortInner()}
  ${getCompoundFeedV2Inner()}
}
`;

export const getCompoundFeedList = /* GraphQL */ `query getCompoundFeedList($customerId: ID!) { ${getCompoundFeedListInner()} }`;

export const getCompoundFeedDataInner = (
  varName1 = `$customerId`,
  varName2 = `$feedId`
) => /* GraphQL */ `
  getCompoundFeedData(customerId: ${varName1}, feedId: ${varName2}) {
    id
    name
    year {
      value
    }
    is_reference
    benchmark
    on_site_activities {
      type
      unit
      value
    }
    # nutritional_analysis_data {
    #   type
    #   unit
    #   value
    # }
    outbound_transport {
      type
      value
      unit
    }
    ingredient_uses {
      id
      quantity
      inbound_transport {
        type
        value
        unit
      }
      requested_ingredient {
        custom_name
        ingredient_name {
          id
          name
        }
        origin
      }
      ingredient {
        ingredient_name {
          id
          name
        }
        origin
      }
    }
    feedmill_location {
      iso_code
    }
    target_species
  }
`;

export const getCompoundFeedData = /* GraphQL */ `query getCompoundFeedData($customerId: ID!, $feedId: ID!) { ${getCompoundFeedDataInner()}}`;

export const getCompoundFeedInputAndData = /* GraphQL */ `
  query getCompoundFeedInputAndData($customerId: ID!, $feedId: ID!) { 
    ${getCompoundFeedInputComponentDataInner('$customerId')},
    ${getCompoundFeedDataInner('$customerId', '$feedId')}
    ${getOriginsInner()}
  }
`;

export const getCompoundFeedInputAndDataAndV2 = /* GraphQL */ `
  query getCompoundFeedInputAndData($customerId: ID!, $feedId: ID!) { 
    ${getCompoundFeedInputComponentDataInner('$customerId')},
    ${getCompoundFeedDataInner('$customerId', '$feedId')}
  }
`;

export const getCompoundFeedInputAndDataWithComparison = /* GraphQL */ `
  query getCompoundFeedInputAndData($customerId: ID!, $feedId: ID!, $comparisonCustomerId: ID!, $comparisonFeedId: ID!) { 
    ${getCompoundFeedInputComponentDataInner('$customerId')},
    firstFeed: ${getCompoundFeedDataInner('$customerId', '$feedId')},
    secondFeed: ${getCompoundFeedDataInner(
      '$comparisonCustomerId',
      '$comparisonFeedId'
    )}
  }
`;

export const getFootprintInner = (
  customerVariableName = '$customerId',
  referencesVariableName = '$references',
  typeVariableName = '$type'
) => /* GraphQL */ `
  getFootprint(references: ${referencesVariableName}, customerId: ${customerVariableName}, type: ${typeVariableName}) {
    calculatedImpact
    warnings
    emissions
    errors
    reporting_period
    calculationInProgress
    total_output
    reference
    stagesImpact
  }
`;

export const getFootprintDashboardInner = (
  customerVariableName = '$customerId',
  referencesVariableName = '$references',
  typeVariableName = '$type'
) => /* GraphQL */ `
  getFootprintDashboard(references: ${referencesVariableName}, customerId: ${customerVariableName}, type: ${typeVariableName}) {
    calculatedImpact
    total_output
    reference
  }
`;

export const getFootprint = /* GraphQL */ `
  query getFootprint($references: [ID!]!, $customerId: ID!, $type: FootPrintType!) { ${getFootprintInner()} }
`;

export const getFootprintsBaselinesInterventions = /* GraphQL */ `
  query getFootprintDashboard($referencesBaselines: [ID!]!, $interventionReferences: [ID!]!, $customerId: ID!) { 
    baselines: ${getFootprintDashboardInner(
      '$customerId',
      '$referencesBaselines',
      'FARM_BASELINE'
    )} 
    interventions: ${getFootprintDashboardInner(
      '$customerId',
      '$interventionReferences',
      'FARM_INTERVENTION'
    )} 
  }
`;

export const listAPITokens = /* GraphQL */ `
  query listAPITokens {
    listAPITokens {
      items {
        key
      }
    }
  }
`;

export const listPartners = /* GraphQL */ `
  query listPartners {
    listPartners {
      items {
        id
        name
        allowedMutations
        allowedQueries
      }
    }
  }
`;

export const getMasterDataAndCFAccess = /* GraphQL */ `
  query getMasterDataAndCFAccess($customerId: ID!, $animalType: AnimalType!) { 
    ${getMasterDataInner('$customerId', '$animalType')},
    getCustomer(id: $customerId) {
      id
      feedsVisible
      reference
    }
  }
`;

export const calculateBovaer3NOP = /* GraphQL */ `
  query calculateBovaer3NOP($input: BovaerCalculationsInput!) {
    calculateBovaer3NOP(input: $input) {
      baseline
      reduction
      reductionPercentage
      warnings
    }
  }
`;
