export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AnimalInput = {
  /**  # Specify the origin facility ID of the animals that are coming from another facility in the system. */
  originId: Scalars['ID'];
  /**  # The number of animals. */
  quantity: Scalars['Float'];
  /**  # The transport of the animal input to go to the facility. */
  transport: Array<Transport>;
};

export enum AnimalInputType {
  /**  Only for Breeding stage */
  BreedingPullet = 'BREEDING_PULLET',
  /**  Only for Growing stage */
  Broiler = 'BROILER',
  /**  Only for Turkey Growing stage */
  BroilerTurkey = 'BROILER_TURKEY',
  /**  Only for Growing stage */
  DayOldChick = 'DAY_OLD_CHICK',
  /**  Only for Turkey Growing stage */
  DayOldTurkey = 'DAY_OLD_TURKEY',
  /**  Only for Hatching stage */
  HatchingEgg = 'HATCHING_EGG',
  /**  Only for Laying stage */
  LayingPullet = 'LAYING_PULLET'
}

export enum AnimalOutputType {
  Broiler = 'BROILER',
  BroilerTurkey = 'BROILER_TURKEY',
  ConsumptionEgg = 'CONSUMPTION_EGG',
  HatchingEgg = 'HATCHING_EGG',
  SpentHen = 'SPENT_HEN',
  SpentRooster = 'SPENT_ROOSTER'
}

export type AnimalProcessing = {
  /**  # A list of facilities for processing the animals. */
  facilities: Array<Facility>;
};

/**   POULTRY MODELS */
export type AnimalsIncoming = {
  /**  # The number of animals entering the stage */
  number: Scalars['Float'];
  /**  # The type of origin of the animals. */
  origin: InputAnimalOrigin;
  /**  # Specify an origin stage ID if the pigs are coming from another stage in the system, otherwise a background product is chosen. */
  originStageId?: InputMaybe<Scalars['ID']>;
  /**  # The distance of transport by truck, in kilometer. Default: 0 km. */
  transportDistance?: InputMaybe<Scalars['Float']>;
};

export type AuxiliaryInputs = {
  /**  # A list of chemicals that are used to clean the facility. */
  chemicals?: InputMaybe<Array<Chemical>>;
  /**  # A list of refrigerants that are used to cool the facility. */
  refrigerants?: InputMaybe<Array<Refrigerant>>;
  /**  # The amount of wastewater that will be discharged to the sewage treatment plant. In cubic meter per year. */
  wastewaterAmount: Scalars['Float'];
};

export type Bedding = {
  /**  # Total over the whole duration of the modelled period. In kilogram. */
  amount: Scalars['Float'];
  type: BeddingType;
};

/**   POULTRY ENUMS */
export enum BeddingType {
  /**  With a default nitrogen content of 5.5 g/kg */
  BarleyStraw = 'BARLEY_STRAW',
  /**  With a default nitrogen content of 5.8 g/kg */
  MaizeStover = 'MAIZE_STOVER',
  /**  With a default nitrogen content of 5.2 g/kg */
  OatStraw = 'OAT_STRAW',
  RiceHusk = 'RICE_HUSK',
  /**  With a default nitrogen content of 1.0 g/kg */
  SawDust = 'SAW_DUST',
  /**  With a default nitrogen content of 1.12 g/kg */
  SunflowerHusk = 'SUNFLOWER_HUSK',
  /**  With a default nitrogen content of 6.1 g/kg */
  WheatStraw = 'WHEAT_STRAW',
  /**  With a default nitrogen content of 1.0 g/kg */
  WoodShavings = 'WOOD_SHAVINGS'
}

export enum BreedingHousingType {
  Aviary = 'AVIARY',
  Cage = 'CAGE',
  FreeRange = 'FREE_RANGE',
  FurnishedCage = 'FURNISHED_CAGE'
}

export type BreedingStage = {
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # A list of all the stage emissions that need to be changed by intervention. */
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # End date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  endDate: Scalars['String'];
  /**  # Each utility, bedding material and other material may be specified on either farm or stage level, where stage level is preferred. */
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # Percentage of time spent in free range. */
  freeRangeTimePercent?: InputMaybe<Scalars['Float']>;
  /**  # Diet of the hens. */
  henFeedingProgram: FeedingProgram;
  /**  # Description of the hens entering the stage. */
  henInput: StageInput;
  /**  # Housing type for the growing stage. */
  housingType?: InputMaybe<BreedingHousingType>;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**   List of the used management systems. For Growing stage, only DAILY_SPREAD_SOLID and POULTRY_MANURE_WITH_OR_WITHOUT_LITTER are allowed. */
  manureManagementSystems?: InputMaybe<Array<InputMaybe<ManureManagementSystem>>>;
  /**  # A custom stage name */
  name?: InputMaybe<Scalars['String']>;
  /**  # Description of all the animals leaving the stage. */
  output: BreedingStageOutput;
  /**  # Diet of the roosters. */
  roosterFeedingProgram: FeedingProgram;
  /**  # Description of the roosters entering the stage. */
  roosterInput: StageInput;
  /**  # Start date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  startDate: Scalars['String'];
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type BreedingStageOutput = {
  /**  # Average weight of eggs if kg/egg. */
  averageWeight: Scalars['Float'];
  /**  # The lca will be calculated, even if no animals are sold. Default = False */
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # Price per egg. */
  price?: InputMaybe<Scalars['Float']>;
  /**  # Average weight of spent hen in kg/animal. */
  spentHenAverageWeight?: InputMaybe<Scalars['Float']>;
  /**  # The lca will be calculated, even if no animals are sold. Default = False */
  spentHenCalculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # Mortality rate of hens. */
  spentHenMortalityPercent?: InputMaybe<Scalars['Float']>;
  /**  # Price of spent hen per head. */
  spentHenPrice?: InputMaybe<Scalars['Float']>;
  /**
   *  # SPENT HENS
   * # Total number of spent hens sold.
   */
  spentHenSold: Scalars['Float'];
  /**  # Total number of spent hens to facility. */
  spentHenToFacility: Scalars['Float'];
  /**  # Weight at mortality of hens in kg/animal. */
  spentHenWeightAtMortality?: InputMaybe<Scalars['Float']>;
  /**  # Average weight of spent roosters in kg/animal. */
  spentRoosterAverageWeight?: InputMaybe<Scalars['Float']>;
  /**  # The lca will be calculated, even if no animals are sold. Default = False */
  spentRoosterCalculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # Mortality rate of roosters. */
  spentRoosterMortalityPercent?: InputMaybe<Scalars['Float']>;
  /**  # Price of spent rooster per head. */
  spentRoosterPrice?: InputMaybe<Scalars['Float']>;
  /**
   *  # SPENT ROOSTERS
   * # Total number of spent roosters sold.
   */
  spentRoosterSold: Scalars['Float'];
  /**  # Total number of spent roosters to facility. */
  spentRoosterToFacility: Scalars['Float'];
  /**  # Weight at mortality of roosters in kg/animal. */
  spentRoosterWeightAtMortality?: InputMaybe<Scalars['Float']>;
  /**  # Total number of eggs sold. */
  totalSold: Scalars['Float'];
  /**  # Total number of eggs going to the next stage or facility. */
  totalToStage: Scalars['Float'];
};

export type Chemical = {
  /**  # The used amount of the chemical. In kilogram per year. */
  amount: Scalars['Float'];
  type: ChemicalType;
};

export enum ChemicalType {
  ChemicalOrganic = 'CHEMICAL_ORGANIC',
  EthanolFromMaize = 'ETHANOL_FROM_MAIZE',
  Glycerine = 'GLYCERINE',
  HydrochloricAcid = 'HYDROCHLORIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  Isopropanol = 'ISOPROPANOL',
  NitrogenLiquid = 'NITROGEN_LIQUID',
  OxygenLiquid = 'OXYGEN_LIQUID',
  PhosphoricAcid = 'PHOSPHORIC_ACID',
  PotassiumHydroxide = 'POTASSIUM_HYDROXIDE',
  SodiumChlorate = 'SODIUM_CHLORATE',
  SodiumChlorideAsBrineSolution = 'SODIUM_CHLORIDE_AS_BRINE_SOLUTION',
  SodiumHydroxide = 'SODIUM_HYDROXIDE',
  SodiumHypochlorite = 'SODIUM_HYPOCHLORITE',
  SulfuricAcid = 'SULFURIC_ACID'
}

export enum ClimateType {
  BorealDry = 'BOREAL_DRY',
  BorealMoist = 'BOREAL_MOIST',
  CoolTemperateDry = 'COOL_TEMPERATE_DRY',
  CoolTemperateMoist = 'COOL_TEMPERATE_MOIST',
  TropicalDry = 'TROPICAL_DRY',
  TropicalMoist = 'TROPICAL_MOIST',
  /**  climate types as specified in in the IPCC */
  TropicalMontane = 'TROPICAL_MONTANE',
  TropicalWet = 'TROPICAL_WET',
  WarmTemperateDry = 'WARM_TEMPERATE_DRY',
  WarmTemperateMoist = 'WARM_TEMPERATE_MOIST'
}

export type CompoundFeed = {
  compoundFeedId: Scalars['ID'];
  energyCarriers?: InputMaybe<Array<EnergyCarrier>>;
  feedmillLocation: Scalars['String'];
  ingredientUses: Array<IngredientUse>;
  name: Scalars['String'];
  outboundTransport?: InputMaybe<Array<Transport>>;
  water?: InputMaybe<Array<Water>>;
};

export type CompoundFeedUse = {
  /**  # Weight percentage ash content as a fraction of the feed weight. */
  ashContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage crude protein content as a fraction of the feed weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # Weight percentage dry matter content as a fraction of the feed weight. */
  dryMatterContent?: InputMaybe<Scalars['Float']>;
  /**  # Gross energy content, in MJ/kg. */
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # ID to find compound feed in compound feed API. */
  id: Scalars['ID'];
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  /**  # Digestible energy content, in MJ/kg. */
  metabolizableEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight permille phosphorus content as a fraction of the feed weight (mg/kg). */
  pPermille?: InputMaybe<Scalars['Float']>;
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram. */
  quantity: Scalars['Float'];
};

/**   APS ENUMS */
export enum DatabaseFoundation {
  Afp6 = 'AFP6',
  Gfli = 'GFLI'
}

export type EmissionMitigation = {
  /**  # Increase in %, use a negative value to decrease the calculated emission value. */
  change: Scalars['Float'];
  emissionType: EmissionType;
};

/**   RESULT MODELS */
export type EmissionResult = {
  __typename?: 'EmissionResult';
  magnitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
};

export enum EmissionType {
  AmmoniaHousing = 'AMMONIA_HOUSING',
  AmmoniaStorage = 'AMMONIA_STORAGE',
  DinitrogenStorage = 'DINITROGEN_STORAGE',
  /**  MMS emissions */
  MethaneMms = 'METHANE_MMS',
  NitrogenOxidesStorage = 'NITROGEN_OXIDES_STORAGE',
  NitrousOxideDirect = 'NITROUS_OXIDE_DIRECT',
  NitrousOxideLeaching = 'NITROUS_OXIDE_LEACHING',
  NitrousOxideVolatilization = 'NITROUS_OXIDE_VOLATILIZATION',
  NonMethaneVolatileOrganicCompounds = 'NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS',
  /**  Stage emissions */
  Pm2_5 = 'PM2_5',
  Pm10 = 'PM10',
  Tsp = 'TSP'
}

export type EnergyCarrier = {
  /**  # The amount of the energy carrier. The unit is based on the EnergyCarrierTypeAps per year. */
  amount: Scalars['Float'];
  type: EnergyCarrierType;
};

export enum EnergyCarrierType {
  /**  The burning of coal, in Mega Joule. */
  Coal = 'COAL',
  /**  The burning of diesel, in Mega Joule. */
  Diesel = 'DIESEL',
  /**  The electricity use from the grid, in kWh. */
  Electricity = 'ELECTRICITY',
  /**  The electricity use from the solar panels, in kWh. */
  ElectricityPhotovoltaic = 'ELECTRICITY_PHOTOVOLTAIC',
  /**  The electricity use from the wind, in kWh. */
  ElectricityWind = 'ELECTRICITY_WIND',
  /**  The burning of gasoline, in Mega Joule. */
  Gasoline = 'GASOLINE',
  HeavyFuelOil = 'HEAVY_FUEL_OIL',
  /**  The burning of natural gas, in Mega Joule. */
  NaturalGas = 'NATURAL_GAS',
  /**  The burning of propane or Liquefied Petroleum Gas, in Mega Joule. */
  PropaneOrLpg = 'PROPANE_OR_LPG'
}

export type Facility = {
  /**  # The auxiliary inputs for the entire facility. */
  auxiliaryInputs: AuxiliaryInputs;
  /**  # The utilities used in the facility, divided in Energy Carriers and Water */
  energyCarriers: Array<EnergyCarrier>;
  /**  # The location of the facility, typically the country. */
  geography: Scalars['String'];
  /**  # A UUID that should be provided for each facility, in order to link the facilities between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # A description of all the animals entering the facility. */
  inputs: FacilityInputs;
  name?: InputMaybe<Scalars['String']>;
  /**  # A description of all the animals leaving the facility. */
  outputs: FacilityOutputs;
  water: Array<Water>;
};

export type FacilityInputs = {
  /**  # A list of animal inputs to go into the facility. */
  animalInputs: Array<AnimalInput>;
  /**  # The average weight of all animals that go into the facility in kg/animal. */
  averageWeight: Scalars['Float'];
  /**  # Required when origin is a stage */
  name?: InputMaybe<AnimalOutputType>;
  /**  # The total live weight entering the entire facility, this can be more than the target animal input flow. In kilogram per year. */
  totalLiveWeightEntering: Scalars['Float'];
};

export type FacilityOutputs = {
  cat1And2MaterialAndWaste: OutputSpecification;
  cat3HidesAndSkins: OutputSpecification;
  cat3SlaughterByProducts: OutputSpecification;
  foodGradeBones: OutputSpecification;
  foodGradeFat: OutputSpecification;
  freshMeatAndEdibleOffal: OutputSpecification;
};

export type Farm = {
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  breedingStages?: InputMaybe<Array<InputMaybe<BreedingStage>>>;
  /**  # The IPCC climate type, it can be found on the map of IPCC climate types */
  climateType: ClimateType;
  /**
   *  # Each utility, bedding material and other material may be specified on either farm or stage level, where stage level is preferred. If not specified a default level of 0 is used.
   * # If a utility is specified on farm level, it is allocated to the stages where it is not specified, based on the feed intake.
   * # The farm utilities should not include the hatching utilities
   */
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # The final period-of-assessment date, in the format "yyyy-mm-dd". This date should be later than all of the stage end dates. */
  finalPeriodOfAssessmentDate?: InputMaybe<Scalars['String']>;
  /**  # The location of the farm, typically the country. */
  geography: Scalars['String'];
  /**  # At least one stage is required. */
  growingStages?: InputMaybe<Array<InputMaybe<GrowingStage>>>;
  hatchingStages?: InputMaybe<Array<InputMaybe<HatchingStage>>>;
  /**  # The initial period-of-assessment date, in the format "yyyy-mm-dd". This date should be preceding all of the stage start dates. */
  initialPeriodOfAssessmentDate?: InputMaybe<Scalars['String']>;
  layingStages?: InputMaybe<Array<InputMaybe<LayingStage>>>;
  turkeyGrowingStages?: InputMaybe<Array<InputMaybe<GrowingStage>>>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type FeedingProgram = {
  /**  # At least one of compound feed or single ingredient is required. */
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedUse>>>;
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export type ForegroundTreeResult = {
  __typename?: 'ForegroundTreeResult';
  emissionResults?: Maybe<Array<Maybe<StageEmissionResult>>>;
  /**  # json dump of BlonkSchema Dataset */
  lci?: Maybe<Scalars['String']>;
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export enum GrowingHousingType {
  Cage = 'CAGE',
  FloorRaised = 'FLOOR_RAISED',
  FreeRange = 'FREE_RANGE'
}

export type GrowingStage = {
  /**  # Average length of production round in days. */
  averageProductionRoundLength: Scalars['Float'];
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # A list of all the stage emissions that need to be changed by intervention. */
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # End date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  endDate: Scalars['String'];
  /**  # Each utility, bedding material and other material may be specified on either farm or stage level, where stage level is preferred. */
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # Diet of the animals. */
  feedingProgram: FeedingProgram;
  /**  # Percentage of time spent in free range. */
  freeRangeTimePercent?: InputMaybe<Scalars['Float']>;
  /**  # Housing type for the growing stage. */
  housingType?: InputMaybe<GrowingHousingType>;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # Description of all the animals entering the stage. */
  input: StageInput;
  /**   List of the used management systems. For Growing stage, only DAILY_SPREAD_SOLID and POULTRY_MANURE_WITH_OR_WITHOUT_LITTER are allowed. */
  manureManagementSystems?: InputMaybe<Array<InputMaybe<ManureManagementSystem>>>;
  /**  # A custom stage name */
  name?: InputMaybe<Scalars['String']>;
  /**  # Description of all the animals leaving the stage. */
  output: GrowingStageOutput;
  /**  # Start date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  startDate: Scalars['String'];
  /**  # If the impact period does not start exactly on the start of a production round and ends with one as well. */
  stockChange?: InputMaybe<GrowingStockChange>;
  /**  # Total number of days when the stage was empty. */
  totalEmptyDays?: InputMaybe<Scalars['Float']>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type GrowingStageOutput = {
  /**  # Average weight of animals in kg/animal. */
  averageWeight: Scalars['Float'];
  /**  # The lca will be calculated, even if no animals are sold. Default = False */
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # Mortality rate. */
  mortalityPercent?: InputMaybe<Scalars['Float']>;
  /**  # Price per kg. */
  price?: InputMaybe<Scalars['Float']>;
  /**  # Total number of animals sold. */
  totalSold: Scalars['Float'];
  /**  # Total number of animals going to the next stage or facility. */
  totalToStage: Scalars['Float'];
  /**  # Weight at mortality in kg/animal. */
  weightAtMortality?: InputMaybe<Scalars['Float']>;
};

export type GrowingStockChange = {
  /**  # Average weight of animals in kg/animal that remains present at the final stage date. */
  finalAnimalAverageWeight?: InputMaybe<Scalars['Float']>;
  /**  # Number of animals that remains present at the final stage date. */
  finalAnimalStock?: InputMaybe<Scalars['Float']>;
  /**  #  Average weight of animals in kg/animal already present at the initial stage date. */
  initialAnimalAverageWeight?: InputMaybe<Scalars['Float']>;
  /**  # Number of animals already present at the initial stage date. */
  initialAnimalStock?: InputMaybe<Scalars['Float']>;
};

export type HatchingStage = {
  /**  # End date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  endDate: Scalars['String'];
  /**
   *  # These utilities are not part of the total given for the whole farm and won't be used in the allocation of utilities.
   *  At least one energy carrier should be given
   */
  energyCarriers: Array<EnergyCarrier>;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # Description of all the animals entering the stage. */
  input: StageInput;
  /**  # A custom stage name */
  name?: InputMaybe<Scalars['String']>;
  /**  # Description of all the animals leaving the stage. */
  output: HatchingStageOutput;
  /**  # Start date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  startDate: Scalars['String'];
  /**  # If the impact period does not start exactly on the start of a production round and ends with one as well. */
  stockChange?: InputMaybe<HatchingStockChange>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type HatchingStageOutput = {
  /**  # Average weight of animals in kg/animal. */
  averageWeight: Scalars['Float'];
  /**  # The lca will be calculated, even if no animals are sold. Default = False */
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # Price of losses per kg. */
  lossesPrice?: InputMaybe<Scalars['Float']>;
  /**  # Price per animal. */
  price?: InputMaybe<Scalars['Float']>;
  /**  # Total number of animals sold. */
  totalSold: Scalars['Float'];
  /**  # Total number of animals going to the next stage. */
  totalToStage: Scalars['Float'];
  /**  # Total weight of egg shell sold or waste. */
  totalWeightEggShellSoldOrWaste?: InputMaybe<Scalars['Float']>;
  /**  # Total weight of losses in kg. */
  totalWeightLosses: Scalars['Float'];
};

export type HatchingStockChange = {
  /**  # Number of animals that remains present at the final stage date. */
  finalAnimalStock?: InputMaybe<Scalars['Float']>;
  /**  # Number of animals already present at the initial stage date. */
  initialAnimalStock?: InputMaybe<Scalars['Float']>;
};

export type Impact = {
  category: Scalars['String'];
  quantity: Scalars['Float'];
  unit: Scalars['String'];
};

export type ImpactAssessmentResult = {
  __typename?: 'ImpactAssessmentResult';
  category?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit?: Maybe<Scalars['String']>;
};

export type ImportedImpact = {
  id: Scalars['ID'];
  impacts: Array<Impact>;
  productName: Scalars['String'];
};

export type Ingredient = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type IngredientUse = {
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  inboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  ingredient: Ingredient;
  /**  # Unit: kg */
  quantity: Scalars['Float'];
};

export enum InputAnimalOrigin {
  /**  Animals bought externally, the impact of these animals is derived from background data. */
  BackgroundData = 'BACKGROUND_DATA',
  /**  Animals coming from a stage modelled in the same production system. */
  ProductionSystem = 'PRODUCTION_SYSTEM'
}

export enum LayingHousingType {
  Aviary = 'AVIARY',
  Cage = 'CAGE',
  FreeRange = 'FREE_RANGE',
  FurnishedCage = 'FURNISHED_CAGE'
}

export type LayingStage = {
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  /**  # A list of all the stage emissions that need to be changed by intervention. */
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # End date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  endDate: Scalars['String'];
  /**  # Each utility, bedding material and other material may be specified on either farm or stage level, where stage level is preferred. */
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  /**  # Diet of the animals. */
  feedingProgram: FeedingProgram;
  /**  # Percentage of time spent in free range. */
  freeRangeTimePercent?: InputMaybe<Scalars['Float']>;
  /**  # Housing type for the growing stage. */
  housingType?: InputMaybe<LayingHousingType>;
  /**  # A UUID that should be provided for each stage, in order to link the stages between which animals are exchanged. */
  id: Scalars['ID'];
  /**  # Description of all the animals entering the stage. */
  input: StageInput;
  /**   List of the used management systems. For Growing stage, only DAILY_SPREAD_SOLID and POULTRY_MANURE_WITH_OR_WITHOUT_LITTER are allowed. */
  manureManagementSystems?: InputMaybe<Array<InputMaybe<ManureManagementSystem>>>;
  /**  # A custom stage name */
  name?: InputMaybe<Scalars['String']>;
  /**  # Description of all the animals leaving the stage. */
  output: LayingStageOutput;
  /**  # Start date of the stage period, in the format "yyyy-mm-dd". This is used for calculation of the stage duration. */
  startDate: Scalars['String'];
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type LayingStageOutput = {
  /**  # Average weight of eggs in kg/egg. */
  averageWeight: Scalars['Float'];
  /**  # The lca will be calculated, even if no animals are sold. Default = False */
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # Price per egg. */
  price?: InputMaybe<Scalars['Float']>;
  /**  # Average weight of spent hen in kg/animal. */
  spentHenAverageWeight?: InputMaybe<Scalars['Float']>;
  /**  # The lca will be calculated, even if no animals are sold. Default = False */
  spentHenCalculateLca?: InputMaybe<Scalars['Boolean']>;
  /**  # Mortality rate of hens. */
  spentHenMortalityPercent?: InputMaybe<Scalars['Float']>;
  /**  # Price of spent hen per head. */
  spentHenPrice?: InputMaybe<Scalars['Float']>;
  /**  # Total number of spent hens sold. */
  spentHenSold: Scalars['Float'];
  /**  # Total number of spent hens to facility. */
  spentHenToFacility: Scalars['Float'];
  /**  # Weight at mortality of hens in kg/animal. */
  spentHenWeightAtMortality?: InputMaybe<Scalars['Float']>;
  /**  # Total number of eggs sold. */
  totalSold: Scalars['Float'];
  /**  # Total number of eggs going to the next stage. */
  totalToStage: Scalars['Float'];
};

export type MmsEmissionResult = {
  __typename?: 'MMSEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsType?: Maybe<Scalars['String']>;
};

export type ManureManagementSystem = {
  /**  # A list of all the manure emissions that need to be changed by intervention. */
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  /**  # Weight percentage of the total manure of a form (solid or liquid/slurry) that goes into this manure management system. */
  share: Scalars['Float'];
  /**  # The type of manure management system. */
  type: ManureManagementSystemType;
};

export enum ManureManagementSystemType {
  /**  Aerobic treatment; Forced aeration systems */
  AerobicForced = 'AEROBIC_FORCED',
  /**  Aerobic treatment; Natural aeration systems */
  AerobicNatural = 'AEROBIC_NATURAL',
  /**  Anaerobic digester; High leakage, low quality technology, high quality gastight storage technology */
  AnaerobicHighLeakHighGastight = 'ANAEROBIC_HIGH_LEAK_HIGH_GASTIGHT',
  /**  Anaerobic digester; High leakage, low quality technology, low quality gastight storage technology */
  AnaerobicHighLeakLowGastight = 'ANAEROBIC_HIGH_LEAK_LOW_GASTIGHT',
  /**  Anaerobic digester; High leakage, low quality technology, open storage */
  AnaerobicHighLeakOpen = 'ANAEROBIC_HIGH_LEAK_OPEN',
  /**  Anaerobic digester; Low leakage, High quality gastight storage, best complete industrial technology */
  AnaerobicLowLeakHighGastight = 'ANAEROBIC_LOW_LEAK_HIGH_GASTIGHT',
  /**  Anaerobic digester; Low leakage, High quality industrial technology, low quality gastight storage technology */
  AnaerobicLowLeakLowGastight = 'ANAEROBIC_LOW_LEAK_LOW_GASTIGHT',
  /**  Anaerobic digester; Low leakage, High quality industrial technology, open storage */
  AnaerobicLowLeakOpen = 'ANAEROBIC_LOW_LEAK_OPEN',
  /**  Composting; Intensive Windrow (Frequent turning) */
  CompostingIntensiveWindrow = 'COMPOSTING_INTENSIVE_WINDROW',
  /**  Composting; In-Vessel */
  CompostingInVessel = 'COMPOSTING_IN_VESSEL',
  /**  Composting; Passive windrow (infrequent turning) */
  CompostingPassiveWindrow = 'COMPOSTING_PASSIVE_WINDROW',
  /**  Composting; Static Pile (Forced aeration) */
  CompostingStaticPile = 'COMPOSTING_STATIC_PILE',
  DailySpreadSlurry = 'DAILY_SPREAD_SLURRY',
  /**  Daily spread */
  DailySpreadSolid = 'DAILY_SPREAD_SOLID',
  /**  Liquid/Slurry; Cover */
  LiquidCover_1Month = 'LIQUID_COVER_1MONTH',
  LiquidCover_3Month = 'LIQUID_COVER_3MONTH',
  LiquidCover_4Month = 'LIQUID_COVER_4MONTH',
  LiquidCover_6Month = 'LIQUID_COVER_6MONTH',
  LiquidCover_12Month = 'LIQUID_COVER_12MONTH',
  /**  Liquid/Slurry; With natural crust cover */
  LiquidCrust_1Month = 'LIQUID_CRUST_1MONTH',
  LiquidCrust_3Month = 'LIQUID_CRUST_3MONTH',
  LiquidCrust_4Month = 'LIQUID_CRUST_4MONTH',
  LiquidCrust_6Month = 'LIQUID_CRUST_6MONTH',
  LiquidCrust_12Month = 'LIQUID_CRUST_12MONTH',
  /**  Liquid/Slurry; Without natural crust cover */
  LiquidNoCrust_1Month = 'LIQUID_NO_CRUST_1MONTH',
  LiquidNoCrust_3Month = 'LIQUID_NO_CRUST_3MONTH',
  LiquidNoCrust_4Month = 'LIQUID_NO_CRUST_4MONTH',
  LiquidNoCrust_6Month = 'LIQUID_NO_CRUST_6MONTH',
  LiquidNoCrust_12Month = 'LIQUID_NO_CRUST_12MONTH',
  /**  Pit storage below animal confinements */
  Pit_1Month = 'PIT_1MONTH',
  Pit_3Month = 'PIT_3MONTH',
  Pit_4Month = 'PIT_4MONTH',
  Pit_6Month = 'PIT_6MONTH',
  Pit_12Month = 'PIT_12MONTH',
  PoultryManureWithOrWithoutLitter = 'POULTRY_MANURE_WITH_OR_WITHOUT_LITTER',
  /**  Solid storage; Additives */
  SolidAdditives = 'SOLID_ADDITIVES',
  /**  Solid storage; Bulking agent addition */
  SolidBulking = 'SOLID_BULKING',
  /**  Solid storage; Covered/compacted */
  SolidCovered = 'SOLID_COVERED',
  /**  Solid storage */
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON'
}

export type OutputSpecification = {
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Packaging>;
  /**
   *  # The use_quantity of the specific output. Used for economic allocation. In euro per kg.
   * # Default values are available for pigs and cattle, it is a required field for the other animal types.
   */
  price?: InputMaybe<Scalars['Float']>;
  /**  # The weight of the specific output. In kilogram. */
  weight: Scalars['Float'];
};

export type Packaging = {
  primaryPackaging?: InputMaybe<Array<PrimaryPackaging>>;
  secondaryPackaging?: InputMaybe<Array<SecondaryPackaging>>;
  tertiaryPackaging?: InputMaybe<Array<TertiaryPackaging>>;
};

/**   ANIMAL PROCESSING MODELS */
export type PrimaryPackaging = {
  /**  # The amount of primary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: PrimaryPackagingType;
};

/**   ANIMAL PROCESSING ENUMS */
export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  Hdpe = 'HDPE',
  Ldpe = 'LDPE',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE'
}

/**  # The impacts and emissions associated with a product */
export type ProductResultNode = {
  __typename?: 'ProductResultNode';
  /**  # Summation of impacts occurring in supply chain (i.e. LCA results) */
  aggregatedImpactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  /**  # Impacts occurring in this node */
  impactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  productName?: Maybe<Scalars['String']>;
  /**  # A list of tags that can be used for grouping of the results */
  tags?: Maybe<Array<Maybe<Tag>>>;
  /**  # Product nodes feeding into this node */
  upstreamProductNodes?: Maybe<Array<ProductResultNode>>;
};

/**   PRODUCTION SYSTEM INPUT */
export type ProductionSystem = {
  /**  # When the animals in the modelled production system will be processed too, the animal processing can be defined. */
  animalProcessing?: InputMaybe<AnimalProcessing>;
  /**  # A list of farms in the modelled production system. A farm is in a single location. */
  farms: Array<InputMaybe<Farm>>;
};

export type ProductionSystemInput = {
  /**  # A list of compound feeds that are used in the production system */
  compoundFeeds?: InputMaybe<Array<CompoundFeed>>;
  /**  # The foundational database that must be used for the impact calculations */
  databaseFoundation: DatabaseFoundation;
  /**  # Precalculated impacts to be used in LCA calculation */
  importedImpacts?: InputMaybe<Array<InputMaybe<ImportedImpact>>>;
  /**  # The poultry production system */
  productionSystem: ProductionSystem;
  /**  # Split ecotoxicity in EF3.1. Default is True. */
  splitEcotoxicity?: InputMaybe<Scalars['Boolean']>;
};

export type ProductionSystemResult = {
  __typename?: 'ProductionSystemResult';
  /**  # Emissions occurring in this production system */
  emissionResults?: Maybe<Array<StageEmissionResult>>;
  /**  # A list of climate impact assessment results for all stages with an external output */
  impactResults?: Maybe<Array<ProductResultNode>>;
  /**  # None code terminating warnings */
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type Query = {
  __typename?: 'Query';
  getLciResultByProductionSystemInput?: Maybe<ForegroundTreeResult>;
  getResultByProductionSystemInput?: Maybe<ProductionSystemResult>;
  getValidationErrorCodes?: Maybe<Array<Maybe<ValidationErrorCode>>>;
};


export type QueryGetLciResultByProductionSystemInputArgs = {
  productionSystemInput?: InputMaybe<ProductionSystemInput>;
};


export type QueryGetResultByProductionSystemInputArgs = {
  productionSystemInput: ProductionSystemInput;
};

export type Refrigerant = {
  /**  # The used amount of the refrigerant. In kilogram per year. */
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export enum RefrigerantType {
  Ammonia = 'AMMONIA',
  CarbonDioxide = 'CARBON_DIOXIDE',
  Propane = 'PROPANE',
  RefrigerantHfc = 'REFRIGERANT_HFC'
}

export type SecondaryPackaging = {
  /**  # The amount of secondary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: SecondaryPackagingType;
};

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX'
}

export type SingleIngredientUse = {
  /**  # Weight percentage ash content as a fraction of the feed weight. */
  ashContent?: InputMaybe<Scalars['Float']>;
  /**  # Weight percentage crude protein content as a fraction of the feed weight. */
  crudeProteinContent: Scalars['Float'];
  /**  # Weight percentage dry matter content as a fraction of the feed weight. */
  dryMatterContent: Scalars['Float'];
  /**  # Gross energy content, in MJ/kg. */
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  /**  # ID of impacts to import. */
  importedImpactId?: InputMaybe<Scalars['ID']>;
  /**  # The ingredient name and country combination that exists in the database. */
  ingredient: Ingredient;
  /**  # Digestible energy content, in MJ/kg. */
  metabolizableEnergyContent: Scalars['Float'];
  /**  # Weight permille phosphorus content as a fraction of the feed weight (mg/kg). */
  pPermille: Scalars['Float'];
  /**  # Total of the whole herd over the whole duration of the stage, in kilogram. */
  quantity: Scalars['Float'];
  /**  # The transport distance by truck used to move the feed to the farm. */
  transportationDistance?: InputMaybe<Scalars['Float']>;
};

export type StageEmissionResult = {
  __typename?: 'StageEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsEmissionResults?: Maybe<Array<MmsEmissionResult>>;
  stageId?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
};

export type StageInput = {
  /**  # Average weight of animals in kg/animal. */
  animalAverageWeight: Scalars['Float'];
  /**  # The input animal type. */
  animalType: AnimalInputType;
  /**  # A list of the animals coming to the stage. */
  animals: Array<InputMaybe<AnimalsIncoming>>;
};

export type Tag = {
  __typename?: 'Tag';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/**   COMPOUND FEED MODELS */
export type TagInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TertiaryPackaging = {
  /**  # The amount of tertiary packaging material. In kilogram. */
  amount: Scalars['Float'];
  type: TertiaryPackagingType;
};

export enum TertiaryPackagingType {
  PlasticFilm = 'PLASTIC_FILM',
  WoodPallet = 'WOOD_PALLET'
}

export type Transport = {
  /**  # In kilometer. */
  distance: Scalars['Float'];
  mode: TransportMode;
};

export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK'
}

export type ValidationErrorCode = {
  __typename?: 'ValidationErrorCode';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Warning = {
  __typename?: 'Warning';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/**   APS MODELS */
export type Water = {
  /**  # The amount water. In cubic meter per year. */
  amount: Scalars['Float'];
  type: WaterType;
};

export enum WaterType {
  /**  The water use, in cubic meter. */
  Water = 'WATER'
}
