import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { Box } from "@material-ui/core";
import PFInterventionEmissionsFormDialog from "./PFInterventionEmissionsFormDialog";
import { InterventionStageProps } from "../CommonDataParts/InterventionTypes";
import { PigIntervention } from "../../../../models/Intervention";
import { PigFatteningBaseline } from "../../../../models/Baseline";
import PFInterventionOutputFormDialog from "./PFInterventionOutputFormDialog";
import { PigBaseline } from "../../../../models/Baseline/PigBaseline";
import PBInterventionOutputFormDialog from "./PBInterventionOutputFormDialog";
import PBInterventionInputFormDialog from "./PBInterventionInputFormDialog";
import PFInterventionInputFormDialog from "./PFInterventionInputFormDialog";
import { StageType } from "../../../../../../graphql/types";
import InterventionHousingManureFormDialog from "./InterventionHousingManureFormDialog";
import FeedsInterventionsFormDialogV2 from "../CommonDataParts/FeedsInterventionsFormDialogV2";
import StageButtonBox from "../../StageButtonBox_v2";
import { ListEntry, PigFarms } from "../../common";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";

interface PigInterventionStageProps
  extends Omit<InterventionStageProps, "manureManagementSystems"> {
  intervention: PigIntervention | null;
  baseline: PigBaseline;
  origins: ListEntry[];
  farms: PigFarms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
}

enum StageDialogType {
  INPUT = "input",
  FEED = "feed",
  MANURE = "manure",
  EMISSIONS = "emissions",
  OUTPUT = "output",
}

const PigInterventionStages = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  stageIndex,
  baseline,
  singleIngredients,
  compoundFeeds,
  origins,
  intervention,
  formType,
}: PigInterventionStageProps) => {
  const [activeDialog, setActiveDialog] = useState<StageDialogType | null>(
    null
  );
  const intl = useIntl();
  const showDialog = (dialog: StageDialogType | null) => {
    setActiveDialog(dialog);
  };

  const fc = useFormContext();
  const stagePrefix = `stages.${stageIndex}`;

  // console.log('baseline: ', baseline);
  const stageType = baseline?.stages?.[stageIndex]?.type;

  return (
    <>
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        value={`${baseline?.stages?.[stageIndex]?.id}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        value={`${baseline?.stages?.[stageIndex]?.name}`}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.type`}
        value={stageType!}
      />
      <input
        ref={fc.register()}
        type="hidden"
        name={`${stagePrefix}.index`}
        value={stageIndex}
      />
      {stageType === StageType.Fattening && activeDialog === "input" && (
        <PFInterventionInputFormDialog
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === "input"}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          intervention={intervention as unknown as PigIntervention}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}
      {stageType === StageType.Breeding && activeDialog === "input" && (
        <PBInterventionInputFormDialog
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === "input"}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}
      {activeDialog === "manure" && (
        <InterventionHousingManureFormDialog
          formVisible={activeDialog === "manure"}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}

      {activeDialog === "feed" && (
        <FeedsInterventionsFormDialogV2
          formVisible={activeDialog === "feed"}
          handleCancel={() => setActiveDialog(null)}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          baseline={baseline}
          origins={origins}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          intervention={intervention as unknown as PigIntervention}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}

      {activeDialog === "emissions" && (
        <PFInterventionEmissionsFormDialog
          formVisible={activeDialog === "emissions"}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline as unknown as PigFatteningBaseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}

      {stageType === StageType.Fattening && activeDialog === "output" && (
        <PFInterventionOutputFormDialog
          formVisible={activeDialog === "output"}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}

      {stageType === StageType.Breeding && activeDialog === "output" && (
        <PBInterventionOutputFormDialog
          formVisible={activeDialog === "output"}
          handleCancel={() => setActiveDialog(null)}
          baseline={baseline}
          // baseline={baselineMOCK as unknown as PigBaseline}
          stageIndex={stageIndex}
          formType={formType}
        />
      )}

      <Box
        style={{ display: "flex", width: "100%" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          // completness={getCompleteness ? getCompleteness(fc.getValues, `${stagePrefix}.stageData.input`) : 0 }
          error={
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog(StageDialogType.INPUT)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.STAGE.FEED"
          description="SUSTELL.STAGE.INPUT_DATA"
          // completness={getCompleteness ? getCompleteness(fc.getValues, `${stagePrefix}.stageData.feed`) : 0 }
          error={
            fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog(StageDialogType.FEED)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="general/building-06"
          // completness={getCompleteness ? getCompleteness(fc.getValues, `${stagePrefix}.stageData.housing`) : 0 }
          descriptionTitle="SUSTELL.STAGE.HOUSING"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={
            fc.errors?.stages &&
            fc.errors?.stages[stageIndex]?.stageData?.housing
          }
          handleOpen={() => showDialog(StageDialogType.MANURE)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          // completness={getCompleteness ? getCompleteness(fc.getValues, `${stagePrefix}.stageData.output`) : 0 }
          error={
            fc.errors?.stages &&
            fc.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog(StageDialogType.OUTPUT)}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="maps-travel/globe-04"
          optional
          descriptionTitle="SUSTELL.STAGE.MITTIGATION"
          description="SUSTELL.STAGE.INPUT_DATA"
          // error={fc.errors?.stages && fc.errors?.stages[stageIndex]?.stageData?.emissions}
          handleOpen={() => showDialog(StageDialogType.EMISSIONS)}
          error={
            fc.errors?.stages &&
            fc.errors?.stages[stageIndex]?.stageData?.emissions
          }
        />
      </Box>
    </>
  );
};

export default PigInterventionStages;
