import { Maybe } from 'graphql/jsutils/Maybe';
import { AnimalType } from '../../../../graphql/types';
import { isBeef, isDairy, isMarineFish, isPig, isPoultry, isShrimp } from '../../helpers/animals';

export enum FormType {
  Add = 'add',
  Edit = 'edit',
  View = 'view',
}

export enum DatasetType {
  BASELINE = 'baseline',
  INTERVENTION = 'intervention',
}

/** Common props for all baseline dialogs */
export interface BaselineDialogProps {
  formType: FormType;
  itemIndex: number;
  baseline?: any;
  formVisible?: boolean;
  handleCancel: (param?: string | null) => void;
  handleSave?: (param?: string | null) => void;
  manureManagementSystems?: [SelectEntry];
  variant?: string;
}

export interface PoultryFarms {
  farmId: string;
  farmName: string;
  productionProcessId: string;
  productionProcessName: string;
  stageId: string;
  stageName: string;
  stageType: string;
  averageWeight: string;
  reference: string;
  animalType: string;
  databaseFoundation: string;
  benchmark: boolean;
}

export interface PigFarms extends PoultryFarms {
  benchmark: boolean;
  averageWeightPiglets: string,
  averageWeightPigs: string,
  averageAgePigs: string,
}

export interface ShrimpFarms {
  farmId: string;
  farmName: string;
  productionProcessId: string;
  productionProcessName: string;
  stageId: string;
  stageName: string;
  stageType: string;
  averageWeight: string;
  reference: string;
  animalType: string;
  databaseFoundation: string;
  outputs: string[];
  benchmark: boolean;
}

export interface BeefFarms {
  farmId: string;
  farmName: string;
  productionProcessId: string;
  productionProcessName: string;
  stageId: string;
  stageName: string;
  stageType: string;
  averageWeight: string;
  reference: string;
  animalType: string;
  databaseFoundation: string;
  benchmark: boolean;
}

export interface DairyV2Farms {
  farmId: string;
  farmName: string;
  productionProcessId: string;
  productionProcessName: string;
  stageId: string;
  stageName: string;
  stageType: string;
  averageWeight: string;
  reference: string;
  animalType: string;
  databaseFoundation: string;
  benchmark: boolean;
}

export interface MMSSystemsBlockProps {
  formType: FormType;
  stageIndex: number;
  itemIndex: number;
  manureManagementSystems?: Array<ListEntry>;
  filteredMMSList?: Array<ListEntry>;
  removeHandler?: Maybe<Function>;
  mmsChangeHandler?: Maybe<Function>;
  isAdditions?: boolean;
}

// Leave any for now
/** Common props for stages component */
export interface BaselineStageProps {
  stageIndex: number;
  baseline: any;
  getCompleteness: (
    getCurrentValues: (arg: string | string[]) => object | unknown,
    fieldGroup: string | string[],
    onlyLiveweight?: boolean
  ) => number;
  manureManagementSystems: any;
  singleIngredients: any;
  compoundFeeds: any;
  item: any;
  formType: FormType;
  stageType: string;
}

export interface SelectEntry {
  display_name: string;
  value: string;
}

/** Common type for drop down lists options - compatibility with Dsm */

export interface ListEntry {
  text: string;
  value: string;
}

export interface FeedPerAnimalEntry {
  feedType: string; // TODO: make enum
  kgPerAnimal?: number | '';
}

// Form types - LH

export interface LayingHensForm {
  animalType: AnimalType;
  copyFrom?: string;
  info: {
    name: string;
    year: number | '';
    timeUnit: string; // TODO: create enum
    numOfCyclesYear: number | '';
    validFrom: Date | '';
    validTo: Date | '';
    description: string;
  };
  resourceUse: {
    electricityUse: number | '';
    dieselUse: number | '';
    gasUse: number | '';
    waterUse: number | '';
  };
  stages: LHStage[] | [];
}

export interface LHStage {
  farmId: string;
  name: string;
  id: string;
  productionSystem: string; // TODO: create enum
  stageData: LHStageData;
}

export interface PigStage {
  farmId: string;
  name: string;
  id: string;
  productionSystem: string; // TODO: create enum
  stageData: LHStageData;
}

export interface LHStageData {
  output: LayingHensOutput;
  input: LHInput;
  manure: LHManure;
  feed: LHFeed;
  emissions: LayingHensEmmisions;
}

export interface LHInput {
  purchasedAnimalsLayingHens?: number | '';
  mortalityRate?: number | '';
  averageLiveWeightMortalities?: number | '';
}

export interface LHManure {
  averageAnimalsPresent?: number | '';
  manureManagementSystem?: number | '';
  solidManureStorage?: number | '';
  slurryManureStorage?: number | '';
  ashContent?: number | '';
  strawForBedding?: number | '';
  sawDust?: number | '';
}

export interface LHFeed {
  totalFeedIntake: number | '';
  digestibility: number | '';
  nitrogenContent: number | '';
  dryMatterContent: number | '';
  compoundFeeds: FeedPerAnimalEntry[] | [];
  singleIngredients: FeedPerAnimalEntry[] | [];
}

export interface LayingHensEmmisions {
  methane?: string | number;
  nitrousOxideDirect?: string | number;
  nitrousOxideIndirect?: string | number;
  amonia?: string | number;
  nitricOxide?: string | number;
  nonMethaneVolatile?: string | number;
  PM10?: string | number;
  PM25?: string | number;
  totalSuspendedParticles?: string | number;
}

export interface LayingHensOutput {
  eggs?: string | number;
  liveWeight?: string | number;
}

export const isReadOnlyAnimalSystem = (animalType: AnimalType) => {
  return !(
    isPig(animalType) ||
    isMarineFish(animalType) ||
    isPoultry(animalType) ||
    isShrimp(animalType) ||
    isBeef(animalType) ||
    isDairy(animalType)
  );
};

export const isVersionV2AnimalSystem = (animalType: AnimalType) => {
  return (
    isPig(animalType) ||
    isMarineFish(animalType) ||
    isPoultry(animalType) ||
    isShrimp(animalType) ||
    isBeef(animalType) ||
    isDairy(animalType)
  );
};

export const getVersionOfAnimalSystem = (animalType: AnimalType) => {
  return isPig(animalType) || 
    isMarineFish(animalType) || 
    isPoultry(animalType) || 
    isShrimp(animalType) ||
    isBeef(animalType) ||
    isDairy(animalType)
    ? ' 2.0'
    : ' 1.0';
};
