import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "../../../../modules/UserProfile/profileStyles/myProfileStyles";
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import DocumentCard from "./DocumentCard";
import { helpCenterStyles } from "../../../../../_metronic/layout";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GettingStarted : React.FC = () => {
  const classes = useStyles();
  const specificClasses = helpCenterStyles();
  const intl = useIntl();

  const DairyDocuments = [
    { name: 'SUSTELL_Onboarding Handbook_Dairy_V1.0.pdf', size: '15409 KB' }
  ];

  const BeefDocuments = [
    { name: 'SUSTELL_Onboarding Handbook_Beef.pdf', size: '15503 KB' }
  ];

  const ShrimpDocuments = [
    { name: 'SUSTELL_Onboarding Handbook_Shrimp_v1.0.pdf', size: '6435 KB' }
  ];

  const PoultryDocuments = [
    { name: 'Sustell poultry module update description.pdf', size: '158 KB' },
    { name: 'SUSTELL_Onboarding Handbook_Poultry_V1.0.pdf', size: '17427 KB' }
  ];

  const PigDocuments = [
    { name: 'Sustell pig module update description_July 2023.pdf', size: '150 KB' },
    { name: 'Sustell Pig Module Update Instructions_July 2023.pdf', size: '2716 KB' }
  ];

  const FishDocuments = [
    { name: 'Sustell marine fish module update description.pdf', size: '136 KB' },
    { name: 'SUSTELL_Onboarding Handbook_Marine Fish_v1.0.pdf', size: '10062 KB' }
  ];

  const DashboardDocuments = [
    { name: 'Sustell Business Insights Dashboard information v1.pdf', size: '1124 KB' }
  ];

  const ProxyDocuments = [
    { name: 'Sustell Automated Proxy Ingredient Selection.pdf', size: '1246 KB' }
  ]; 
  
  const ProcessingDocuments = [
    { name: 'SUSTELL Onboarding Handbook for Processing v1.pdf', size: '3505 KB' }
  ];

  return (
    <div className={classes.profileSubContainer}>
       <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.DAIRY' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {DairyDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
      <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.BEEF' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {BeefDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
      <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.SHRIMP' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {ShrimpDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
      <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.POULTRY' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {PoultryDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
      <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.PIG' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {PigDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
      <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.MARINE_FISH' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {FishDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
      <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.DASHBOARD' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {DashboardDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
      <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.PROXY_INGREDIENT_SELECTION' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {ProxyDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
      <Typography className={specificClasses.gettingStartedSectionTitle}>
        {intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED.PROCESSING' })}
      </Typography>
      <DsmGrid className={specificClasses.gettingStartedSectionGrid}>
        {ProcessingDocuments.map((eachDocument) => {
          return (<DocumentCard
            key={eachDocument.name}
            iconName="files/file-check-02"
            documentName={eachDocument.name}
            documentSize={eachDocument.size}
          ></DocumentCard>)
        })}
      </DsmGrid>
    </div>
  )};

export default GettingStarted;