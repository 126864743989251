import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid, DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { Box, Tooltip } from '@material-ui/core';
import { FormType } from '../types';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../_metronic/layout';
import { UserProfilePrefs } from '../../../../modules/Helpers/UserProfilePrefs';
import ReactHookDsmInput from '../../../../modules/Helpers/ReactHookDsmInput2';
import { CSSClassesList } from '../../../helpers/helperTypes';
import { defaultUnitsV2 as defaultMetric } from '../../../utils/unit-utils';
import lookupValues from '../../../helpers/lookupValues';
import { floatValueInUserUnits } from '../../../../modules/Farms/Baseline/mapper/inCommonMapperSustellV2';

interface PoultryNutritionProps {
  formType: FormType;
  targetSpecies: keyof typeof lookupValues.compoundFeedNutrientsDefault;
}

const PoultryNutrition: FC<PoultryNutritionProps> = ({
  formType,
  targetSpecies,
}) => {
  const intl = useIntl();
  const formContext = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  const classes: CSSClassesList = processAndStageStylesV2();

  const toCurrentEnergyUnits = (
    value?: number | string | null
  ): number | string | undefined => {
    if (value === null || value === undefined || value === '') return '';

    let numVal: number;
    if (typeof value === 'string') numVal = parseFloat(value);
    else numVal = value;

    // if ther is a change in units, do conversion
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      defaultMetric.unitNutritionalGrossEnergy !==
        userUOM.unitNutritionalGrossEnergy ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      defaultMetric.unitIngredientQuantityInputMass !==
        userUOM.unitIngredientQuantityInputMass
    )
      numVal = floatValueInUserUnits(
        numVal,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
        `${userUOM.unitNutritionalGrossEnergy}/${userUOM.unitIngredientQuantityInputMass}`,
        `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitIngredientQuantityInputMass}`
      ) as number;

    return numVal;
  };

  const energyUnit =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    userUOM?.unitIngredientQuantityInputMass &&
    userUOM?.unitNutritionalGrossEnergy
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        `${userUOM?.unitNutritionalGrossEnergy as string}/${
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          userUOM?.unitIngredientQuantityInputMass as string
        }`
      : `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitIngredientQuantityInputMass}`;

  const resetDefault = () => {
    formContext.setValue(
      'nutritional_analysis_data.dryMatterContent',
      lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
        ?.dryMatterContent
    );
    formContext.setValue(
      'nutritional_analysis_data.grossEnergyContent',
      toCurrentEnergyUnits(
        lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
          ?.grossEnergyContent
      )
    );
    formContext.setValue(
      'nutritional_analysis_data.ashContent',
      lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]?.ashContent
    );
    formContext.setValue(
      'nutritional_analysis_data.metabolizableEnergyContent',
      toCurrentEnergyUnits(
        lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
          ?.metabolizableEnergyContent
      )
    );
    formContext.setValue(
      'nutritional_analysis_data.pContent',
      lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]?.pContent
    );
  };

  return (
    <div>
      <DsmGrid className={classes.dsmGridThreeColumnNoRowSpacing}>
        <ReactHookDsmInput
          name="nutritional_analysis_data.crudeProteinContent"
          label={intl.formatMessage({
            id: 'COMPOUNDFEEDS.NUTRITION_LABEL.CRUDE_PROTEIN_CONTENT',
          })}
          type="number"
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          adornment="% of DM"
          size="medium"
          required
          tooltip={intl.formatMessage({
            id: 'COMPOUND_FEED.NUTRITION.CRUDE_PROTEIN_CONTENT.TOOLTIP',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={formContext.getValues(
            'nutritional_analysis_data.crudeProteinContent'
          )}
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.dryMatterContent"
          label={intl.formatMessage({
            id: 'COMPOUNDFEEDS.NUTRITION_LABEL.DRY_MATTER_CONTENT',
          })}
          type="number"
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          adornment="%"
          size="medium"
          tooltip={intl.formatMessage({
            id: 'COMPOUND_FEED.NUTRITION.DRY_MATTER_CONTENT.TOOLTIP',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={
            lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
              ?.dryMatterContent
          }
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.grossEnergyContent"
          label={intl.formatMessage({
            id: 'COMPOUNDFEEDS.NUTRITION_LABEL.GROSS_ENERGY_CONTENT',
          })}
          type="number"
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          adornment={`${energyUnit} DM`}
          size="medium"
          tooltip={intl.formatMessage({
            id: 'COMPOUND_FEED.NUTRITION.GROSS_ENERGY_CONTENT.TOOLTIP',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={toCurrentEnergyUnits(
            lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
              ?.grossEnergyContent
          )}
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.metabolizableEnergyContent"
          label={intl.formatMessage({
            id: 'COMPOUNDFEEDS.NUTRITION_LABEL.METABOLIZABLE_ENERGY',
          })}
          type="number"
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          adornment={`${energyUnit} DM`}
          size="medium"
          tooltip={intl.formatMessage({
            id: 'COMPOUND_FEED.NUTRITION.METABOLIZABLE_ENERGY.TOOLTIP',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={toCurrentEnergyUnits(
            lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
              ?.metabolizableEnergyContent
          )}
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.pContent"
          label={intl.formatMessage({
            id: 'COMPOUNDFEEDS.NUTRITION_LABEL.P_CONTENT',
          })}
          type="number"
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          adornment="g P/kg DM"
          size="medium"
          tooltip={intl.formatMessage({
            id: 'COMPOUND_FEED.NUTRITION.P_CONTENT.TOOLTIP',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={
            lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]?.pContent
          }
        />
        <ReactHookDsmInput
          name="nutritional_analysis_data.ashContent"
          label={intl.formatMessage({
            id: 'COMPOUNDFEEDS.NUTRITION_LABEL.ASH_CONTENT',
          })}
          type="number"
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          adornment="% of DM"
          size="medium"
          tooltip={intl.formatMessage({
            id: 'COMPOUND_FEED.NUTRITION.ASH_CONTENT.TOOLTIP',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          defaultValue={
            lookupValues.compoundFeedNutrientsDefault?.[targetSpecies]
              ?.ashContent
          }
        />
      </DsmGrid>
      <Box>
        <Box />
        <div className={classes.resetButtonContainer}>
          <DsmButton
            variant="text"
            style={{ float: 'right' }}
            onClick={() => resetDefault()}
            className="mt-3"
          >
            <DsmIcon slot="before" name="arrows/refresh-cw-01" />
            <u>
              {intl.formatMessage({
                id: 'COMPOUND_FEED.NUTRITIONAL_ANALYSIS.RESET_DEFAULT',
              })}
            </u>
          </DsmButton>
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
            })}
            placement="left"
            className='ml-3'
            style={{transform: 'translateY(85%)'}}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
      </Box>
    </div>
  );
};

export default PoultryNutrition;
